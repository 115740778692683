'use strict'

import React, { useState } from 'react'
import reactCSS from 'reactcss'
import { GithubPicker } from 'react-color'

const CustomReactColor = (props) => {
    const [displayColorPicker, setDisplayColorPicker] = useState(false);
    // const [color, setColor] = useState(props.primaryColor);
    const styles = reactCSS({
        'default': {
            color: {
                height: '24px',
                borderRadius: '4px',
                background: `${props.primaryColor}`,
            },
            swatch: {
                padding: '8px 9px',
                background: '#fff',
                borderRadius: '4px',
                display: 'inline-block',
                cursor: 'pointer',
            },
            popover: {
                position: 'absolute',
                zIndex: '2',
            },
            cover: {
                position: 'fixed',
                top: '0px',
                right: '0px',
                bottom: '0px',
                left: '0px',
            },
        },
    });
    const handleClick = () => {
        setDisplayColorPicker(!displayColorPicker);
    };

    const handleClose = () => {
        setDisplayColorPicker(false);
    };

    const handleChange = (color) => {
        // setColor(color.hex)
        props.colorChange(color.hex);

    };
    const $green = '#37D3EE';
    const $Grapefruit = '#FF5856';
    const $GrapePurple = '#5B1055';
    const $LilacGeode = '#B48CFC';
    const $BianchiGreen = '#38CBBD';
    const $WildPoppy = '#B75B5B';
    const $KobaiRed = '#D9576D';
    const $MandarinPeel = '#FF9C00';
    const $Abyss = '#909E9D';
    const $Perrywinkle = '#8C9AE8';
    const $DevilsGrass = '#4CAF50';
    const $LavenderPerceptions = '#C584E3';
    const $GloomyPurple = '#9057D9';
    const $BrookGreen = '#AFDBCE';
    const $HinduLotus = '#F25087';
    const $AdamantineBlue = '#4EAEFB';
    const $PacMan = '#FFEB3B';
    const $LakeThun = '#3FC8E7';
    const $OrchidEcstasy = '#B44685';
    const $Assault = '#18416F';
    const $BubblegumBabyGirl = '#D657EC';
    const $BlueDacnis = '#49D7E9';
    const $Marigold = '#FFC107';
    const $LustRed = '#E91E1E';
    const options = [
        $green,
        $Grapefruit,
        $GrapePurple,
        $LilacGeode,
        $BianchiGreen,
        $WildPoppy,
        $KobaiRed,
        $MandarinPeel,
        $Abyss,
        $Perrywinkle,
        $DevilsGrass,
        $LavenderPerceptions,
        $GloomyPurple,
        $BrookGreen,
        $HinduLotus,
        $AdamantineBlue,
        $PacMan,
        $LakeThun,
        $OrchidEcstasy,
        $Assault,
        $BubblegumBabyGirl,
        $BlueDacnis,
        $Marigold,
        $LustRed,
    ]
    return (
        <div>
            <div style={styles.swatch} className='mainColorBox' onClick={handleClick}>
                <div style={styles.color} className="innerBox" />
            </div>
            {displayColorPicker ?
                <div style={styles.popover} className='menuColorPicker'>
                    <div style={styles.cover} onClick={handleClose} />
                    <GithubPicker color={props.primaryColor} colors={options} onChange={(e) => { handleChange(e); setDisplayColorPicker(false); }} />
                </div> : null}

        </div>
    )
}
export default CustomReactColor;