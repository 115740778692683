export const GET_CONNECTOR_TYPES = 'GET_CONNECTOR_TYPES'
export const GET_CONNECTOR_TYPES_SUCCESS = 'GET_CONNECTOR_TYPES_SUCCESS'
export const GET_MODEL_TYPES = 'GET_MODEL_TYPES'
export const GET_MODEL_TYPES_SUCCESS = 'GET_MODEL_TYPES_SUCCESS'
export const ADD_EVSE = 'ADD_EVSE';
export const LIST_ALL_EVSE = 'LIST_ALL_EVSE'
export const LIST_ALL_EVSE_SUCCESS = 'LIST_ALL_EVSE_SUCCESS';
export const DETAIL_EVSE = 'DETAIL_EVSE';
export const DETAIL_EVSE_SUCCESS = 'DETAIL_EVSE_SUCCESS';
export const DETAIL_EVSE_CONNECTOR = 'DETAIL_EVSE_CONNECTOR';
export const DETAIL_EVSE_CONNECTOR_SUCCESS = 'DETAIL_EVSE_CONNECTOR_SUCCESS';
export const EMPTY_EVSE_CONNECTOR = 'EMPTY_EVSE_CONNECTOR';
export const GET_CONNECTORS_ICONS = 'GET_CONNECTOR_ICON';
export const GET_CONNECTORS_ICONS_SUCCESS = 'GET_CONNECTOR_ICON_SUCCESS';
export const GET_CONNECTOR_LIST = "GET_CONNECTOR_LIST";
export const GET_CONNECTOR_LIST_SUCCESS = "GET_CONNECTOR_LIST_SUCCESS";
export const ADD_CONNECTORS_TYPE_DATA = 'ADD_CONNECTOR_TYPE_DATA'
export const EDIT_CONNECTOR_TYPE = "EDIT_CONNECTOR_LIST";
export const DETAIL_CONNECTOR_TYPE = "DETAIL_CONNECTOR_LIST";
export const DETAIL_CONNECTOR_TYPE_SUCCESS = "DETAIL_CONNECTOR_LIST_SUCCESS";
export const EDIT_EVSE_MODEL = "EDIT_EVSE_MODELS";
export const DETAIL_EVSE_SINGLE_CONNECTOR = 'DETAIL_EVSE_SINGLE_CONNECTOR';
export const DETAIL_EVSE_SINGLE_CONNECTOR_SUCCESS = 'DETAIL_EVSE_SINGLE_CONNECTOR_SUCCESS';
export const GET_CONNECTORS_DETAILS = 'GET_CONNECTOR_ICON'
export const GET_CONNECTORS_DETAILS_SUCCESS = 'GET_CONNECTOR_ICON_SUCCESS';
export const EDIT_EVSE_CONNECTER = "EDIT_EVSE_CONNECTER";
export const TOGGLE_EVSE = 'TOGGLE_EVSE';
export const DELET_EVSE = 'DELET_EVSE';
export const TOGGLE_CONNECTOR = 'TOGGLE_CONNECTOR';
export const DELET_CONNECTOR = 'DELET_CONNECTOR';















