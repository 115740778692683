export const ALL_BUSINESS_LIST = "ALL_BUSINESS_LIST"
export const BUSINESS_LIST_SUCCESS = "BUSINESS_LIST_SUCCESS"
export const BUSINESS_LIST_FAILURE = "BUSINESS_LIST_FAILURE"
export const ADD_NEW_BUSINESS = 'ADD_NEW_BUSINESS'
export const GET_BUSINESS_DETAIL = 'GET_BUSINESS_DETAIL'
export const BUSINESS_DETAIL_SUCCESS = 'BUSINESS_DETAIL_SUCCESS'
export const EDIT_BUSINESS = 'EDIT_BUSINESS'
export const GET_MATCH_EMAIL = 'GET_MATCH_EMAIL'
export const GET_MATCH_EMAIL_SUCC = 'GET_MATCH_EMAIL_SUCC'
export const BUSINESS_RESEND_INVITE = 'BUSINESS_RESEND_INVITE'
export const GET_BUSINESS_LOGO = 'GET_BUSINESS_LOGO'
export const DELETE_BUSINESS = "DELETE_BUSINESS";
export const ACTIVATE_DEACTIVATE_BUSINESS = "ACTIVATE_DEACTIVATE_BUSINESS";
