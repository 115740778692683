import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Toast } from 'react-bootstrap';
import { hideToast } from '../../store/toast/action';
import '../../assets/scss/components/_ToastComponent.scss';
import { Button, UncontrolledAlert } from 'reactstrap';

const ToastComponent = (props) => {
    const dispatch = useDispatch();
    const [showToast, setShowToast] = useState(true);

    const { toast: { type, message, show } } = useSelector(state => ({
        toast: state.Toast.toast
    }));
    
    useEffect(() => {
        setShowToast(show);
    }, [show]);

    let toastClass;
    if (type === 'success') {
        toastClass = 'success-txt';
    } else if (type === 'error') {
        toastClass = 'error-txt';
    } else {
        toastClass = '';
    }
    return (
        message !== '' && <><Toast onClose={() => dispatch(hideToast())} className={toastClass} show={showToast} delay={3000} autohide>
            <div className="tostcenter">
                <div className='leftIcon'>
                    {type === 'success' &&
                        <i className='icon bx bx-check-circle'></i>}
                    {type === 'error' &&
                        <i className='bx bx-x-circle' />}
                    <p className='tostMassage'>{message}</p>
                </div>
                <div className='rightContent'>
                    <Button
                    onClick={() => dispatch(hideToast(true))}
                    >
                        <i className='bx bx-x'></i>
                    </Button>
                </div>
            </div>
        </Toast>
        </>
    );
};

export default ToastComponent;
