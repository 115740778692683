import {
	API_RESPONSE_SUCCESS,
	API_RESPONSE_SUCCESS_DATA,
	API_RESPONSE_ERROR,
	GET_PROJECT_CHARTS_DATA,
	GET_PROJECTSTATUS_CHARTS_DATA,
	GET_SUPERADMIN_CUSTOMER,
	GET_SUPERADMIN_CUSTOMER_SUCCESS,
	GET_SUPERADMIN_CUSTOMER_FAILURE,
	GET_BUSINESS_LOCATION,
	GET_BUSINESS_LOCATION_SUCCESS,
	GET_BUSINESS_LOCATION_FAILURE,
	GET_BUSINESS_LOCATION_DOWNLOAD,
	GET_BUSINESS_LOCATION_DOWNLOAD_SUCCESS,
	GET_LOCATION_MANAGER_DOWNLOAD_DATA,
	GET_LOCATION_MANAGER_DOWNLOAD_DATA_SUCCESS,
	GET_LOCATION_MANAGER_DATA,
	GET_SUPERADMIN_DASHBOARD_YEAR,
	GET_SUPERADMIN_DASHBOARD_YEAR_SUCCESS,
	GET_LOCATION_MANAGER_DATA_SUCCESS,
	GET_LOCATION_MANAGER_DATA_FAILURE,
	GET_SUPERADMIN_BUSINESS_TRANSACTION,
	GET_SUPERADMIN_BUSINESS_TRANSACTION_SUCCESS,
	GET_HEATMAP_DATA,
	GET_HEATMAP_DATA_SUCCESS,
	SUPER_ADMIN_DASHBOARD_CUSTOMERS_DOWNLOAD,
	SUPER_ADMIN_DASHBOARD_CUSTOMERS_DOWNLOAD_SUCCESS
} from "./actionType";

// common success
export const dashboardDataSuccess = () => ({
	type: API_RESPONSE_SUCCESS,
	payload: {},
});

// common error
export const dashboardSuccessData = (data) => ({
	type: API_RESPONSE_SUCCESS_DATA,
	payload: { data },
});

// Project Overview
export const getProjectChartsData = (projectData) => ({
	type: GET_PROJECT_CHARTS_DATA,
	payload: projectData,
});

// Project Status
export const getProjectStatusChartsData = (projectStatusData) => ({
	type: GET_PROJECTSTATUS_CHARTS_DATA,
	payload: projectStatusData,
});

export const getSuperAdminCustomer = (query) => ({
	type: GET_SUPERADMIN_CUSTOMER,
	payload: query,
});
export const getSuperAdminCustomerSuccess = (data) => ({
	type: GET_SUPERADMIN_CUSTOMER_SUCCESS,
	payload: data,
});
export const getSuperAdminCustomerFailure = (error) => ({
	type: GET_SUPERADMIN_CUSTOMER_FAILURE,
	payload: error,
});

export const getBusinessLocation = (query) => ({
	type: GET_BUSINESS_LOCATION,
	payload: query,
});
export const getBusinessLocationSuccess = (data) => ({
	type: GET_BUSINESS_LOCATION_SUCCESS,
	payload: data,
});
export const getBusinessLocationFailure = (error) => ({
	type: GET_BUSINESS_LOCATION_FAILURE,
	payload: error,
});

export const getLocationManager = (query) => ({
	type: GET_LOCATION_MANAGER_DATA,
	payload: query,
});
export const getLocationManagerSuccess = (data) => ({
	type: GET_LOCATION_MANAGER_DATA_SUCCESS,
	payload: data,
});
export const getLocationManagerFailure = (error) => ({
	type: GET_LOCATION_MANAGER_DATA_FAILURE,
	payload: error,
});

export const getSuperAdminDashboardYear = () => ({
	type: GET_SUPERADMIN_DASHBOARD_YEAR,
	payload: {},
});

export const getSuperAdminDashboardYearSuccess = (data) => ({
	type: GET_SUPERADMIN_DASHBOARD_YEAR_SUCCESS,
	payload: data,
});



export const getBusinessTransactionSA = (data) => ({
	type: GET_SUPERADMIN_BUSINESS_TRANSACTION,
	payload: data,
});

export const getBusinessTransactionSASucsess = (data) => ({
	type: GET_SUPERADMIN_BUSINESS_TRANSACTION_SUCCESS,
	payload: data,
});




export const getHeatmaptData = (data) => ({
	type: GET_HEATMAP_DATA,
	payload: data,
});

export const getHeatmaptDataSucsess = (data) => ({
	type: GET_HEATMAP_DATA_SUCCESS,
	payload: data,
});


export const getSuperAdminCustomerDownload = (query) => ({
	type: SUPER_ADMIN_DASHBOARD_CUSTOMERS_DOWNLOAD,
	payload: query,
});
export const getSuperAdminCustomerSuccessDownload = (data) => ({
	type: SUPER_ADMIN_DASHBOARD_CUSTOMERS_DOWNLOAD_SUCCESS,
	payload: data,
});



export const getBusinessLocationDownload = (query) => ({
	type: GET_BUSINESS_LOCATION_DOWNLOAD,
	payload: query,
});
export const getBusinessLocationDownloadSuccess = (data) => ({
	type: GET_BUSINESS_LOCATION_DOWNLOAD_SUCCESS,
	payload: data,
});


export const getLocationManagerDownload = (query) => ({
	type: GET_LOCATION_MANAGER_DOWNLOAD_DATA,
	payload: query,
});
export const getLocationManagerDownloadSuccess = (data) => ({
	type: GET_LOCATION_MANAGER_DOWNLOAD_DATA_SUCCESS,
	payload: data,
});
