import { call, put, takeEvery, all, fork } from "redux-saga/effects";

// Crypto Redux States
import {
	GET_PROJECT_CHARTS_DATA,
	GET_PROJECTSTATUS_CHARTS_DATA,
	API_RESPONSE_SUCCESS,
	GET_SUPERADMIN_CUSTOMER,
	SUPER_ADMIN_DASHBOARD_CUSTOMERS_DOWNLOAD,
	GET_BUSINESS_LOCATION,
	GET_BUSINESS_LOCATION_DOWNLOAD,
	GET_LOCATION_MANAGER_DATA,
	GET_LOCATION_MANAGER_DOWNLOAD_DATA,
	GET_SUPERADMIN_BUSINESS_TRANSACTION,
	GET_HEATMAP_DATA,
	GET_SUPERADMIN_DASHBOARD_YEAR
} from "./actionType";
import {
	dashboardDataSuccess,
	dashboardProjectApiError,
	dashboardSuccessData,
	getSuperAdminCustomerSuccess,
	getSuperAdminDashboardYearSuccess,
	getBusinessTransactionSASucsess,
	getHeatmaptDataSucsess,
	getSuperAdminCustomerSuccessDownload,
	getBusinessLocationDownloadSuccess,
	getSuperAdminCustomerFailure,
	getBusinessLocationFailure,
	getBusinessLocationSuccess,
	getLocationManagerSuccess,
	getLocationManagerDownloadSuccess,
	getLocationManagerFailure,
} from "./action";

import {
	businessOwnerBusinessLocations,
	businessOwnerBusinessLocationsDownload,
	dashboardData,
	stationDetailsForLocationManager,
	stationDetailsForLocationManagerDownload,
	SuperAdminBusinessYear,
	BusinessTransactionSA,
	superAdminDashboardCustomersDownload,
	HeatMapData,
	superAdminDashboardCustomers,
} from "../../helpers/Auth";
import { showLoader, showToast } from "../actions";

function* listDashboardData({ payload: { } }) {
	try {
		const response = yield call(dashboardData);
		if (response) {
			yield put(dashboardSuccessData(response.payload));
		}
	} catch (e) {
		console.log(e);
		yield put(
			showToast({
				message: e?.response?.data?.message, //'Account verified successfully.',
				type: "error",
			})
		);
	}
}

function* getSuperAdminCustomers({ payload }) {
	yield put(showLoader(true));
	try {
		const response = yield call(superAdminDashboardCustomers, payload);
		if (response) {
			yield put(getSuperAdminCustomerSuccess(response.payload));
			yield put(showLoader(false));
		}
	} catch (e) {
		yield put(
			showToast({
				message: e?.response?.data?.message, //'Account verified successfully.',
				type: "error",
			})
		);
		yield put(getSuperAdminCustomerFailure(e?.response?.data?.message));
		yield put(showLoader(false));
	}
}

function* getBusinessLocation({ payload }) {
	yield put(showLoader(true));
	try {
		const response = yield call(businessOwnerBusinessLocations, payload);
		if (response) {
			yield put(getBusinessLocationSuccess(response.payload));
			yield put(showLoader(false));
		}
	} catch (e) {
		yield put(
			showToast({
				message: e?.response?.data?.message, //'Account verified successfully.',
				type: "error",
			})
		);
		yield put(getBusinessLocationFailure(e?.response?.data?.message));
		yield put(showLoader(false));
	}
}

function* getLocationManager({ payload }) {
	yield put(showLoader(true));
	try {
		const response = yield call(stationDetailsForLocationManager, payload);
		if (response) {
			yield put(getLocationManagerSuccess(response.payload));
			yield put(showLoader(false));
		}
	} catch (e) {
		yield put(
			showToast({
				message: e?.response?.data?.message, //'Account verified successfully.',
				type: "error",
			})
		);
		yield put(getLocationManagerFailure(e?.response?.data?.message));
		yield put(showLoader(false));
	}
}
// function* getProjectStatusChartsData({ payload: data }) {
//   try {
//     var response;
//     if (data === "all") {
//       response = yield call(getAllProjectStatusData, data);
//     }
//     if (data === "week") {
//       response = yield call(getWeekProjectStatusData, data);
//     }
//     if (data === "month") {
//       response = yield call(getMonthProjectStatusData, data);
//     }
//     if (data === "quarter") {
//       response = yield call(getQuarterProjectStatusData, data);
//     }
//     yield put(dashboardProjectApiSuccess(GET_PROJECTSTATUS_CHARTS_DATA, response));
//   } catch (error) {
//     yield put(dashboardProjectApiError(GET_PROJECTSTATUS_CHARTS_DATA, error));
//   }
// }

function* getSuperAdminBusinessYear({ payload }) {
	yield put(showLoader(true));
	try {
		const response = yield call(SuperAdminBusinessYear, payload);
		if (response) {
			yield put(getSuperAdminDashboardYearSuccess(response.payload));
			yield put(showLoader(false));
		}
	} catch (e) {
		yield put(showLoader(false));
	}
}

function* getbusinessTransactionFunc(data) {
	try {
		const response = yield call(BusinessTransactionSA, data?.payload)
		if (response) {
			yield put(getBusinessTransactionSASucsess(response.payload));
		}
	} catch (error) {
		yield put(showLoader(false));
	}
}


function* getheatMapdataFunc(data) {
	try {
		const response = yield call(HeatMapData, data?.payload)
		if (response) {
			yield put(getHeatmaptDataSucsess(response.payload));
		}
	} catch (error) {
		yield put(showLoader(false));
	}
}

function* getSuperAdminCustomersDownload({ payload }) {
	yield put(showLoader(true));
	try {
		const response = yield call(superAdminDashboardCustomersDownload, payload);
		if (response) {
			yield put(getSuperAdminCustomerSuccessDownload(response.payload));
			yield put(showLoader(false));
		}
	} catch (e) {
		yield put(showLoader(false));
	}
}



function* getBusinessLocationDownload({ payload }) {
	yield put(showLoader(true));
	try {
		const response = yield call(businessOwnerBusinessLocationsDownload, payload);
		if (response) {
			yield put(getBusinessLocationDownloadSuccess(response.payload));
			yield put(showLoader(false));
		}
	} catch (e) {
		yield put(showLoader(false));

	}
}


function* getLocationManagerDownload({ payload }) {
	yield put(showLoader(true));
	try {
		const response = yield call(stationDetailsForLocationManagerDownload, payload);
		if (response) {
			yield put(getLocationManagerDownloadSuccess(response.payload));
			yield put(showLoader(false));
		}
	} catch (e) {
		yield put(
			showToast({
				message: e?.response?.data?.message, //'Account verified successfully.',
				type: "error",
			})
		);
		yield put(getLocationManagerFailure(e?.response?.data?.message));
		yield put(showLoader(false));
	}
}

export function* watchGetProjectChartsData() {
	yield takeEvery(API_RESPONSE_SUCCESS, listDashboardData);
	yield takeEvery(GET_SUPERADMIN_CUSTOMER, getSuperAdminCustomers);
	yield takeEvery(GET_SUPERADMIN_DASHBOARD_YEAR, getSuperAdminBusinessYear);
	yield takeEvery(GET_BUSINESS_LOCATION, getBusinessLocation);
	yield takeEvery(GET_LOCATION_MANAGER_DATA, getLocationManager);
	yield takeEvery(GET_SUPERADMIN_BUSINESS_TRANSACTION, getbusinessTransactionFunc);
	yield takeEvery(GET_HEATMAP_DATA, getheatMapdataFunc);
	yield takeEvery(SUPER_ADMIN_DASHBOARD_CUSTOMERS_DOWNLOAD, getSuperAdminCustomersDownload);
	yield takeEvery(GET_BUSINESS_LOCATION_DOWNLOAD, getBusinessLocationDownload);
	yield takeEvery(GET_LOCATION_MANAGER_DOWNLOAD_DATA, getLocationManagerDownload);
}

function* dashboardProjectSaga() {
	yield all([fork(watchGetProjectChartsData)]);
}

export default dashboardProjectSaga;
