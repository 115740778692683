import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Input, Label, Modal, ModalBody, ModalHeader, Row, } from 'reactstrap';
import DeleteModal from '../../../Components/Common/DeleteModal'
import QRCode from "qrcode.react";
import { Grid, _ } from 'gridjs-react';
import moment from "moment";
//import Components
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import DisableModal from '../../../Components/Common/DisableModal';
import { useDispatch, useSelector } from 'react-redux';
import { showToast } from '../../../store/actions';
import TableContainer from '../../../Components/Common/TableContainer';
import {
    LocationDetail,
    listAllStation,
    activateDeactivateLocationAction,
    deleteLocationAction

} from "../../../store/actions";
import { getUserRole, getValueFromObject } from '../../../helpers/helper.service';
import CustomDropdown from '../../../Components/Common/CustomDropdown';
import OutsideClickHandler from 'react-outside-click-handler';
import useWindowDimension from '../../../Components/Hooks/useWindowDimension';
import ContentLoader from '../../../Components/Common/ContentLoader';
import { decryptResponseData } from '../../../helpers/encryptDecrypt'

const ViewLocation = () => {
    document.title = "View location | Vidhyutam";
    const history = useHistory();
    const { state } = useLocation();
    const dispatch = useDispatch()
    const [qrModel, setQrModel] = useState();
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteModalUser, setdeleteModalUser] = useState(false)
    const [disabledModal, setDisabledModal] = useState(false);
    const [enableModal, setEnableModal] = useState(false);
    const [disabledStationModal, setdisabledStationModal] = useState(false);
    const [enableStationModal, setenableStationModal] = useState(false);
    const [qrValue, setQrValue] = useState("jeftar");
    const [isChecked, setIsChecked] = useState(false);
    const [selectValue, setSelectValue] = useState();
    const [locationData, setLocationData] = useState('');
    const [timerDetail, setTimerDetail] = useState([]);
    const [timerDetailFull, setTimerDetailFull] = useState([]);
    const [serviceTime, setServiceTime] = useState('')
    const [timeModal, setTimeModal] = useState(false);
    const [filterModal, setFilterModal] = useState(false);
    const [noOfConn, setNoOfConn] = useState('');
    const [noOfStation, setNoOfStation] = useState('');
    const [StationLoader, SetStationLoader] = useState(false);
    const [locationDetail, setLocationDetail] = useState([
        {
            _id: "1",
            stationName: 'Station-1',
            type: 'Private',
            location: '2',
            business: 'Titania Pvt Ltd',
            connectors: '5',
            version: "1.0",
            model: 'IC005',
            status: 'Online'
        },
        {
            _id: "2",
            stationName: 'Station-2',
            type: 'Public',
            location: '2',
            business: 'Titania Pvt Ltd',
            connectors: '6',
            version: "2.0",
            model: 'IC006',
            status: 'Offline'
        }
    ])

    const dimensions = useWindowDimension();
    const [headerHeight, SetHeaderHeight] = useState(0);
    const [breadCrumb, setBreadCrumbHeight] = useState(0);
    const [footerHeight, SetFooterHeight] = useState(0);
    const [tableTaskBar, setTableTaskBar] = useState(0);
    const [paginationDiv, setPaginationDiv] = useState(0);
    const [filteredTable, setfilteredTable] = useState([]);
    const [allListLocation, setAllListLocation] = useState([]);

    // handle filtered table counting
    const handleFilterCount = () => {
        let count = 0;
        filteredTable.forEach((item) => {
            count = count + item.original.nConnectors;
        })
        // setNoOfConn(count)

    }

    useEffect(() => {



    }, [allListLocation])


    // redirect back to listing if no id is found in case of manual copying url from previos tab
    useLayoutEffect(() => {
        if (state == undefined) {
            history.push('/location')
        }
    }, [])

    useEffect(() => {
        handleFilterCount();
    }, [filteredTable])


    useEffect(() => {
        SetHeaderHeight(
            document.getElementsByClassName('navbar-header')[0]?.offsetHeight
        );
        SetFooterHeight(
            document.getElementsByClassName('footer')[0]?.offsetHeight
        );
        setBreadCrumbHeight(
            document.getElementsByClassName('breadCrumb')[0]?.offsetHeight
        );
        setTableTaskBar(
            document.getElementsByClassName('tableTaskBar')[0]?.offsetHeight
        );
        setPaginationDiv(
            document.getElementsByClassName('paginationDiv')[0]?.offsetHeight
        );
    }, []);

    const handleResize = () => {

        SetHeaderHeight(
            document.getElementsByClassName('navbar-header')[0]?.offsetHeight
        );
        SetFooterHeight(
            document.getElementsByClassName('footer')[0]?.offsetHeight
        );
        setBreadCrumbHeight(
            document.getElementsByClassName('breadCrumb')[0]?.offsetHeight
        );
        setTableTaskBar(
            document.getElementsByClassName('tableTaskBar')[0]?.offsetHeight
        );
        setPaginationDiv(
            document.getElementsByClassName('paginationDiv')[0]?.offsetHeight
        );
    };

    useEffect(() => {
        setTimeout(() => {
            SetStationLoader(true)
        }, 2000)
    }, []);

    useEffect(() => {
        window.addEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (state !== undefined) {
            const locationId = state?.state?.value
            dispatch(LocationDetail(locationId));
            dispatch(listAllStation(`?locationId=${locationId}`));
        }
    }, []);

    const { locationDetails, stationDetail, businessDetails, socketData } = useSelector(state => ({
        locationDetails: state?.locationsList?.LocationDetail[0],
        stationDetail: state?.ManageStations?.listAllStation,
        socketData: JSON.parse(window.localStorage.getItem('socketHeart'))

        // businessDetails: state?.locationsList?.LocationDetail?.business
    }));
    // map station status from web socket
    useEffect(() => {

        if (socketData !== undefined) {
            // setAllListLocation(prev => {
            //     return prev.map(location => {
            //         const isPresent = socketData.filter(data => {
            //             return data.station == location.stationName
            //         })
            //         if (isPresent.length > 0) {
            //             return { ...location, connectorStatus: isPresent.some(item => item.statusNotification.status !== 'Unavailable') ? 'Available' : 'Unavailable' }
            //         } else {
            //             return { ...location, connectorStatus: 'Unavailable' }
            //         }
            //     })
            // })
            if (socketData && Array.isArray(socketData)) {

                let a = stationDetail.map(row => ({
                    ...row,
                    businessName: decryptResponseData(row.businessName),
                }));
                if (socketData && Array.isArray(socketData)) {
                    setAllListLocation(a.map(location => {
                        const status = JSON.parse(window.localStorage.getItem('socketHeart'))?.find(hb => hb.chargerId === location.stationName && hb.updateResp === 'Accepted');
                        // location['connectorStatus'] = matchingHeartbeat ? 'Available' : 'Unavailable';
                        // return { ...location };
                        location['connectorStatus'] = status ? 'Available' : 'Unavailable'
                        return {
                            ...location
                        }
                    }));
                }

                // if (socketData && Array.isArray(socketData)) {
                //     setAllListLocation(a.map(location => {

                //         const status = connectors.some(conn => socketData[conn] !== undefined && socketData[conn] !== 'Unavailable' && conn.includes(location.stationName));

                //         console.log(status, 'status');

                //         location['connectorStatus'] = status ? 'Available' : 'Unavailable'
                //         return {
                //             ...location
                //         }
                //     }))
                // }


            }
            // setAllListLocation(stationDetail.map(location => {
            //     const connectors = Object.keys(socketData);
            //     const status = connectors.some(conn => socketData[conn] !== undefined && socketData[conn] !== 'Unavailable' && conn.includes(location.stationName));
            //     return {
            //         ...location, connectorStatus: status ? 'Available' : 'Unavailable'
            //     }
            // })
            // )
        }
    }, [socketData, stationDetail])

    useEffect(() => {
        if (stationDetail.length > 0 && socketData == undefined) {
            const newdata = stationDetail.map(i => {
                return { ...i, connectorStatus: 'Unavailable' }
            })

            setAllListLocation(newdata);
        } else if (stationDetail.length < 1 && socketData == undefined) {
            setAllListLocation([]);
        }
    }, [stationDetail])

    useEffect(() => {
        if (locationDetails?.timings) {
            const getDay = new Date().getDay();
            setTimerDetail(JSON.parse(locationDetails?.timings)?.filter(e => e.id == getDay));



            const LocServiceTime = JSON.parse(locationDetails?.timings)?.map((evt, ind) => {

                return {
                    id: `${evt.id}`,
                    value: evt.dayCheck ? evt.fullDayCheck ? `${evt?.day}` : `${evt?.day}` : `${evt?.day}`,
                    secondValue: evt.dayCheck ? evt.fullDayCheck ? `24 Hours` : (evt?.startTime !== '' || evt?.endTime !== '') ? (`${moment(evt?.startTime).format('hh:mm a')} - ${moment(evt?.endTime).format('hh:mm a')}`) : `Closed` : `Closed`
                }
            })
            setTimerDetailFull(LocServiceTime);

        }
    }, [locationDetails])

    useEffect(() => {
        if (stationDetail.length > 0) {
            const numOfConn = stationDetail?.map((e, i) => e.nConnectors).reduce((Acc, e) => Acc + e, 0);
            setNoOfConn(numOfConn);

            // const numOfStat = stationDetail?.map((e, i) => e.nStations).reduce((Acc, e) => Acc + e, 0);
            // setNoOfStation(numOfStat);
        }
    }, [stationDetail])

    const handleDeleteContact = () => {
        dispatch(
            deleteLocationAction(`?locationId=${locationDetails?.locationId}`)
        );
        if (stationDetail.length === 0) {
            history.push("/location");
        } else {

            setTimeout(() => {
                if (state !== undefined) {
                    const locationId = state?.state?.value;
                    dispatch(LocationDetail(locationId));
                    dispatch(listAllStation(`?locationId=${locationId}`));
                }
            }, 1000);
        }

        setDeleteModal(false);
    };
    const handleEnableContact = () => {
        setIsChecked(!isChecked);
        dispatch(
            activateDeactivateLocationAction(
                `?locationId=${locationDetails?.locationId}&status=Activate`
            )
        );


        setTimeout(() => {
            if (state !== undefined) {
                const locationId = state?.state?.value;
                dispatch(LocationDetail(locationId));
                dispatch(listAllStation(`?locationId=${locationId}`));
            }
        }, 1000);
        setEnableModal(false);
    };
    const handleEnableStationContact = () => {
        toggleData();
        dispatch(showToast({
            message: 'station has been enabled successfully.',
            type: 'success'
        }));
        setenableStationModal(false);
    };
    const handleDisableStationContact = () => {
        toggleData();
        dispatch(showToast({
            message: 'station has been disabled successfully.',
            type: 'success'
        }));
        setdisabledStationModal(false);
    };
    const toggleData = () => {
        const temp = [...locationDetail]
        temp?.map((e, index) => {
            if (e._id === locationData._id) {
                temp[index].checked = !locationData.checked
            }

        })
        setLocationDetail([...temp])
    };
    const handleDisableContact = () => {
        setIsChecked(!isChecked);
        dispatch(
            activateDeactivateLocationAction(
                `?locationId=${locationDetails?.locationId}&status=Deactivate`
            )
        );
        setTimeout(() => {
            if (state !== undefined) {
                const locationId = state?.state?.value;
                dispatch(LocationDetail(locationId));
                dispatch(listAllStation(`?locationId=${locationId}`));
            }
        }, 1000);
        setDisabledModal(false);
    };
    const handleAddButtonClicks = () => {
        history.push('/stations/addstation', {
            state: {
                type: 'VIEW',
                id: locationDetails?.locationId
            }
        });
    }
    const handleViewDetailClick = (e) => {
        //(e);
        history.push('/stations/viewstation', {
            state: {
                stationId: e.original?.stationId,
                EvseID: e.original?.EVSEId,
            }
        });
    }

    // const serviceTimeItem1 = () => {
    //     const timeFormat = timerDetail
    //         id: .id,
    //         value: timerDetail
    //     }
    // }

    const serviceTimeItem = [
        {
            id: '1',
            value: 'Monday  10:00 am - 05:00 pm'
        },
        {
            id: '2',
            value: 'Tuesday  10:00 am - 05:00 pm'
        },
        {
            id: '3',
            value: 'Wednesday  10:00 am - 05:00 pm'
        },
        {
            id: '4',
            value: 'Thursday  10:00 am - 05:00 pm'
        },
        {
            id: '5',
            value: 'Friday  10:00 am - 05:00 pm'
        },
        {
            id: '6',
            value: 'Saturday  10:00 am - 05:00 pm'
        },
        {
            id: '7',
            value: 'Sunday  10:00 am - 05:00 pm'
        },
    ]

    const handleStationNameSort = React.useMemo(() => {
        return (rowA, rowB, columnId, desc) => {
            return rowA.values.stationName.toLowerCase() > rowB.values.stationName.toLowerCase() ? 1 : -1;
        }
    }, [])

    const handleBusinessNameSort = React.useMemo(() => {
        return (rowA, rowB, columnId, desc) => {
            return rowA.values.businessName.toLowerCase() > rowB.values.businessName.toLowerCase() ? 1 : -1;
        }
    }, [])


    // Column
    const columns1 = useMemo(
        () => [
            {
                Header: "Station Name",
                accessor: "stationName",
                filterable: true,
                sortType: handleStationNameSort,
                Cell: (cell) => {
                    return <p className='text textOverflow'>{cell.value}</p>;
                },
            },
            {
                Header: "Type",
                accessor: "stationType",
                filterable: true,
                Cell: (type) => {
                    return (
                        <div>
                            <p className={type.value === 'Private' ? 'text blueTxt' : 'text'}>{type.value}</p>
                        </div>
                    )
                },
            },
            {
                Header: "Business",
                accessor: "businessName",
                filterable: true,
                sortType: handleBusinessNameSort,
                Cell: (cell) => {
                    return <p className='text textOverflow'>{cell.value}</p>;
                },
            },
            {
                Header: `Connectors (${noOfConn ? noOfConn : '0'})`,
                accessor: "nConnectors",
                filterable: true,
                Cell: (cell) => {
                    return <p className='text textOverflow'>{cell.value}</p>;
                },
            },
            {
                Header: "Firmware Version",
                accessor: "version",
                filterable: true,
                disableSortBy: true,
                Cell: (cell) => {
                    return <p className='text textOverflow'>{cell.value ? cell.value : '-'}</p>;
                },
            },
            {
                Header: "Model",
                accessor: "modelName",
                filterable: true,
                disableSortBy: true,
                Cell: (cell) => {
                    return <p className='text textOverflow'>{decryptResponseData(cell.value)}</p>;
                },
            },
            {
                Header: "Status",
                accessor: "connectorStatus",
                disableSortBy: true,
                filterable: true,
                Cell: (cell) => {
                    // return <span className={cell.value === "Active" ? "badge text-uppercase green" : 'badge text-uppercase red'}> {cell.value === "Active" ? 'Online' : 'Offline'} </span>;
                    return <span className={cell.value === "Available" ? 'badge green' : 'badge red'}>{cell.value === "Available" ? "Online" : "Offline"}</span>
                },
            }
        ],
        [history, isChecked, noOfConn, handleBusinessNameSort, handleStationNameSort]
    );
    const bussinessSelectData = [
        {
            id: '1',
            value: 'All'
        },
        {
            id: '2',
            value: 'Titania Pvt Ltd'
        },
        {
            id: '3',
            value: 'Deimos LLP'
        },
        {
            id: '4',
            value: 'Hyperion LLP '
        },
        {
            id: '5',
            value: 'Phobos Pvt Ltd'
        },
        {
            id: '6',
            value: 'Nereid LLC'
        }
    ]
    const locationDropdownValue = [
        {
            id: '1',
            value: 'All'
        },
        {
            id: '2',
            value: 'Location01'
        },
        {
            id: '3',
            value: 'Location02'
        },
        {
            id: '4',
            value: 'Location03'
        }
    ]
    const timeItemData = [
        {
            id: '1',
            day: 'Monday',
            slot: 'Closed'
        },
        {
            id: '2',
            day: 'Tuesday',
            slot: '10:00 am - 05:00 pm'
        },
        {
            id: '3',
            day: 'Wednesday',
            slot: '10:00 am - 05:00 pm'
        },
        {
            id: '4',
            day: 'Thursday',
            slot: '10:00 am - 05:00 pm'
        },
        {
            id: '5',
            day: 'Friday',
            slot: '10:00 am - 05:00 pm'
        },
        {
            id: '6',
            day: 'Saturday',
            slot: '10:00 am - 05:00 pm'
        },
        {
            id: '7',
            day: 'Sunday ',
            slot: '10:00 am - 05:00 pm'
        },
    ]

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb
                        onClickBack={(e) => {
                            // history.push('/location');
                            e.preventDefault();
                            history.goBack();
                        }}
                        isButton={true}
                        title={decryptResponseData(getValueFromObject('locationName', locationDetails, ''))}
                        pageTitle="Locations"
                        OnClickpageTitle={(e) => { e.preventDefault(); history.goBack(); }}

                    />
                    {StationLoader ? <div className='viewLocationPage'>
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardHeader>
                                        <p className='headerTxt'>View Location</p>
                                        <Row>
                                            <Col lg={12} className='mb-2'>
                                                <div className='main'>
                                                    <Row>
                                                        <Col lg={12} md={12} className='d-flex align-items-center justify-content-start'>
                                                            <Row>
                                                                <Col lg={12} className='d-flex align-items-center justify-content-start'>
                                                                    <p className='lnameText wordBreakAll'>{decryptResponseData(getValueFromObject('locationName', locationDetails, ''))}</p>
                                                                    <p className='rName'>
                                                                        {locationDetails?.region
                                                                            ? `${getValueFromObject('region', locationDetails, '')}${locationDetails?.city ? `, ${getValueFromObject('city', locationDetails, '')}` : ''}`
                                                                            : getValueFromObject('city', locationDetails, '')}
                                                                    </p>
                                                                    {/* <i className='rateIcon bx bxs-star' />
                                                                    <p className='count'>3.0</p> */}
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <div className='mainContect'>
                                                            <div className='content'>
                                                                <div className='box'>
                                                                    <p className='mainTitle'>Business</p>
                                                                    <p className='txt'>{decryptResponseData(getValueFromObject('businessName', locationDetails, '-'))}</p>
                                                                </div>
                                                                <div className='box'>
                                                                    <p className='mainTitle'>Business Owner</p>
                                                                    <p className='txt'>{`${decryptResponseData(getValueFromObject('bFirstName', locationDetails, ''))} ${decryptResponseData(getValueFromObject('bLastName', locationDetails, ''))}`}</p>
                                                                    <p className='txt'><a href='mailto:someone@example.com' target="">{decryptResponseData(getValueFromObject('bEmail', locationDetails, ''))}</a></p>
                                                                    <p className='txt'>{locationDetails?.bContactNumer ? `+${locationDetails?.bCountryCode} ${locationDetails?.bContactNumer}` : '-'}</p>
                                                                </div>
                                                                <div className='box'>
                                                                    <p className='mainTitle'>Location Manager</p>
                                                                    <p className='txt'>{`${decryptResponseData(getValueFromObject('lFirstName', locationDetails, ''))} ${decryptResponseData(getValueFromObject('lLastName', locationDetails, ''))}`}</p>
                                                                    <p className='txt'><a href='mailto:someone@example.com' target="">{decryptResponseData(getValueFromObject('lEmail', locationDetails, ''))}</a></p>
                                                                    <p className='txt'>{locationDetails?.lContactNumber ? `+${locationDetails?.lCountryCode} ${locationDetails?.lContactNumber}` : '-'}</p>
                                                                </div>
                                                                {/* <div className='box'>
                                                                    <p className='mainTitle'>Service Timing</p>
                                                                    <CustomDropdown
                                                                        defaultValue={timerDetail[0]?.dayCheck ? timerDetail[0]?.fullDayCheck ? `${timerDetail[0]?.day} 24 hrs` : `${timerDetail[0]?.day} ${moment(timerDetail[0]?.startTime).format('hh:mm a')} - ${moment(timerDetail[0]?.endTime).format('hh:mm a')}` : `${timerDetail[0]?.day}  Closed`}
                                                                        // selectedValue={serviceTime}   //{`${serviceTime?.value} ${serviceTime?.secondValue}`}
                                                                        moreValue={true}
                                                                        dropDownItems={timerDetailFull}
                                                                        placeholder={''}
                                                                        onSelect={(evt) => {
                                                                            setServiceTime(evt);
                                                                            
                                                                        }}
                                                                    />
                                                                </div> */}
                                                                <div className='box'>
                                                                    <p className='mainTitle'>Service Timing</p>
                                                                    <div className='aA'>
                                                                        <p className='txt' onClick={() => {
                                                                            setTimeModal(true);
                                                                        }}>
                                                                            {timerDetail[0]?.dayCheck ? timerDetail[0]?.fullDayCheck ? `${timerDetail[0]?.day} 24 Hours` : ((timerDetail[0]?.startTime !== '' || timerDetail[0]?.endTime !== '') ? `${timerDetail[0]?.day} ${moment(timerDetail[0]?.startTime).format('hh:mm a')} - ${moment(timerDetail[0]?.endTime).format('hh:mm a')}` : `${timerDetail[0]?.day}  Closed`) : `${timerDetail[0]?.day}  Closed`}
                                                                            <i className='ri ri-arrow-down-s-line' />
                                                                        </p>
                                                                        {
                                                                            timeModal &&
                                                                            <OutsideClickHandler
                                                                                onOutsideClick={() => {
                                                                                    setTimeModal(false);
                                                                                }}
                                                                            >
                                                                                <div className='menu show'>
                                                                                    {timerDetailFull?.map((item, index) => {

                                                                                        return (
                                                                                            <>
                                                                                                <div className={timerDetail[0]?.id == item?.id ? 'item selected' : 'item'}>
                                                                                                    <span className='day'>{item.value}</span>
                                                                                                    <span className={(item?.secondValue === 'Closed' && 'closed') || (item.secondValue === '24 Hours' && 'fullDay')}>{item.secondValue}</span>

                                                                                                </div>
                                                                                            </>
                                                                                        )
                                                                                    })}
                                                                                </div>
                                                                            </OutsideClickHandler>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            {/* {getUserRole() !== 'LM' && <div className='option'> */}
                                                            {window.localStorage.getItem('users') !== null && window.localStorage.getItem('users') !== undefined && JSON.parse(window.localStorage.getItem('users')).userRole !== 'LocationManager' && <div className='option'>
                                                                <Button>
                                                                    <div className="form-check form-switch">
                                                                        <Input
                                                                            onClick={() => { }}
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            role="switch"
                                                                            id=""
                                                                            checked={locationDetails?.status === 'Active' ? true : false}
                                                                            onChange={(e) => {

                                                                                if (
                                                                                    locationDetails?.status ===
                                                                                    "Active"
                                                                                ) {

                                                                                    setDisabledModal(
                                                                                        true
                                                                                    );
                                                                                } else {

                                                                                    setEnableModal(
                                                                                        true
                                                                                    );
                                                                                }
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </Button>
                                                                <Button
                                                                    onClick={() => {
                                                                        history.push('/location/editlocation', {
                                                                            state: {
                                                                                businessID: locationDetails?.businessId,
                                                                                locationId: locationDetails?.locationId,
                                                                                type: 'LocEdit'
                                                                            }
                                                                        })
                                                                    }}
                                                                    className={locationDetails?.status === 'Active' ? '' : 'opactityDisabled'}
                                                                    disabled={locationDetails?.status === 'Inactive' ? true : false}
                                                                >
                                                                    <i className='bx bx-edit-alt editBtn' />
                                                                </Button>
                                                                <Button
                                                                >
                                                                    <i className='bx bx-trash deleteBtn' onClick={() => { setdeleteModalUser(true); }} />
                                                                </Button>
                                                            </div>}
                                                        </div>
                                                    </Row>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                    <CardBody>
                                        <div className='title'>
                                            <p className='Txt'>Stations</p>
                                        </div>
                                        <div className={filteredTable?.length > 0 && stationDetail.length > 0 ? 'table-content' : 'table-content disablePointer'}>
                                            <TableContainer
                                                columns={columns1}
                                                // data={stationDetail}
                                                data={allListLocation}
                                                isGlobalFilter={true}
                                                customPageSize={16}
                                                // isDropdown={businessManeger ? false : true}
                                                // dropdownTitle='Location'
                                                // dropDownItems={locationDropdownValue}
                                                // selectedValue={selectValue}
                                                // onSelect={(evt) => {
                                                //     setSelectValue(evt);
                                                // }}
                                                setfilteredTable={setfilteredTable}
                                                maxHeight={dimensions.height - headerHeight - breadCrumb - footerHeight - tableTaskBar - paginationDiv - 80}
                                                divClass="table-responsive mb-1 viewLocationTable"
                                                tableClass="align-middle table-nowrap"
                                                theadClass="table-light text-muted"
                                                isAddButton={true}
                                                isAddButtonDisabled={locationDetails?.status === 'Active' ? false : true}
                                                handleAddButtonClicks={handleAddButtonClicks}
                                                handleViewDetailClick={(row) => { handleViewDetailClick(row) }}
                                                searchPlaceholder='Search for Station name'
                                                addButtonTitle='Add Station'
                                                noData={allListLocation?.length > 0 ? false : true}
                                                noDataImage={"https://res.cloudinary.com/djyl1goby/image/upload/v1670566470/Lubi/WebApp/Static-images/No_Station_found.svg"}
                                                noDataTitle={'No Stations Found!'}

                                            // customFilter={true}
                                            // allFilter={false}
                                            // bussinessSelectData={bussinessSelectData}
                                            // locationData={locationDropdownValue}
                                            // modalName={filterModal}
                                            // onClickCustomFilter={() => { setFilterModal(true); }}
                                            // handleClose={() => { setFilterModal(false); }}
                                            />
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div> : <ContentLoader />}
                </Container>
            </div>
            <DeleteModal
                show={deleteModalUser}
                title='Are you sure?'
                description='Are you sure you want to delete this record?'
                onDeleteClick={handleDeleteContact}
                onCloseClick={() => setdeleteModalUser(false)}
            />
            <DeleteModal
                show={deleteModal}
                title='Are you sure?'
                description='Are you sure you want to delete this record?'
                onDeleteClick={handleDeleteContact}
                onCloseClick={() => setDeleteModal(false)}
            />
            <DisableModal
                show={disabledModal}
                title='Are you sure?'
                subtitle='Are you sure you want to disable this location?'
                btnActionText='Yes, Disable it!'
                btncancelText='Cancel'
                onActionClick={handleDisableContact}
                onCloseClick={() => setDisabledModal(false)}
            />
            <DisableModal
                show={enableModal}
                title='Are you sure?'
                subtitle='Are you sure you want to enable this location?'
                btnActionText='Yes, enable it!'
                btncancelText='Cancel'
                onActionClick={handleEnableContact}
                onCloseClick={() => setEnableModal(false)}
            />
            <DisableModal
                show={disabledStationModal}
                title='Are you sure?'
                subtitle='Are you sure you want to disable this station?'
                btnActionText='Yes, Disable it!'
                btncancelText='Cancel'
                onActionClick={handleDisableStationContact}
                onCloseClick={() => setdisabledStationModal(false)}
            />
            <DisableModal
                show={enableStationModal}
                title='Are you sure?'
                subtitle='Are you sure you want to enable this station?'
                btnActionText='Yes, enable it!'
                btncancelText='Cancel'
                onActionClick={handleEnableStationContact}
                onCloseClick={() => setenableStationModal(false)}
            />
            <Modal className='qrModel' isOpen={qrModel} toggle={() => {
                setQrModel(false);
            }}
                centered={true}>
                <ModalHeader>
                    <p>QR Code</p>
                    <i className='bx bx-x' onClick={() => {
                        setQrModel(false);
                    }} />
                </ModalHeader>
                <ModalBody className="">
                    <div className='qrMain'>
                        <QRCode
                            id="qr-gen"
                            value={qrValue}
                            size={270}
                            level={"H"}
                            includeMargin={true}
                        />
                        <div className='bottom d-flex justify-content-center mt-1 mb-3'>
                            <Button onClick={() => { setQrModel(false); }}>
                                Download
                            </Button>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};

export default ViewLocation;