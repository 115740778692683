import { PROFILE_ERROR, PROFILE_SUCCESS, USER_PROFILE_DATA_SUCCESS, EDIT_PROFILE, RESET_PROFILE_FLAG, CHANGE_PASSWORD, CHANGE_PASSWORD_SUCCESS, CHANGE_PASSWORD_ERROR } from "./actionTypes";

const initialState = {
  error: "",
  success: "",
  user: {},
  alUserList: [],
  changeData: [],
  changeDataSuc: '',
  changeDataErr: ''

};

const profile = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_PROFILE:
      state = { ...state };
      break;
    case PROFILE_SUCCESS:
      state = {
        ...state,
        success: action.payload.status,
        user: action.payload.data
      };
      break;
    case USER_PROFILE_DATA_SUCCESS:
      state = {
        ...state,
        alUserList: action.payload,
      };
      break;
    case CHANGE_PASSWORD:
      state = {
        ...state,
        changeData: action.payload,
      };
      break;
    case CHANGE_PASSWORD_SUCCESS:
      state = {
        ...state,
        changeDataSuc: action.payload,
      };
      break;
    case CHANGE_PASSWORD_ERROR:
      state = {
        ...state,
        changeDataErr: action.payload,
      };
      break;

    case PROFILE_ERROR:
      state = {
        ...state,
        error: action.payload
      };
      break;
    case RESET_PROFILE_FLAG:
      state = {
        ...state,
        success: null
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default profile;
