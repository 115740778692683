import React, { useLayoutEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row, Button, Form, Input, FormFeedback } from 'reactstrap';
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import { verifyOtp, resendOTP } from "../../store/actions";

//import images
import logoLight from "../../assets/images/LubiLogoFinal.svg";
import { useState } from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Constant from '../../utils/constant';
import { useSelector, useDispatch } from 'react-redux';
// import { useHistory, useLocation } from 'react-router-dom';
import AuthLogo from '../../Components/Common/AuthLogo';
import CustomButton from '../../Components/Common/CustomButton';
import { showToast } from '../../store/actions';


const Verify = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [isTimer, setIsTimer] = useState(false);
    const Ref = useRef(null);
    const [errorShow] = useState();
    const { state } = useLocation();
    const [token, setToken] = useState('');
    const [email, setEmail] = useState('');
    const [userID, setUserId] = useState('');


    // send user back to reset password if user comes back to otp
    useEffect(() => {
        if (window.localStorage.getItem('current') !== 'verify') {
            history.push('/login');
        }
    }, [])

    useLayoutEffect(() => {
        if (window.sessionStorage.getItem('users') !== null) {
            history.push('/dashboard');
        }
    }, [])


    // The state for our timer
    const [timer, setTimer] = useState('00:00');

    document.title = "Verify OTP | Vidhyutam";

    const { loading } = useSelector(state => ({
        loading: state.Toast.loader
    }));

    const getInputElement = (index) => {
        return document.getElementById('digit' + index + '-input');
    }

    const moveToNext = (index) => {
        if (getInputElement(index).value.length === 1) {
            if (index !== 4) {
                getInputElement(index + 1).focus();
            } else {
                getInputElement(index).blur();
                // Submit code
                
            }
        }
    }

    const moveToBack = (index, event) => {
        if (event.key === 'Backspace') {
            getInputElement(index - 1).focus()
            
        }
    }


    const getTimeRemaining = (e) => {
        const total = Date.parse(e) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        return {
            total, minutes, seconds
        };
    };

    const startTimer = (e) => {
        const { total, minutes, seconds } = getTimeRemaining(e);
        if (total >= 0) {
            setTimer((minutes > 9 ? minutes : '0' + minutes) + ':' + (seconds > 9 ? seconds : '0' + seconds));
        } else {
            setIsTimer(false);
        }
    };

    const clearTimer = (e) => {
        setTimer('00:30');
        if (Ref.current) clearInterval(Ref.current);
        const id = setInterval(() => {
            startTimer(e);
        }, 1000);
        Ref.current = id;
    };

    const getDeadTime = () => {
        const deadline = new Date();
        deadline.setSeconds(deadline.getSeconds() + 30);
        return deadline;
    };
    // useEffect(() => {
    //     clearTimer(getDeadTime());
    // }, []);

    const { forgetSuccessData } = useSelector(state => ({
        forgetSuccessData: state.ForgetPassword.forgetSuccessdata,
    }));
    

    useEffect(() => {
        setUserId(forgetSuccessData.userId);
    }, [forgetSuccessData])

    useEffect(() => {
        
        // setToken(state?.state?.token?.resetPasswordToken);
        // setEmail(state?.state?.email);
        // setToken(JSON.parse(window.localStorage.getItem('token')));
        setEmail(window.localStorage.getItem('email'));
        setIsTimer(true);
        clearTimer(getDeadTime());
    }, []);
    

    const onClickReset = () => {
        dispatch(resendOTP(email));
        setIsTimer(true);
        clearTimer(getDeadTime());
    };

    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            digitOne: '',
            digitTwo: '',
            digitThree: '',
            digitFour: '',
        },
        validationSchema: Yup.object({
            digitOne: Yup.string()
                .required('')
                .matches(Constant.REGEX.NUMBER, 'Invalid OTP'),
            digitTwo: Yup.string()
                .required('')
                .matches(Constant.REGEX.NUMBER, 'Invalid OTP'),
            digitThree: Yup.string()
                .required('')
                .matches(Constant.REGEX.NUMBER, 'Invalid OTP'),
            digitFour: Yup.string()
                .required('')
                .matches(Constant.REGEX.NUMBER, 'Invalid OTP'),
        }),

        onSubmit: (values) => {
            
            const params = {
                otp: values?.digitOne + values?.digitTwo + values?.digitThree + values?.digitFour
            };
            // dispatch(verifyOtp(params, props.history, token));
            dispatch(verifyOtp(params, props.history, userID));
            
            window.localStorage.setItem('current', 'resetpassword');
        }

    });

    // const { forgetPassToken } = useSelector(state => (console.log(state) || {
    //     forgetPassToken: state?.ForgetPassword?.forgetSuccessdata
    // }));
    

    return (
        <React.Fragment>
            <ParticlesAuth>
                <div className="auth-page-content verify verify_pagecss">
                    <Container>
                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="mt-4">
                                    <CardBody className="p-4">
                                        <Row>
                                            <Col lg={12}>
                                                <div className="text-center mt-sm-2 mb-4 text-white-50">
                                                    <AuthLogo />
                                                </div>
                                            </Col>
                                        </Row>
                                        <div className="mb-4">
                                            <div className="avatar-lg mx-auto">
                                                <div className="avatar-title bg-light text-primary display-5 rounded-circle">
                                                    <i className="ri-mail-line"></i>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="p-2 mt-4">
                                            <div className="text-muted text-center mb-4 mx-lg-3">
                                                <h4 className='text-success'>Verify Your Email</h4>
                                                <p>Please enter the 4 digits code sent to your registered email <span>{email}</span></p>
                                            </div>

                                            <Form onSubmit={validation.handleSubmit}>
                                                <Row>
                                                    <Col className="col-3 otpCol-3">
                                                        <div className="mb-3">
                                                            <label htmlFor="digit1-input" className="visually-hidden">Digit 1</label>
                                                            <Input
                                                                type="tel"
                                                                keyboardType = 'numeric'
                                                                placeholder='-'
                                                                maxLength='1'
                                                                className={errorShow ? 'form-control   bg-light border-light text-center is-invalid' : 'form-control   bg-light border-light text-center'}
                                                                // className="form-control   bg-light border-light text-center"
                                                                name="digitOne"
                                                                value={validation.values.digitOne}
                                                                // onChange={(e) => {
                                                                //     if (!(e.target.value.charCodeAt() > 47) &&(e.target.value.charCodeAt() < 58) ||(e.target.value.charCodeAt() === 66)) {
                                                                //         e.preventDefault();
                                                                //     } else {
                                                                //         validation.handleChange('digitOne') (e.target.value)}
                                                                //         moveToNext(1);
                                                                //         moveToBack(1, e);
                                                                //     }
                                                                // }
                                                                onChange={validation.handleChange}
                                                                invalid={validation.errors.digitOne ? true : false}
                                                                id="digit1-input"
                                                                onKeyDown={(e) => {
                                                                    if (!((e.key.charCodeAt() > 47 && e.key.charCodeAt() < 58) || e.key.charCodeAt() === 66)) {
                                                                        e.preventDefault();
                                                                    }
                                                                }}
                                                                onKeyUp={(e) => {
                                                                    moveToNext(1);
                                                                    moveToBack(1, e);
                                                                }}
                                                            />
                                                            {(validation.errors.digitOne &&
                                                                validation.errors.digitTwo &&
                                                                validation.errors.digitThree &&
                                                                validation.errors.digitFour)
                                                                ? (
                                                                    <FormFeedback type="invalid">{(validation.errors.digitOne &&
                                                                        validation.errors.digitTwo && validation.errors.digitThree &&
                                                                        validation.errors.digitFour)}
                                                                    </FormFeedback>
                                                                )
                                                                : null}
                                                        </div>
                                                    </Col>

                                                    <Col className="col-3 otpCol-3">
                                                        <div className="mb-3">
                                                            <label htmlFor="digit2-input" className="visually-hidden">Digit 2</label>
                                                            <Input
                                                                 type="tel"
                                                                 keyboardType = 'numeric'
                                                                placeholder='-'
                                                                className="form-control   bg-light border-light text-center"
                                                                maxLength='1'
                                                                name="digitTwo"
                                                                value={validation.values.digitTwo}
                                                                // onChange={(e) => {
                                                                //     if (!(e.target.value.charCodeAt() > 47) &&(e.target.value.charCodeAt() < 58) ||(e.target.value.charCodeAt() === 66)) {
                                                                //         e.preventDefault();
                                                                //     } else {
                                                                //         validation.handleChange('digitTwo') (e.target.value)}
                                                                //         moveToNext(2);
                                                                //         moveToBack(2, e);
                                                                //     }
                                                                // }
                                                                onChange={validation.handleChange}
                                                                invalid={validation.errors.digitTwo ? true : false}
                                                                id="digit2-input"
                                                                onKeyDown={(e) => {
                                                                    if (!((e.key.charCodeAt() > 47 && e.key.charCodeAt() < 58) || e.key.charCodeAt() === 66)) {
                                                                        e.preventDefault();
                                                                    }
                                                                }}
                                                                onKeyUp={(e) => {
                                                                    moveToNext(2);
                                                                    moveToBack(2, e);
                                                                }}
                                                            />
                                                        </div>
                                                    </Col>

                                                    <Col className="col-3 otpCol-3">
                                                        <div className="mb-3">
                                                            <label htmlFor="digit3-input" className="visually-hidden">Digit 3</label>
                                                            <Input
                                                                type="tel"
                                                                keyboardType = 'numeric'
                                                                placeholder='-'
                                                                className="form-control bg-light border-light text-center"
                                                                maxLength='1'
                                                                name="digitThree"
                                                                value={validation.values.digitThree}
                                                                // onChange={(e) => {
                                                                //     if (!(e.target.value.charCodeAt() > 47) &&(e.target.value.charCodeAt() < 58) ||(e.target.value.charCodeAt() === 66)) {
                                                                //         e.preventDefault();
                                                                //     } else {
                                                                //         validation.handleChange('digitThree') (e.target.value)}
                                                                //         moveToNext(3);
                                                                //         moveToBack(3, e);
                                                                //     }
                                                                // }
                                                                onChange={validation.handleChange}
                                                                invalid={validation.errors.digitThree ? true : false}
                                                                id="digit3-input"
                                                                onKeyDown={(e) => {
                                                                    if (!((e.key.charCodeAt() > 47 && e.key.charCodeAt() < 58) || e.key.charCodeAt() === 66)) {
                                                                        e.preventDefault();
                                                                    }
                                                                }}
                                                                onKeyUp={(e) => {
                                                                    moveToNext(3);
                                                                    moveToBack(3, e);
                                                                }}
                                                            />
                                                        </div>
                                                    </Col>

                                                    <Col className="col-3 otpCol-3">
                                                        <div className="mb-3">
                                                            <label htmlFor="digit4-input" className="visually-hidden">Digit 4</label>
                                                            <Input
                                                                type="tel"
                                                                placeholder='-'
                                                                keyboardType = 'numeric'
                                                                className="form-control bg-light border-light text-center"
                                                                maxLength='1'
                                                                name="digitFour"
                                                                value={validation.values.digitFour}
                                                                // onChange={(e) => {
                                                                
                                                                //     if (!(e.target.value.charCodeAt() > 47) && (e.target.value.charCodeAt() < 58) || (e.target.value.charCodeAt() === 66)) {
                                                                
                                                                //         e.preventDefault();
                                                                //     } else {
                                                                
                                                                //         validation.handleChange('digitFour') (e.target.value)}
                                                                //         moveToNext(4);
                                                                //         moveToBack(4, e);
                                                                //     }
                                                                // }
                                                                onChange={validation.handleChange}
                                                                invalid={validation.errors.digitFour ? true : false}
                                                                id="digit4-input"
                                                                onKeyDown={(e) => {
                                                                    if (!((e.key.charCodeAt() > 47 && e.key.charCodeAt() < 58) || e.key.charCodeAt() === 66)) {
                                                                        e.preventDefault();
                                                                    }
                                                                }}
                                                                onKeyUp={(e) => {
                                                                    moveToNext(4);
                                                                    moveToBack(4, e);
                                                                }}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                {errorShow && <p className='errorText'>Invalid OTP</p>}

                                                <div className="mt-3">
                                                    <CustomButton
                                                        title='Confirm'
                                                        disabled={!(validation.isValid && validation.dirty)}
                                                        type='submit'
                                                        loading={loading}
                                                        className='submitBTN'
                                                    />
                                                </div>
                                            </Form>
                                        </div>
                                    </CardBody>
                                </Card>
                                <div className="mt-4 text-center">
                                    <>
                                        <p className='timerDiv'>
                                            {isTimer ? "Resend OTP in  :" : "Didn't receive a code ?"}
                                            <span style={{ display: isTimer ? 'none' : 'block' }}>&nbsp;</span>
                                            <Button style={{ display: isTimer ? 'none' : 'block' }} type='button' disabled={isTimer} className='resendBtnText text-success text-decoration-underline'
                                                onClick={() => {
                                                    onClickReset();
                                                    validation.resetForm();
                                                }
                                                }>Resend</Button>
                                            <span>&nbsp;</span>
                                            {isTimer && <>
                                                <span className='otpTimerStart text-success'>{timer}
                                                </span>
                                            </>
                                            }
                                        </p>
                                    </>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </ParticlesAuth>
        </React.Fragment>
    );
};

export default Verify;