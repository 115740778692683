import {
    LIST_ALL_TARIFFS_SUCCESS,
    VIEW_DETAIL_TARIFF_SUCCESS
} from "./actionTypes";

const initialState = {
    listAllTariff: [],
    DetailTariff: [],
};

const ManageTariffs = (state = initialState, action) => {
    switch (action.type) {
        case LIST_ALL_TARIFFS_SUCCESS:
            state = {
                ...state,
                listAllTariff: action.payload,
            };
            break;
        case VIEW_DETAIL_TARIFF_SUCCESS:
            state = {
                ...state,
                DetailTariff: action.payload,
            };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default ManageTariffs;