import { useEffect, useRef, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";

const SearchDropDown = ({
    options,
    label,
    id,
    selectedVal,
    handleChange,
    placeholder
}) => {
    const [query, setQuery] = useState("");
    const [isOpen, setIsOpen] = useState(false);

    const inputRef = useRef(null);

    useEffect(() => {
        document.addEventListener("click", toggle);
        return () => document.removeEventListener("click", toggle);
    }, []);

    const selectOption = (option) => {
        setQuery(() => "");
        handleChange(option);
        setIsOpen((isOpen) => !isOpen);
    };

    function toggle(e) {
        setIsOpen(e && e.target === inputRef.current);
    }

    const getDisplayValue = () => {
        if (query) return query;
        if (selectedVal) return selectedVal;

        return "";
    };

    const filter = (options) => {
        return options.filter(
            (option) => option[label].toLowerCase().indexOf(query.toLowerCase()) > -1
        );
    };

    return (
        <>
            <OutsideClickHandler
                onOutsideClick={() => {
                    setIsOpen(false);
                }}
            >
                <div className="customSearchDropdown">
                    <div className="dropdown">
                        <div className="control">
                            <div className="selected-value">
                                <input
                                    ref={inputRef}
                                    placeholder={placeholder}
                                    type="text"
                                    value={getDisplayValue()}
                                    name="searchTerm"
                                    onChange={(e) => {
                                        setQuery(e.target.value);
                                        handleChange(null);
                                    }}
                                    onClick={toggle} />
                            </div>
                            <div className={`arrow ${isOpen ? "open" : ""}`}></div>
                        </div>

                        <div className={`options ${isOpen ? "open" : ""}`}>
                            <div style={{ padding: '8px 0' }}>
                                {filter(options).map((option, index) => {
                                    return (
                                        <div
                                            onClick={() => selectOption(option)}
                                            className={`option ${option[label] === selectedVal ? "selected" : ""}`}
                                            key={`${id}-${index}`}
                                        >
                                            {option[label]}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </OutsideClickHandler>
        </>
    );
};

export default SearchDropDown;
