// Actions
export const API_RESPONSE_SUCCESS = "API_RESPONSE_SUCCESS";
export const API_RESPONSE_SUCCESS_DATA = "API_RESPONSE_SUCCESS_DATA";
export const API_RESPONSE_ERROR = "API_RESPONSE_ERROR";
export const GET_PROJECT_CHARTS_DATA = "GET_PROJECT_CHARTS_DATA";
export const GET_PROJECTSTATUS_CHARTS_DATA = "GET_PROJECTSTATUS_CHARTS_DATA";

export const GET_SUPERADMIN_CUSTOMER = "GET_SUPERADMIN_CUSTOMER";
export const GET_SUPERADMIN_CUSTOMER_SUCCESS =
  "GET_SUPERADMIN_CUSTOMER_SUCCESS";
export const GET_SUPERADMIN_CUSTOMER_FAILURE =
  "GET_SUPERADMIN_CUSTOMER_FAILURE";

export const GET_BUSINESS_LOCATION = "GET_BUSINESS_LOCATION";
export const GET_BUSINESS_LOCATION_SUCCESS =
  "GET_BUSINESS_LOCATION_SUCCESS";
export const GET_BUSINESS_LOCATION_FAILURE =
  "GET_BUSINESS_LOCATION_FAILURE";

export const GET_LOCATION_MANAGER_DATA = "GET_LOCATION_MANAGER_DATA";
export const GET_SUPERADMIN_DASHBOARD_YEAR = "GET_SUPERADMIN_DASHBOARD_YEAR";
export const GET_SUPERADMIN_DASHBOARD_YEAR_SUCCESS = "GET_SUPERADMIN_DASHBOARD_YEAR_SUCCESS";

export const GET_LOCATION_MANAGER_DATA_SUCCESS =
  "GET_LOCATION_MANAGER_DATA_SUCCESS";
export const GET_LOCATION_MANAGER_DATA_FAILURE =
  "GET_LOCATION_MANAGER_DATA_FAILURE";

export const GET_SUPERADMIN_BUSINESS_TRANSACTION =
  "GET_SUPERADMIN_BUSINESS_TRANSACTION";
export const GET_SUPERADMIN_BUSINESS_TRANSACTION_SUCCESS =
  "GET_SUPERADMIN_BUSINESS_TRANSACTION_SUCCESS";


export const GET_HEATMAP_DATA = "GET_HEATMAP_DATA";
export const GET_HEATMAP_DATA_SUCCESS = "GET_HEATMAP_DATA_SUCCESS";


export const SUPER_ADMIN_DASHBOARD_CUSTOMERS_DOWNLOAD = "SUPER_ADMIN_DASHBOARD_CUSTOMERS_DOWNLOAD";
export const SUPER_ADMIN_DASHBOARD_CUSTOMERS_DOWNLOAD_SUCCESS = "SUPER_ADMIN_DASHBOARD_CUSTOMERS_DOWNLOAD_SUCCESS";


export const GET_BUSINESS_LOCATION_DOWNLOAD = "GET_BUSINESS_LOCATION_DOWNLOAD";
export const GET_BUSINESS_LOCATION_DOWNLOAD_SUCCESS = "GET_BUSINESS_LOCATION_DOWNLOAD_SUCCESS";


export const GET_LOCATION_MANAGER_DOWNLOAD_DATA = "GET_LOCATION_MANAGER_DOWNLOAD_DATA";
export const GET_LOCATION_MANAGER_DOWNLOAD_DATA_SUCCESS = "GET_LOCATION_MANAGER_DOWNLOAD_DATA_SUCCESS";


