import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';

const Footer = () => {
    return (
        <React.Fragment>
            <footer className="footer">
                <Container fluid>
                    <Row>
                        <Col sm={6}>
                            {/* {new Date().getFullYear()} © Vidhyutam EV Solutions */}
                            {new Date().getFullYear()} © Vidhyutam
                        </Col>
                        <Col sm={6}>
                            <div className="text-sm-end d-none d-sm-block">
                                Designed & Developed by <a href='https://www.nimblechapps.com/' target={"_blank"} rel="noreferrer" >Nimblechapps</a>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </footer>
        </React.Fragment>
    );
};

export default Footer;