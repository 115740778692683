// Actions
export const API_RESPONSE_SUCCESS = "API_RESPONSE_SUCCESS";
export const API_RESPONSE_ERROR = "API_RESPONSE_ERROR";
export const GET_DIRECT_CONTACT = "GET_DIRECT_CONTACT";
/* MESSAGES */
export const GET_MESSAGES = "GET_MESSAGES";
export const GET_MESSAGES_SUCCESS = "GET_MESSAGES_SUCCESS";
export const GET_MESSAGES_FAIL = "GET_MESSAGES_FAIL";

export const GET_CHANNELS = "GET_CHANNELS";
export const ADD_MESSAGE = "ADD_MESSAGE";

export const DELETE_MESSAGE = "DELETE_MESSAGE";