import React from 'react';
import { Button } from 'reactstrap';
import Loader from '../../assets/images/giphy.gif';

const CustomButton = (props) => {
    const { title, children, disabled, type, loading, className, btnIcon } = props;

    return (
        <Button
            className={loading ? 'custombtn btn-loading ' + className : 'custombtn ' + className}
            type={type} onKeyDown={props.onKeyDown} disabled={disabled || loading} onClick={props.onClick} >
            {loading ? <div className="btn-loader"><img src={Loader} alt='' height={20} width={20} /></div> : title}
            {children}
            {
                !loading &&
                <i className={btnIcon} />
            }
        </Button>
    );
};

CustomButton.defaultProps = {
    className: '',
    loading: false
};

export default CustomButton;
