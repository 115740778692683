import {
    GET_CONNECTOR_TYPES_SUCCESS,
    GET_MODEL_TYPES_SUCCESS,
    GET_CONNECTORS_ICONS_SUCCESS,
    LIST_ALL_EVSE_SUCCESS,
    DETAIL_EVSE_SUCCESS,
    DETAIL_EVSE_CONNECTOR_SUCCESS,
    EMPTY_EVSE_CONNECTOR,
    GET_CONNECTOR_LIST_SUCCESS,
    DETAIL_CONNECTOR_TYPE_SUCCESS,
    DETAIL_EVSE_SINGLE_CONNECTOR_SUCCESS,
    GET_CONNECTORS_DETAILS_SUCCESS
} from "./actionTypes";

const initialState = {
    connectorTypes: [],
    modelTypes: [],
    listAllEvse: [],
    detailEvse: [],
    detailEvseConnector: [],
    connectorsIcon: [],
    connectorList: [],
    detailConnectorTypes: [],
    detailSingleEvseConnector: [],
    connecterDetials: [],
};

const AssetManagment = (state = initialState, action) => {
    switch (action.type) {
        case GET_CONNECTOR_TYPES_SUCCESS:
            state = {
                ...state,
                connectorTypes: action.payload,
            };
            break;
        case GET_MODEL_TYPES_SUCCESS:
            state = {
                ...state,
                modelTypes: action.payload,
            };
            break;
        case LIST_ALL_EVSE_SUCCESS:
            state = {
                ...state,
                listAllEvse: action.payload,
            };
            break;
        case DETAIL_EVSE_SUCCESS:
            state = {
                ...state,
                detailEvse: action.payload,
            };
            break;
        case DETAIL_EVSE_CONNECTOR_SUCCESS:
            state = {
                ...state,
                detailEvseConnector: action.payload,
            };
            break;
        case GET_CONNECTORS_ICONS_SUCCESS:
            state = {
                ...state,
                connectorsIcon: action.payload,
            };
            break;
        case GET_CONNECTOR_LIST_SUCCESS:
            state = {
                ...state,
                connectorList: action.payload,
            };
            break;
        case DETAIL_CONNECTOR_TYPE_SUCCESS:
            state = {
                ...state,
                detailConnectorTypes: action.payload,
            };
            break;
        case DETAIL_EVSE_SINGLE_CONNECTOR_SUCCESS:
            state = {
                ...state,
                detailSingleEvseConnector: action.payload,
            };
            break;
        case EMPTY_EVSE_CONNECTOR:
            state = {
                ...state,
                detailEvseConnector: [],
            };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default AssetManagment;