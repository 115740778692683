import {
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_ERROR,
  RESET_PASSWORD,
  VERIFY_OTP,
  RESEND_OTP,
  VERIFY_OTP_SUCCESS,
  VERIFY_EMAIL,
  VERIFY_EMAIL_SUCCESS,
  VERIFY_EMAIL_FAIL
} from "./actionTypes";

export const userForgetPassword = (user, history) => {
  return {
    type: FORGET_PASSWORD,
    payload: { user, history },
  };
};

export const userForgetPasswordSuccess = data => {
  return {
    type: FORGET_PASSWORD_SUCCESS,
    payload: data,
  };
};

export const userForgetPasswordError = message => {
  return {
    type: FORGET_PASSWORD_ERROR,
    payload: message,
  };
};

export const userResetPassword = (user, history, token) => {
  
  return {
    type: RESET_PASSWORD,
    payload: { user, history, token }
  };
};

export const verifyOtp = (user, history, token) => {
  return {
    type: VERIFY_OTP,
    payload: { user, history, token }
  }
}

export const resendOTP = (user) => {
  return {
    type: RESEND_OTP,
    payload: { user }
  }
}

export const VerifyOtpSuccess = data => {
  return {
    type: VERIFY_OTP_SUCCESS,
    payload: data,
  };
};

export const verifyEmail = data => {
  
  return {
    type: VERIFY_EMAIL,
    payload: data,
  };
};

export const verifyEmailSuccess = (error) => {
  if (error !== 'error') {
    return {
      type: VERIFY_EMAIL_SUCCESS,
    };
  } else {
    return {
      type: VERIFY_EMAIL_FAIL,
    };
  }
};

