import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import { LOGS_LISTING } from "./actionType";

import { LogsListSuccess } from "../actions"

import { Logs, } from "../../helpers/Auth";
import { showToast, showLoader } from "../actions";

function* logsList({ payload: { data }, success, error }) {
    try {
        const response = yield call(Logs, data)
        if (response) {
            yield put(LogsListSuccess(response.payload));
            success(response.payload)
            error(response.payload)
        }
    } catch (e) {
        yield put(LogsListSuccess([]));
    }
}


export function* logsAllList() {
    yield takeEvery(LOGS_LISTING, logsList);


}

export default logsAllList;