import {
  ALL_CUSTOMER_LIST,
  CUSTOMER_LIST_FAILURE,
  CUSTOMER_LIST_SUCCESS,
} from "./actionTypes";

export const allCustomersList = (data) => {
  return {
    type: ALL_CUSTOMER_LIST,
    payload: { data },

  };
};

export const allCustomersListFailure = (error) => {
  return {
    type: CUSTOMER_LIST_FAILURE,
    payload: error,
  };
};
export const allCustomersListSuccess = (list) => {
  return {
    type: CUSTOMER_LIST_SUCCESS,
    payload: list,
  };
};
