//REGISTER
// export const POST_FAKE_REGISTER = "/auth/signup";

//LOGIN
export const LOGIN = "/auth/login";
export const FORGET = "/auth/forgotPassword";
export const RESET = "/auth/resetPassword/";
export const REFRESH_TOKEN = "/auth/refreshToken";
export const VERIFY_OTP = "/auth/verifyOtp/";
export const RESEND_OTP = "/auth/resendOtp";
export const VERIFY_EMAIL = "/customer/verifyEmail?token=";
export const SET = "/auth/setPassword/";


//customers

export const GET_ALL_CUSTOMER_URL = "/customer/getAllCustomersList";
export const ACTIVATE_DEACTIVATE_CUSTOMER_URL = "/customer/getAllCustomers";
export const DELETE_CUSTOMER_URL = "/customer/getAllCustomers";

// export const POST_FAKE_JWT_LOGIN = "/post-jwt-login";
// export const POST_FAKE_PASSWORD_FORGET = "/auth/forgot-password";
// export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd";
// export const SOCIAL_LOGIN = "/social-login";

// //PROFILE
// export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile";
// export const POST_EDIT_PROFILE = "/user";

// Business
export const BUSINESS = "/business/listAllBusinesses";
export const GET_BUSINESS_DETAIL = "/business/getBusinessDetails/";
export const ADD_NEW_BUSINESS = "/business/addBusiness";
export const CHANGE_PASSWORD_URL = "auth/changePassword";
export const USER_PROFILE_URL = "auth/userProfile";

export const EDIT_PROFILE_URL = "auth/updateUserProfile";

export const BUSINESSTRANSACTIONURL = "/business/businessTransactionEnergySA";

export const HEATMAPDATAURL = "/dashboard/LocationManagerHeatMapData";


export const UPDATE_BUSINESS = "/business/editBusiness/"; // /business/editBusiness/businessId
export const GET_MATCHING_EMAIL = "/auth/getMatchingData?"; // '/auth/getMatchingEmails?role=BusinessOwner&email=sh'
export const RESEND_BUSINESS_INVITE = "/business/resendBusinessInvite";
export const ACTIVATE_DEACTIVATE_BUSINESS_URL =
  "business/activateDeactivateBusiness";
export const DELETE_BUSINESS_URL = "business/deleteBusiness";
// export const DELETE_EVENT = "/delete/event";
// export const GET_CATEGORIES = "/categories";

// Location
export const LOCATION = "/location/listAllLocations";
export const LOGS = "/customer/getlogs";
export const GET_LOCATION_DETAIL = "/location/getLocationDetails/";
export const ADD_LOCATION = "/location/addLocation/";
export const EDIT_LOCATION = "/location/editLocation/";
export const RESEND_LOCATION_INVITE = "/location/resendLocationInvite";
export const LOC_MAN_BUSINESS = "/location/getBusinessDetails/";
export const ACTIVATE_DEACTIVATE_LOCATION_URL =
  "location/activateDeactivateLocation";
export const DELETE_LOCATION_URL = "location/deleteLocation";

export const STATIONLISTFUNC = "/station/stationListByBusinessId";

export const CONNECTORLISTFUNC = "/station/connectorListByStationId";
export const LOCATIONFILTER = "/location/listAllLocationsFilter";
export const BUSINESS_OWNER_DASHBOARD_BUSINESS_LOCATION_URL =
  "/dashboard/BusinessOwnerDashboard";

// // Chat
// export const GET_DIRECT_CONTACT = "/chat";
// export const GET_MESSAGES = "/messages";
// export const ADD_MESSAGE = "add/message";
// export const GET_CHANNELS = "/channels";
// export const DELETE_MESSAGE = "delete/message";

// Asset
export const GET_CONNECTORS = "/asset/getConnectorTypes?isActive=";
export const GET_MODEL = "/asset/getModelTypes";
export const ADD_EVSE = "/asset/addEVSE";
export const LIST_ALL_EVSE = "/asset/listAllEVSE?isActive=";
export const DETAIL_EVSE = "/asset/getEVSEDetails/";
export const DETAIL_EVSE_CONNECTOR_SINGLE = "/asset/getConnectorDetails/";
export const DETAIL_EVSE_CONNECTOR = "/asset/getEVSEConnectors/";
export const GET_CONNECTORS_ICONS = "/asset/getConnectorIcons";
export const GET_CONNECTOR_TYPES = "/asset/getConnectorTypes?isActive=";
export const ADD_CONNECTER_TYPE = "/asset/addConnectorType";
export const EDIT_CONNECTER_TYPE = "/asset/editConnectorType/";
export const DETAIL_CONNECTER_TYPE = "/asset/getConnectorTypeDetail/";
export const EVSE_EDIT_MODEL = "/asset/editEVSE/";
export const EVSE_EDIT_CONNECTER = "/asset/editEVSEConnector/";
export const TOGGLE_EVSE_MODLE = "/asset/EVSEStatus/";
export const DELET_EVSE_MODEL = "/asset/deleteEVSE/";
export const TOGGLE_CONNECTOR_MODLE = "/asset/connectorTypeStatus/";
export const DELET_CONNECTOR_MODLE = "/asset/deleteConnectorType/";

// Station
export const LIST_ALL_STATIONS = "/station/listAllStations";

export const GET_RESERVATION = "/station/getMyReservation";

export const DELETE_RESERVATION = "/station/deleteReservationWeb";


export const LIST_ALL_CONNECTOR = "/location/listAllConnector";


export const UPDATE_RESERVATION = "/station/reservationUpdate";


export const STATIONS_DETAIL = "/station/getStationDetails/";
export const ADD_STATION = "/station/addStation";
export const GET_STATION_NAME = "/station/getStationName/";
export const CHECK_STATION_NAME = "/station/checkStationName";
export const EDIT_STATION = "/station/editStation/";
export const GET_STATION_CONNECTOR = "/asset/getStationConnectors/";
export const ACTIVATE_DEACTIVATE_STATION_URL =
  "/station/activateDeactivateStation";
export const DELETE_STATION_URL = "/station/deleteStation";
// Tariff
export const LIST_ALL_TARIFFS = "/tariff/listAllTariffs";
export const TARIFF_DETAIL = "/tariff/getTariffDetails/";
export const ADD_TARIFF = "/tariff/addTariff";
export const EDIT_TARIFF = "/tariff/editTariff/";
export const DELET_TARIFF = "/tariff/deleteTariff/";
export const ASSIGN_TARIFF = "/tariff/assignTariff";

//DAshboard
export const DASHBOARD_DATA = "/dashboard/getDashboardCount";
export const SUPER_ADMIN_DASHBOARD_CUSTOMERS_URL =
  "/dashboard/superAdminDashboardCustomer";

export const LOCATION_MANAGER_DASHBOARD_DATA_URL =
  "/dashboard/LocationManagerDashboard";
export const SUPER_ADMIN_DASHBOARD_YEAR_URL =
  "/dashboard/dashboardYearBusiness";


export const LOCATION_MANAGER_DASHBOARD_DATA_URL_DOWNLOAD = "/dashboard/LocationManagerDashboardDownload";


export const BUSINESS_OWNER_DASHBOARD_BUSINESS_LOCATION_URL_DOWNLOAD = "/dashboard/BusinessOwnerDashboardDownload";





// RFID
export const ADD_NEW_RFID = "/rfid/addRFID";
export const BULK_IMPORT_RFID = "rfid/bulkImport";
export const UPDATE_RFID = "/rfid/updateRFID/";
export const DELETE_RFID = "/rfid/deleteRFID";
export const ASSIGN_BUSINESS_TO_RFID_URL = "/rfid/assignBusiness";
export const UNASSIGN_BUSINESS_FROM_RFID_URL = "/rfid/unAssignBusiness";
export const GET_UNASSIGNED_RFID_LIST = "/rfid/getUnassignedRFIDList";
export const GET_ASSIGNED_RFID_LIST = "/rfid/getAssignedRFIDList?filter=";
export const GET_RFID_COUNT = "/rfid/getRFIDCount";
export const GET_RFID_DATA = "/rfid/getRFIDDetails";



//business owner
export const ASSIGN_RFID_TO_STATION_BY_BUSINESS_OWNER_URL =
  "/rfid/assignLocationStation";

export const BUSINESS_OWNER_RFID_LIST_URL = "/rfid/getRFIDListAsBusinessOwner";
export const DATA_RFID_LIST_URL = "/rfid/getAssignedRFIDListByClient";
export const BUSINESS_OWNER_RFID_ASSIGN_LIST_URL =
  "/rfid/getStationLocationDetails";

export const SUPER_ADMIN_DASHBOARD_CUSTOMERS_DOWNLOAD_URL = "/dashboard/superAdminDashboardCustomerDownload";




// // Ecommerce
// // Product
// export const GET_PRODUCTS = "/apps/product";
// export const DELETE_PRODUCT = "/apps/product";
// export const ADD_NEW_PRODUCT = "/apps/product";
// export const UPDATE_PRODUCT = "/apps/product";

// // Orders
// export const GET_ORDERS = "/apps/order";
// export const ADD_NEW_ORDER = "/apps/order";
// export const UPDATE_ORDER = "/apps/order";
// export const DELETE_ORDER = "/apps/order";

// // Customers
// export const GET_CUSTOMERS = "/apps/customer";
// export const ADD_NEW_CUSTOMER = "/apps/customer";
// export const UPDATE_CUSTOMER = "/apps/customer";
// export const DELETE_CUSTOMER = "/apps/customer";

// // Sellers
// export const GET_SELLERS = "/sellers";

// // Project list
// export const GET_PROJECT_LIST = "/project/list";

// // Task
// export const GET_TASK_LIST = "/apps/task";
// export const ADD_NEW_TASK = "/apps/task";
// export const UPDATE_TASK = "/apps/task";
// export const DELETE_TASK = "/apps/task";

// // CRM
// // Conatct
// export const GET_CONTACTS = "/apps/contact";
// export const ADD_NEW_CONTACT = "/apps/contact";
// export const UPDATE_CONTACT = "/apps/contact";
// export const DELETE_CONTACT = "/apps/contact";

// // Companies
// export const GET_COMPANIES = "/apps/company";
// export const ADD_NEW_COMPANIES = "/apps/company";
// export const UPDATE_COMPANIES = "/apps/company";
// export const DELETE_COMPANIES = "/apps/company";

// // Lead
// export const GET_LEADS = "/apps/lead";
// export const ADD_NEW_LEAD = "/apps/lead";
// export const UPDATE_LEAD = "/apps/lead";
// export const DELETE_LEAD = "/apps/lead";

// // Deals
// export const GET_DEALS = "/deals";

// // Crypto
// export const GET_TRANSACTION_LIST = "/transaction-list";
// export const GET_ORDRER_LIST = "/order-list";

// // Invoice
// export const GET_INVOICES = "/apps/invoice";
// export const ADD_NEW_INVOICE = "/apps/invoice";
// export const UPDATE_INVOICE = "/apps/invoice";
// export const DELETE_INVOICE = "/apps/invoice";

// // TicketsList
// export const GET_TICKETS_LIST = "/apps/ticket";
// export const ADD_NEW_TICKET = "/apps/ticket";
// export const UPDATE_TICKET = "/apps/ticket";
// export const DELETE_TICKET = "/apps/ticket";

// // Dashboard Analytics

// // Sessions by Countries
// export const GET_ALL_DATA = "/all-data";
// export const GET_HALFYEARLY_DATA = "/halfyearly-data";
// export const GET_MONTHLY_DATA = "/monthly-data";

// // Audiences Metrics
// export const GET_ALLAUDIENCESMETRICS_DATA = "/allAudiencesMetrics-data";
// export const GET_MONTHLYAUDIENCESMETRICS_DATA = "/monthlyAudiencesMetrics-data";
// export const GET_HALFYEARLYAUDIENCESMETRICS_DATA = "/halfyearlyAudiencesMetrics-data";
// export const GET_YEARLYAUDIENCESMETRICS_DATA = "/yearlyAudiencesMetrics-data";

// // Users by Device
// export const GET_TODAYDEVICE_DATA = "/todayDevice-data";
// export const GET_LASTWEEKDEVICE_DATA = "/lastWeekDevice-data";
// export const GET_LASTMONTHDEVICE_DATA = "/lastMonthDevice-data";
// export const GET_CURRENTYEARDEVICE_DATA = "/currentYearDevice-data";

// // Audiences Sessions by Country
// export const GET_TODAYSESSION_DATA = "/todaySession-data";
// export const GET_LASTWEEKSESSION_DATA = "/lastWeekSession-data";
// export const GET_LASTMONTHSESSION_DATA = "/lastMonthSession-data";
// export const GET_CURRENTYEARSESSION_DATA = "/currentYearSession-data";

// // Dashboard CRM

// // Balance Overview
// export const GET_TODAYBALANCE_DATA = "/todayBalance-data";
// export const GET_LASTWEEKBALANCE_DATA = "/lastWeekBalance-data";
// export const GET_LASTMONTHBALANCE_DATA = "/lastMonthBalance-data";
// export const GET_CURRENTYEARBALANCE_DATA = "/currentYearBalance-data";

// // Deal type
// export const GET_TODAYDEAL_DATA = "/todayDeal-data";
// export const GET_WEEKLYDEAL_DATA = "/weeklyDeal-data";
// export const GET_MONTHLYDEAL_DATA = "/monthlyDeal-data";
// export const GET_YEARLYDEAL_DATA = "/yearlyDeal-data";

// // Sales Forecast

// export const GET_OCTSALES_DATA = "/octSales-data";
// export const GET_NOVSALES_DATA = "/novSales-data";
// export const GET_DECSALES_DATA = "/decSales-data";
// export const GET_JANSALES_DATA = "/janSales-data";

// // Dashboard Ecommerce
// // Revenue
// export const GET_ALLREVENUE_DATA = "/allRevenue-data";
// export const GET_MONTHREVENUE_DATA = "/monthRevenue-data";
// export const GET_HALFYEARREVENUE_DATA = "/halfYearRevenue-data";
// export const GET_YEARREVENUE_DATA = "/yearRevenue-data";

// // Dashboard Crypto
// // Portfolio
// export const GET_BTCPORTFOLIO_DATA = "/btcPortfolio-data";
// export const GET_USDPORTFOLIO_DATA = "/usdPortfolio-data";
// export const GET_EUROPORTFOLIO_DATA = "/euroPortfolio-data";

// // Market Graph
// export const GET_ALLMARKETDATA_DATA = "/allMarket-data";
// export const GET_YEARMARKET_DATA = "/yearMarket-data";
// export const GET_MONTHMARKET_DATA = "/monthMarket-data";
// export const GET_WEEKMARKET_DATA = "/weekMarket-data";
// export const GET_HOURMARKET_DATA = "/hourMarket-data";

// // Dashboard Crypto
// // Project Overview
// export const GET_ALLPROJECT_DATA = "/allProject-data";
// export const GET_MONTHPROJECT_DATA = "/monthProject-data";
// export const GET_HALFYEARPROJECT_DATA = "/halfYearProject-data";
// export const GET_YEARPROJECT_DATA = "/yearProject-data";

// // Project Status
// export const GET_ALLPROJECTSTATUS_DATA = "/allProjectStatus-data";
// export const GET_WEEKPROJECTSTATUS_DATA = "/weekProjectStatus-data";
// export const GET_MONTHPROJECTSTATUS_DATA = "/monthProjectStatus-data";
// export const GET_QUARTERPROJECTSTATUS_DATA = "/quarterProjectStatus-data";

// // Dashboard NFT
// // Marketplace
// export const GET_ALLMARKETPLACE_DATA = "/allMarketplace-data";
// export const GET_MONTHMARKETPLACE_DATA = "/monthMarketplace-data";
// export const GET_HALFYEARMARKETPLACE_DATA = "/halfYearMarketplace-data";
// export const GET_YEARMARKETPLACE_DATA = "/yearMarketplace-data";

// // Project
// export const ADD_NEW_PROJECT = "/add/project";
// export const UPDATE_PROJECT = "/update/project";
// export const DELETE_PROJECT = "/delete/project";

// // Pages > Team
// export const GET_TEAMDATA = "/teamData";
// export const DELETE_TEAMDATA = "/delete/teamData";
// export const ADD_NEW_TEAMDATA = "/add/teamData";
// export const UPDATE_TEAMDATA = "/update/teamData";

// // File Manager
// // Folder
// export const GET_FOLDERS = "/folder";
// export const DELETE_FOLDER = "/delete/folder";
// export const ADD_NEW_FOLDER = "/add/folder";
// export const UPDATE_FOLDER = "/update/folder";

// // File
// export const GET_FILES = "/file";
// export const DELETE_FILE = "/delete/file";
// export const ADD_NEW_FILE = "/add/file";
// export const UPDATE_FILE = "/update/file";

// // To do
// export const GET_TODOS = "/todo";
// export const DELETE_TODO = "/delete/todo";
// export const ADD_NEW_TODO = "/add/todo";
// export const UPDATE_TODO = "/update/todo";

// // To do Project
// export const GET_PROJECTS = "/projects";
// export const ADD_NEW_TODO_PROJECT = "/add/project";
