import { PROFILE_ERROR, PROFILE_SUCCESS, EDIT_PROFILE, CHANGE_PASSWORD_SUCCESS, CHANGE_PASSWORD_ERROR, RESET_PROFILE_FLAG, CHANGE_PASSWORD, USER_PROFILE_DATA, USER_PROFILE_DATA_SUCCESS, EDIT_PROFILE_DATA_SUCESS, EDIT_PROFILE_DATA } from "./actionTypes"
export const editProfile = user => {
  return {
    type: EDIT_PROFILE,
    payload: { user },
  }
}

export const changePasswordData = user => {
  return {
    type: CHANGE_PASSWORD,
    payload: { user },
  }
}



export const userProfile = (history) => {
  return {
    type: USER_PROFILE_DATA,
    payload: {},
  }
}

export const editProfileData = (user) => {
  return {
    type: EDIT_PROFILE_DATA,
    payload: { user },
  }
}

export const userProfileSuccess = data => {
  return {
    type: USER_PROFILE_DATA_SUCCESS,
    payload: data,

  }
}

export const editProfileDataSucess = (user) => {
  return {
    type: EDIT_PROFILE_DATA_SUCESS,
    payload: { user },
  }
}

export const profileSuccess = msg => {
  return {
    type: PROFILE_SUCCESS,
    payload: msg,
  }
}

export const changePasswordSucess = data => {
  console.log(data, 'ACTIONNNN')
  return {
    type: CHANGE_PASSWORD_SUCCESS,
    payload: data,
  }
}

export const changePasswordError = data => {
  console.log("data==>", data)
  return {
    type: CHANGE_PASSWORD_ERROR,
    payload: data,
  }
}


export const profileError = error => {
  return {
    type: PROFILE_ERROR,
    payload: error,
  }
}

export const resetProfileFlag = error => {
  return {
    type: RESET_PROFILE_FLAG,
  }
}
