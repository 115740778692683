import { LOCATION_LISTING_SUCCESS, LOCATION_DETAIL_SUCCESS, LOCATION_MAN_BUSINESS_SUCCESS, ALLSTATION_BUSINESS_LISTING_SUCCESS, ALLCONNECTOR_STATION_LISTING_SUCCESS, LOCATION_FILTER_LISTING_SUCCESS } from "./actionType";

const initialState = {
    allLocationList: [],
    allLocationListFilter: [],
    allStationBusinessList: [],
    LocationDetail: [],
    LocManBussDetail: [],
    allConnectorStationList: [],

};

const locationsList = (state = initialState, action) => {
    switch (action.type) {
        case LOCATION_LISTING_SUCCESS:
            state = {
                ...state,
                allLocationList: action?.payload,
            };
            break;
        case ALLSTATION_BUSINESS_LISTING_SUCCESS:
            state = {
                ...state,
                allStationBusinessList: action?.payload,
            };
            break;
        case ALLCONNECTOR_STATION_LISTING_SUCCESS:
            state = {
                ...state,
                allConnectorStationList: action?.payload,
            };
            break;


        case LOCATION_FILTER_LISTING_SUCCESS:
            state = {
                ...state,
                allLocationListFilter: action?.payload,
            };
            break;


        case LOCATION_DETAIL_SUCCESS:
            state = {
                ...state,
                LocationDetail: action?.payload
            };
            break;
        case LOCATION_MAN_BUSINESS_SUCCESS:
            state = {
                ...state,
                LocManBussDetail: action?.payload
            };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default locationsList;