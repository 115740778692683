import PropTypes from "prop-types";
import React from "react";
import { Modal, ModalBody } from "reactstrap";

const DisableModal = (prop) => {
  const { show, onActionClick, onCloseClick, subtitle, title, btnActionText, btncancelText } = prop;
  return (
    <Modal className='disableModal' isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalBody className="py-3 px-5">
        <div className="mt-2 text-center">
          {/* <lord-icon
          src="https://cdn.lordicon.com/gsqxdxog.json"
          trigger="loop"
          colors="primary:#FFBC0A,secondary:#f06548"
          style={{ width: "100px", height: "100px" }}
        ></lord-icon> */}
          <div className="outline">
            <div className="inner"></div>
          </div>
          <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
            <h4>{title}</h4>
            <p className="text-muted mx-4 mb-0">
              {subtitle}
            </p>
          </div>
        </div>
        <div className="d-flex justify-content-center mt-4 mb-2">
          <button
            type="button"
            className="btn w-sm btn-light"
            data-bs-dismiss="modal"
            onClick={onCloseClick}
          >
            {btncancelText}
          </button>
          <button
            type="button"
            className="btn w-sm btn-danger "
            id="delete-record"
            onClick={onActionClick}
          >
            {btnActionText}
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};

DisableModal.propTypes = {
  onCloseClick: PropTypes.func,
  onActionClick: PropTypes.func,
  show: PropTypes.any
};

export default DisableModal;