export const LIST_ALL_STATION = 'LIST_ALL_STATION'
export const LIST_ALL_STATION_SUCCESS = 'LIST_ALL_STATION_SUCCESS'
export const UPDATE_RESERVATION = 'UPDATE_RESERVATION'
export const UPDATE_RESERVATION_SUCCESS = 'UPDATE_RESERVATION_SUCCESS'
export const VIEW_DETAIL_STATION = 'VIEW_DETAIL_STATION'
export const VIEW_DETAIL_STATION_SUCCESS = 'VIEW_DETAIL_STATION_SUCCESS'
export const ADD_STATION = 'ADD_STATION';
export const GET_STATION_NAME = 'GET_STATION_NAME'
export const GET_STATION_NAME_SUCCESS = 'GET_STATION_NAME_SUCCESS';
export const CHECK_STATION = 'CHECK_STATION';
export const EDIT_STATIONS = 'EDIT_STATIONS';
export const GET_STATION_CONNECTOR = 'GET_STATION_CONNECTOR';
export const GET_STATION_CONNECTOR_SUCCESS = 'GET_STATION_CONNECTOR_SUCCESS';
export const GET_SOCKET_DATA = 'GET_SOCKET_DATA';
export const GET_SOCKET_HEART_DATA = 'GET_SOCKET_HEART_DATA';

export const SOCKET_CREDS = 'SOCKET_CREDS';
export const FIRMWARE_STATUS = 'FIRMWARE_STATUS';
export const RESET_FIRMWARE_STATUS = 'RESET_FIRMWARE_STATUS';
export const ACTIVATE_DEACTIVATE_STATION = "ACTIVATE_DEACTIVATE_STATION";
export const DELETE_STATION = "DELETE_STATION";
export const GET_RESERVATION = "GET_RESERVATION";
export const LIST_RESERVATION_SUCCESS = "LIST_RESERVATION_SUCCESS";
export const DELETE_RESERVATION = "DELETE_RESERVATION";
export const DELETE_RESERVATION_SUCESS = "DELETE_RESERVATION_SUCESS";

export const LIST_ALL_CONNECTOR = "LIST_ALL_CONNECTOR";
export const LIST_ALL_CONNECTOR_SUCCESS = "LIST_ALL_CONNECTOR_SUCCESS";
