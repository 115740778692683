import {
    LOGS_LISTING,
    LOGS_LISTING_SUCCESS,
 
} from "./actionType";


export const allLogs = (data,success,error) => {
    return {
        type: LOGS_LISTING,
        payload: { data },
        success : (data) =>success(data),
        error : (data) =>error(data)
    };
};

export const LogsListSuccess = data => {
    return {
        type: LOGS_LISTING_SUCCESS,
        payload: data,
    };
};

 