import {
    GET_CONNECTOR_TYPES,
    GET_CONNECTOR_TYPES_SUCCESS,
    GET_MODEL_TYPES,
    GET_MODEL_TYPES_SUCCESS,
    GET_CONNECTORS_ICONS,
    GET_CONNECTORS_ICONS_SUCCESS,
    ADD_EVSE,
    LIST_ALL_EVSE,
    LIST_ALL_EVSE_SUCCESS,
    DETAIL_EVSE,
    DETAIL_EVSE_SUCCESS,
    DETAIL_EVSE_CONNECTOR,
    DETAIL_EVSE_CONNECTOR_SUCCESS,
    GET_CONNECTOR_LIST_SUCCESS,
    GET_CONNECTOR_LIST,
    ADD_CONNECTORS_TYPE_DATA,
    EDIT_CONNECTOR_TYPE,
    DETAIL_CONNECTOR_TYPE,
    DETAIL_CONNECTOR_TYPE_SUCCESS,
    DETAIL_EVSE_SINGLE_CONNECTOR_SUCCESS,
    DETAIL_EVSE_SINGLE_CONNECTOR,
    EDIT_EVSE_MODEL,
    GET_CONNECTORS_DETAILS,
    GET_CONNECTORS_DETAILS_SUCCESS,
    EDIT_EVSE_CONNECTER,
    TOGGLE_EVSE,
    DELET_EVSE,
    TOGGLE_CONNECTOR,
    DELET_CONNECTOR
} from "./actionTypes";

export const connectorTypes = (data) => {
    return {
        type: GET_CONNECTOR_TYPES,
        payload: { data },
    };
};

export const connectorTypesSuccess = data => {
    return {
        type: GET_CONNECTOR_TYPES_SUCCESS,
        payload: data,
    };
}

export const modelTypes = () => {
    return {
        type: GET_MODEL_TYPES,
        payload: {},
    };
};

export const modelTypesSuccess = data => {
    return {
        type: GET_MODEL_TYPES_SUCCESS,
        payload: data,
    };
}
export const connecterIcon = () => {
    return {
        type: GET_CONNECTORS_ICONS,
        payload: {},
    };
}

export const connecterIconSuccess = data => {
    return {
        type: GET_CONNECTORS_ICONS_SUCCESS,
        payload: data,
    };
}

export const addEVSE = (data, history) => {
    return {
        type: ADD_EVSE,
        payload: { data, history }
    }

}

export const listAllEVSE = (data) => {
    return {
        type: LIST_ALL_EVSE,
        payload: { data }
    };
}

export const listAllEVSEsuccess = (data) => {
    return {
        type: LIST_ALL_EVSE_SUCCESS,
        payload: data
    };
}

export const detailEvse = (Id) => {
    return {
        type: DETAIL_EVSE,
        payload: { Id }
    };
}

export const DetailEvseSuccess = (data) => {
    return {
        type: DETAIL_EVSE_SUCCESS,
        payload: data
    };
}

export const DetailEvseConnector = (Id) => {
    return {
        type: DETAIL_EVSE_CONNECTOR,
        payload: { Id }
    };
}

export const DetailEvseConnectorSuccess = (data) => {
    return {
        type: DETAIL_EVSE_CONNECTOR_SUCCESS,
        payload: data
    };
}

export const getConnectorList = (data) => {
    return {
        type: GET_CONNECTOR_LIST,
        payload: { data }
    };
}

export const getConnectorListSuccess = (data) => {
    return {
        type: GET_CONNECTOR_LIST_SUCCESS,
        payload: data
    };
}

export const addconnectortype = (data, history) => {
    return {
        type: ADD_CONNECTORS_TYPE_DATA,
        payload: { data, history },
    };
}
export const editconnecter = (data, ID) => {
    return {
        type: EDIT_CONNECTOR_TYPE,
        payload: { data, ID },
    };
}

export const Detailconnecter = (data) => {
    return {
        type: DETAIL_CONNECTOR_TYPE,
        payload: { data },
    };
}

export const DetailconnecterSuccess = (data) => {
    return {
        type: DETAIL_CONNECTOR_TYPE_SUCCESS,
        payload: data,
    };
}

export const editEvseModel = (data, ID, history) => {
    return {
        type: EDIT_EVSE_MODEL,
        payload: { data, ID, history },
    };
}

export const DetailEvseSingleConnector = (Id) => {
    return {
        type: DETAIL_EVSE_SINGLE_CONNECTOR,
        payload: { Id }
    };
}

export const DetailEvseSingleConnectorSuccess = (data) => {
    return {
        type: DETAIL_EVSE_SINGLE_CONNECTOR_SUCCESS,
        payload: data
    };
}

export const editEvseConnecter = (data, Id, history) => {
    return {
        type: EDIT_EVSE_CONNECTER,
        payload: { data, Id, history },
    };
}

export const toggelEvse = (data, id) => {
    return {
        type: TOGGLE_EVSE,
        payload: { data, id },
    };
}

export const DeletEvse = (data, id, history) => {
    return {
        type: DELET_EVSE,
        payload: { data, id, history },
    };
}

export const toggelCONNECTOR = (data, id) => {
    return {
        type: TOGGLE_CONNECTOR,
        payload: { data, id },
    };
}

export const DeletCONNECTOR = (data, id) => {
    return {
        type: DELET_CONNECTOR,
        payload: { data, id },
    };
}