import React from 'react'
import '../../assets/scss/components/_logoComponent.scss'

const LogoComponent = ({ image }) => {
    return (
        <div className={image !== false ? 'main d-flex justify-content-center' : 'main'}>
            {image !== false ? image !== '' ? <img style={{
                height: '68px',
                objectFit: 'cover'
            }} src={`${process.env.REACT_APP_CLOUDINARY_URL}${image}`} alt="" /> : <>
                <div className='outLine logoComponent d-flex align-items-center justify-content-center mb-2' style={{ borderRadius: '50px', width: '72px', height: '72px' }}>
                    <span style={{ color: 'rgb(255, 255, 255)', fontSize: '20px' }}>{
                        localStorage.getItem('businessName')?.split(' ').map(i => i.charAt(0).toUpperCase()).join('')
                    }</span>
                </div>
            </>
                : <>
                    <p className='text1'>Vidhyutam</p>
                    {/* <p className='text2'>Electric Vehicle Chargers</p> */}
                </>
            }
        </div>

    )
}

export default LogoComponent;