import {
  API_RESPONSE_SUCCESS_DATA,
  GET_SUPERADMIN_CUSTOMER_SUCCESS,
  SUPER_ADMIN_DASHBOARD_CUSTOMERS_DOWNLOAD_SUCCESS,
  GET_BUSINESS_LOCATION_DOWNLOAD_SUCCESS,
  GET_SUPERADMIN_CUSTOMER_FAILURE,
  GET_BUSINESS_LOCATION_SUCCESS,
  GET_BUSINESS_LOCATION_FAILURE,
  GET_LOCATION_MANAGER_DATA_SUCCESS,
  GET_SUPERADMIN_DASHBOARD_YEAR_SUCCESS,
  GET_LOCATION_MANAGER_DATA_FAILURE,
  GET_SUPERADMIN_DASHBOARD_YEAR,
  GET_SUPERADMIN_BUSINESS_TRANSACTION_SUCCESS,
  GET_HEATMAP_DATA_SUCCESS,
  GET_LOCATION_MANAGER_DOWNLOAD_DATA_SUCCESS
} from "./actionType";

const INIT_STATE = {
  dashboardData: [],
  projectStatusData: [],
  dashboardCustomer: [],
  dashboardCustomerDownload: [],
  dashboardBusinessYear: [],
  businessLocations: [],
  businessLocationsDownload: [],
  stationDetailsDownload: [],
  stationDetails: [],
  businessTransactionSA: [],
  heatMapDataLM: []
};

const DashboardProject = (state = INIT_STATE, action) => {
  switch (action.type) {
    case API_RESPONSE_SUCCESS_DATA:
      state = {
        ...state,
        dashboardData: action.payload,
      };
      break;
    case GET_SUPERADMIN_BUSINESS_TRANSACTION_SUCCESS:
      state = {
        ...state,
        businessTransactionSA: action.payload,
      };
      break;
    case GET_HEATMAP_DATA_SUCCESS:
      state = {
        ...state,
        heatMapDataLM: action.payload,
      };
      break;

    case GET_SUPERADMIN_CUSTOMER_SUCCESS:
      state = {
        ...state,
        dashboardCustomer: action.payload,
      };
      break;

    case SUPER_ADMIN_DASHBOARD_CUSTOMERS_DOWNLOAD_SUCCESS:
      state = {
        ...state,
        dashboardCustomerDownload: action.payload,
      };
      break;
    case GET_BUSINESS_LOCATION_DOWNLOAD_SUCCESS:
      state = {
        ...state,
        businessLocationsDownload: action.payload,
      };
      break;
    case GET_LOCATION_MANAGER_DOWNLOAD_DATA_SUCCESS:
      state = {
        ...state,
        stationDetailsDownload: action.payload,
      };
      break;

    case GET_SUPERADMIN_DASHBOARD_YEAR:
      state = {
        ...state,
        dashboardBusinessYear: action.payload,
      };
      break;



    case GET_SUPERADMIN_CUSTOMER_FAILURE:
      state = {
        ...state,
        dashboardCustomer: [],
      };
      break;
    case GET_BUSINESS_LOCATION_SUCCESS:
      state = {
        ...state,
        businessLocations: action.payload,
      };
      break;
    case GET_BUSINESS_LOCATION_FAILURE:
      state = {
        ...state,
        businessLocations: [],
      };
      break;
    case GET_LOCATION_MANAGER_DATA_SUCCESS:
      state = {
        ...state,
        stationDetails: action.payload,
      };
      break;
    case GET_LOCATION_MANAGER_DATA_FAILURE:
      state = {
        ...state,
        stationDetails: [],
      };
      break;
    default:
      state = { ...state };
      break;


    case GET_SUPERADMIN_DASHBOARD_YEAR_SUCCESS:
      state = {
        ...state,
        dashboardBusinessYear: action.payload,
      };
      break;


  }
  return state;
};
export default DashboardProject;
