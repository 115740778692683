import {
  LOGIN,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  RESET_LOGIN_FLAG,
  REFRESH_TOKEN
} from "./actionTypes";

const initialState = {
  error: "",
  loading: false,
  isLoggedIn: false || window.localStorage.getItem('islogged') == 'true'
};

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      state = {
        ...state,
        loading: true,
        // isLoggedIn: true
      };
      break;
    case LOGIN_SUCCESS:
      state = {
        ...state,
        loading: false,
        isLoggedIn: true
      };
      break;
    case LOGOUT_USER:
      state = { ...state, isUserLogout: false, isLoggedIn: false };
      break;
    case LOGOUT_USER_SUCCESS:
      state = { ...state, isUserLogout: true, isLoggedIn: false };
      break;
    case REFRESH_TOKEN:
      state = {
        ...state,
      }
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default login;
