import React from 'react'

const AuthLogo = () => {
    return (
        <div className='mainAuth'>
            <p className='text1'>Vidhyutam</p>
            {/* <p className='text2'>Electric Vehicle Chargers</p> */}
        </div>
    )
}

export default AuthLogo;