import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button, Card, CardBody, Col, Container, Row, Form, Input, Label, FormFeedback, UncontrolledAlert } from 'reactstrap';
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import { setPassword } from '../../store/actions';
import { verifyEmail } from '../../store/auth/forgetpwd/actions';

//formik
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Constant from '../../utils/constant';
import { showToast } from '../../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import AuthLogo from '../../Components/Common/AuthLogo';
import CustomButton from '../../Components/Common/CustomButton';

const TokenVerified = (props) => {

    document.title = "Set Password | Vidhyutam";
    const dispatch = useDispatch();
    const history = useHistory();

    const { user } = useSelector(state => ({
        user: state.ForgetPassword,
    }));

    

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const linkToken = urlParams.get('token');
        dispatch(verifyEmail(linkToken))
    }, [])


    
    return (
        <ParticlesAuth>
            <div className="auth-page-content set-password">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <Card className="mt-4">

                                <CardBody className="p-4">
                                    <Row>
                                        <Col lg={12}>
                                            <div className="text-center mt-sm-2 mb-4 text-white-50">
                                                <AuthLogo />
                                            </div>
                                        </Col>
                                    </Row>
                                    {!user.customerVerified && !user.verifyEmailError && <div className="text-center mt-2">
                                        <h5 className="text-success">Please wait...</h5>
                                        <p className="text-muted">Verifying your account</p>
                                    </div>}
                                    {user.customerVerified && !user.verifyEmailError &&
                                        <div className="text-center mt-2">
                                            <h5 className="text-success">Your account has been verified</h5>
                                        </div>
                                    }
                                    {!user.customerVerified && user.verifyEmailError &&
                                        <div className="text-center mt-2">
                                            <h5 className="text-success">Error: Please try again</h5>
                                        </div>
                                    }

                                    <div className="p-2">
                                    </div>
                                </CardBody>
                            </Card>
                            {/* <div className="mt-4 text-center">
                                <p className="mb-0">Back to <Link to="/login" className="text-success text-decoration-underline"> Sign-in </Link> </p>
                            </div> */}
                        </Col>
                    </Row>
                </Container>
            </div>
        </ParticlesAuth>
    );
};

export default TokenVerified;