import React, { useEffect, useState } from "react";
import { Redirect, Route, useHistory } from "react-router-dom";
import { setAuthorization } from "../helpers/api_helper";
import { useDispatch } from "react-redux";

import { useProfile } from "../Components/Hooks/UserHooks";

import { logoutUser } from "../store/actions";

const AuthProtected = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [token, setToken] = useState();
  // const { userProfile, loading, token } = useProfile();
  useEffect(() => {
    const token = JSON.parse(sessionStorage.getItem("token")) ? JSON.parse(sessionStorage.getItem("token"))?.accessToken : null;
    setToken(token)
    if (token) {
      setAuthorization(token);
    } else {
      // dispatch(logoutUser());
    }
  }, [dispatch]);





  /*
    redirect is un-auth access protected routes via url
    */

  // redirect to login if no token found or expired
  // if (token === null || window.localStorage.getItem('islogged') == null) {
  //   return (
  //     <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
  //   );
  // }
  // if (window.localStorage.getItem('islogged') == null) {
  //   return (
  //     <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
  //   );
  // }


  return <>{props.children}</>;

}




const AccessRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        return (<> <Component {...props} /> </>);
      }}
    />
  );
};

export { AuthProtected, AccessRoute };