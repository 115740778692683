import React, { useEffect, useState } from 'react';
import useWindowDimension from '../Hooks/useWindowDimension';

const ContentLoader = ({ style }) => {
    const dimensions = useWindowDimension();
    const [headerHeight, SetHeaderHeight] = useState(0);
    const [footerHeight, SetFooterHeight] = useState(0);
    const [tableTaskBar, setTableTaskBar] = useState(0);
    const [paginationDiv, setPaginationDiv] = useState(0);
    useEffect(() => {
        SetHeaderHeight(
          document.getElementsByClassName('navbar-header')[0]?.offsetHeight
        );
        setTableTaskBar(
          document.getElementsByClassName('tableTaskBar')[0]?.offsetHeight
        );
        setPaginationDiv(
          document.getElementsByClassName('paginationDiv')[0]?.offsetHeight
        );
        SetFooterHeight(
          document.getElementsByClassName('footer')[0]?.offsetHeight
        );
      }, []);
      const handleResize = () => {
    
        SetHeaderHeight(
          document.getElementsByClassName('navbar-header')[0]?.offsetHeight
        );
        setTableTaskBar(
          document.getElementsByClassName('tableTaskBar')[0]?.offsetHeight
        );
        setPaginationDiv(
          document.getElementsByClassName('paginationDiv')[0]?.offsetHeight
        );
        SetFooterHeight(
          document.getElementsByClassName('footer')[0]?.offsetHeight
        );
      };
    
      useEffect(() => {
        window.addEventListener('resize', handleResize);
      }, []);
    
    return (
        <div className='loaderWrapper' style={{ height: dimensions.height - headerHeight - footerHeight - tableTaskBar - paginationDiv - 210 + 'px' }}>
            <div className='contentLoader'></div>
        </div>
    );
};

export default ContentLoader;