import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import SimpleBar from "simplebar-react";
//import logo
import logoSm from "../assets/images/LubiLogoFinal.svg";
import logoDark from "../assets/images/LubiLogoFinal.svg";
import logoLight from "../assets/images/LubiLogoFinal.svg";

//Import Components
import VerticalLayout from "./VerticalLayouts";
import TwoColumnLayout from "./TwoColumnLayout";
import { Container } from "reactstrap";
import LogoComponent from "../Components/Common/LogoComponent";
import { useSelector } from "react-redux";

const Sidebar = ({ layoutType }) => {
  const { pathname } = useLocation();

  // const { user } = useSelector(state => ({
  //   // user: state.businessList.allBusinessList,
  //   user: state.businessList,
  // }));

  const { data } = useSelector(state => ({
    data: state?.DashboardProject?.dashboardData?.data
  }))

  // { "prefix": "USR", "userId": 333, "firstName": "Avolt", "lastName": "Admin", "email": "avolt@mailinator.com", "userRole": "BusinessOwner", "profileImage": null, "countryCode": 91, "contactNumber": 8989898989, "primaryColor": "#18416f" }
  

  useEffect(() => {
    var verticalOverlay = document.getElementsByClassName("vertical-overlay");
    if (verticalOverlay) {
      verticalOverlay[0].addEventListener("click", function () {
        document.body.classList.remove("vertical-sidebar-enable");
      });
    }
  });

  const addEventListenerOnSmHoverMenu = () => {
    // add listener Sidebar Hover icon on change layout from setting
    if (document.documentElement.getAttribute('data-sidebar-size') === 'sm-hover') {
      document.documentElement.setAttribute('data-sidebar-size', 'sm-hover-active');
    } else if (document.documentElement.getAttribute('data-sidebar-size') === 'sm-hover-active') {
      document.documentElement.setAttribute('data-sidebar-size', 'sm-hover');
    } else {
      document.documentElement.setAttribute('data-sidebar-size', 'sm-hover');
    }
  };


  // https://res.cloudinary.com/djyl1goby/image/upload/v1686112608/Lubi/upload-image/3c8a64e95dbcc3b4273faaf6359eeaf1.png
  return (
    <React.Fragment>
      <div className="app-menu navbar-menu" style={{ display: (pathname === '/onboarding') ? 'none' : '' }}>
        <div className="app-menuClose">
          <button className="bx bx-x-circle" type="button" onClick={() => document.body.classList.remove("vertical-sidebar-enable")}></button>
        </div>
        <div className="navbar-brand-box">
          {/* <Link to="/" className="logo logo-dark">
            <span className="logo-sm">
              <img src={logoSm} alt="" height="15" />
            </span>
            <span className="logo-lg">
              <img src={logoDark} alt="" height="40" />
            </span>
          </Link> */}
          <div className="mt-4 mb-4">
            {window.localStorage.getItem('businessLogo') !== null && JSON.parse(localStorage.getItem('users')).userRole !== 'SuperAdmin' ? <LogoComponent image={window.localStorage.getItem('businessLogo')} /> : <LogoComponent image={false} />}
          </div>
          <Link to="/" className="logo logo-light">
            {/* <span className="logo-sm">
              <img src={logoSm} alt="" height="15" />
            </span> */}
            {/* <span className="logo-lg">
              <img src={logoLight} alt="" height="40" />
            </span> */}
          </Link>
          <button
            onClick={addEventListenerOnSmHoverMenu}
            type="button"
            className="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover"
            id="vertical-hover"
          >
            <i className="bx bx-shopping-bag"></i>
          </button>
        </div>
        {layoutType === "horizontal" ? (
          <div id="scrollbar">
            <Container fluid>
              <div id="two-column-menu"></div>
              <ul className="navbar-nav" id="navbar-nav">
                {/* <HorizontalLayout /> */}
              </ul>
            </Container>
          </div>
        ) : layoutType === 'twocolumn' ? (
          <React.Fragment>
            <TwoColumnLayout layoutType={layoutType} />
            <div className="sidebar-background"></div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <SimpleBar id="scrollbar" className="h-100">
              <Container fluid>
                <div id="two-column-menu"></div>
                <ul className="navbar-nav" id="navbar-nav">
                  <VerticalLayout layoutType={layoutType} />
                </ul>
              </Container>
            </SimpleBar>
            <div className="sidebar-background"></div>
          </React.Fragment>
        )}
      </div>
      <div className="vertical-overlay"></div>
    </React.Fragment>
  );
};

export default Sidebar;
