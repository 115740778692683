import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Form,
    FormFeedback,
    Input,
    Label,
    Row,
} from "reactstrap";
import useWindowDimension from "../../Components/Hooks/useWindowDimension";
import OnBoardingPracticle from "./OnBoardingPracticle";
import logo from "../../assets/images/LubiLogoFinal.svg";
import bussinessLogo from "../../assets/images/companies/img-2.png";
import Constant from "../../utils/constant";
import { showToast } from "../../store/actions";
import OutsideClickHandler from "react-outside-click-handler";
import ReactInputMask from "react-input-mask";
import CustomReactColor from "../../Components/Common/CustomReactColor";

const OnBoarding = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const dimensions = useWindowDimension();
    const { pathname } = useLocation();
    const [fileImage, setImage] = useState(false);
    const [primaryColor, setPrimaryColor] = useState(false);
    const [changeEmailValue, setChangeEmailValue] = useState(false);
    const [editForm, setEditForm] = useState(false);
    const addbusinesses = useFormik({
        enableReinitialize: true,
        initialValues: {
            businessName: "Titania Pvt Ltd@",
            email: "mark.d@example.com",
            contactNumber: "83266 99852",
            businessOwnerName: "Mark Doe",
            website: "www.example.com",
            businessLogo: "Logo.svg",
            primaryColor: "",
            secondaryColor: "",
        },
        validationSchema: Yup.object({
            businessName: Yup.string().required("Business name required"),
            // .matches(Constant.ALPHABETIC.BUSINESSNAME, "Please use only ( A-z, . , _ - )"),
            email: Yup.string()
                .required("Email is required")
                .matches(Constant.REGEX.EMAIL, "Please provide a valid email address"),
            businessOwnerName: Yup.string().matches(
                Constant.REGEX.BUSINESSOWNERNAME,
                "Please use only ( A-z, . , _ - )"
            ),
            website: Yup.string().matches(
                Constant.REGEX.WEBSITEURL,
                "Please provide a valid website address"
            ),
            contactNumber: Yup.string()
                .required("Contact number is required")
                .min(14, "Contact number must be at least 10"),
            // .matches(Constant.REGEX.NOSPECIALCHARACTERS, 'No special character')
            // .matches(Constant.REGEX.PHONENUMBER, 'Please provide a valid contact number'),
            primaryColor: Yup.string(),
            secondaryColor: Yup.string(),
        }),
        onSubmit: (values, fileImage) => {
            //(">>>", values, fileImage);
            onSuccess();
        },
    });

    const onSuccess = (values) => {
        dispatch(
            showToast({
                message: "Business details updated successfully.",
                type: "success",
            })
        );
        setTimeout(() => {
            history.goBack();
        }, 3000);
    };
    const handleFileUpload = (event) => {
        setImage(URL.createObjectURL(event.target.files[0]));
        
    };
    // const emailAutoData = [
    //     {
    //         id: '1',
    //         emailName: 'mark@mailinator.com'
    //     },
    //     {
    //         id: '2',
    //         emailName: 'james@mailinator.com'
    //     },
    //     {
    //         id: '3',
    //         emailName: 'jack@mailinator.com'
    //     },
    // ]
    const OnBoardingData = [
        {
            DataTitle: "Business Name",
            DataCompanyname: "Titania Pvt Ltd",
        },
        {
            DataTitle: "Business Owner Name",
            DataCompanyname: "EH00018",
        },
        {
            DataTitle: "Email Address",
            DataCompanyname: "EH00018",
        },
        {
            DataTitle: "Contact Number",
            DataCompanyname: "83266 99852",
        },
        {
            DataTitle: "Website",
            DataCompanyname: "www.example-1.org",
        },
    ];

    return (
        <OnBoardingPracticle>
            <div
                className="onBoardingPage"
                style={{ height: dimensions.height + "px" }}
            >
                <Row>
                    <Col lg={12}>
                        <div className="text-center mb-5 mt-3 text-white-50">
                            <div>
                                <Link to="/" className="d-inline-block auth-logo">
                                    <img src={logo} alt="" />
                                </Link>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <div className="main-container">
                        {editForm ? (
                            <div className="EditonBording">
                                <Form
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        addbusinesses.handleSubmit();
                                        return false;
                                    }}
                                    action="#"
                                >
                                    <Row>
                                        <Col xxl={8} xl={8}>
                                            <Card className="">
                                                <CardHeader>
                                                    <Row>
                                                        <Col lg={12}>
                                                            <p className="headerText headerTextTitle">
                                                                Edit Business
                                                            </p>
                                                        </Col>
                                                    </Row>
                                                </CardHeader>
                                                <CardBody className="">
                                                    <Row>
                                                        <Col lg={12}>
                                                            <div className="mb-3">
                                                                <Label
                                                                    htmlFor="businessName"
                                                                    className="form-label requierdLabel"
                                                                >
                                                                    Business Name
                                                                </Label>
                                                                <Input
                                                                    name="businessName"
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="businessName"
                                                                    maxLength={250}
                                                                    placeholder="Enter business name"
                                                                    value={
                                                                        addbusinesses.values.businessName || ""
                                                                    }
                                                                    onChange={addbusinesses.handleChange}
                                                                    onKeyUp={addbusinesses.handleBlur}
                                                                    invalid={
                                                                        addbusinesses.errors.businessName &&
                                                                            addbusinesses.touched.businessName
                                                                            ? true
                                                                            : false
                                                                    }
                                                                />
                                                                {addbusinesses.errors.businessName &&
                                                                    addbusinesses.touched.businessName ? (
                                                                    <FormFeedback type="invalid">
                                                                        {addbusinesses.errors.businessName}
                                                                    </FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </Col>
                                                        <Col lg={12}>
                                                            <div className="mb-3">
                                                                <Label
                                                                    htmlFor="emailInput"
                                                                    className="form-label required"
                                                                >
                                                                    Email Address
                                                                </Label>

                                                                <div className="emailAuto">
                                                                    <Input
                                                                        name="email"
                                                                        type="email"
                                                                        className={
                                                                            pathname === "/businesses/editbusiness"
                                                                                ? "form-control readEmail"
                                                                                : "form-control"
                                                                        }
                                                                        id="emailInput"
                                                                        placeholder="Enter email address"
                                                                        disabled={true}
                                                                        onChange={(e) => {
                                                                            addbusinesses.handleChange(e);
                                                                            setChangeEmailValue(true);
                                                                        }}
                                                                        onKeyUp={addbusinesses.handleBlur}
                                                                        value={addbusinesses.values.email || ""}
                                                                        invalid={
                                                                            addbusinesses.errors.email &&
                                                                                addbusinesses.touched.email
                                                                                ? true
                                                                                : false
                                                                        }
                                                                    />

                                                                    {addbusinesses.errors.email &&
                                                                        addbusinesses.touched.email ? (
                                                                        <FormFeedback type="invalid">
                                                                            {addbusinesses.errors.email}
                                                                        </FormFeedback>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col lg={12}>
                                                            <div className="mb-3 phoneDiv">
                                                                <Label
                                                                    htmlFor="phonenumberInput"
                                                                    className="form-label requierdLabel "
                                                                >
                                                                    Contact Number
                                                                </Label>
                                                                <ReactInputMask
                                                                    mask={"+\\91 9999999999"}
                                                                    maskChar={""}
                                                                    name="contactNumber"
                                                                    className={
                                                                        addbusinesses.errors.contactNumber &&
                                                                            addbusinesses.touched.contactNumber
                                                                            ? "form-control is-invalid"
                                                                            : "form-control"
                                                                    }
                                                                    id="phonenumberInput"
                                                                    placeholder="Enter contact number"
                                                                    onChange={addbusinesses.handleChange}
                                                                    onKeyUp={addbusinesses.handleBlur}
                                                                    value={
                                                                        addbusinesses.values.contactNumber || ""
                                                                    }
                                                                />
                                                                {addbusinesses.errors.contactNumber &&
                                                                    addbusinesses.touched.contactNumber ? (
                                                                    <FormFeedback>
                                                                        {addbusinesses.errors.contactNumber}
                                                                    </FormFeedback>
                                                                ) : null}

                                                            </div>
                                                        </Col>
                                                        <Col lg={12}>
                                                            <div className="mb-3">
                                                                <Label
                                                                    htmlFor="businessOwnerName"
                                                                    className="form-label"
                                                                >
                                                                    Business Owner Name
                                                                </Label>
                                                                <Input
                                                                    name="businessOwnerName"
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="businessOwnerName"
                                                                    placeholder="Business Owner Name"
                                                                    value={
                                                                        addbusinesses.values.businessOwnerName || ""
                                                                    }
                                                                    onChange={addbusinesses.handleChange}
                                                                    onKeyUp={addbusinesses.handleBlur}
                                                                    invalid={
                                                                        addbusinesses.errors.businessOwnerName &&
                                                                            addbusinesses.touched.businessOwnerName
                                                                            ? true
                                                                            : false
                                                                    }
                                                                />
                                                                {addbusinesses.errors.businessOwnerName &&
                                                                    addbusinesses.touched.businessOwnerName ? (
                                                                    <FormFeedback type="invalid">
                                                                        {addbusinesses.errors.businessOwnerName}
                                                                    </FormFeedback>
                                                                ) : null}
                                                                {/* {addbusinesses.errors.businessOwnerName && addbusinesses.touched.businessOwnerName ? (
                                                        <>
                                                            {!addbusinesses.values.businessOwnerName && <FormFeedback type="invalid">{addbusinesses.errors.businessOwnerName}</FormFeedback>}
                                                            {!Constant.REGEX.BUSINESSOWNERNAME.test(addbusinesses.values.businessOwnerName) && addbusinesses.values.businessOwnerName && <FormFeedback type="invalid">Please provide a valid contact number</FormFeedback>}
                                                        </>
                                                    ) : null} */}
                                                            </div>
                                                        </Col>

                                                        <Col lg={12}>
                                                            <Label
                                                                htmlFor="websiteInput1"
                                                                className="form-label"
                                                            >
                                                                Website
                                                            </Label>
                                                            <Input
                                                                name="website"
                                                                type="text"
                                                                className="form-control"
                                                                id="websiteInput1"
                                                                placeholder="Enter your website"
                                                                defaultValue=""
                                                                maxLength={250}
                                                                value={addbusinesses.values.website || ""}
                                                                invalid={
                                                                    addbusinesses.errors.website ? true : false
                                                                }
                                                                onChange={addbusinesses.handleChange}
                                                                onKeyUp={addbusinesses.handleBlur}
                                                            />
                                                            {addbusinesses.errors.website ? (
                                                                <FormFeedback type="invalid">
                                                                    {addbusinesses.errors.website}
                                                                </FormFeedback>
                                                            ) : null}
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col xxl={4} xl={4}>
                                            <Card className="businesslogo-page">
                                                <CardHeader>
                                                    <Row>
                                                        <Col lg={12}>
                                                            <p className="headerText">Business Logo</p>
                                                        </Col>
                                                    </Row>
                                                </CardHeader>
                                                <CardBody className="">
                                                    <Col lg={12}>
                                                        <div>
                                                            <Label htmlFor="formFile" className="form-label">
                                                                Business Logo (Opt.)
                                                            </Label>
                                                            <Input
                                                                className="form-control"
                                                                type="file"
                                                                id="formFile"
                                                                name="file"
                                                                accept="image/png, image/jpeg"
                                                                onChange={handleFileUpload}
                                                            />
                                                        </div>
                                                    </Col>
                                                </CardBody>
                                            </Card>
                                            <Card className="businesslogo-page">
                                                <CardHeader>
                                                    <Row>
                                                        <Col lg={12}>
                                                            <p className="headerText">Business Theme</p>
                                                        </Col>
                                                    </Row>
                                                </CardHeader>
                                                <CardBody className="">
                                                    {/* <Col lg={12}>
                                            <div className='mb-3'>
                                                <Label htmlFor="colorPicker" className="form-label">Primary Color (Opt.)</Label>
                                                <Input
                                                    type="color"
                                                    className="form-control form-control-color w-100"
                                                    id="colorPicker"
                                                    value={primaryColor || '#37D3EE'}
                                                    onChange={(e) => {
                                                        
                                                        setPrimaryColor(e.target.value);
                                                        addbusinesses.handleChange(e);
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                        <Col lg={12}>
                                            <div>
                                                <Label htmlFor="colorPicker" className="form-label">Secondary Color (Opt.)</Label>
                                                <Input
                                                    type="color"
                                                    className="form-control form-control-color w-100"
                                                    id="colorPicker"
                                                    value={secondaryColor || '#6D7080'}
                                                    onChange={(e) => {
                                                        
                                                        setSecondaryColor(e.target.value);
                                                        addbusinesses.handleChange(e);
                                                    }}
                                                />
                                            </div>
                                        </Col> */}
                                                    <Col lg={12}>
                                                        <div>
                                                            <Label
                                                                htmlFor="colorPicker"
                                                                className="form-label"
                                                            >
                                                                Primary Color
                                                            </Label>
                                                            <CustomReactColor
                                                                colorChange={(value) => {
                                                                    setPrimaryColor(value);
                                                                    
                                                                }}
                                                            />
                                                        </div>
                                                    </Col>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col lg={12}>
                                            <div className="BottomFormBtn">
                                                <Button
                                                    color="success"
                                                    disabled={
                                                        !(addbusinesses.dirty && addbusinesses.isValid)
                                                    }
                                                    className="btn btn-add-more btn btn-success"
                                                >
                                                    Update
                                                </Button>
                                                <Button
                                                    type="button"
                                                    className="btn cancelBtn btn-soft"
                                                    onClick={() => {
                                                        // history.push('/businesses')
                                                        setEditForm(false);
                                                    }}
                                                >
                                                    Cancel
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        ) : (
                            <>
                                <Card>
                                    <div className="card-header">
                                        <p className="headerText">Onboarding</p>
                                    </div>
                                    <Row>
                                        <Col lg={3}>
                                            <div className="leftPart  d-flex align-items-center justify-content-center flex-column">
                                                <div className="outLine d-flex align-items-center justify-content-center">
                                                    <img src={bussinessLogo} alt="" />
                                                </div>
                                                <p className="text-center">Titania Pvt Ltd</p>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={12}>
                                            <div className="rightPart">
                                                <Row>
                                                    <Col lg={10} className="lside">
                                                        <ul className="grid-Onboard">
                                                            {OnBoardingData.map((item, index) => {
                                                                return (
                                                                    <li key={index}>
                                                                        <div className="content">
                                                                            <p className="title">{item.DataTitle}</p>
                                                                            <p className="txt1">
                                                                                {item.DataCompanyname}
                                                                            </p>
                                                                        </div>
                                                                    </li>
                                                                );
                                                            })}
                                                            <li>
                                                                <div className="content">
                                                                    <p className="title mb-2">Theme</p>
                                                                    <div className="round">
                                                                        <span
                                                                            style={{ backgroundColor: "#37D3EE" }}
                                                                        ></span>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </Col>
                                                    <Col lg={2} className="rside">
                                                        <div className="box d-flex align-items-end justify-content-start flex-column">
                                                            <div className="actionBox">
                                                                <Button
                                                                    className={"editBtn"}
                                                                    onClick={() => {
                                                                        setEditForm(true);
                                                                    }}
                                                                >
                                                                    <i className="bx bx-edit-alt" />
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card>
                                <div className="BottomFormBtn">
                                    <Button color="success" className="btn btn-add-more">
                                        Go to Dashboard
                                    </Button>
                                </div>
                            </>
                        )}
                    </div>
                </Row>
            </div>
        </OnBoardingPracticle>
    );
};

export default OnBoarding;
