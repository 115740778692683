import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Col, Container, Form, FormFeedback, Input, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import classnames from "classnames";
import Flatpickr from "react-flatpickr";
import { getUserRole, uploadFile } from '../../../helpers/helper.service';

//import images
import avatar1 from '../../../assets/images/users/avatar-1.jpg';
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import Constant from '../../../utils/constant';
import { changePasswordError, changePasswordSucess, showToast } from '../../../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import ReactInputMask from 'react-input-mask';
import { changePasswordData, userProfile, editProfileData, logoutUser } from "../../../store/actions";
import PhoneInput from 'react-phone-input-2'
import startsWith from 'lodash.startswith';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import socket from "../../../socketConfig";

import { isValidPhoneNumber } from 'libphonenumber-js';

import 'react-phone-input-2/lib/style.css'
import { decryptResponseData } from '../../../helpers/encryptDecrypt'

const EditProfile = () => {
    document.title = "Edit Profile | Vidhyutam";
    const [activeTab, setActiveTab] = useState("1");
    const tabChange = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };
    const dispatch = useDispatch();
    const history = useHistory();
    const [oldPasswordShow, setOldPasswordShow] = useState(false);
    const [passwordShow, setPasswordShow] = useState(false);
    const [confrimPasswordShow, setConfrimPasswordShow] = useState(false);
    const [userData, setUserData] = useState([]);

    const { profileData } = useSelector((state) => {
        const alUserList = state?.Profile?.alUserList || [];

        // Check if alUserList is an array and map over it
        const decryptedUserList = Array.isArray(alUserList)
            ? alUserList.map(row => ({
                ...row,
                email: decryptResponseData(row.email),
                firstName: decryptResponseData(row.firstName),
                lastName: decryptResponseData(row.lastName),
            }))
            : [];

        return {
            profileData: decryptedUserList[0] || null, // Return the first decrypted user if available
        };
    });

    const { successToast, errorToast } = useSelector(state => ({
        errorToast: state?.Profile.changeDataErr,
        successToast: state?.Profile.changeDataSuc,
    }));

    const [phoneTouched, setPhoneTouched] = useState(false)
    const [phoneNumberError, setPhoneNumberError] = useState(false)

    const [phoneCode, setPhoneCode] = useState('')
    const [phoneContact, setPhoneContact] = useState('')
    const [fullContactNumber, setFullContactNumber] = useState()
    const [isValid, setIsValid] = useState(null);




    useEffect(() => {
        // if ((errorToast && errorToast != "") && (successToast && successToast != "")) {
        if (errorToast.message === 'Error') {
            dispatch(showToast({
                message: 'Incorrect Current Password',
                type: 'error'
            }));
            dispatch(changePasswordError(''))
        }
        if (successToast.message === 'Success') {
            onSuccessChangePassword()
            dispatch(changePasswordSucess(''))
        }
        // }


        // setFullContactNumber(fullContact)


    }, [errorToast, successToast]);
    useEffect(() => {
        let fullContact = `${profileData?.countryCode || ''}${profileData?.contactNumber || ''}`;

        setFullContactNumber(fullContact)
        if (fullContact) {
            setIsValid(true)

        }
    }, [profileData]);


    const ProfileForm = useFormik({
        enableReinitialize: true,

        initialValues: {
            email: profileData?.email || '',
            firstname: profileData?.firstName || '',
            lastname: profileData?.lastName || '',
            // phoneNumber: '+91 ' + profileData?.contactNumber.toString() || ''
            phoneNumber: profileData?.countryCode + profileData?.contactNumber || ''
        },
        validationSchema: Yup.object({
            firstname: Yup.string()
                .required('First name is required')
                .matches(Constant.REGEX.ALPHABETIC, 'Alphabetic characters only'),
            lastname: Yup.string()
                .matches(Constant.REGEX.ALPHABETIC, 'Alphabetic characters only'),
            email: Yup.string()
                .matches(Constant.REGEX.EMAIL, 'Invalid email address'),
            // phoneNumber: Yup.string()
            //     .required('Contact number is required')
            //     .min(14, 'Contact number must be at least 10'),
            phoneNumber: Yup.string()
                .required('Contact number is required')
            // .matches(/^([0-9]{8,15})$/, 'Please enter a valid phone number'),
        }),
        onSubmit: (values) => {
            let newObj = {
                firstName: values.firstname,
                lastName: values.lastname,
                contactNumber: Number(phoneContact),
                countryCode: phoneCode,
                profileImage: imageURL,
            }
            // dispatch(editBusiness(newObj));
            dispatch(editProfileData(newObj));
            dispatch(userProfile());
            onFormSuccess();

        }
    });
    const onFormSuccess = (values) => {
        // Success Toast
        dispatch(showToast({
            message: 'Personal details updated successfully.',
            type: 'success'
        }));
        setTimeout(() => {
            // history.push('/dashboard')
        }, 3000);
    }

    const changePassword = useFormik({
        enableReinitialize: true,

        initialValues: {
            new_password: "",
            confrim_password: "",
        },
        validationSchema: Yup.object({
            password: Yup.string()
                .required("This field is required"),
            // .matches('Admin@12345', 'Invalid password'),
            new_password: Yup.string()
                .required("This field is required")
                .min(8, 'Password must be in 8 to 16 characters')
                .max(16, 'Password must be in 8 to 16 characters')
                .matches(Constant.REGEX.UPPERCASE, 'At least 1 Capital letter')
                .matches(Constant.REGEX.LOWERCASE, 'At least 1 small letter')
                .matches(Constant.REGEX.SPECIALCHARACTERS, '1 Special character'),
            // .matches(Constant.REGEX.NUMBER, 'At least one number')
            // .matches(RegExp('(.*[a-z].*)'), 'At least lowercase letter')
            // .matches(RegExp('(.*[A-Z].*)'), 'At least uppercase letter')
            // .matches(RegExp('(.*[0-9].*)'), 'At least one number'),
            confrim_password: Yup.string()
                .when("new_password", {
                    is: (val) => (val && val.length > 0 ? true : false),
                    then: Yup.string().oneOf(
                        [Yup.ref("new_password")],
                        "Both passwords need to be the same"
                    ),
                })
                .required("Confirm password is required"),
        }),
        onSubmit: (values) => {
            dispatch(changePasswordData(values));
            // console.log(changedataAll, 'changedataAll.payload!!!!!!!!!!')
            // if (changedataAll.payload.message === 'Incorrect password') {
            //     dispatch(showToast({
            //         message: 'Current Password is Wrong',
            //         type: 'error'
            //     }));
            // } else {
            //     //  onSuccessChangePassword()
            // }
            // onSuccessChangePassword();
        }

    });
    const onSuccessChangePassword = (values) => {
        // Success Toast
        dispatch(showToast({
            message: 'Password updated successfully.',
            type: 'success'
        }));

        setTimeout(() => {
            // history.push('/dashboard')
            //   history.push('/login')
        }, 3000);
    }

    const FileUpload = useFormik({
        enableReinitialize: true,

        initialValues: {
        },
        validationSchema: Yup.object({

        }),
        onSubmit: (values) => {
            onSuccessChangePassword();
        }

    });

    const handleFileUpload = async (event) => {
        let logo = await uploadFile(event.target.files[0]);
        setImage(logo)
        setImageURL(logo)
        let newObj = {
            profileImage: logo,
            firstName: profileData.firstName,
            lastName: profileData.lastName,
            contactNumber: profileData.contactNumber,
        }
        dispatch(editProfileData(newObj));
    };
    const [fileImage, setImage] = useState(false);
    const [imageURL, setImageURL] = useState('');
    const [phone, setPhone] = useState('');
    const [country, setCountry] = useState('in'); // Default country code
    useEffect(() => {
        dispatch(userProfile());
        const userDetails = JSON.parse(localStorage.getItem('users'));
        setImageURL(userDetails?.profileImage)
        setImage(userDetails?.profileImage)
    }, []);




    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <div className='profilePage'>
                        <Row>
                            <Col xxl={3}>
                                <Card className="">
                                    <CardBody className="p-4">
                                        <div className="text-center">
                                            <div className="profile-user position-relative d-inline-block mx-auto  mb-4">
                                                {fileImage ? <img src={`${process.env.REACT_APP_CLOUDINARY_URL}${fileImage}`}
                                                    className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                                                    alt="user-profile" />
                                                    : <span className="rounded-circle avatar-xl img-thumbnail user-profile-image" style={{ fontSize: '34px', display: 'flex', justifyContent: 'center', alignItems: "center", color: 'red' }}>{`${profileData?.firstName} ${profileData?.lastName}`?.toUpperCase()?.match(/\b\w/g)?.join('')}</span>}
                                                {/* {profileData?.profileImage ?
                                                    <img src={profileData?.profileImage} alt='' />
                                                    : <span style={{ color: '#fff' }}>{`${profileData?.firstName} ${profileData?.lastName}`?.toUpperCase()?.match(/\b\w/g)?.join('')}</span>
                                                } */}
                                                <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
                                                    <Input
                                                        id="profile-img-file-input"
                                                        type="file"
                                                        className="profile-img-file-input"
                                                        accept="image/png, image/jpeg"
                                                        name="file"
                                                        onChange={handleFileUpload}
                                                    />
                                                    <Label htmlFor="profile-img-file-input"
                                                        className="profile-photo-edit avatar-xs">
                                                        <span className="avatar-title rounded-circle bg-light text-body">
                                                            <i className="ri-camera-fill"></i>
                                                        </span>
                                                    </Label>
                                                </div>
                                            </div>
                                            <h5 className="fs-16 mb-1">{`${profileData?.firstName || ''} ${profileData?.lastName || ''}`}</h5>
                                            <p className="text-muted mb-0">{profileData?.email || ''}</p>
                                            <p className="text-muted mb-0">
                                                {(profileData?.countryCode || profileData?.contactNumber) && `+${profileData?.countryCode || ''} ${profileData?.contactNumber || ''}`}
                                            </p>

                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col xxl={9}>
                                <Card className="profileCard">
                                    <CardHeader>
                                        <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                                            role="tablist">
                                            <NavItem className='pointer'>
                                                <NavLink
                                                    className={classnames({ active: activeTab === "1" })}
                                                    onClick={() => {
                                                        tabChange("1");
                                                    }}>
                                                    <i className="fas fa-home"></i>
                                                    Personal Details
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className='pointer'>
                                                <NavLink
                                                    className={classnames({ active: activeTab === "2" })}
                                                    onClick={() => {
                                                        tabChange("2");
                                                    }}
                                                >
                                                    <i className="far fa-user"></i>
                                                    Change Password
                                                </NavLink>
                                            </NavItem>
                                        </Nav>
                                    </CardHeader>
                                    <CardBody className="p-4 profileBody">
                                        <TabContent activeTab={activeTab}>
                                            <TabPane tabId="1">
                                                <Form

                                                    onSubmit={(e) => {
                                                        e.preventDefault();
                                                        ProfileForm.handleSubmit();
                                                        return true;
                                                    }}
                                                    action="#">
                                                    <Row>
                                                        <Col lg={6} md={6}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="firstnameInput" className="form-label requierdLabel">First
                                                                    Name</Label>
                                                                <Input
                                                                    name='firstname'
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="firstnameInput"
                                                                    placeholder="Enter First Name"
                                                                    maxLength={250}
                                                                    value={ProfileForm.values.firstname.trimStart()}
                                                                    invalid={
                                                                        ProfileForm.errors.firstname && ProfileForm.touched.firstname ? true : false
                                                                    }
                                                                    onChange={ProfileForm.handleChange}
                                                                    onKeyUp={ProfileForm.handleBlur}
                                                                />
                                                                {ProfileForm.errors.firstname && ProfileForm.touched.firstname ? (
                                                                    <FormFeedback type="invalid">{ProfileForm.errors.firstname}</FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </Col>
                                                        <Col lg={6} md={6}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="lastnameInput" className="form-label">Last Name</Label>
                                                                <Input
                                                                    name='lastname'
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="lastnameInput"
                                                                    placeholder="Enter Last Name"
                                                                    maxLength={250}
                                                                    value={ProfileForm.values.lastname.trimStart()}
                                                                    invalid={
                                                                        ProfileForm.errors.lastname && ProfileForm.touched.lastname ? true : false
                                                                    }

                                                                    onChange={ProfileForm.handleChange}
                                                                    onKeyUp={ProfileForm.handleBlur}
                                                                />
                                                                {ProfileForm.errors.lastname && ProfileForm.touched.lastname ? (
                                                                    <FormFeedback type="invalid">{ProfileForm.errors.lastname}</FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </Col>
                                                        <Col lg={6} md={6}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="emailInput" className="form-label requierdLabel">Email Address</Label>
                                                                <Input
                                                                    name='email'
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="emailInput"
                                                                    placeholder="Enter Email Address"
                                                                    maxLength={250}
                                                                    disabled={true}
                                                                    value={ProfileForm.values.email.trim() || profileData?.email}
                                                                    invalid={
                                                                        ProfileForm.errors.email && ProfileForm.touched.email ? true : false
                                                                    }
                                                                    onChange={ProfileForm.handleChange}
                                                                    onKeyUp={ProfileForm.handleBlur}
                                                                />
                                                                {ProfileForm.errors.email && ProfileForm.touched.email ? (
                                                                    <FormFeedback type="invalid">{ProfileForm.errors.email}</FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </Col>
                                                        <Col lg={6} md={6}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="numberInput" className="form-label">Contact Number</Label>
                                                                <PhoneInput
                                                                    className={'phoneinput textField'}
                                                                    placeholder={'Enter your phone number'}
                                                                    enableSearch={true}
                                                                    showDropdown={false}
                                                                    // priority={{ in: 1 }}
                                                                    inputProps={{
                                                                        name: 'phoneNumber',
                                                                        required: true,
                                                                    }}

                                                                    // country={'in'}
                                                                    countryCodeEditable={false}
                                                                    // value={businessDetail[0]?.countryCode + businessDetail[0]?.contactNumber}
                                                                    value={fullContactNumber || ''}
                                                                    // onChange={handlePhoneChange}

                                                                    onChange={(value, country, e, formattedValue) => {
                                                                        console.log(country, 'country')

                                                                        setPhone(value);
                                                                        setCountry(country.countryCode);

                                                                        // const phoneNumber = parsePhoneNumberFromString(value, countryData.countryCode.toUpperCase());
                                                                        const phoneNumber = parsePhoneNumberFromString(value, country.countryCode.toUpperCase());



                                                                        // console.log(formattedValue.split(' ')[0], 'country code')
                                                                        // console.log(formattedValue.split(' ')[1], 'Phone number')

                                                                        let [firstPart, ...restParts] = formattedValue.split(' ');
                                                                        // Join the rest of the parts, remove all special characters and spaces
                                                                        let cleanedPart = restParts.join('').replace(/[^\d+]/g, '');
                                                                        firstPart = firstPart.replace(/^\+/, '');
                                                                        setPhoneTouched(true);
                                                                        setPhoneCode(firstPart)
                                                                        setPhoneContact(cleanedPart)
                                                                        if (phoneNumber.isValid()) {
                                                                            setIsValid(phoneNumber.isValid());
                                                                            ProfileForm.setFieldValue('phoneNumber', cleanedPart);
                                                                        } else {
                                                                            setIsValid(false)
                                                                            ProfileForm.setFieldValue('phoneNumber', '');
                                                                        }

                                                                        // if (!phoneTouched) {
                                                                        //     console.log('YYYY')
                                                                        //     ProfileForm.setFieldValue('phoneNumber', cleanedPart);


                                                                        //     return true
                                                                        // }
                                                                        // else if (phoneNumber) {
                                                                        //     setPhoneNumberError('')
                                                                        //     ProfileForm.setFieldValue('phoneNumber', cleanedPart);

                                                                        // }
                                                                        // else {
                                                                        //     if (cleanedPart == '') {
                                                                        //         // setPhoneNumberError('Please enter phone number')
                                                                        //         ProfileForm.setFieldValue('phoneNumber', '');
                                                                        //         return;
                                                                        //     } else {
                                                                        //         // setPhoneNumberError('Please enter valid phone number')
                                                                        //         ProfileForm.setFieldValue('phoneNumber', '');

                                                                        //         return;
                                                                        //     }
                                                                        // }
                                                                    }}
                                                                />
                                                                {isValid !== null && (
                                                                    <div style={{ color: "#f06548" }}>
                                                                        {isValid ? '' : 'Contact number is not valid'}
                                                                    </div>
                                                                )}
                                                                {/* <ReactInputMask
                                                                    mask={"+\\91 9999999999"}
                                                                    maskChar={""}
                                                                    name="phoneNumber"
                                                                    className={
                                                                        ProfileForm.errors.phoneNumber &&
                                                                            ProfileForm.touched.phoneNumber
                                                                            ? "form-control is-invalid"
                                                                            : "form-control"
                                                                    }
                                                                    id="phonenumberInput"
                                                                    placeholder="Enter Contact Number"
                                                                    onChange={ProfileForm.handleChange}
                                                                    onKeyUp={ProfileForm.handleBlur}
                                                                    value={ProfileForm.values.phoneNumber || profileData?.contactNumber}
                                                                /> */}
                                                                {/* {phoneNumberError !== '' && phoneTouched &&
                                                                    <div className=" forminvalid-feedback"><span>{phoneNumberError}</span></div>
                                                                } */}

                                                                {/* {ProfileForm.errors.phoneNumber &&
                                                                    ProfileForm.touched.phoneNumber ? (
                                                                    <FormFeedback>
                                                                        {ProfileForm.errors.phoneNumber}
                                                                    </FormFeedback>
                                                                ) : null} */}
                                                                {/* <Input
                                                                    name='phoneNumber'
                                                                    type="tel"
                                                                    className="form-control"
                                                                    id="numberInput"
                                                                    placeholder="Enter contact number"
                                                                    maxLength={10}
                                                                    value={ProfileForm.values.phoneNumber || ''}
                                                                    invalid={
                                                                        ProfileForm.errors.phoneNumber && ProfileForm.touched.phoneNumber ? true : false
                                                                    }
                                                                    onChange={ProfileForm.handleChange}
                                                                    onKeyUp={ProfileForm.handleBlur}
                                                                />
                                                                {ProfileForm.errors.phoneNumber && ProfileForm.touched.phoneNumber ? (
                                                                    <>
                                                                        {!ProfileForm.values.phoneNumber && <FormFeedback type="invalid">{ProfileForm.errors.phoneNumber}</FormFeedback>}
                                                                        {!Constant.REGEX.PHONENUMBER.test(ProfileForm.values.phoneNumber) && ProfileForm.values.phoneNumber && <FormFeedback type="invalid">Please provide a valid contact number</FormFeedback>}
                                                                        {!Constant.REGEX.MIN_10_NUMBER.test(ProfileForm.values.phoneNumber) && ProfileForm.values.phoneNumber && <FormFeedback type="invalid">Contact number must be at least 10</FormFeedback>}
                                                                        {!Constant.REGEX.NOSPECIALCHARACTERS.test(ProfileForm.values.phoneNumber) && ProfileForm.values.phoneNumber && <FormFeedback type="invalid">No special character</FormFeedback>}
                                                                    </>
                                                                ) : null} */}
                                                            </div>
                                                        </Col>
                                                        <Col lg={12}>
                                                            <div className="hstack gap-2  mt-3 justify-content-end">
                                                                <Button type="submit"
                                                                    className="btn btn-success" disabled={!(ProfileForm.dirty && ProfileForm.isValid)}>Update</Button>
                                                                {/* className="btn btn-success">Update</Button> */}
                                                                <Button type="button"
                                                                    className="btn cancelBtn btn-soft" onClick={() => {
                                                                        history.push('/dashboard');
                                                                    }}>Cancel</Button>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            </TabPane>

                                            <TabPane tabId="2">
                                                <Form className='changePasswordForm' onSubmit={changePassword.handleSubmit} action="/reset-password">
                                                    <Row className="g-2">
                                                        <Col lg={4} className='m-0'>
                                                            <div className='mb-3'>
                                                                <Label htmlFor="oldpasswordInput" className="form-label requierdLabel">Current Password</Label>
                                                                <div className='position-relative auth-pass-inputgroup'>
                                                                    <Input
                                                                        name="password"
                                                                        type={oldPasswordShow ? "text" : "password"}
                                                                        className="form-control eyeInput"
                                                                        id="oldpasswordInput"
                                                                        placeholder="Enter Password"
                                                                        value={changePassword.values.password}
                                                                        onKeyUp={changePassword.handleBlur}
                                                                        onChange={(e) => changePassword.handleChange('password')(e.target.value.trim())}
                                                                        invalid={changePassword.errors.password && changePassword.touched.password ? true : false}
                                                                    />
                                                                    {changePassword.errors.password && changePassword.touched.password ? (
                                                                        <FormFeedback type="invalid">{changePassword.errors.password}</FormFeedback>
                                                                    ) : null}
                                                                    <Button
                                                                        color="link"
                                                                        onClick={() => setOldPasswordShow(!oldPasswordShow)}
                                                                        className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                                                                        type="button"
                                                                        id="password-addon"
                                                                        tabIndex="-1"
                                                                    >
                                                                        <i className="ri-eye-fill align-middle"></i>
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </Col>

                                                        <Col lg={4} className='m-0'>
                                                            <div className='mb-3'>
                                                                <Label htmlFor="newpasswordInput" className="form-label requierdLabel">New Password</Label>
                                                                <div className='position-relative auth-pass-inputgroup'>
                                                                    <Input
                                                                        name='new_password'
                                                                        type={passwordShow ? "text" : "password"}
                                                                        className="form-control password-input eyeInput"
                                                                        id="newpasswordInput"
                                                                        placeholder="Enter Password"
                                                                        value={changePassword.values.new_password.trim()}
                                                                        onChange={changePassword.handleChange}
                                                                        onKeyUp={changePassword.handleBlur}
                                                                        invalid={changePassword.errors.new_password && changePassword.touched.new_password ? true : false}
                                                                    />
                                                                    {changePassword.errors.new_password && changePassword.touched.new_password ? (
                                                                        <>
                                                                            {!changePassword.values.new_password && <FormFeedback type="invalid">{changePassword.errors.new_password}</FormFeedback>}
                                                                            {!Constant.REGEX.MIN_MAX_PASSWORD.test(changePassword.values.new_password) && changePassword.values.new_password && <FormFeedback type="invalid">Password must be in 8 to 16 characters</FormFeedback>}
                                                                            {!Constant.REGEX.UPPERCASE.test(changePassword.values.new_password) && changePassword.values.new_password && <FormFeedback type="invalid">At least 1 Capital letter</FormFeedback>}
                                                                            {!Constant.REGEX.LOWERCASE.test(changePassword.values.new_password) && changePassword.values.new_password && <FormFeedback type="invalid">At least 1 small letter</FormFeedback>}
                                                                            {!Constant.REGEX.NUMBER.test(changePassword.values.new_password) && changePassword.values.new_password && <FormFeedback type="invalid">At least 1 number</FormFeedback>}
                                                                            {!Constant.REGEX.SPECIALCHARACTERS.test(changePassword.values.new_password) && changePassword.values.new_password && <FormFeedback type="invalid">1 Special character</FormFeedback>}
                                                                        </>
                                                                    ) : null}
                                                                    <Button
                                                                        color="link"
                                                                        onClick={() => setPasswordShow(!passwordShow)}
                                                                        className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                                                                        type="button"
                                                                        id="password-addon"
                                                                        tabIndex="-1"
                                                                    >
                                                                        <i className="ri-eye-fill align-middle"></i>
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </Col>

                                                        <Col lg={4} className='m-0'>
                                                            <div className='mb-3'>
                                                                <Label htmlFor="confirmpasswordInput" className="form-label requierdLabel">Confirm Password</Label>
                                                                <div className='position-relative auth-pass-inputgroup'>
                                                                    <Input
                                                                        name="confrim_password"
                                                                        type={confrimPasswordShow ? "text" : "password"}
                                                                        className="form-control eyeInput"
                                                                        id="confirmpasswordInput"
                                                                        placeholder="Enter Password"
                                                                        value={changePassword.values.confrim_password.trim()}
                                                                        onChange={changePassword.handleChange}
                                                                        onKeyUp={changePassword.handleBlur}
                                                                        invalid={changePassword.errors.confrim_password && changePassword.touched.confrim_password ? true : false}
                                                                    />
                                                                    <Button
                                                                        color="link"
                                                                        onClick={() => setConfrimPasswordShow(!confrimPasswordShow)}
                                                                        className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                                                                        type="button"
                                                                        id="password-addon"
                                                                        tabIndex="-1"
                                                                    >
                                                                        <i className="ri-eye-fill align-middle"></i>
                                                                    </Button>
                                                                    {changePassword.errors.confrim_password && changePassword.touched.confrim_password ? (
                                                                        <FormFeedback type="invalid">{changePassword.errors.confrim_password}</FormFeedback>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        </Col>

                                                        <Col lg={12} className='m-0'>
                                                            <div className="hstack gap-2 mt-3 justify-content-end">
                                                                <Button type="submit"
                                                                    className="btn btn-success" disabled={!(changePassword.dirty && changePassword.isValid)}>Update</Button>
                                                                <Button type="button"
                                                                    className="btn cancelBtn btn-soft" onClick={() => {
                                                                        history.push('/dashboard');
                                                                    }}>Cancel</Button>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Form>

                                            </TabPane>
                                        </TabContent>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div >
        </React.Fragment >
    );
};

export default EditProfile;