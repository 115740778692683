import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';

const BreadCrumb = (prop) => {
    const { isButton, title, pageTitle, pageTitle2, onClickBack, child, OnClickpageTitle, OnClickpageTitle2, className } = prop;
    return (
        <React.Fragment>
            <Row className={`breadCrumb ${className}`}>
                <Col xs={12}>
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <div>
                            {isButton
                                ?
                                <Button className='backButton' onClick={onClickBack}>
                                    <i className='bx bx-chevron-left' />
                                    <span>Back</span>
                                </Button>
                                : <h4 className="mb-sm-0">{title}</h4>
                            }

                        </div>
                        {/* <Col sm={6}> */}
                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item"><Link onClick={OnClickpageTitle} to="#">{pageTitle}</Link></li>
                                {child && <li className="breadcrumb-item"><Link onClick={OnClickpageTitle2} to="#">{pageTitle2}</Link></li>}
                                <li className="breadcrumb-item active">{title}</li>
                            </ol>
                        </div>
                        {/* </Col> */}

                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default BreadCrumb;