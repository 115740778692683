
import React, { useEffect, useRef, useState } from 'react';
import { GoogleMap, InfoBox, Marker, withGoogleMap } from '@react-google-maps/api';

const MapComponent = (props) => {
    // let mapRef = useRef();
    const [center, setCenter] = useState()
    const [instance, setInstance] = React.useState(null);

    const onLoad = React.useCallback(
        function onLoad(map) {
            setInstance(map);
        },
        [setInstance]
    );

    const handleChange = React.useCallback(
        function onCenterChanged() {
            if (instance) {
                const newCenter = instance.getCenter();
                const lat = newCenter.lat();
                const lng = newCenter.lng();
                setCenter(newCenter)
                props.onChange({ lat, lng });
            }
        },
        [instance]
    );

    useEffect(() => {

        props.mapCenter && setCenter(props.mapCenter)
    }, [props.mapCenter])


    return (
        <div style={{ height: '100%', width: '100%' }}>
            <GoogleMap
                mapContainerStyle={{ width: '100%', height: '100%' }}
                center={center}
                zoom={12}
                onDragEnd={handleChange}
                // onCenterChanged={onCenterChanged}
                onLoad={onLoad}
                options={
                    {
                        streetViewControl: false,
                        mapTypeControl: false,
                        zoomControl: false,
                        fullscreenControl: false
                    }
                }
            >
                <div id='map11'></div>
                {props.isMultipleMarker &&
                    <>
                        {
                            props.markerItem?.length > 0 && props.markerItem?.map((item, index) => {
                                return (
                                    <Marker key={index} position={item.markerPosition} icon={'https://res.cloudinary.com/djyl1goby/image/upload/v1672219012/Lubi/WebApp/Static-images/addLocationPin.svg'} draggable={false}>
                                    </Marker>
                                )
                            })}

                    </>
                }
            </GoogleMap>
            {/* <WrappedMap
                loadingElement={<div style={{ height: '100%' }} />}
                containerElement={<div id="map" style={{ height: '100%' }} />}
                mapElement={<div style={{ height: '100%' }} />}
            /> */}
        </div>
    );
}

export default MapComponent;