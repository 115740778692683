export const uploadFile = async (file) => {
    const formdata = new FormData();
    formdata.append('file', file);
    formdata.append('cloud_name', process.env.REACT_APP_CLOUDINARY_CLOUD_NAME);
    formdata.append('upload_preset', process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET);
    const res = await fetch(
        // 'https://api.cloudinary.com/v1_1/djyl1goby/upload',
        `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/upload`,
        {
            method: 'post',
            mode: 'cors',
            body: formdata
        }
    );

    const json = await res.json();
    const imageURL = json.secure_url.split("/upload/");
    return imageURL[imageURL.length - 1];
};

export const isValidValue = (value) => {
    if (typeof (value) === "undefined" || value === null || value === "") {
        return '-'
    }
    return value
};


export const getValueFromObject = (key, object, change) => {
    if (hasProperty(object, key)) {
        if (isValidValue(object[key])) {
            return object[key]
        }
    } else {
        return change
    }
}

export const hasProperty = (object, key) => {
    if (Array.isArray(object) || typeof key != 'string' || !object) {
        return false
    } else {
        // eslint-disable-next-line no-prototype-builtins
        return object.hasOwnProperty(key)
    }
}
export const getUserRole = () => {
    const user = JSON.parse(localStorage.getItem('users'))
    let userRole = ""
    if (!user?.userRole && !sessionStorage.getItem('users')   ) {
        return  true
    }
    // const role = JSON.parse(sessionStorage.getItem('users')).userRole
    let role = '';
    if (window.localStorage.getItem('users') !== null) {
        role = JSON.parse(localStorage.getItem('users'))?.userRole
    }


    if (role === 'BusinessOwner') {
        return false;
    } else if (role === 'LocationManager') {
        return 'LM';
    } else {
        return true;
    }
}
export const formatValues = (value) => {
    return value?.toLocaleString('en-IN', {
        style: 'decimal',
        maximumFractionDigits: 2, // Set the number of decimal places you want
        minimumFractionDigits: 2, // Set the minimum number of decimal places
    });
}

export const getDaysInMonth = (month, year) => {
    let selectedMonth = month - 1
    var date = new Date(year, selectedMonth, 1);
    var days = [];
    while (date.getMonth() === selectedMonth) {
        let day = date.getDate()
        day = day < 10 ? `0${day}` : `${day}`
        days.push({ id: day, value: day, label: "IC" + day });
        date.setDate(date.getDate() + 1);
    }
    return days;
}

export const getWeekNumber = (date) => {
    const currentDate = new Date(date);
    const startDate = new Date(currentDate.getFullYear(), 0, 1);
    const days = Math.floor(
        (currentDate - startDate) / (24 * 60 * 60 * 1000)
    );
    const weekNumber = Math.ceil((days + 1) / 7);
    return weekNumber;
}

export const getWeeksInfo = (month, year) => {
    let selectedMonth = month - 1

    const weeks = [];
    const firstDay = new Date(year, selectedMonth, 1);
    const lastDay = new Date(year, selectedMonth + 1, 0);
    let currentWeek = getWeekNumber(firstDay);
    let currentDate = new Date(firstDay);

    let week = 1
    while (currentDate <= lastDay) {
        weeks.push({
            id: `${week}`,
            value: "Week" + week,
            weekNumber: currentWeek,
            startDate: new Date(currentDate),
            endDate: new Date(new Date(currentDate.setDate(currentDate.getDate() + 6)).setHours(23, 59, 0, 0)),
        });
        currentDate.setDate(currentDate.getDate() + 1);
        if (getWeekNumber(currentDate) !== currentWeek) {
            currentWeek = getWeekNumber(currentDate);
        }
        week++
    }

    return weeks;
}
export const formatPrice = (number) => new Intl.NumberFormat('en-IN').format(number)