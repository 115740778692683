import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
//import Components
import TableContainer from '../../../Components/Common/TableContainer';
import { useDispatch, useSelector } from 'react-redux';
import { listAllEVSE } from '../../../store/actions';
import { getValueFromObject } from '../../../helpers/helper.service';
import useWindowDimension from '../../../Components/Hooks/useWindowDimension';
import ContentLoader from '../../../Components/Common/ContentLoader';
import { decryptResponseData } from '../../../helpers/encryptDecrypt'

const EVSEPage = () => {
    document.title = "EVSE Model | Vidhyutam";
    const history = useHistory();
    const dimensions = useWindowDimension();
    const [headerHeight, setHeaderHeight] = useState(0);
    const [footerHeight, setFooterHeight] = useState(0);
    const [tableTaskBar, setTableTaskBar] = useState(0);
    const [paginationDiv, setPaginationDiv] = useState(0);
    const [breadCrumb, setBreadCrumbHeight] = useState(0);
    const [cardHeader, setCardHeaderHeight] = useState(0);
    const [noOfConnectors, setNoOfConnectors] = useState('');
    const [filteredTable, setfilteredTable] = useState([]);
    const [customLoader, setCustomLoader] = useState(false)
    const dispatch = useDispatch();

    // get dynamic search table array
    const handleFilterCount = () => {
        let count = 0
        filteredTable.forEach((item) => {

            count = count + item.original.nConnectors
        })
        setNoOfConnectors(count)
    }

    useEffect(() => {
        handleFilterCount();
    }, [filteredTable])


    useEffect(() => {
        setTimeout(() => {
            setCustomLoader(true)
        }, 2000)
    }, [])
    useEffect(() => {
        dispatch(listAllEVSE(true));

    }, []);

    const { listAllEvse } = useSelector(state => (
        {
            listAllEvse: state?.AssetManagment?.listAllEvse
                .map(row => ({
                    ...row,
                    modelName: decryptResponseData(row.modelName),
                })),
        }));


    useEffect(() => {
        if (listAllEvse.length > 0) {
            const numOfConn = listAllEvse?.map((e, i) => e.nConnectors).reduce((Acc, e) => Acc + e, 0);

            setNoOfConnectors(numOfConn);
        }
    }, [listAllEvse])

    const handleViewDetailClick = (e) => {

        history.push('/assets/evsemodel/viewevse', {
            state: {
                EVSE_id: e?.original?.EVSEId
            }
        });
    }

    useEffect(() => {
        setHeaderHeight(
            document.getElementsByClassName('navbar-header')[0]?.offsetHeight
        );
        setFooterHeight(
            document.getElementsByClassName('footer')[0]?.offsetHeight
        );
        setTableTaskBar(
            document.getElementsByClassName('tableTaskBar')[0]?.offsetHeight
        );
        setPaginationDiv(
            document.getElementsByClassName('paginationDiv')[0]?.offsetHeight
        );
        // setBreadCrumbHeight(
        //     document.getElementsByClassName('breadCrumb')[0]?.offsetHeight
        // );
        setCardHeaderHeight(
            document.getElementsByClassName('cardHeader')[0]?.offsetHeight
        );
    }, []);

    const handleResize = () => {

        setHeaderHeight(
            document.getElementsByClassName('navbar-header')[0]?.offsetHeight
        );
        setFooterHeight(
            document.getElementsByClassName('footer')[0]?.offsetHeight
        );
        setTableTaskBar(
            document.getElementsByClassName('tableTaskBar')[0]?.offsetHeight
        );
        setPaginationDiv(
            document.getElementsByClassName('paginationDiv')[0]?.offsetHeight
        );
        // setBreadCrumbHeight(
        //     document.getElementsByClassName('breadCrumb')[0]?.offsetHeight
        // );
        setCardHeaderHeight(
            document.getElementsByClassName('cardHeader')[0]?.offsetHeight
        );
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
    }, []);
    const handleAddButtonClicks = () => {
        history.push('/assets/addevsemodel');
        // var v = document.getElementById("sidebarApps");
        // v.className += " CustomeClassActiveAdd";
        // document.body.style.overflow = 'hidden';
        // document.getElementById("sidebarApps").style.display = 'block';
    }

    const connectorData = [
        {
            _id: "1",
            idNumber: "01",
            modelName: 'Model 01',
            modelType: 'Terra 24 CG',
            maxPowerSupply: '20 kW',
            connectors: '04'
        },
        {
            _id: "1",
            idNumber: "01",
            modelName: 'Model 01',
            modelType: 'Terra 24 CG',
            maxPowerSupply: '20 kW',
            connectors: '04'
        },
        {
            _id: "1",
            idNumber: "01",
            modelName: 'Model 01',
            modelType: 'Terra 24 CG',
            maxPowerSupply: '20 kW',
            connectors: '04'
        },
        {
            _id: "1",
            idNumber: "01",
            modelName: 'Model 01',
            modelType: 'Terra 24 CG',
            maxPowerSupply: '20 kW',
            connectors: '04'
        },
        {
            _id: "1",
            idNumber: "01",
            modelName: 'Model 01',
            modelType: 'Terra 24 CG',
            maxPowerSupply: '20 kW',
            connectors: '04'
        },
        {
            _id: "1",
            idNumber: "01",
            modelName: 'Model 01',
            modelType: 'Terra 24 CG',
            maxPowerSupply: '20 kW',
            connectors: '04'
        },
        {
            _id: "1",
            idNumber: "01",
            modelName: 'Model 01',
            modelType: 'Terra 24 CG',
            maxPowerSupply: '20 kW',
            connectors: '04'
        },
        {
            _id: "1",
            idNumber: "01",
            modelName: 'Model 01',
            modelType: 'Terra 24 CG',
            maxPowerSupply: '20 kW',
            connectors: '04'
        },
        {
            _id: "1",
            idNumber: "01",
            modelName: 'Model 01',
            modelType: 'Terra 24 CG',
            maxPowerSupply: '20 kW',
            connectors: '04'
        },
        {
            _id: "1",
            idNumber: "01",
            modelName: 'Model 01',
            modelType: 'Terra 24 CG',
            maxPowerSupply: '20 kW',
            connectors: '04'
        },
        {
            _id: "1",
            idNumber: "01",
            modelName: 'Model 01',
            modelType: 'Terra 24 CG',
            maxPowerSupply: '20 kW',
            connectors: '04'
        },
        {
            _id: "1",
            idNumber: "01",
            modelName: 'Model 01',
            modelType: 'Terra 24 CG',
            maxPowerSupply: '20 kW',
            connectors: '04'
        },
        {
            _id: "1",
            idNumber: "01",
            modelName: 'Model 01',
            modelType: 'Terra 24 CG',
            maxPowerSupply: '20 kW',
            connectors: '04'
        },
        {
            _id: "1",
            idNumber: "01",
            modelName: 'Model 01',
            modelType: 'Terra 24 CG',
            maxPowerSupply: '20 kW',
            connectors: '04'
        },
        {
            _id: "1",
            idNumber: "01",
            modelName: 'Model 01',
            modelType: 'Terra 24 CG',
            maxPowerSupply: '20 kW',
            connectors: '04'
        },
        {
            _id: "1",
            idNumber: "01",
            modelName: 'Model 01',
            modelType: 'Terra 24 CG',
            maxPowerSupply: '20 kW',
            connectors: '04'
        },
        {
            _id: "1",
            idNumber: "01",
            modelName: 'Model 01',
            modelType: 'Terra 24 CG',
            maxPowerSupply: '20 kW',
            connectors: '04'
        },
        {
            _id: "1",
            idNumber: "01",
            modelName: 'Model 01',
            modelType: 'Terra 24 CG',
            maxPowerSupply: '20 kW',
            connectors: '04'
        },
        {
            _id: "1",
            idNumber: "01",
            modelName: 'Model 01',
            modelType: 'Terra 24 CG',
            maxPowerSupply: '20 kW',
            connectors: '04'
        },
        {
            _id: "1",
            idNumber: "01",
            modelName: 'Model 01',
            modelType: 'Terra 24 CG',
            maxPowerSupply: '20 kW',
            connectors: '04'
        },
        {
            _id: "1",
            idNumber: "01",
            modelName: 'Model 01',
            modelType: 'Terra 24 CG',
            maxPowerSupply: '20 kW',
            connectors: '04'
        },
        {
            _id: "1",
            idNumber: "01",
            modelName: 'Model 01',
            modelType: 'Terra 24 CG',
            maxPowerSupply: '20 kW',
            connectors: '04'
        },
        {
            _id: "1",
            idNumber: "01",
            modelName: 'Model 01',
            modelType: 'Terra 24 CG',
            maxPowerSupply: '20 kW',
            connectors: '04'
        },
    ]

    const handleModelNameSort = React.useMemo(() => {
        return (rowA, rowB, columnId, desc) => {

            return rowA.values.modelName.toLowerCase() > rowB.values.modelName.toLowerCase() ? 1 : -1;
        }
    }, [])


    // Column
    const connectorColumn = useMemo(
        () => [
            {
                Header: "EVSE Model ID",
                accessor: d => `${d.prefix}${d.EVSEId}`,
                filterable: true,
                Cell: (cell) => {

                    return <p className='text textOverflow'>{getValueFromObject('prefix', cell.row.original, '')}{getValueFromObject('EVSEId', cell.row.original, '')}</p>;
                },
            },
            {
                Header: "Model Name",
                accessor: "modelName",
                sortType: handleModelNameSort,
                filterable: true,
                Cell: (cell) => {
                    return <p className='text textOverflow'>{cell.value}</p>;
                },
            },
            {
                Header: "Model Type",
                accessor: "modelType",
                disableGlobalFilter: true,
                disableSortBy: true,
                filterable: true,
                Cell: (cell) => {
                    return <p className='text textOverflow'>{cell.value}</p>;
                },
            },
            {
                Header: "Max. Power Supply",
                accessor: "maxPower",
                disableGlobalFilter: true,
                disableSortBy: true,
                filterable: true,
                Cell: (cell) => {
                    return <p className='text textOverflow'>{cell.value}</p>;
                },
            },
            {
                Header: `Connectors (${noOfConnectors ? noOfConnectors : '0'})`,
                accessor: "nConnectors",
                disableGlobalFilter: true,
                filterable: true,
                Cell: (cell) => {
                    return <p className='text textOverflow'>{cell.value}</p>;
                },
            }
        ],
        [history, noOfConnectors, handleModelNameSort]
    );
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <div className='evsePage'>
                        <Row>
                            <Col lg={12}>
                                <Card className='mb-0'>
                                    <CardHeader className='cardHeader'>
                                        <p className='headerTxt'>EVSE Model</p>
                                    </CardHeader>
                                    <CardBody>
                                        <div className={filteredTable.length > 0 && listAllEvse.length > 0 ? 'table-content' : 'table-content disablePointer'}>
                                            {customLoader ? <TableContainer
                                                columns={connectorColumn}
                                                data={listAllEvse}
                                                isGlobalFilter={true}
                                                customPageSize={16}
                                                setfilteredTable={setfilteredTable}
                                                handleViewDetailClick={(row) => { handleViewDetailClick(row) }}
                                                maxHeight={dimensions.height - headerHeight - footerHeight - tableTaskBar - paginationDiv - cardHeader - 105}
                                                // tableHeight=''
                                                divClass="table-responsive mb-1 connectorTable"
                                                tableClass="align-middle table-nowrap"
                                                theadClass="table-light text-muted"
                                                isAddButton={true}
                                                searchPlaceholder='Search EVSE Model ID, Model Name'
                                                handleAddButtonClicks={handleAddButtonClicks}
                                                addButtonTitle='Add Model'
                                                noData={listAllEvse.length > 0 ? false : true}
                                                noDataImage={"https://res.cloudinary.com/djyl1goby/image/upload/v1671183458/Lubi/WebApp/Static-images/no_evse.svg"}
                                                noDataTitle={'No EVSE Model Found!'}
                                            />
                                                : <ContentLoader />}
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default EVSEPage;