import {
  ADD_NEW_RFID,
  DELETE_RFID,
  GET_RFID_COUNT,
  ALL_ASSIGNED_RFID,
  RFID_ASSIGNED_LIST_SUCCESS,
  ALL_UNASSIGNED_RFID,
  RFID_UNASSIGNED_LIST_SUCCESS,
  UPDATE_RFID,
  ASSIGN_BUSINESS_TO_RFID,
  GET_RFID_COUNT_SUCCESS,
  GET_RFID_ALL_DATA,
  GET_RFID_ALL_DATA_SUCCESS,
  BULK_IMPORT_RFID,
  UNASSIGN_BUSINESS_FROM_RFID,
  BUSINESS_OWNER_RFID_ASSIGN_SUCCESS,
  BUSINESS_OWNER_RFID_ASSIGN_FAILURE,
  BUSINESS_OWNER_RFID_ASSIGN,
  OLD_RFID_DATA,
  BUSINESS_OWNER_RFID_LIST_SUCCESS,
  DATA_RFID_LIST_SUCCESS,
  BUSINESS_OWNER_RFID_LIST_FAILURE,
  BUSINESS_OWNER_RFID_LIST,
  BUSINESS_OWNER_RFID_ASSIGN_LIST_SUCCESS,
  BUSINESS_OWNER_RFID_ASSIGN_LIST_FAILURE,
  BUSINESS_OWNER_RFID_ASSIGN_LIST,
  ADD_RFID_SET_LOADER,
  UPDATE_RFID_SET_LOADER,
  UNASSIGN_RFID_SET_LOADER,
} from "./actionTypes";

export const addNewRFID = (data) => {
  return {
    type: ADD_NEW_RFID,
    payload: { data },
  };
};
export const setLoaderForAddingRFID = (data) => {
  return {
    type: ADD_RFID_SET_LOADER,
    payload: data,
  };
};
export const bulkImportRFID = (data) => {
  return {
    type: BULK_IMPORT_RFID,
    payload: { data },
  };
};

export const setLoaderForEditingRFID = (data) => {
  return {
    type: UPDATE_RFID_SET_LOADER,
    payload: data,
  };
};
export const editRFID = (data, id) => {
  return {
    type: UPDATE_RFID,
    payload: { data, id },
  };
};

export const setLoaderForUnAssigningRFID = (data) => {
  return {
    type: UNASSIGN_RFID_SET_LOADER,
    payload: data,
  };
};
export const deleteRFID = (data) => {
  return {
    type: DELETE_RFID,
    payload: { data },
  };
};

export const assignBusinessToRFID = (data) => {
  return {
    type: ASSIGN_BUSINESS_TO_RFID,
    payload: { data },
  };
};
export const unAssignRFIDFromBusiness = (data) => {
  return {
    type: UNASSIGN_BUSINESS_FROM_RFID,
    payload: { data },
  };
};

export const getAllAssignedRFID = (data) => {
  return {
    type: ALL_ASSIGNED_RFID,
    payload: { data },
  };
};
export const RFIDAssignedListSuccess = (data) => {
  return {
    type: RFID_ASSIGNED_LIST_SUCCESS,
    payload: data,
  };
};

export const getAllUnAssignedRFID = () => {
  return {
    type: ALL_UNASSIGNED_RFID,
    payload: {},
  };
};
export const RFIDUnAssignedListSuccess = (data) => {
  return {
    type: RFID_UNASSIGNED_LIST_SUCCESS,
    payload: data,
  };
};

export const RFIDCount = () => {
  return {
    type: GET_RFID_COUNT,
    payload: {},
  };
};
export const RFIDCountSuccess = (data) => {
  return {
    type: GET_RFID_COUNT_SUCCESS,
    payload: data,
  };
};
export const RFIDAllData = (data) => {
  return {
    type: GET_RFID_ALL_DATA,
    payload: { data },
  };
};
export const RFIDAllDataSuccess = (data) => {
  return {
    type: GET_RFID_ALL_DATA_SUCCESS,
    payload: data,
  };
};
export const businessOwnerRFIDAssignSuccess = () => {
  return {
    type: BUSINESS_OWNER_RFID_ASSIGN_SUCCESS,
  };
};
export const businessOwnerRFIDAssignFailure = () => {
  return {
    type: BUSINESS_OWNER_RFID_ASSIGN_FAILURE,
  };
};
export const businessOwnerRFIDAssign = (data) => {
  return {
    type: BUSINESS_OWNER_RFID_ASSIGN,
    payload: data,
  };
};

export const getOldRFID = (data) => {
  return {
    type: OLD_RFID_DATA,
    payload: data,
  };
};


export const DataRFIDListSuccess = (data) => {
  return {
    type: DATA_RFID_LIST_SUCCESS,
    payload: data,
  };
};

export const businessOwnerRFIDListSuccess = (data) => {
  return {
    type: BUSINESS_OWNER_RFID_LIST_SUCCESS,
    payload: data,
  };
};
export const businessOwnerRFIDListFailure = (data) => {
  return {
    type: BUSINESS_OWNER_RFID_LIST_FAILURE,
    payload: data,
  };
};
export const businessOwnerRFIDList = (params) => {
  return {
    type: BUSINESS_OWNER_RFID_LIST,
    payload: params,
  };
};
export const businessOwnerRfidAssignListSuccess = (data) => {
  return {
    type: BUSINESS_OWNER_RFID_ASSIGN_LIST_SUCCESS,
    payload: data,
  };
};
export const businessOwnerRfidAssignListFailure = (data) => {
  return {
    type: BUSINESS_OWNER_RFID_ASSIGN_LIST_FAILURE,
    payload: data,
  };
};
export const businessOwnerRFIDAssignList = () => {
  return {
    type: BUSINESS_OWNER_RFID_ASSIGN_LIST,
  };
};
