
// const socket = io(process.env.REACT_APP_WEB_SOCKET_URL, {
//     transports: ['websocket'], 'sync disconnect on unload': true, reconnect: true, forceNew: true
// });

// const socket = io.connect(process.env.REACT_APP_WEB_SOCKET_URL);

// export default socket;
import { io } from 'socket.io-client';
//ws://54.221.123.243:4000
const socket = io('https://socket.vidhyutam.com', { transports: ['websocket'] });
// const socket = io('http://localhost:4000', { transports: ['websocket'] });

export default socket;