import { useFormik } from 'formik';
import React, { useState, useEffect, useLayoutEffect } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Container, Form, FormFeedback, Input, Label, Row } from 'reactstrap';
//import Components
import * as Yup from "yup";
import PhoneInput from 'react-phone-input-2'
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import OutsideClickHandler from 'react-outside-click-handler';
import CustomReactColor from '../../../../Components/Common/CustomReactColor';
import BreadCrumb from "../../../../Components/Common/BreadCrumb";
import CustomButton from "../../../../Components/Common/CustomButton";
import Constant from '../../../../utils/constant';
import { addNewBusiness, editBusiness, getBusinessDetails, getMatchEmail } from '../../../../store/actions';
import { uploadFile } from '../../../../helpers/helper.service';
import 'react-phone-input-2/lib/style.css'
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { decryptResponseData } from '../../../../helpers/encryptDecrypt'

const AddBusiness = (props) => {
    document.title = window.location.href.includes('edit') ? "Edit Businesses | Vidhyutam" : "Add Businesses | Vidhyutam";
    const dispatch = useDispatch();
    const history = useHistory();
    const { pathname, state } = useLocation();
    const [fileImage, setImage] = useState('');
    // const [oldLogo, setOldLogo] = useState('')
    const [filesize, setFileSize] = useState(true);
    const [primaryColor, setPrimaryColor] = useState('#37D3EE');
    const [secondaryColor, setSecondaryColor] = useState(false);
    const [changeEmailValue, setChangeEmailValue] = useState(false);
    const [typeCheck, setTypeCheck] = useState('')
    const [colorChange, setColorChange] = useState(false)
    const [read, setRead] = useState(false);
    const [loader, setloader] = useState(false)
    const [phoneNumberValid, setPhoneNumberValid] = useState(true);

    // allow only single click for submit
    const handleSingleClick = () => {
        if (loader) {
            return;
        }
        setloader(true);
    }

    // redirect back to listing if no id is found in case of manual copying url from previos tab
    useLayoutEffect(() => {
        if (state == undefined && window.location.href.includes('edit')) {
            history.push('/businesses')
        }
    }, [])


    // validate type of file upload for image upload
    const checkImge = (e, correctSize) => {
        const myarr = ["jpg", "png", "jpeg"];
        const isValid = myarr.includes(e.target.files[0].name.split(".").pop());
        if (!isValid || !correctSize) {
            document.getElementById("formFile").value = "";
            setFileSize(false);
            setImage('');
        } else {
            addbusinesses.handleChange(e);
            setImage(e.target.files[0]);
            setFileSize(true)
        }
    };
    useEffect(() => {
        setTypeCheck(state?.state?.type);
        if (state?.state?.type === 'Edit') {
            const businessId = state?.state?.value;
            dispatch(getBusinessDetails(props.history, businessId));
        }
    }, []);

    const { businessDetail, getMatchingEmail, loading } = useSelector(state => ({
        businessDetail: (typeCheck === 'Edit') && state?.businessList?.businessDetail,
        getMatchingEmail: state?.businessList?.getMatchedEmail?.data?.userDetails,
        loading: state.Toast.loader
    }));

    const [dialCode, setDialCode] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');

    // Use an effect to set the values once businessDetail is available
    useEffect(() => {
        if (businessDetail.length > 0) {
            setDialCode(businessDetail[0].countryCode);
            setPhoneNumber(businessDetail[0].contactNumber);
        }
    }, [businessDetail]);

    useEffect(() => {
        setloader(loading)
    }, [loading])

    useEffect(() => {
        if (businessDetail.length > 0 && typeCheck === 'Edit') {
            setPrimaryColor(businessDetail[0]?.primaryColor);
            setImage(businessDetail[0].businessLogo);
            setColorChange(true)
        }
    }, [businessDetail]);

    const businessOwnerRegex = /^([a-zA-Z0-9\.]{1,})$|^([a-zA-Z0-9\.]{1,})\s([a-zA-Z0-9\.]{1,})$|^([a-zA-Z0-9\.]{1,})\s([a-zA-Z0-9\.]{1,})\s([a-zA-Z0-9\.]{1,})$/g

    const mergedContactNumber = businessDetail[0] ? `+${businessDetail[0].countryCode}${businessDetail[0].contactNumber}` : '';

    const addbusinesses = useFormik({
        enableReinitialize: true,

        initialValues: {
            businessName: (decryptResponseData(businessDetail[0] && businessDetail[0].businessName)) || '',
            businessOwnerName: (businessDetail[0] && decryptResponseData(businessDetail[0].firstName) !== '')
                ? decryptResponseData(businessDetail[0].lastName) !== ''
                    ? `${decryptResponseData(businessDetail[0].firstName)} ${decryptResponseData(businessDetail[0].lastName)}`
                    : decryptResponseData(businessDetail[0].firstName)
                : '',
            email: (decryptResponseData(businessDetail[0] && businessDetail[0].email)) || "",
            contactNumber: (typeCheck === 'Edit' ? mergedContactNumber : '') || '',
            website: (businessDetail[0] && businessDetail[0].website) || "",
            businessLogo: (businessDetail[0] && businessDetail[0].businessLogo) || '',
            // primaryColor: (businessDetail[0] && businessDetail[0]?.primaryColor) || primaryColor,
        },
        validationSchema: Yup.object(
            {
                businessName: Yup.string()
                    .required('Business name is required')
                    .matches(Constant.REGEX.ALPHANUMERIC, 'Alphanumeric characters only'),
                businessOwnerName: Yup.string()
                    .matches(businessOwnerRegex, "Please use only ( A-z, 0-9, . )"),
                email: Yup.string().max(50, 'Email address should be less than 50 alphabets')
                    .required('Email is required')
                    .matches(/^([a-z0-9]+(?:[._-][a-z0-9]+)*)@([a-z0-9]+(?:[.-][a-z0-9]+)*\.[a-z]{2,})$/i, 'Please provide a valid email address'),
                contactNumber: Yup.string()
                    .required(),
                // contactNumber: Yup.string().test('phoneNumberValid', 'Invalid phone number', () => phoneNumberValid)
                //     .required('Phone number is required'),
                // .matches(/^([0-9]{8,15})$/, 'Please enter a valid phone number'),
                website: Yup.string()
                    .test('upc', 'Please provide a valid website address in lowercase', function (value) {
                        return value?.match(/[A-Z]/g) == null
                    })
                    .matches(Constant.REGEX.WEBSITEURL, 'Please provide a valid website address')
                // primaryColor: Yup.string(),
                // secondaryColor: Yup.string(),
            }),

        onSubmit: async (values) => {
            let logo = '';
            if (typeCheck === 'Edit') {
                handleSingleClick();
                const businessId = state?.state?.value;
                // eslint-disable-next-line no-constant-condition
                if (typeof fileImage === 'object' && fileImage) {
                    logo = await uploadFile(fileImage);
                } else {
                    logo = fileImage;
                }

                let params = {
                    businessName: values.businessName,
                    firstName: values.businessOwnerName.split(' ')[0] || '',
                    lastName: values.businessOwnerName.split(' ').slice(1).join(' ') || '',
                    email: values.email,
                    countryCode: dialCode,
                    contactNumber: phoneNumber,
                    website: values.website,
                    businessLogo: logo,
                    primaryColor: primaryColor,
                }
                dispatch(editBusiness(params, props.history, businessId));
                if (JSON.parse(window.localStorage.getItem('users')).userRole !== 'SuperAdmin') {
                    document.getElementById('12').setAttribute("data-theme", primaryColor);
                }
                //
            } else {
                handleSingleClick();
                if (fileImage) {
                    logo = await uploadFile(fileImage);
                }

                let params = {
                    businessName: values.businessName,
                    firstName: values.businessOwnerName.split(' ')[0] || '',
                    lastName: values.businessOwnerName.split(' ').slice(1).join(' ') || '',
                    email: values.email,
                    countryCode: dialCode,
                    contactNumber: phoneNumber,
                    website: values.website,
                    businessLogo: logo,
                    primaryColor: primaryColor,
                }
                dispatch(addNewBusiness(params, props.history));

                if (JSON.parse(window.localStorage.getItem('users')).userRole !== 'SuperAdmin') {
                    document.getElementById('12').setAttribute("data-theme", primaryColor);
                }
            }
        }
    });

    useEffect(() => {

    }, [addbusinesses])


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb
                        onClickBack={(e) => {
                            // history.push('/businesses')
                            e.preventDefault();
                            history.goBack();
                        }}
                        isButton={true}
                        child={typeCheck === 'Edit' ? true : false}
                        title={
                            typeCheck === 'Edit'
                                ? "Edit Business"
                                : "Add Business"
                        }
                        pageTitle="Businesses"
                        pageTitle2={typeCheck === 'Edit' && decryptResponseData(businessDetail[0]?.businessName)}
                        OnClickpageTitle={(e) => {
                            e.preventDefault();
                            history.goBack();
                        }}
                        OnClickpageTitle2={(e) => {
                            e.preventDefault();
                            history.goBack();
                        }}
                    />
                    <div className="addBusinessPage">
                        <Form onChange={(e) => {
                            const { name, value } = e.target;
                            addbusinesses.setFieldValue(name, value);
                        }} onSubmit={(e) => {
                            e.preventDefault();
                            addbusinesses.handleSubmit();
                            return false
                        }} action="#">
                            <Row className='addBusinessPageRow'>
                                <Col xxl={8} xl={8} lg={8} md={12} className='addBusinessPageRowColmn'>
                                    <Card className="">
                                        <CardHeader>
                                            <Row>
                                                <Col lg={12}>
                                                    <p className="headerText headerTextTitle">
                                                        {pathname === "/businesses/editbusiness"
                                                            ? "Edit Business"
                                                            : "Add Business"}
                                                    </p>
                                                </Col>
                                            </Row>
                                        </CardHeader>
                                        <CardBody className="">
                                            {typeCheck === 'Edit' &&
                                                <Row>
                                                    <Col lg={12}>
                                                        <div className="mb-1 mt-2">
                                                            <Label
                                                                htmlFor="BusinessID"
                                                                className="form-label"
                                                            >
                                                                Business ID
                                                                <span className="idText">{`${businessDetail[0]?.bPrefix}${businessDetail[0]?.rBusinessId}`}</span>
                                                            </Label>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            }
                                            <Row>
                                                <Col lg={12}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="businessName" className="form-label required">
                                                            Business Name
                                                        </Label>
                                                        <Input
                                                            name="businessName"
                                                            type="text"
                                                            className="form-control"
                                                            id="businessName"
                                                            maxLength={50}
                                                            placeholder="Enter Business Name"
                                                            value={addbusinesses.values.businessName.trimStart() || ""}
                                                            onChange={addbusinesses.handleChange}
                                                            onKeyUp={addbusinesses.handleBlur}
                                                            invalid={
                                                                addbusinesses.errors.businessName &&
                                                                    addbusinesses.touched.businessName
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                        {addbusinesses.errors.businessName &&
                                                            addbusinesses.touched.businessName ? (
                                                            <FormFeedback type="invalid">
                                                                {addbusinesses.errors.businessName}
                                                            </FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                                <Col lg={12}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="emailInput" className="form-label required">
                                                            Email Address
                                                        </Label>
                                                        <div className="emailAuto">
                                                            <Input
                                                                autoComplete="off"
                                                                name="email"
                                                                type="text"
                                                                className={typeCheck === 'Edit' ? 'form-control readEmail' : 'form-control'}
                                                                readOnly={typeCheck === 'Edit' ? true : false}
                                                                id="emailInput"
                                                                placeholder="Enter Email Address"
                                                                onChange={(e) => {
                                                                    addbusinesses.handleChange(e);
                                                                    setRead(false)
                                                                    setChangeEmailValue(true);

                                                                    if (e.target.value.length >= 3) {
                                                                        dispatch(getMatchEmail('role=BusinessOwner', `email=${e.target.value}`))
                                                                    } else {
                                                                        setChangeEmailValue(false);
                                                                    }
                                                                }}
                                                                onKeyUp={addbusinesses.handleBlur}
                                                                value={addbusinesses.values.email.trimStart() || ""}
                                                                invalid={
                                                                    addbusinesses.errors.email &&
                                                                        addbusinesses.touched.email
                                                                        ? true
                                                                        : false
                                                                }
                                                            />
                                                            {/* email autocomplete */}

                                                            {
                                                                changeEmailValue && getMatchingEmail?.length > 0 &&
                                                                <OutsideClickHandler
                                                                    onOutsideClick={() => {
                                                                        setChangeEmailValue(false);
                                                                    }}
                                                                >
                                                                    <div className="menu">
                                                                        <ul>
                                                                            {
                                                                                getMatchingEmail?.map((item, index) => {
                                                                                    const mergedContactNumber = `+${item.countryCode}${item.contactNumber}`;
                                                                                    return (
                                                                                        <li key={index} onClick={() => {
                                                                                            addbusinesses.setFieldValue('email', decryptResponseData(item.email), { shouldValidate: true })
                                                                                            setTimeout(() => {
                                                                                                setRead(true);
                                                                                                addbusinesses.setFieldValue('contactNumber', mergedContactNumber, { shouldValidate: true });
                                                                                                setDialCode(item.countryCode)
                                                                                                setPhoneNumber(item.contactNumber)
                                                                                                if (item.firstName == '' && item.lastName == '') {
                                                                                                    return;
                                                                                                }
                                                                                                addbusinesses.setFieldValue(
                                                                                                    'businessOwnerName',
                                                                                                    `${decryptResponseData(item?.firstName || '')} ${decryptResponseData(item?.lastName || '')}`,
                                                                                                    { shouldValidate: true }
                                                                                                );
                                                                                            }, 100)
                                                                                            setTimeout(() => {
                                                                                                addbusinesses.setFieldTouched('businessOwnerName', true);
                                                                                                addbusinesses.setFieldTouched('contactNumber', { shouldValidate: true });
                                                                                            }, 1000)
                                                                                            setChangeEmailValue(false);
                                                                                        }}>{decryptResponseData(item.email)}</li>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </ul>
                                                                    </div>
                                                                </OutsideClickHandler>
                                                            }
                                                            {addbusinesses.errors.email &&
                                                                addbusinesses.touched.email ? (
                                                                <FormFeedback type="invalid">
                                                                    {addbusinesses.errors.email}
                                                                </FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col lg={12}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="businessOwnerName" className="form-label">
                                                            Business Owner Name
                                                        </Label>
                                                        <Input
                                                            name="businessOwnerName"
                                                            type="text"
                                                            maxLength={50}
                                                            className="form-control"
                                                            id="businessOwnerName"
                                                            placeholder="Business Owner Name"
                                                            value={addbusinesses.values.businessOwnerName.trimStart() || ''}
                                                            readOnly={read}
                                                            onChange={addbusinesses.handleChange}
                                                            onKeyUp={addbusinesses.handleBlur}
                                                            invalid={
                                                                addbusinesses.errors.businessOwnerName &&
                                                                    addbusinesses.touched.businessOwnerName
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                        {addbusinesses.errors.businessOwnerName &&
                                                            addbusinesses.touched.businessOwnerName ? (
                                                            <FormFeedback type="invalid">
                                                                {addbusinesses.errors.businessOwnerName}
                                                            </FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                                <Col lg={12}>
                                                    <div className="mb-3 phoneDiv">
                                                        <Label htmlFor="phonenumberInput" className="form-label required">
                                                            Contact Number
                                                        </Label>
                                                        <PhoneInput
                                                            className={'phoneinput textField'}
                                                            placeholder={'Enter your phone number'}
                                                            enableSearch={true}
                                                            // onlyCountries={['ae']}
                                                            // showDropdown={false}
                                                            inputProps={{
                                                                name: 'contactNumber',
                                                                required: true,
                                                            }}
                                                            country={'ae'}
                                                            // autoFormat={false}
                                                            countryCodeEditable={false}
                                                            countryCallingCodeEditable={false}
                                                            value={addbusinesses.values.contactNumber || ''}

                                                            onChange={(formattedValue, countryData) => {

                                                                // if (condition) {

                                                                // }

                                                                const phoneNumber = parsePhoneNumberFromString(formattedValue, countryData.countryCode.toUpperCase());
                                                                if (phoneNumber?.isValid()) {
                                                                    setPhoneNumberValid(phoneNumber?.isValid());
                                                                    addbusinesses.setFieldValue('contactNumber', `+${formattedValue}`);
                                                                } else {
                                                                    setPhoneNumberValid(false)
                                                                }
                                                                setDialCode(`${countryData?.dialCode}`);
                                                                setPhoneNumber(formattedValue?.replace(`${countryData?.dialCode}`, ''))
                                                            }}

                                                        // isValid={(value, country) => {
                                                        //     console.log('value',value);
                                                        //     console.log('country',country.countryCode);

                                                        //     if (value.match(/12345/)) {
                                                        //       return 'Invalid value: '+value+', '+country.name;
                                                        //     } else if (value.match(/1234/)) {
                                                        //       return false;
                                                        //     } else {
                                                        //       return true;
                                                        //     }
                                                        //   }}
                                                        />
                                                        {phoneNumberValid !== null && (
                                                            <div className=" invalid-feedback"><span>{phoneNumberValid ? '' : 'Please enter a valid contact number'}</span></div>
                                                        )}
                                                    </div>
                                                </Col>
                                                <Col lg={12}>
                                                    <div className="mb-3">
                                                        <Label
                                                            htmlFor="websiteInput1"
                                                            className="form-label"
                                                        >
                                                            Website
                                                        </Label>
                                                        <Input
                                                            name="website"
                                                            type="text"
                                                            className="form-control"
                                                            id="websiteInput1"
                                                            placeholder="Enter Your Website"
                                                            defaultValue=""
                                                            maxLength={250}
                                                            value={addbusinesses.values.website.trimStart() || ""}
                                                            invalid={
                                                                addbusinesses.errors.website ? true : false
                                                            }
                                                            onChange={addbusinesses.handleChange}
                                                            onKeyUp={addbusinesses.handleBlur}
                                                        />
                                                        {addbusinesses.errors.website ? (
                                                            <FormFeedback type="invalid">
                                                                {addbusinesses.errors.website}
                                                            </FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col xxl={4} xl={4} lg={4} md={12} className='addBusinessPageRowColmn'>
                                    <Card className="">
                                        <CardHeader>
                                            <Row>
                                                <Col lg={12}>
                                                    <p className="headerText">Business Logo</p>
                                                </Col>
                                            </Row>
                                        </CardHeader>
                                        <CardBody className="">
                                            <Col lg={12}>
                                                <div>
                                                    <Label htmlFor="formFile" className="form-label">
                                                        Business Logo
                                                    </Label>
                                                    <Input
                                                        placeholder='cvvvNo File Chosen'
                                                        className="form-control"
                                                        type="file"
                                                        id="formFile"
                                                        name="businessLogo"
                                                        // value={fileImage}
                                                        accept='image/jpeg,image/jpg,image/png'
                                                        // value={addbusinesses.values.businessLogo || fileImage }
                                                        // onChange={handleFileUpload}
                                                        onChange={(e) => {

                                                            const maxfilesize = (1024 * 2 * 1024);
                                                            const filesize = e.target.files[0].size;

                                                            checkImge(e, filesize < maxfilesize);
                                                            // if (filesize > maxfilesize) {
                                                            //     setFileSize(false);
                                                            //     // setFileSize()
                                                            // } else {
                                                            //     // setFileSize(e.target.files[0])
                                                            //     setFileSize(true);
                                                            //     setImage(e.target.files[0]);
                                                            //     // addbusinesses.handleChange(e);

                                                            // }

                                                        }}

                                                    />
                                                    <p className={filesize === true ? 'businessLogoMaxSize' : 'businessLogoMaxSizeError businessLogoMaxSize'}>Maximum size should be 2 MB (.png, .jpg, or .jpeg format only)</p>
                                                </div>
                                            </Col>
                                        </CardBody>
                                    </Card>
                                    <Card className="">
                                        <CardHeader>
                                            <Row>
                                                <Col lg={12}>
                                                    <p className="headerText">Business Theme</p>
                                                </Col>
                                            </Row>
                                        </CardHeader>
                                        <CardBody className="">
                                            <Col lg={12}>
                                                <div>
                                                    <Label htmlFor="colorPicker" className="form-label">Primary Color</Label>
                                                    <CustomReactColor primaryColor={primaryColor} name='primaryColor' colorChange={(value) => {
                                                        // addbusinesses.handleChange(value);
                                                        setPrimaryColor(value);

                                                        setColorChange(false)

                                                    }}
                                                    />
                                                </div>
                                            </Col>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col lg={12}>
                                    <div className="hstack gap-2  mt-0 justify-content-start">
                                        <CustomButton
                                            title={pathname === '/businesses/editbusiness' ? 'Update' : 'Add Business'}
                                            disabled={typeCheck === 'Edit' ? colorChange ? (!addbusinesses.dirty || !addbusinesses.isValid || !phoneNumberValid) : colorChange : (!addbusinesses.isValid || !addbusinesses.dirty || !phoneNumberValid)}
                                            // disabled={!addbusinesses.isValid || !phoneNumberValid}
                                            type='submit'
                                            loading={loading}
                                            className='btn addButtonWithLoader'
                                            btnIcon={pathname === '/businesses/editbusiness' ? '' : "ri-add-line align-middle fs-16 me-2"}
                                        />

                                        <Button
                                            type="button"
                                            className="btn cancelBtn btn-soft"
                                            onClick={() => {
                                                history.goBack();
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default AddBusiness;
