import {
  ALL_ASSIGNED_RFID,
  RFID_ASSIGNED_LIST_SUCCESS,
  ALL_UNASSIGNED_RFID,
  RFID_UNASSIGNED_LIST_SUCCESS,
  GET_RFID_COUNT_SUCCESS,
  BUSINESS_OWNER_RFID_ASSIGN_SUCCESS,
  GET_RFID_ALL_DATA_SUCCESS,
  BUSINESS_OWNER_RFID_ASSIGN_FAILURE,
  BUSINESS_OWNER_RFID_ASSIGN,
  BUSINESS_OWNER_RFID_LIST_SUCCESS,
  BUSINESS_OWNER_RFID_LIST_FAILURE,
  BUSINESS_OWNER_RFID_LIST,
  OLD_RFID_DATA,
  DATA_RFID_LIST_SUCCESS,
  BUSINESS_OWNER_RFID_ASSIGN_LIST_SUCCESS,
  BUSINESS_OWNER_RFID_ASSIGN_LIST_FAILURE,
  BUSINESS_OWNER_RFID_ASSIGN_LIST,
  ADD_RFID_SET_LOADER,
  UPDATE_RFID_SET_LOADER,
  UNASSIGN_RFID_SET_LOADER,
} from "./actionTypes";

const initialState = {
  allAssignedRFIDList: [],
  allUnAssignedRFIDList: [],
  rfidCount: { unAssignedRFID: 0, assignedRFID: 0, totalRFID: 0 },
  businessOwnerRFIDR: null,
  RFIDListData: [],
  RFIDListTotalAmount: 0,
  businessOwnerRFIDListR: null,
  dataRFIDListR: null,
  BusinessOwnerRfidAssignListR: [],
  error: null,
  isLoadingRFID: { adding: false, updating: false, unassigning: false },
};

const RFIDList = (state = initialState, action) => {
  // console.log("dfghjkljhg", action.payload);
  switch (action.type) {
    case ADD_RFID_SET_LOADER:
      state = {
        ...state,
        isLoadingRFID: { ...state.isLoadingRFID, adding: action.payload },
      };
      break;
    case UPDATE_RFID_SET_LOADER:
      state = {
        ...state,
        isLoadingRFID: { ...state.isLoadingRFID, updating: action.payload },
      };
      break;
    case UNASSIGN_RFID_SET_LOADER:
      state = {
        ...state,
        isLoadingRFID: { ...state.isLoadingRFID, unassigning: action.payload },
      };
      break;
    case ALL_ASSIGNED_RFID:
      state = {
        ...state,
      };
      break;
    case RFID_ASSIGNED_LIST_SUCCESS:
      state = {
        ...state,
        allAssignedRFIDList: action.payload,
      };
      break;
    case ALL_UNASSIGNED_RFID:
      state = {
        ...state,
      };
      break;
    case RFID_UNASSIGNED_LIST_SUCCESS:
      state = {
        ...state,
        allUnAssignedRFIDList: action.payload,
        // isLoading: false
      };
      break;
    case GET_RFID_COUNT_SUCCESS:
      state = {
        ...state,
        rfidCount: action.payload,
      };
      break;
    case BUSINESS_OWNER_RFID_ASSIGN_SUCCESS:
      state = {
        ...state,
        businessOwnerRFIDR: action.payload,
        isLoading: false,
        error: null,
      };
      break;

    case GET_RFID_ALL_DATA_SUCCESS:
      state = {
        ...state,
        RFIDListData: action.payload,
        RFIDListTotalAmount: action.payload.totalAmount,
        isLoading: false,
        error: null,
      };
      break;

    case BUSINESS_OWNER_RFID_ASSIGN:
      state = {
        ...state,
        isLoading: true,
        error: null,
      };
      break;
    case OLD_RFID_DATA:
      state = {
        ...state,
        isLoading: true,
        error: null,

      };
      break;
    case DATA_RFID_LIST_SUCCESS:
      state = {
        ...state,
        dataRFIDListR: action.payload,
      };
      break;
    case BUSINESS_OWNER_RFID_ASSIGN_FAILURE:
      state = {
        ...state,
        businessOwnerRFIDR: null,
        isLoading: false,
        error: action.error,
      };
      break;

    case BUSINESS_OWNER_RFID_LIST_SUCCESS:
      state = {
        ...state,
        businessOwnerRFIDListR: action.payload,
        isLoading: false,
        error: null,
      };
      break;

    case BUSINESS_OWNER_RFID_LIST:
      state = {
        ...state,
        isLoading: true,
        error: null,
      };
      break;
    case BUSINESS_OWNER_RFID_LIST_FAILURE:
      state = {
        ...state,
        businessOwnerRFIDR: [],
        isLoading: false,
        error: action.error,
      };
      break;
    case BUSINESS_OWNER_RFID_ASSIGN_LIST_SUCCESS:
      state = {
        ...state,
        BusinessOwnerRfidAssignListR: action.payload,
        isLoading: false,
        error: null,
      };
      break;

    case BUSINESS_OWNER_RFID_ASSIGN_LIST:
      state = {
        ...state,
        isLoading: true,
        error: null,
      };
      break;
    case BUSINESS_OWNER_RFID_ASSIGN_LIST_FAILURE:
      state = {
        ...state,
        BusinessOwnerRfidAssignListR: [],
        isLoading: false,
        error: action.error,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default RFIDList;
