import { ALL_BUSINESS_LIST, BUSINESS_LIST_SUCCESS, ADD_NEW_BUSINESS, BUSINESS_DETAIL_SUCCESS, EDIT_BUSINESS, GET_MATCH_EMAIL_SUCC,DELETE_BUSINESS,ACTIVATE_DEACTIVATE_BUSINESS } from "./actionTypes";

const initialState = {
    allBusinessList: [],
    businessDetail: [],
    getMatchedEmail: []
};

const businessList = (state = initialState, action) => {
    switch (action.type) {
        case BUSINESS_LIST_SUCCESS:
            state = {
                ...state,
                allBusinessList: action.payload,
            };
            break;
        case ADD_NEW_BUSINESS:
            state = {
                ...state
            };
            break;
        case BUSINESS_DETAIL_SUCCESS:
            state = {
                ...state,
                businessDetail: action.payload
            }
            break;
        case EDIT_BUSINESS:
            state = {
                ...state
            };
            break;
        case GET_MATCH_EMAIL_SUCC:
            state = {
                ...state,
                getMatchedEmail: action.payload
            }
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default businessList;