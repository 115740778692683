import { LOGS_LISTING_SUCCESS, } from "./actionType";

const initialState = {
    allLocationList: [],
    LocationDetail: [],
    LocManBussDetail: []
};

const logsList = (state = initialState, action) => {
    switch (action.type) {
        case LOGS_LISTING_SUCCESS:
            state = {
                ...state,
                allLocationList: action?.payload,
            };
            break;
         default:
            state = { ...state };
            break;
    }
    return state;
}

export default logsList;