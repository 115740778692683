import { takeEvery, put, call } from "redux-saga/effects";
import {
  ADD_NEW_RFID,
  DELETE_RFID,
  GET_RFID_COUNT,
  GET_RFID_ALL_DATA,
  BULK_IMPORT_RFID,
  ALL_UNASSIGNED_RFID,
  UPDATE_RFID,
  ASSIGN_BUSINESS_TO_RFID,
  ALL_ASSIGNED_RFID,
  UNASSIGN_BUSINESS_FROM_RFID,
  BUSINESS_OWNER_RFID_ASSIGN,
  BUSINESS_OWNER_RFID_LIST,
  OLD_RFID_DATA,
  BUSINESS_OWNER_RFID_ASSIGN_LIST,
} from "./actionTypes";
import {
  RFIDAssignedListSuccess,
  RFIDCount,
  RFIDCountSuccess,
  RFIDAllDataSuccess,
  RFIDUnAssignedListSuccess,
  getAllAssignedRFID,
  getAllUnAssignedRFID,
  businessOwnerRFIDAssignSuccess,
  businessOwnerRFIDAssignFailure,
  businessOwnerRFIDListSuccess,
  DataRFIDListSuccess,
  businessOwnerRFIDListFailure,
  businessOwnerRfidAssignListSuccess,
  businessOwnerRfidAssignListFailure,
  setLoaderForAddingRFID,
  setLoaderForEditingRFID,
  setLoaderForUnAssigningRFID,
  businessOwnerRFIDAssignList,
  businessOwnerRFIDList,
} from "./actions";

import {
  addRFID,
  deleteRFID,
  getAssignedRFIDList,
  getUnassignedRFIDList,
  updateRFID,
  bulkImportRFID,
  getRFIDCount,
  getRFIDData,
  assignBusinessToRFID,
  assignRFIDtoStationByBusinessOwner,
  getbusinessOwnerRFIDList,
  businessOwnerRfidAssignList,
  getdataRFIDList,
  unAssignRFIDFromBusiness,
} from "../../helpers/Auth";
import { showToast, showLoader } from "../actions";

function* getRFIDAssignListAsBusinessOwner() {
  yield put(showLoader(true));
  try {
    const response = yield call(businessOwnerRfidAssignList);
    if (response.status === 200) {
      yield put(businessOwnerRfidAssignListSuccess(response.payload));
      yield put(showLoader(false));
    }
  } catch (e) {
    yield put(showLoader(false));
    yield put(
      showToast({
        message: e?.response?.data?.message,
        type: "error",
      })
    );
    yield put(businessOwnerRfidAssignListFailure());
  }
}
function* getRFIDListAsBusinessOwner(payload) {
  yield put(showLoader(true));
  try {
    const response = yield call(getbusinessOwnerRFIDList, payload);

    if (response.status === 200) {
      yield put(businessOwnerRFIDListSuccess(response.payload));
      yield put(showLoader(false));
    }
  } catch (e) {
    yield put(showLoader(false));
    yield put(
      showToast({
        message: e?.response?.data?.message,
        type: "error",
      })
    );
    yield put(businessOwnerRFIDListFailure());
  }
}


function* getRFIDListDATA(payload) {
  yield put(showLoader(true));
  try {
    const response = yield call(getdataRFIDList, payload);

    if (response.status === 200) {
      yield put(DataRFIDListSuccess(response.payload));
      yield put(showLoader(false));
    }
  } catch (e) {
    yield put(showLoader(false));
    yield put(
      showToast({
        message: e?.response?.data?.message,
        type: "error",
      })
    );
    yield put(businessOwnerRFIDListFailure());
  }
}


function* assignRFIDtoStationByBusinessOwnerSaga(payload) {
  yield put(showLoader(true));
  try {
    const response = yield call(assignRFIDtoStationByBusinessOwner, payload);
    if (response.status === 200) {
      yield put(
        showToast({
          message: response.message,
          type: "success",
        })
      );
      yield put(businessOwnerRFIDAssignSuccess(response.payload));
      yield put(businessOwnerRFIDList("?filter=All"))
      yield put(businessOwnerRFIDAssignList())
      yield put(showLoader(false));
    }
  } catch (e) {
    yield put(showLoader(false));

    yield put(
      showToast({
        message: e?.response?.data?.message,
        type: "error",
      })
    );
    yield put(businessOwnerRFIDAssignFailure());
  }
}
function* addingRFID({ payload: { data } }) {
  yield put(setLoaderForAddingRFID(true))
  try {
    const response = yield call(addRFID, {
      RFID: data.rfidnumber,
    });
    if (response) {
      yield put(
        showToast({
          message: response.message,
          type: "success",
        })
      );
      yield put(setLoaderForAddingRFID(false))
      yield put(getAllUnAssignedRFID());
      yield put(RFIDCount());
    }
  } catch (e) {
    yield put(setLoaderForAddingRFID(false))
    yield put(
      showToast({
        message: e?.response?.data?.message, //'Account verified successfully.',
        type: "error",
      })
    );
  }
}

function* importingBulkRFID({ payload: { data } }) {
  yield put(showLoader(true));
  try {
    const response = yield call(bulkImportRFID, {
      rfidList: data,
    });
    if (response) {
      yield put(
        showToast({
          message: response.message,
          type: "success",
        })
      );
      yield put(getAllUnAssignedRFID());
      yield put(RFIDCount());
      yield put(showLoader(false));
    }
  } catch (e) {
    yield put(showLoader(false));
    yield put(
      showToast({
        message: e?.response?.data?.message, //'Account verified successfully.',
        type: "error",
      })
    );
  }
}

function* unAssignedRFIDList() {
  yield put(showLoader(true));
  try {
    const response = yield call(getUnassignedRFIDList);
    if (response) {
      let newPayload = [...response.payload];
      newPayload = newPayload.map((item) => {
        const newObj = {
          id: item.id,
          rfidNumber: item.RFID,
          status: item.status,
          businessStatus: item.businessId === null ? "Un-Assigned" : "Assigned",
          isEdit: false,
        };
        return newObj;
      });
      yield put(RFIDUnAssignedListSuccess(newPayload));
      yield put(showLoader(false));
    }
  } catch (error) {
    // yield put(refreshToken());
    yield put(showLoader(false));
    yield put(
      showToast({
        message: error.message,
        type: "error",
      })
    );
  }
}

function* assignedRFIDList({ payload: { data } }) {
  yield put(showLoader(true));
  try {
    const response = yield call(getAssignedRFIDList, data);
    if (response) {
      let newPayload = [...response.payload];
      newPayload = newPayload.map((item) => {
        const newObj = {
          id: item.id,
          rfidNumber: item.RFID,
          status: item.status,
          business: item.businessName,
          isEdit: false,
        };
        return newObj;
      });
      yield put(RFIDAssignedListSuccess(newPayload));
      yield put(showLoader(false));
    }
  } catch (error) {
    // yield put(refreshToken());
    yield put(showLoader(false));
    yield put(
      showToast({
        message: error.message,
        type: "error",
      })
    );
  }
}

function* countRFID() {
  yield put(showLoader(true));
  try {
    const response = yield call(getRFIDCount);
    if (response) {
      yield put(RFIDCountSuccess(response.payload));
      yield put(showLoader(false));
    }
  } catch (error) {
    // yield put(refreshToken());
    // yield put(RFIDUnAssignedListFailure());
    yield put(showLoader(false));
    yield put(
      showToast({
        message: error.message,
        type: "error",
      })
    );
  }
}


function* RFIDdata({ payload: { data } }) {
  yield put(showLoader(true));
  try {
    const response = yield call(getRFIDData, data);
    if (response) {
      yield put(RFIDAllDataSuccess(response.payload));
      yield put(showLoader(false));
    }
  } catch (error) {
    // yield put(refreshToken());
    // yield put(RFIDUnAssignedListFailure());
    yield put(showLoader(false));
    yield put(
      showToast({
        message: error.message,
        type: "error",
      })
    );
  }
}




function* updatingRFID({ payload: { data, id } }) {
  yield put(setLoaderForEditingRFID(true))
  try {
    const response = yield call(updateRFID, { RFID: data }, id);
    if (response) {
      yield put(
        showToast({
          message: response.message,
          type: "success",
        })
      );
      yield put(getAllUnAssignedRFID());
      yield put(getAllAssignedRFID("All"));
      yield put(setLoaderForEditingRFID(false))
    }
  } catch (e) {
    yield put(setLoaderForEditingRFID(false))
    yield put(
      showToast({
        message: e?.response?.data?.message, //'Account verified successfully.',
        type: "error",
      })
    );
  }
}

function* assigningBusinessToRFID({ payload: { data } }) {
  yield put(showLoader(true));
  try {
    const response = yield call(assignBusinessToRFID, data);

    if (response) {
      yield put(
        showToast({
          message: response.message,
          type: "success",
        })
      );
      yield put(getAllUnAssignedRFID());
      yield put(getAllAssignedRFID("All"));
      yield put(RFIDCount());
      yield put(showLoader(false));
    }
  } catch (e) {
    yield put(showLoader(false));
    yield put(
      showToast({
        message: e?.response?.data?.message, //'Account verified successfully.',
        type: "error",
      })
    );
  }
}
function* unAssigningBusinessToRFID({ payload: { data } }) {
  yield put(setLoaderForUnAssigningRFID(true));
  try {
    const response = yield call(unAssignRFIDFromBusiness, { rfidList: data });
    if (response) {
      yield put(
        showToast({
          message: response.message,
          type: "success",
        })
      );
      yield put(getAllUnAssignedRFID());
      yield put(getAllAssignedRFID("All"));
      yield put(RFIDCount());
      yield put(setLoaderForUnAssigningRFID(false));
    }
  } catch (e) {
    yield put(setLoaderForUnAssigningRFID(false));
    yield put(
      showToast({
        message: e?.response?.data?.message, //'Account verified successfully.',
        type: "error",
      })
    );
  }
}

function* deletingRFID({ payload: { data } }) {
  try {
    const response = yield call(deleteRFID, { rfidList: data });
    if (response) {
      yield put(
        showToast({
          message: response.message,
          type: "success",
        })
      );
      yield put(getAllUnAssignedRFID());
      yield put(getAllAssignedRFID("All"));
      yield put(RFIDCount());
    }
  } catch (e) {
    yield put(
      showToast({
        message: e?.response?.data?.message, //'Account verified successfully.',
        type: "error",
      })
    );
  }
}

export function* listAllRFID() {
  yield takeEvery(ADD_NEW_RFID, addingRFID);
  yield takeEvery(ALL_UNASSIGNED_RFID, unAssignedRFIDList);
  yield takeEvery(ALL_ASSIGNED_RFID, assignedRFIDList);
  yield takeEvery(UPDATE_RFID, updatingRFID);
  yield takeEvery(DELETE_RFID, deletingRFID);
  yield takeEvery(BULK_IMPORT_RFID, importingBulkRFID);
  yield takeEvery(GET_RFID_COUNT, countRFID);
  yield takeEvery(GET_RFID_ALL_DATA, RFIDdata);
  yield takeEvery(ASSIGN_BUSINESS_TO_RFID, assigningBusinessToRFID);
  yield takeEvery(
    BUSINESS_OWNER_RFID_ASSIGN,
    assignRFIDtoStationByBusinessOwnerSaga
  );
  yield takeEvery(BUSINESS_OWNER_RFID_LIST, getRFIDListAsBusinessOwner);
  yield takeEvery(OLD_RFID_DATA, getRFIDListDATA);
  yield takeEvery(
    BUSINESS_OWNER_RFID_ASSIGN_LIST,
    getRFIDAssignListAsBusinessOwner
  );
  yield takeEvery(UNASSIGN_BUSINESS_FROM_RFID, unAssigningBusinessToRFID);
}

export default listAllRFID;
