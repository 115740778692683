import {
  takeEvery,
  fork,
  put,
  all,
  call,
  actionChannel,
  take,
} from "redux-saga/effects";

import {
  ALL_CUSTOMER_LIST,
  CUSTOMER_LIST_FAILURE,
  CUSTOMER_LIST_SUCCESS,
} from "./actionTypes";

import {
  allCustomersListSuccess,
  allCustomersListFailure,
  allCustomersList,
} from "../actions";

import { getAllCustomers } from "../../helpers/Auth";
import { showToast, showLoader } from "../actions";

function* allCustomers({ payload: { data } }) {
  // yield put(showLoader(true));
  try {
    const response = yield call(getAllCustomers, data);
    if (response.status === 200) {
      yield put(allCustomersListSuccess(response.payload));
    }
  } catch (error) {
    // yield put(showLoader(false));
    yield put(allCustomersListFailure(error));
  }
}

export function* customer() {
  yield takeEvery(ALL_CUSTOMER_LIST, allCustomers);
}

export default customer;
