export const EDIT_PROFILE = "EDIT_PROFILE"
export const EDIT_PROFILE_DATA = "EDIT_PROFILE_DATA"

export const PROFILE_SUCCESS = "PROFILE_SUCCESS"
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS"
export const CHANGE_PASSWORD = "CHANGE_PASSWORD"
export const USER_PROFILE_DATA = "USER_PROFILE_DATA"
export const USER_PROFILE_DATA_SUCCESS = "USER_PROFILE_DATA_SUCCESS"
export const CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR"

export const EDIT_PROFILE_DATA_SUCESS = "EDIT_PROFILE_DATA_SUCESS"

export const PROFILE_ERROR = "PROFILE_ERROR"
export const RESET_PROFILE_FLAG = "RESET_PROFILE_FLAG";