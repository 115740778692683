import { CHANGE_PASSWORD, EDIT_PROFILE_DATA } from "../store/auth/profile/actionTypes";
import instance from "./Axios";
import {
  LOGIN,
  FORGET,
  RESET,
  SET,
  BUSINESS,
  ADD_NEW_BUSINESS,
  UPDATE_BUSINESS,
  GET_BUSINESS_DETAIL,
  VERIFY_OTP,
  RESEND_OTP,
  REFRESH_TOKEN,
  GET_MATCHING_EMAIL,
  LOCATION,
  LOCATIONFILTER,
  STATIONLISTFUNC,
  LOGS,
  GET_LOCATION_DETAIL,
  ADD_LOCATION,
  RESEND_BUSINESS_INVITE,
  EDIT_LOCATION,
  RESEND_LOCATION_INVITE,
  GET_CONNECTORS,
  GET_MODEL,
  ADD_EVSE,
  LIST_ALL_EVSE,
  DETAIL_EVSE,
  DETAIL_EVSE_CONNECTOR,
  LIST_ALL_STATIONS,
  GET_RESERVATION,
  DELETE_RESERVATION,
  LIST_ALL_CONNECTOR,
  UPDATE_RESERVATION,
  STATIONS_DETAIL,
  GET_CONNECTOR_TYPES,
  GET_CONNECTORS_ICONS,
  ADD_CONNECTER_TYPE,
  ADD_STATION,
  GET_STATION_NAME,
  EDIT_CONNECTER_TYPE,
  DETAIL_CONNECTER_TYPE,
  CHECK_STATION_NAME,
  EVSE_EDIT_MODEL,
  EDIT_STATION,
  GET_CONNECTERS_DETAILS,
  EVSE_EDIT_CONNECTER,
  DETAIL_EVSE_CONNECTOR_SINGLE,
  TOGGLE_EVSE_MODLE,
  DELET_EVSE_MODEL,
  GET_STATION_CONNECTOR,
  LIST_ALL_TARIFFS,
  TARIFF_DETAIL,
  ADD_TARIFF,
  EDIT_TARIFF,
  DELET_TARIFF,
  ASSIGN_TARIFF,
  TOGGLE_CONNECTOR_MODLE,
  DELET_CONNECTOR_MODLE,
  LOC_MAN_BUSINESS,
  DASHBOARD_DATA,
  VERIFY_EMAIL,
  DELETE_BUSINESS_URL,
  ACTIVATE_DEACTIVATE_STATION_URL,
  DELETE_LOCATION_URL,
  ACTIVATE_DEACTIVATE_LOCATION_URL,
  ACTIVATE_DEACTIVATE_BUSINESS_URL,
  DELETE_STATION_URL,
  GET_ALL_CUSTOMER_URL,
  ADD_NEW_RFID,
  UPDATE_RFID,
  DELETE_RFID,
  ASSIGN_BUSINESS_TO_RFID_URL,
  GET_ASSIGNED_RFID_LIST,
  GET_UNASSIGNED_RFID_LIST,
  GET_RFID_COUNT,
  GET_RFID_DATA,
  BULK_IMPORT_RFID,
  ASSIGN_RFID_TO_STATION_BY_BUSINESS_OWNER_URL,
  BUSINESS_OWNER_RFID_LIST_URL,
  DATA_RFID_LIST_URL,
  BUSINESS_OWNER_RFID_ASSIGN_LIST_URL,
  UNASSIGN_BUSINESS_FROM_RFID_URL,
  SUPER_ADMIN_DASHBOARD_CUSTOMERS_URL,
  SUPER_ADMIN_DASHBOARD_YEAR_URL,
  USER_PROFILE_URL,
  CHANGE_PASSWORD_URL,
  EDIT_PROFILE_URL,
  SUPER_ADMIN_DASHBOARD_CUSTOMERS_DOWNLOAD_URL,
  BUSINESS_OWNER_DASHBOARD_BUSINESS_LOCATION_URL,
  HEATMAPDATAURL,
  BUSINESSTRANSACTIONURL,
  BUSINESS_OWNER_DASHBOARD_BUSINESS_LOCATION_URL_DOWNLOAD,
  CONNECTORLISTFUNC,
  LOCATION_MANAGER_DASHBOARD_DATA_URL,
  LOCATION_MANAGER_DASHBOARD_DATA_URL_DOWNLOAD,

} from "./EndPoint";

// const api = new APIClient();

export const Login = (data) => instance.post(LOGIN, data);

export const Forget = (data) => instance.post(FORGET, data);

export const Reset = (data, linkToken) =>
  instance.post(RESET + linkToken, data);

export const Set = (data, linkToken) => instance.post(SET + linkToken, data);

export const Business = (data) => instance.get(BUSINESS + data);

export const newBusinessAdd = (data) => instance.post(ADD_NEW_BUSINESS, data);

export const EditBusiness = (data, id) =>
  instance.put(UPDATE_BUSINESS + id, data);

export const GetBusinessDetail = (businessId) =>
  instance.get(GET_BUSINESS_DETAIL + businessId);

export const VerifyOTP = (data, token) =>
  instance.post(VERIFY_OTP + token, data);

export const ResendOTP = (data) => instance.post(RESEND_OTP, data);

export const VerifyEmail = (data) => instance.put(VERIFY_EMAIL + data, data);

export const RefreshToken = (data) => instance.post(REFRESH_TOKEN, data);

export const getMatchedEmail = (role, email) =>
  instance.get(GET_MATCHING_EMAIL + role + "&" + email);

export const Locations = (data) => instance.get(LOCATION + data);
export const LocationsFilter = (data) => instance.get(LOCATIONFILTER + data);


export const stationListBusinessF = (data) => instance.get(STATIONLISTFUNC + data);

export const connectorListStationF = (data) => instance.get(CONNECTORLISTFUNC + data);



export const LocationDetail = (locationId) =>
  instance.get(GET_LOCATION_DETAIL + locationId);

export const newLocationAdd = (data, businessId) =>
  instance.post(ADD_LOCATION + businessId, data);


export const Logs = (data) => instance.post(LOGS + data);


export const businessInviteResend = (data) =>
  instance.post(RESEND_BUSINESS_INVITE, data);

export const LocationEdit = (data, locationID) =>
  instance.put(EDIT_LOCATION + locationID, data);

export const locationInviteResend = (data) =>
  instance.post(RESEND_LOCATION_INVITE, data);

export const getConnectorTypes = (data) => instance.get(GET_CONNECTORS + data);

export const getModelTypes = () => instance.get(GET_MODEL);

export const addEVSE = (data) => instance.post(ADD_EVSE, data);

export const listAllEvse = (data) => instance.get(LIST_ALL_EVSE + data);

export const detailEvse = (EvseID) => instance.get(DETAIL_EVSE + EvseID);

export const detailEvseConnector = (EvseID) =>
  instance.get(DETAIL_EVSE_CONNECTOR + EvseID);

export const ListallStations = (id) => instance.get(LIST_ALL_STATIONS + id);
export const listReservation = (id) => instance.get(GET_RESERVATION + id);

export const ListallConnector = (id) => instance.get(LIST_ALL_CONNECTOR);
export const updateReservation = (id) => instance.put(UPDATE_RESERVATION + id);


export const delReservation = (id) => instance.put(DELETE_RESERVATION + id);


export const StationsDetail = (stationID) =>
  instance.get(STATIONS_DETAIL + stationID);

export const getConnectorIcons = () => instance.get(GET_CONNECTORS_ICONS);

export const getConnectorType = (data) =>
  instance.get(GET_CONNECTOR_TYPES + data);

export const addconnecterType = (data) =>
  instance.post(ADD_CONNECTER_TYPE, data);

export const AddStation = (data) => instance.post(ADD_STATION, data);

export const getStationName = (locationID) =>
  instance.get(GET_STATION_NAME + locationID);

export const editconnecterType = (data, editId) =>
  instance.put(EDIT_CONNECTER_TYPE + editId, data);

export const detailConnecterType = (typeID) =>
  instance.get(DETAIL_CONNECTER_TYPE + typeID);

export const checkStationName = (Id, data) =>
  instance.post(CHECK_STATION_NAME + Id, data);

export const editEvseModel = (data, Id) =>
  instance.put(EVSE_EDIT_MODEL + Id, data);

export const editStation = (data, EVSEid) =>
  instance.put(EDIT_STATION + EVSEid, data);

export const detailEvseSingleConnector = (EvseID) =>
  instance.get(DETAIL_EVSE_CONNECTOR_SINGLE + EvseID);

export const toggleEvseModel = (data, Id) =>
  instance.put(TOGGLE_EVSE_MODLE + Id, data);

export const deleteEvseModel = (data, Id) =>
  instance.put(DELET_EVSE_MODEL + Id, data);

export const toggleConnectorModel = (data, Id) =>
  instance.put(TOGGLE_CONNECTOR_MODLE + Id, data);

export const deleteConnectorModel = (data, Id) =>
  instance.put(DELET_CONNECTOR_MODLE + Id, data);

export const editEvseConnecter = (data, Id) =>
  instance.put(EVSE_EDIT_CONNECTER + Id, data);

export const getStationConnectors = (Id) =>
  instance.get(GET_STATION_CONNECTOR + Id);

export const ListallTariffs = (ID) => instance.get(LIST_ALL_TARIFFS + ID);

export const detailTariff = (TariffId) =>
  instance.get(TARIFF_DETAIL + TariffId);

export const ADDTariff = (data) => instance.post(ADD_TARIFF, data);

export const EDITtariff = (data, TariffId) =>
  instance.put(EDIT_TARIFF + TariffId, data);

export const DeletTariff = (TariffId) => instance.put(DELET_TARIFF + TariffId);

export const AssingTariff = (data) => instance.post(ASSIGN_TARIFF, data);

export const LocmanBuss = (userId) => instance.get(LOC_MAN_BUSINESS + userId);

export const dashboardData = () => instance.get(DASHBOARD_DATA);

export const deleteBusiness = (data) =>
  instance.put(DELETE_BUSINESS_URL + data?.payload?.data);
export const activateDeactivateStation = (data) =>
  instance.put(ACTIVATE_DEACTIVATE_STATION_URL + data.payload);
export const activateDeactivateBusiness = (data) =>
  instance.put(ACTIVATE_DEACTIVATE_BUSINESS_URL + data.payload);
export const activateDeactivateLocation = (data) =>
  instance.put(ACTIVATE_DEACTIVATE_LOCATION_URL + data.payload);
export const deleteLocation = (data) =>
  instance.delete(DELETE_LOCATION_URL + data.payload);
export const deleteStation = (data) =>
  instance.put(DELETE_STATION_URL + data.payload);
export const getAllCustomers = (data) => instance.get(GET_ALL_CUSTOMER_URL + data);

// RFID
export const addRFID = (data) => instance.post(ADD_NEW_RFID, data);
export const bulkImportRFID = (data) => instance.post(BULK_IMPORT_RFID, data);
export const updateRFID = (data, id) => instance.put(UPDATE_RFID + id, data);
export const deleteRFID = (data) => instance.put(DELETE_RFID, data);
export const assignBusinessToRFID = (data) =>
  instance.put(ASSIGN_BUSINESS_TO_RFID_URL, data);
export const unAssignRFIDFromBusiness = (data) =>
  instance.put(UNASSIGN_BUSINESS_FROM_RFID_URL, data);
export const getAssignedRFIDList = (data) =>
  instance.get(GET_ASSIGNED_RFID_LIST + data);
export const getUnassignedRFIDList = () =>
  instance.get(GET_UNASSIGNED_RFID_LIST);
export const getRFIDCount = () => instance.get(GET_RFID_COUNT);
export const getRFIDData = (data) => instance.get(GET_RFID_DATA + data);

export const assignRFIDtoStationByBusinessOwner = (data) =>
  instance.post(ASSIGN_RFID_TO_STATION_BY_BUSINESS_OWNER_URL, data.payload);

export const getbusinessOwnerRFIDList = (data) =>
  instance.get(BUSINESS_OWNER_RFID_LIST_URL + data.payload);


export const getdataRFIDList = (data) =>
  instance.post(DATA_RFID_LIST_URL, data);


export const businessOwnerRfidAssignList = () =>
  instance.get(BUSINESS_OWNER_RFID_ASSIGN_LIST_URL);

export const userProfileData = () =>
  instance.get(USER_PROFILE_URL);


export const userEditData = (data) => instance.post(EDIT_PROFILE_URL, data);

export const superAdminDashboardCustomersDownload = (data) =>
  instance.get(SUPER_ADMIN_DASHBOARD_CUSTOMERS_DOWNLOAD_URL + data);

export const businessOwnerBusinessLocationsDownload = (data) =>
  instance.get(BUSINESS_OWNER_DASHBOARD_BUSINESS_LOCATION_URL_DOWNLOAD + data);

export const stationDetailsForLocationManagerDownload = (data) =>
  instance.get(LOCATION_MANAGER_DASHBOARD_DATA_URL_DOWNLOAD + data);

export const superAdminDashboardCustomers = (data) =>
  instance.get(SUPER_ADMIN_DASHBOARD_CUSTOMERS_URL + data);

export const businessOwnerBusinessLocations = (data) =>
  instance.get(BUSINESS_OWNER_DASHBOARD_BUSINESS_LOCATION_URL + data);

export const stationDetailsForLocationManager = (data) =>
  instance.get(LOCATION_MANAGER_DASHBOARD_DATA_URL + data);

export const SuperAdminBusinessYear = () =>
  instance.get(SUPER_ADMIN_DASHBOARD_YEAR_URL);


export const BusinessTransactionSA = (data) => instance.get(BUSINESSTRANSACTIONURL + data);
export const HeatMapData = (data) => instance.get(HEATMAPDATAURL + data);
export const changePasswordData = (data) => instance.post(CHANGE_PASSWORD_URL, data);
