/* eslint-disable no-unreachable */
/* eslint-disable no-prototype-builtins */
import React, { useMemo, useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Col, Container, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import DeleteModal from '../../../Components/Common/DeleteModal'
import QRCode from "qrcode.react";
import { Grid, _ } from 'gridjs-react';

//import Components
import DisableModal from '../../../Components/Common/DisableModal';
import { useDispatch, useSelector } from 'react-redux';
import { showToast } from '../../../store/actions';
import CustomDropdown from '../../../Components/Common/CustomDropdown';
import TableContainer from '../../../Components/Common/TableContainer';
import { GoogleMap, InfoBox, Marker, useJsApiLoader } from '@react-google-maps/api';
import Constant from '../../../utils/constant';
import useWindowDimension from '../../../Components/Hooks/useWindowDimension';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import ChangingProgressProvider from '../../../Components/Common/ChangingProgressProvider';
import { listAllStation, allConnectorStationId, allStaionBusinessId, allBusinessList, allLocation } from '../../../store/actions';
import ContentLoader from '../../../Components/Common/ContentLoader';
import { getUserRole } from '../../../helpers/helper.service';
import useWebSocket from 'react-use-websocket';
// const WS_URL = 'ws:/192.168.1.100:8000';
import ReactInputMask from 'react-input-mask';
import { decryptResponseData } from '../../../helpers/encryptDecrypt'

const Stations = () => {
    document.title = "Station | Vidhyutam";
    const history = useHistory();
    const dispatch = useDispatch();
    const [selectValue, setSelectValue] = useState();
    const [selectValueTH, setSelectValueTH] = useState('');
    const [IsShowMap, setIsShowMap] = useState(false);
    const [filterModal, setFilterModal] = useState(false);
    const [businessName, setBusinessName] = useState([]);
    const dimensions = useWindowDimension();
    const [headerHeight, setHeaderHeight] = useState(0);
    const [footerHeight, setFooterHeight] = useState(0);
    const [tableTaskBar, setTableTaskBar] = useState(0);
    const [paginationDiv, setPaginationDiv] = useState(0);
    const [breadCrumb, setBreadCrumbHeight] = useState(0);
    const [cardHeader, setCardHeaderHeight] = useState(0);
    const [lSelectFilter, setLSelectFilter] = useState('');
    const [bSelectFilter, setBSelectFilter] = useState('');
    const [typeSelectFilter, setTypeSelectFilter] = useState({});
    const [allListLocation, setAllListLocation] = useState([]);
    const [locationListDAta, setLocationListDAta] = useState([]);
    const [noOfConn, setNoOfConn] = useState('');
    const [isFilterApplied, setisFilterApplied] = useState(false)
    // also shows all tables if search is empty
    const [filteredTable, setfilteredTable] = useState([]);
    const [customLoader, setCustomLoader] = useState(false)
    const [modelNameFilter, setModelNameFilter] = useState('');
    const [regionFilter, setRegionFilter] = useState('');

    const [statusFilter, setStatusFilter] = useState(false);
    const [activeInactiveFilter, setActiveInactiveFilter] = useState('');
    const [listAllStationsNewData, setListAllStationsNewData] = useState([]);


    const [stationFilterModel, setStationFilterModel] = useState(false);
    const [emailFilter, setEmailFilter] = useState('');
    const [phoneFilter, setPhoneFilter] = useState('');

    const [getBusId, setGetBusId] = useState('');
    const [getStaId, setGetStaId] = useState('');
    const [getLocId, setGetLocId] = useState('');
    const [getConId, setGetConId] = useState('');



    const [filterbusinessId, setFilterbusinessId] = useState('');
    const [filterstationId, setFilterstationId] = useState('');
    const [filterLocationId, setFilterLocationId] = useState('');
    const [filterConnectorId, setFilterconnectorId] = useState('');


    const [stationListDAta, setStationListDAta] = useState([]);
    const [connectorListDAta, setConnectorListDAta] = useState([]);
    const [stationTypeValue, setStationTypeValue] = useState('');
    const [stationStatusValue, setStationStatusValue] = useState('');

    const [buttonFilter, setButtonFilter] = useState(false)


    // const [ws, setWs] = useState(null);

    // handle filtered table counting
    const handleFilterCount = () => {

        let connectors = 0;
        filteredTable.forEach((item) => {
            connectors = connectors + item.original.nConnectors;
        })
        setNoOfConn(connectors);
    }

    const { ListAllStations, businessList, locationList, socketData, allConnectorStationList } = useSelector(state => ({
        ListAllStations: state?.ManageStations?.listAllStation,
        // .map(row => ({
        //     ...row,
        //     // stationName: decryptResponseData(row.stationName),
        //     // locationName: decryptResponseData(row.locationName),
        //     modelName: decryptResponseData(row.modelName),
        // })),
        businessList: state?.businessList?.allBusinessList
            .map(row => ({
                ...row,
                businessName: decryptResponseData(row.businessName),
            })),
        locationList: state?.locationsList?.allLocationList
            .map(row => ({
                ...row,
                locationName: decryptResponseData(row.locationName),
            })),
        // socketData: state?.ManageStations?.getSocketStatus?.data != undefined ? state?.ManageStations?.getSocketStatus?.data : JSON.parse(window.localStorage.getItem('socketHeart')),
        socketData: JSON.parse(window.localStorage.getItem('socketHeart')),
        allConnectorStationList: state?.locationsList?.allConnectorStationList,

    }));


    useEffect(() => {
        handleFilterCount();
    }, [filteredTable])
    // useEffect(() => {
    //     setTimeout(() => {
    //         setCustomLoader(true)
    //     }, 2000)
    // }, [])
    useEffect(() => {
        setHeaderHeight(
            document.getElementsByClassName('navbar-header')[0]?.offsetHeight
        );
        setFooterHeight(
            document.getElementsByClassName('footer')[0]?.offsetHeight
        );
        setTableTaskBar(
            document.getElementsByClassName('tableTaskBar')[0]?.offsetHeight
        );
        setPaginationDiv(
            document.getElementsByClassName('paginationDiv')[0]?.offsetHeight
        );
        // setBreadCrumbHeight(
        //     document.getElementsByClassName('breadCrumb')[0]?.offsetHeight
        // );
        setCardHeaderHeight(
            document.getElementsByClassName('cardHeader')[0]?.offsetHeight
        );



        window.addEventListener('resize', handleResize);
        dispatch(listAllStation(`?locationId=`));
        dispatch(allBusinessList('?id='));

    }, []);
    // useEffect(() => {
    // }, []);


    // useEffect(() => {
    //     // dispatch(listAllStation(`?locationId=`));
    //     // dispatch(allBusinessList('?id='));
    //     // dispatch(allLocation('?id='));
    //     // 
    // }, []);

    // socket io mapping


    useEffect(() => {
        let soketHeartData = JSON.parse(window.localStorage.getItem('socketHeart'));
        if (soketHeartData?.length > 0) {
            const updatedListAllStations = ListAllStations.map(location => {

                const matchingHeartbeat = soketHeartData?.find(hb => hb.chargerId === location.stationName && hb.updateResp === 'Accepted');
                // location['connectorStatus'] = matchingHeartbeat ? 'Available' : 'Unavailable';
                // return { ...location };
                return { ...location, connectorStatus: matchingHeartbeat ? 'Available' : 'Unavailable' };
            });
            let a = updatedListAllStations.map(row => ({
                ...row,
                locationName: decryptResponseData(row.locationName),
                modelName: decryptResponseData(row.modelName),
                businessName: decryptResponseData(row.businessName),
            }))
            setListAllStationsNewData(a)
        }

    }, [window.localStorage.getItem('socketHeart'), ListAllStations]);


    useEffect(() => {
        if (socketData !== undefined && socketData !== null) {
            if (ListAllStations.length > 0) {
                // console.log(ListAllStations, 'ListAllStations')
                // console.log(JSON.parse(window.localStorage.getItem('socketStatus')), 'socketDatasocketStatus')
                // console.log(socketData, 'socketData')
                if (socketData && Array.isArray(socketData)) {
                    setAllListLocation(ListAllStations.map(location => {
                        const connectors = Object.keys(socketData);

                        const status = connectors.some(conn => socketData[conn] !== undefined && socketData[conn] !== 'Unavailable' && conn.includes(location.stationName));

                        location['connectorStatus'] = status ? 'Available' : 'Unavailable'
                        return {
                            ...location
                        }
                    }))
                }
                // let newSockeData = socketData != undefined ? socketData : JSON.parse(window.localStorage.getItem('socketStatus'))
                // setAllListLocation(ListAllStations.map(location => {
                //     // console.log(newSockeData, 'newSockeData')

                //     const connectors = Object.keys(newSockeData);

                //     // const status = connectors.some(conn => {
                //     //     socketData[conn.split('_')[0]] !== undefined && socketData[conn.split('_')[0]] !== 'Unavailable' && conn.split('_')[0] == location.stationName

                //     // });
                //     const status = connectors.reduce((accumulator, conn) => {
                //         if (accumulator) {
                //             // If status is already true, no need to check further
                //             return true;
                //         }
                //         return conn.split('_')[0] == location.stationName;
                //     }, false);
                //     return {
                //         ...location, connectorStatus: status ? 'Available' : 'Unavailable'
                //     }
                // }

                // ))
                //  setListAllStationsNewData(allListLocation)
                setCustomLoader(true)



            }


        }
    }, [JSON.stringify(socketData), ListAllStations])


    useEffect(() => {
        if (ListAllStations.length > 0) {
            const numOfConn = ListAllStations?.map((e, i) => e.nConnectors).reduce((Acc, e) => Acc + e, 0);
            setNoOfConn(numOfConn);
        }

        if (ListAllStations.length > 0 && socketData == undefined && socketData == null) {
            // setAllListLocation(ListAllStations.map(i => ({ ...i, connectorStatus: 'Unavailable' })));
            ListAllStations.map(i => ({ ...i, connectorStatus: 'Unavailable' }));
            setCustomLoader(true)

        } else {
            // setAllListLocation([])
            setCustomLoader(true)

        }


        if (stationStatusValue !== '' && socketData != undefined && socketData != null) {
            console.log(stationStatusValue, 'stationStatusValue11')
            let ListAllStationsNew = ListAllStations.map(location => {
                const connectors = Object.keys(socketData);

                // const status = connectors.some(conn => {
                //     socketData[conn.split('_')[0]] !== undefined && socketData[conn.split('_')[0]] !== 'Unavailable' && conn.split('_')[0] == location.stationName

                // });
                const status = connectors.reduce((accumulator, conn) => {
                    if (accumulator) {
                        // If status is already true, no need to check further
                        return true;
                    }
                    return conn.split('_')[0] == location.stationName;
                }, false);
                return {
                    ...location, connectorStatus: status ? 'Available' : 'Unavailable'
                }
            }

            )
            let a = ListAllStationsNew.map(row => ({
                ...row,
                locationName: decryptResponseData(row.locationName),
                modelName: decryptResponseData(row.modelName),
                businessName: decryptResponseData(row.businessName),

            }))
            setListAllStationsNewData(a)
        }



        if (statusFilter || stationStatusValue !== '') {
            console.log(JSON.parse(window.localStorage.getItem('socketHeart')), 'JSON.parse(window.localStorage.getItem)')
            console.log(ListAllStations, 'ListAllStations')


            const updatedListAllStations = ListAllStations.map(location => {
                const matchingHeartbeat = JSON.parse(window.localStorage.getItem('socketHeart'))?.find(hb => hb.chargerId === location.stationName && hb.updateResp === 'Accepted');
                // location['connectorStatus'] = matchingHeartbeat ? 'Available' : 'Unavailable';
                // return { ...location };
                return { ...location, connectorStatus: matchingHeartbeat ? 'Available' : 'Unavailable' };
            });
            const filteredStations = updatedListAllStations.filter(itm => itm.connectorStatus === 'Available');

            let a = filteredStations.map(row => ({
                ...row,
                locationName: decryptResponseData(row.locationName),
                modelName: decryptResponseData(row.modelName),
                businessName: decryptResponseData(row.businessName),

            }))
            setListAllStationsNewData(a);
        } else {

            const updatedListAllStations = ListAllStations.map(location => {
                const matchingHeartbeat = JSON.parse(window.localStorage.getItem('socketHeart'))?.find(hb => hb.chargerId === location.stationName && hb.updateResp === 'Accepted');
                // location['connectorStatus'] = matchingHeartbeat ? 'Available' : 'Unavailable';
                // return { ...location };
                return { ...location, connectorStatus: matchingHeartbeat ? 'Available' : 'Unavailable' };
            });
            // If no status filter is applied, retain the original list
            // temp causing issue with offline

            let a = updatedListAllStations.map(row => ({
                ...row,
                locationName: decryptResponseData(row.locationName),
                modelName: decryptResponseData(row.modelName),
                businessName: decryptResponseData(row.businessName),

            }))

            setListAllStationsNewData(a);
        }



        // setListAllStationsNewData(ListAllStations)

    }, [window.localStorage.getItem('socketHeart'), ListAllStations])



    useEffect(() => {
        const setLocationFilter = () => {
            const TempList = locationList?.map((eve, ind) => {
                return {
                    id: `${ind + 1}`,
                    value: eve.locationName,
                    LocationID: eve.locationId
                }
            })
            return TempList;
        }
        const LocationT = setLocationFilter()
        setLocationListDAta(LocationT);

    }, [locationList]);


    useEffect(() => {
        const setLocationFilter = () => {
            const TempList = allConnectorStationList?.map((eve, ind) => {
                return {
                    id: `${ind + 1}`,
                    value: eve.connectorNumber,
                    connectorId: eve.connectorId
                }
            })
            return TempList;
        }
        const LocationT = setLocationFilter()
        setConnectorListDAta(LocationT);

    }, [allConnectorStationList]);


    useEffect(() => {
        const setLocationFilter = () => {
            const TempList = ListAllStations?.map((eve, ind) => {
                return {
                    id: `${ind + 1}`,
                    value: eve.stationName,
                    stationID: eve.stationId
                }
            })
            return TempList;
        }
        const StationT = setLocationFilter()
        setStationListDAta(StationT);

    }, [ListAllStations]);
    useEffect(() => {
        if (businessList?.length > 0) {
            const BusinessNameT = setBusiness();
            setBusinessName([...BusinessNameT]);
        }
    }, [businessList]);
    const handleResize = () => {

        setHeaderHeight(
            document.getElementsByClassName('navbar-header')[0]?.offsetHeight
        );
        setFooterHeight(
            document.getElementsByClassName('footer')[0]?.offsetHeight
        );
        setTableTaskBar(
            document.getElementsByClassName('tableTaskBar')[0]?.offsetHeight
        );
        setPaginationDiv(
            document.getElementsByClassName('paginationDiv')[0]?.offsetHeight
        );
        // setBreadCrumbHeight(
        //     document.getElementsByClassName('breadCrumb')[0]?.offsetHeight
        // );
        setCardHeaderHeight(
            document.getElementsByClassName('cardHeader')[0]?.offsetHeight
        );
    };



    // let socketData = JSON.parse(window.localStorage.getItem('socketStatus')) != null ? JSON.parse(window.localStorage.getItem('socketStatus')) : null;











    const setBusiness = () => {
        const tempArray = [{ id: '0', value: 'All', BusinessID: 'All' }]
        const businessName = businessList.map((eve, ind) => {
            return {
                id: `${ind + 1}`,
                value: eve.businessName,
                BusinessID: eve.businessId
            }
        })
        // return tempArray.concat(businessName);
        return businessName;
    }
    const tempArray = [{ id: '0', value: 'All', BusinessID: 'All' }]



    // useEffect(() => {

    // }, [ListAllStations])

    // useWebSocket(WS_URL, {
    //     onOpen: (res) => {

    //         
    //     },
    // });

    // const ws = new WebSocket(WS_URL);

    // ws.onmessage = (e) => {




    //     //setSocketValue(e.data)
    //     setSocketValue(JSON.parse(e.data).stationName)


    // };

    const staionListData = [
        {
            _id: "1",
            stationName: 'Station-1',
            location: 'Location Name 01',
            type: 'Public',
            Business: 'Titania Pvt Ltd',
            noOfconnectors: '5',
            version: "1.0",
            model: 'IC005',
            status: 'Online',
        },
        {
            _id: "2",
            stationName: 'Station-1',
            location: 'Location Name 01',
            type: 'Private',
            Business: 'Titania Pvt Ltd',
            noOfconnectors: '5',
            version: "1.0",
            model: 'IC005',
            status: 'offline',
        }
    ]
    // useEffect(() => {
    // }, [selectValueTH]);

    const bussinessDropdownValue = [
        {
            id: '1',
            value: 'All'
        },
        {
            id: '2',
            value: 'Titania Pvt Ltd'
        },
        {
            id: '3',
            value: 'Deimos LLP'
        },
        {
            id: '4',
            value: 'Hyperion LLP '
        },
        {
            id: '5',
            value: 'Phobos Pvt Ltd'
        },
        {
            id: '6',
            value: 'Nereid LLC'
        }
    ]
    const bussinessSelectData = [
        {
            id: '1',
            value: 'All'
        },
        {
            id: '2',
            value: 'Titania Pvt Ltd'
        },
        {
            id: '3',
            value: 'Deimos LLP'
        },
        {
            id: '4',
            value: 'Hyperion LLP '
        },
        {
            id: '5',
            value: 'Phobos Pvt Ltd'
        },
        {
            id: '6',
            value: 'Nereid LLC'
        }
    ]
    const typeSelectDataArray = [
        {
            id: '1',
            value: 'Private'
        },
        {
            id: '2',
            value: 'Public'
        },

    ]

    const stationStatustDataArray = [
        {
            id: '1',
            value: 'Online'
        },
        {
            id: '2',
            value: 'Offline'
        },

    ]

    const locationDropdownValue = [
        {
            id: '1',
            value: 'All'
        },
        {
            id: '2',
            value: 'Location01'
        },
        {
            id: '3',
            value: 'Location02'
        },
        {
            id: '4',
            value: 'Location03'
        }
    ]

    // custom sort fn
    const handleBusinesNameSort = React.useMemo(() => {
        return (rowA, rowB, columnId, desc) => {
            return rowA.values.businessName.toLowerCase() > rowB.values.businessName.toLowerCase() ? 1 : -1;
        }
    }, [])

    const handleStationNameSort = React.useMemo(() => {
        return (rowA, rowB, columnId, desc) => {
            return rowA.values.stationName.toLowerCase() > rowB.values.stationName.toLowerCase() ? 1 : -1;
        }
    }, [])


    const statusData = [{ id: '1', value: 'Active' }, { id: '2', value: 'Inactive' }]


    // Column
    let columns1 = useMemo(
        () => [
            {
                Header: "Station Name",
                accessor: 'stationName',
                filterable: true,
                show: true,
                sortType: handleStationNameSort,
                Cell: (cell) => {
                    // if (SocketValue === cell.value) {
                    //     setSocketValueData('online')
                    // }
                    return <p className={cell?.row.original.stationType === 'Private' ? 'text blueTxt textOverflow' : 'text textOverflow'}>{cell.value}</p>;
                }
            },
            {
                Header: "Type",
                accessor: "stationType",
                filterable: true,
                show: true,
                disableGlobalFilter: true,
                Cell: (type) => {

                    return (
                        <div>
                            <p className={type.value === 'Private' ? 'text blueTxt' : 'text'}>{type.value}</p>
                        </div>
                    )
                },
            },
            {
                Header: "Location",
                accessor: "locationName",
                disableSortBy: true,
                filterable: true,

                show: true,
                Cell: (cell) => {
                    return <p className='text wordBreakAll textOverflow'>{cell.value}</p>;
                },

            },
            {
                Header: "Business",
                accessor: "businessName",
                filterable: true,
                sortType: handleBusinesNameSort,
                show: window.localStorage.getItem('users') !== undefined && window.localStorage.getItem('users') !== null && JSON.parse(window.localStorage.getItem('users')).userRole !== 'BusinessOwner',
                Cell: (cell) => {
                    return <p className='text wordBreakAll textOverflow'>{cell.value}</p>;
                },
            },
            {
                Header: `Connectors (${noOfConn ? noOfConn : '0'})`,
                accessor: "nConnectors",
                disableGlobalFilter: true,
                filterable: true,
                show: true,
                Cell: (cell) => {
                    return <p className='text'>{cell.value}</p>;
                },
            },
            {
                Header: "Firmware Version",
                accessor: "firmwareVersion",
                filterable: true,
                show: true,
                disableGlobalFilter: true,
                disableSortBy: true,
                Cell: (cell) => {
                    return <p className='text'>{cell.value === 'null' ? '-' : cell.value}</p>;
                },
            },
            {
                Header: "Model Name",
                accessor: "modelName",
                filterable: true,
                show: true,
                disableGlobalFilter: true,
                disableSortBy: true,
                Cell: (cell) => {
                    return <p className='text textOverflow wordBreak'>{cell.value}</p>;
                },
            },
            {
                Header: "Status",
                accessor: "connectorStatus",
                filterable: true,
                show: true,
                disableGlobalFilter: true,
                disableSortBy: true,
                Cell: (cell) => {
                    return <div>
                        {/* <span className={cell.value === 'Active' ? "badge green" : 'badge red'}> {cell.value === 'Active' ? 'Online' : 'Ofline'} </span>; */}
                        {/* <span className={allListLocation?.stationName === socketData?.stationName ? socketData?.status === "Available" ? 'badge green' : 'badge red' : 'badge red'}>{allListLocation?.stationName === socketData?.stationName ? socketData?.status === "Available" ? 'Online' : 'Offline' : 'Offline'}</span> */}
                        <span className={cell.value === "Available" ? 'badge green' : 'badge red'}>{cell.value === "Available" ? "Online" : "Offline"}</span>
                    </div>
                },
            }
        ],
        [selectValueTH, noOfConn, handleStationNameSort, handleBusinesNameSort, JSON.parse(window.localStorage.getItem('socketHeart')), ListAllStations]
    );

    useEffect(() => {

    }, [listAllStationsNewData])

    const handleAddButtonClicks = () => {
        history.push('/stations/addstation');
    }

    const handleViewDetailClick = (e) => {



        history.push('/stations/viewstation', {
            state: {
                stationId: e.original.stationId,
                EvseID: e.original.EVSEId
            }
        });
    }


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* <div className='stationListingPage' style={{ height: !IsShowMap ? dimensions.height - 130 + 'px' : 'auto' }}> */}
                    <div className='stationListingPage'>
                        <Row>
                            <Col lg={12}>
                                <Card className='mb-0'>
                                    <CardHeader className='cardHeader'>
                                        <p className='headerTxt'>Station Management</p>
                                    </CardHeader>
                                    <CardBody>
                                        <div className={listAllStationsNewData.length > 0 ? 'table-content' : 'table-content disablePointer'}>
                                            {customLoader ? <TableContainer
                                                columns={columns1.filter(a => a.show)}
                                                setfilteredTable={setfilteredTable}
                                                customFilterButton={buttonFilter}
                                                data={listAllStationsNewData}
                                                isGlobalFilter={true}
                                                customPageSize={16}
                                                maxHeight={dimensions.height - headerHeight - footerHeight - tableTaskBar - paginationDiv - cardHeader - 105}
                                                divClass="table-responsive mb-1 viewLocationTable"
                                                tableClass="align-middle table-nowrap"
                                                theadClass="table-light text-muted"
                                                isAddButton={true}
                                                handleAddButtonClicks={handleAddButtonClicks}
                                                addButtonTitle='Add Station'
                                                searchPlaceholder={getUserRole() ? 'Search Station Name, Business Name, Location Name' : 'Search Station Name, Business Name'}
                                                handleViewDetailClick={(row) => handleViewDetailClick(row)}
                                                noData={listAllStationsNewData.length > 0 ? false : true}
                                                noDataImage={"https://res.cloudinary.com/djyl1goby/image/upload/v1670566470/Lubi/WebApp/Static-images/No_Station_found.svg"}
                                                noDataTitle={'No Stations Found!'}

                                                // bussinessDropdown
                                                // isDropdown={true}
                                                // dropdownTitle='Business'
                                                // selectedValue={selectValue}
                                                // dropDownItems={businessName}
                                                // onSelect={(evt) => {
                                                //     setSelectValue(evt);
                                                // }}
                                                customFilter={true}
                                                allFilter={false}
                                                // BUSINESS FILTER
                                                bussinessSelectData={tempArray.concat(businessName.sort((a, b) => { return a.value?.toLowerCase() > b.value?.toLowerCase() ? 1 : -1 }))}
                                                typeSelectData={typeSelectDataArray}
                                                BusinessSelect={bSelectFilter}
                                                TypeSelect={typeSelectFilter}
                                                onBusinessSelect={(evt) => {

                                                    setBSelectFilter(evt);
                                                    setLSelectFilter('')
                                                    if (evt.value === 'All') {
                                                        dispatch(allLocation('?id='));
                                                    } else {
                                                        dispatch(allLocation(`?id=${evt?.BusinessID}`));
                                                    }
                                                }}
                                                onTypeSelect={(evt) => {
                                                    setTypeSelectFilter(evt)
                                                }}
                                                onModelName={(evt) => {
                                                    setModelNameFilter(evt.target.value)
                                                }}
                                                onstatusStatus={(e) => {
                                                    setActiveInactiveFilter(e.value)
                                                }}

                                                statusOnclick={(e) => {
                                                    setStatusFilter(e.target.checked)

                                                }}
                                                statusCheckedValue={statusFilter || false}
                                                // stationOnclick={(e) => {
                                                //     setActiveInactiveFilter(e.target.checked)

                                                // }}

                                                stationCheckedValue={activeInactiveFilter || ''}

                                                // LOCATION FILTER
                                                locationData={locationListDAta.sort((a, b) => { return a.value?.toLowerCase() > b.value?.toLowerCase() ? 1 : -1 })}
                                                LocationSelect={lSelectFilter}
                                                modelNameFilter={modelNameFilter}

                                                onLocaionSelect={(evt) => {

                                                    if (evt == undefined) {
                                                        return;
                                                    }
                                                    setLSelectFilter(evt);

                                                }}
                                                modalName={filterModal}
                                                onClickCustomFilter={() => {
                                                    window.localStorage.getItem("users") !== null &&
                                                        window.localStorage.getItem("users") !== undefined &&
                                                        JSON.parse(window.localStorage.getItem("users"))?.userRole !=
                                                        "LocationManager" ? setFilterModal(true) : setStationFilterModel(true)
                                                }}
                                                onApplyFilterBtn={() => {
                                                    // let statusFilterData = 'Inactive'
                                                    // if (activeInactiveFilter) {
                                                    //     statusFilterData = 'Active'
                                                    // }
                                                    const typeValue = typeSelectFilter?.value || '';
                                                    const locationID = lSelectFilter?.LocationID;
                                                    const businessID = bSelectFilter?.BusinessID === 'All' ? '' : bSelectFilter?.BusinessID;

                                                    // Base query object
                                                    const params = {
                                                        locationId: locationID || '',
                                                        businessId: businessID || '',
                                                        stationType: typeValue || '',
                                                        modelName: modelNameFilter || '',
                                                        stationStatus: activeInactiveFilter || ''
                                                    };

                                                    // Build query string
                                                    const queryString = Object.entries(params)
                                                        .filter(([, value]) => value !== undefined && value !== '')
                                                        .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
                                                        .join('&');
                                                    // Dispatch the action
                                                    dispatch(listAllStation(`?${queryString}`));
                                                    // if (bSelectFilter.BusinessID == 'All') {
                                                    //     if (lSelectFilter?.LocationID == undefined) {

                                                    //         if (typeSelectFilter?.value && !modelNameFilter && !activeInactiveFilter) {
                                                    //             dispatch(listAllStation(`?locationId=&businessId=&stationType=${typeSelectFilter?.value}`));

                                                    //         }
                                                    //         else if (!typeSelectFilter?.value && modelNameFilter && !activeInactiveFilter) {
                                                    //             dispatch(listAllStation(`?locationId=&businessId=&modelName=${modelNameFilter}`));

                                                    //         }
                                                    //         else if (!typeSelectFilter?.value && !modelNameFilter && activeInactiveFilter) {
                                                    //             dispatch(listAllStation(`?locationId=&businessId=&stationStatus=${activeInactiveFilter}`));

                                                    //         }
                                                    //         else if (typeSelectFilter?.value && modelNameFilter && !activeInactiveFilter) {
                                                    //             dispatch(listAllStation(`?locationId=&businessId=&stationType=${typeSelectFilter?.value}&modelName=${modelNameFilter}`));

                                                    //         }
                                                    //         else if (typeSelectFilter?.value && activeInactiveFilter && !modelNameFilter) {
                                                    //             dispatch(listAllStation(`?locationId=&businessId=&stationType=${typeSelectFilter?.value}&stationStatus=${activeInactiveFilter}`));

                                                    //         }
                                                    //         else if (modelNameFilter && activeInactiveFilter && !typeSelectFilter?.value) {
                                                    //             dispatch(listAllStation(`?locationId=&businessId=&modelName=${modelNameFilter}&stationStatus=${activeInactiveFilter}`));

                                                    //         }
                                                    //         else if (activeInactiveFilter && modelNameFilter && typeSelectFilter?.value) {
                                                    //             dispatch(listAllStation(`?locationId=&businessId=&modelName=${modelNameFilter}&stationStatus=${activeInactiveFilter}&stationType=${typeSelectFilter?.value}`));

                                                    //         }
                                                    //         else {
                                                    //             dispatch(listAllStation(`?locationId=&businessId=`));

                                                    //         }
                                                    //     } else {
                                                    //         if (typeSelectFilter?.value && !modelNameFilter && !activeInactiveFilter) {
                                                    //             dispatch(listAllStation(`?locationId=&businessId=&stationType=${typeSelectFilter?.value}`));

                                                    //         }
                                                    //         else if (!typeSelectFilter?.value && modelNameFilter && !activeInactiveFilter) {
                                                    //             dispatch(listAllStation(`?locationId=&businessId=&modelName=${modelNameFilter}`));

                                                    //         }
                                                    //         else if (!typeSelectFilter?.value && !modelNameFilter && activeInactiveFilter) {
                                                    //             dispatch(listAllStation(`?locationId=&businessId=&stationStatus=${activeInactiveFilter}`));

                                                    //         }
                                                    //         else if (typeSelectFilter?.value && modelNameFilter && !activeInactiveFilter) {
                                                    //             dispatch(listAllStation(`?locationId=&businessId=&stationType=${typeSelectFilter?.value}&modelName=${modelNameFilter}`));

                                                    //         }
                                                    //         else if (typeSelectFilter?.value && activeInactiveFilter && !modelNameFilter) {
                                                    //             dispatch(listAllStation(`?locationId=&businessId=&stationType=${typeSelectFilter?.value}&stationStatus=${activeInactiveFilter}`));

                                                    //         }
                                                    //         else if (modelNameFilter && activeInactiveFilter && !typeSelectFilter?.value) {
                                                    //             dispatch(listAllStation(`?locationId=&businessId=&modelName=${modelNameFilter}&stationStatus=${activeInactiveFilter}`));

                                                    //         }
                                                    //         else if (activeInactiveFilter && modelNameFilter && typeSelectFilter?.value) {
                                                    //             dispatch(listAllStation(`?locationId=&businessId=&modelName=${modelNameFilter}&stationStatus=${activeInactiveFilter}&stationType=${typeSelectFilter?.value}`));

                                                    //         }
                                                    //         else {
                                                    //             dispatch(listAllStation(`?locationId=${lSelectFilter?.LocationID}&businessId=`));


                                                    //         }
                                                    //     }
                                                    // } else {
                                                    //     if (lSelectFilter?.LocationID == undefined) {
                                                    //         if (typeSelectFilter?.value && !modelNameFilter && !activeInactiveFilter) {
                                                    //             dispatch(listAllStation(`?locationId=&businessId=&stationType=${typeSelectFilter?.value}`));

                                                    //         }
                                                    //         else if (!typeSelectFilter?.value && modelNameFilter && !activeInactiveFilter) {
                                                    //             dispatch(listAllStation(`?locationId=&businessId=&modelName=${modelNameFilter}`));

                                                    //         }
                                                    //         else if (!typeSelectFilter?.value && !modelNameFilter && activeInactiveFilter) {
                                                    //             dispatch(listAllStation(`?locationId=&businessId=&stationStatus=${activeInactiveFilter}`));

                                                    //         }
                                                    //         else if (typeSelectFilter?.value && modelNameFilter && !activeInactiveFilter) {
                                                    //             dispatch(listAllStation(`?locationId=&businessId=&stationType=${typeSelectFilter?.value}&modelName=${modelNameFilter}`));

                                                    //         }
                                                    //         else if (typeSelectFilter?.value && activeInactiveFilter && !modelNameFilter) {
                                                    //             dispatch(listAllStation(`?locationId=&businessId=&stationType=${typeSelectFilter?.value}&stationStatus=${activeInactiveFilter}`));

                                                    //         }
                                                    //         else if (modelNameFilter && activeInactiveFilter && !typeSelectFilter?.value) {
                                                    //             dispatch(listAllStation(`?locationId=&businessId=&modelName=${modelNameFilter}&stationStatus=${activeInactiveFilter}`));

                                                    //         }
                                                    //         else if (activeInactiveFilter && modelNameFilter && typeSelectFilter?.value) {
                                                    //             dispatch(listAllStation(`?locationId=&businessId=&modelName=${modelNameFilter}&stationStatus=${activeInactiveFilter}&stationType=${typeSelectFilter?.value}`));

                                                    //         }
                                                    //         else {
                                                    //             dispatch(listAllStation(`?locationId=&businessId=${bSelectFilter?.BusinessID}`));



                                                    //         }
                                                    //     } else {
                                                    //         if (typeSelectFilter?.value && !modelNameFilter && !activeInactiveFilter) {
                                                    //             dispatch(listAllStation(`?locationId=&businessId=&stationType=${typeSelectFilter?.value}`));

                                                    //         }
                                                    //         else if (!typeSelectFilter?.value && modelNameFilter && !activeInactiveFilter) {
                                                    //             dispatch(listAllStation(`?locationId=&businessId=&modelName=${modelNameFilter}`));

                                                    //         }
                                                    //         else if (!typeSelectFilter?.value && !modelNameFilter && activeInactiveFilter) {
                                                    //             dispatch(listAllStation(`?locationId=&businessId=&stationStatus=${activeInactiveFilter}`));

                                                    //         }
                                                    //         else if (typeSelectFilter?.value && modelNameFilter && !activeInactiveFilter) {
                                                    //             dispatch(listAllStation(`?locationId=&businessId=&stationType=${typeSelectFilter?.value}&modelName=${modelNameFilter}`));

                                                    //         }
                                                    //         else if (typeSelectFilter?.value && activeInactiveFilter && !modelNameFilter) {
                                                    //             dispatch(listAllStation(`?locationId=&businessId=&stationType=${typeSelectFilter?.value}&stationStatus=${activeInactiveFilter}`));

                                                    //         }
                                                    //         else if (modelNameFilter && activeInactiveFilter && !typeSelectFilter?.value) {
                                                    //             dispatch(listAllStation(`?locationId=&businessId=&modelName=${modelNameFilter}&stationStatus=${activeInactiveFilter}`));

                                                    //         }
                                                    //         else if (activeInactiveFilter && modelNameFilter && typeSelectFilter?.value) {
                                                    //             dispatch(listAllStation(`?locationId=&businessId=&modelName=${modelNameFilter}&stationStatus=${activeInactiveFilter}&stationType=${typeSelectFilter?.value}`));

                                                    //         }
                                                    //         else {
                                                    //             dispatch(listAllStation(`?locationId=${lSelectFilter?.LocationID}&businessId=${bSelectFilter?.BusinessID}`));
                                                    //         }
                                                    //     }

                                                    // }
                                                    setFilterModal(false);
                                                    setisFilterApplied(true);

                                                }}
                                                handleClose={() => {
                                                    setFilterModal(false);
                                                    dispatch(allLocation('?id='));
                                                    if (!isFilterApplied) {
                                                        setLSelectFilter('');
                                                        setBSelectFilter('');
                                                    }

                                                }}
                                                clearFilterData={() => {
                                                    dispatch(listAllStation(`?locationId=`));
                                                    dispatch(allLocation('?id='));
                                                    setFilterModal(false);
                                                    setisFilterApplied(false);
                                                    setLSelectFilter('');
                                                    setBSelectFilter('');
                                                    setModelNameFilter('');
                                                    setStatusFilter(false);
                                                    setActiveInactiveFilter('');
                                                    setTypeSelectFilter({})

                                                }}
                                            />
                                                : <ContentLoader />}
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>





            <Modal className='tariffModal' isOpen={stationFilterModel}
                centered={true}>
                <ModalHeader>
                    <p>Filter</p>
                    <i className='bx bx-x' onClick={() => {
                        setStationFilterModel(false)

                    }} />
                </ModalHeader>
                <ModalBody className="">
                    <div className='qrMain'>
                        <div className='fleet-filter-view'>
                            <Row>
                                <Col lg={6}>
                                    <div className="lg-6">
                                        <Label>Email</Label>
                                        <Input
                                            name='connectorType'
                                            type="email"
                                            className="form-control"
                                            id="connectorType"
                                            placeholder="Enter Email"
                                            value={emailFilter || ''}
                                            onChange={(e) => {
                                                // console.log(e.target.value, '======>')
                                                setEmailFilter(e.target.value)
                                            }}
                                        />
                                    </div>
                                </Col>
                                <Col lg={6} className='dateInputRowStyle'>
                                    <div className="lg-6">
                                        <Label>Phone No.</Label>
                                        <ReactInputMask
                                            mask={"+\\91 9999999999"}
                                            maskChar={""}
                                            name="contactNumber"
                                            className={
                                                "form-control"
                                            }
                                            id="phonenumberInput"
                                            placeholder="Enter Contact Number"
                                            value={phoneFilter || ''}
                                            onChange={(e) => {
                                                // console.log(e.target.value)
                                                setPhoneFilter(e.target.value)

                                            }}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <></>

                            <>
                                <Row style={{ marginTop: '15px' }}>

                                    <Col lg={6}>
                                        <div className="lg-6">
                                            <Label>Region</Label>
                                            <Input
                                                name="modelName"
                                                type="text"
                                                className="form-control"
                                                id="Region"
                                                placeholder="Enter Region"
                                                value={
                                                    regionFilter || ""
                                                }
                                                onChange={(e) => {
                                                    setRegionFilter(e.target.value)
                                                }}
                                                maxLength={15}

                                            />
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <div className="lg-6">
                                            <Label>Charger Model Name</Label>
                                            <Input
                                                name="modelName"
                                                type="text"
                                                className="form-control"
                                                id="modelName"
                                                placeholder="Enter Charger Model"
                                                value={
                                                    modelNameFilter || ""
                                                }
                                                onChange={(e) => {
                                                    setModelNameFilter(e.target.value)
                                                }}

                                                maxLength={15}

                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </>
                            <>
                                <Row style={{ marginTop: '15px' }}>
                                    <Col lg={6}>
                                        <div className="lg-6" >
                                            <Label>Station Type</Label>
                                            <CustomDropdown
                                                className='allDropdowns'
                                                moreValue={true}
                                                dropDownItems={typeSelectDataArray}
                                                defaultValue={stationTypeValue}
                                                placeholder={'Choose Station Type'}
                                                selectedValue={stationTypeValue}
                                                showSecondVal={false}
                                                onSelect={evt => {
                                                    setStationTypeValue(evt.value)

                                                }}
                                            />
                                        </div>
                                    </Col>

                                    <Col lg={6}>
                                        <div className="lg-6" >
                                            <Label>Station Status</Label>

                                            <div className="form-check form-switch">
                                                <Input
                                                    onClick={(e) => {
                                                        setStationStatusValue(e.target.checked)

                                                    }}
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    role="switch"
                                                    checked={stationStatusValue || false}
                                                    id=""

                                                />
                                            </div>
                                            {/* <CustomDropdown
                                                className='allDropdowns'
                                                moreValue={true}
                                                dropDownItems={stationStatustDataArray}
                                                defaultValue={stationStatusValue}
                                                placeholder={'Choose Station Status'}
                                                selectedValue={stationStatusValue}
                                                showSecondVal={false}
                                                onSelect={evt => {
                                                    setStationStatusValue(evt.value)

                                                }}
                                            /> */}
                                        </div>
                                    </Col>
                                </Row>
                            </>

                            <>
                                <Row style={{ marginTop: '15px' }}>
                                    <Col lg={6}>
                                        <div className="lg-6" >
                                            <Label>Business</Label>
                                            <CustomDropdown
                                                className='allDropdowns'
                                                moreValue={true}
                                                dropDownItems={businessName}
                                                defaultValue={getBusId}
                                                placeholder={'Choose Business'}
                                                selectedValue={getBusId}
                                                showSecondVal={false}
                                                onSelect={evt => {
                                                    setGetBusId(evt.value)
                                                    setFilterbusinessId(evt.BusinessID)
                                                    dispatch(allLocation(`?id=${evt?.BusinessID}`));
                                                    setGetConId('')
                                                    setGetLocId('')
                                                    setGetStaId('')
                                                }}
                                            />
                                        </div>
                                    </Col>

                                    <Col lg={6}>
                                        <div className="lg-6" >
                                            <Label>Location</Label>
                                            <CustomDropdown
                                                className='allDropdowns'
                                                moreValue={true}
                                                dropDownItems={locationListDAta}
                                                defaultValue={getLocId}
                                                placeholder={'Choose Location'}
                                                selectedValue={getLocId}
                                                showSecondVal={false}
                                                onSelect={evt => {
                                                    setGetLocId(evt.value)
                                                    setFilterLocationId(evt.LocationID)
                                                    dispatch(listAllStation(`?locationId=${evt.LocationID}`));
                                                    setGetConId('')
                                                    setGetStaId('')

                                                }}
                                            />
                                        </div>

                                    </Col>
                                </Row>
                            </>
                            <Row style={{ marginTop: '15px' }}>

                                <Col lg={6}>
                                    <div className="lg-6" >
                                        <Label>Station</Label>
                                        <CustomDropdown
                                            className='allDropdowns'
                                            moreValue={true}
                                            dropDownItems={stationListDAta}
                                            defaultValue={getStaId}
                                            placeholder={'Choose Station'}
                                            selectedValue={getStaId}
                                            showSecondVal={false}
                                            onSelect={evt => {
                                                setGetStaId(evt.value)
                                                setFilterstationId(evt.stationID)
                                                setGetConId('')
                                                dispatch(allConnectorStationId(`?stationId=${evt.stationID}`));

                                            }}
                                        />
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className="lg-6" >
                                        <Label>Connector</Label>
                                        <CustomDropdown
                                            className='allDropdowns'
                                            moreValue={true}
                                            dropDownItems={connectorListDAta}
                                            defaultValue={getConId}
                                            placeholder={'Choose Connector'}
                                            selectedValue={getConId}
                                            showSecondVal={false}
                                            onSelect={evt => {
                                                setGetConId(evt.value)
                                                setFilterconnectorId(evt.connectorId)

                                            }}
                                        />
                                    </div>
                                </Col>
                            </Row>


                            <>
                                <Row style={{ marginTop: '15px' }}>
                                    <Col lg={6}>
                                        <div className="lg-6" >
                                            <Label>Location Status</Label>
                                            <CustomDropdown
                                                className='allDropdowns'
                                                moreValue={true}
                                                dropDownItems={statusData}
                                                defaultValue={activeInactiveFilter}
                                                placeholder={'Choose Status'}
                                                selectedValue={activeInactiveFilter}
                                                showSecondVal={false}
                                                onSelect={evt => {
                                                    setActiveInactiveFilter(evt.value)
                                                    // setFilterconnectorId(evt.connectorId)
                                                }}
                                            />
                                            {/* <div className="form-check form-switch">
                                                <Input
                                                    onClick={(e) => {
                                                        setActiveInactiveFilter(e.target.checked)

                                                    }}
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    role="switch"
                                                    checked={activeInactiveFilter || false}
                                                    id=""

                                                />
                                            </div> */}

                                        </div>
                                    </Col>

                                </Row>
                            </>
                        </div>
                    </div>
                    <div className="assignMain">
                        <div className="hstack gap-2  mt-0 justify-content-end">
                            <Button
                                onClick={() => {
                                    setButtonFilter(false)
                                    setGetBusId('')
                                    setGetStaId('')
                                    setGetConId('')
                                    setGetLocId('')
                                    setStationListDAta([])
                                    setConnectorListDAta([])
                                    setLocationListDAta([])

                                    setFilterbusinessId('')
                                    setFilterstationId('')
                                    setFilterconnectorId('')
                                    setEmailFilter('')
                                    setPhoneFilter('')
                                    setStationFilterModel(false)
                                    setModelNameFilter('')
                                    setStationTypeValue('')
                                    setRegionFilter('')
                                    setFilterLocationId('')
                                    setStationStatusValue('')
                                    setActiveInactiveFilter('')
                                    dispatch(listAllStation(`?locationId=`));
                                    // dispatch(allLocation('?id='));

                                }}
                            >
                                Clear
                            </Button>
                            <Button
                                color={stationStatusValue == '' && (!emailFilter && !stationTypeValue && !modelNameFilter && !regionFilter && activeInactiveFilter === '' && !phoneFilter && !filterbusinessId && !filterLocationId && !filterstationId && !filterConnectorId) ? 'secondary' : 'yellow'}
                                type="submit"
                                disabled={stationStatusValue == '' && !emailFilter && !stationTypeValue && !modelNameFilter && !regionFilter && activeInactiveFilter === '' && !phoneFilter && !filterbusinessId && !filterLocationId && !filterstationId && !filterConnectorId}
                                onClick={async () => {
                                    setButtonFilter(true)
                                    let queryParams = '';
                                    // let statusFilter = 'Inactive'
                                    // if (activeInactiveFilter) {
                                    //     statusFilter = 'Active'
                                    // }

                                    if (emailFilter && !stationTypeValue && !modelNameFilter && !regionFilter && !activeInactiveFilter && !phoneFilter && !filterbusinessId && !filterLocationId && !filterstationId && !filterConnectorId) {
                                        queryParams += `?status=${activeInactiveFilter}&email=${emailFilter}`;
                                    } else if (activeInactiveFilter && !stationTypeValue && !modelNameFilter && !regionFilter && !emailFilter && !phoneFilter && !filterbusinessId && !filterLocationId && !filterstationId && !filterConnectorId) {
                                        queryParams += `?status=${activeInactiveFilter}`;
                                    } else if (phoneFilter && !stationTypeValue && !modelNameFilter && !regionFilter && !emailFilter && !activeInactiveFilter && !filterbusinessId && !filterLocationId && !filterstationId && !filterConnectorId) {
                                        queryParams += `?status=${activeInactiveFilter}&phone=${phoneFilter.split(' ')[1]}`;
                                    } else if (emailFilter && !stationTypeValue && !modelNameFilter && !regionFilter && activeInactiveFilter && !phoneFilter && !filterbusinessId && !filterLocationId && !filterstationId && !filterConnectorId) {
                                        queryParams += `?email=${emailFilter}&status=${activeInactiveFilter}`;
                                    } else if (emailFilter && !stationTypeValue && !modelNameFilter && !regionFilter && phoneFilter && !activeInactiveFilter && !filterbusinessId && !filterLocationId && !filterstationId && !filterConnectorId) {
                                        queryParams += `?status=${activeInactiveFilter}&email=${emailFilter}&phone=${phoneFilter.split(' ')[1]}`;
                                    } else if (activeInactiveFilter && !stationTypeValue && !modelNameFilter && !regionFilter && phoneFilter && !emailFilter && !filterbusinessId && !filterLocationId && !filterstationId && !filterConnectorId) {
                                        queryParams += `?status=${activeInactiveFilter}&phone=${phoneFilter.split(' ')[1]}`;
                                    } else if (activeInactiveFilter && !stationTypeValue && !modelNameFilter && !regionFilter && phoneFilter && emailFilter && !filterbusinessId && !filterLocationId && !filterstationId && !filterConnectorId) {
                                        queryParams += `?status=${activeInactiveFilter}&phone=${phoneFilter.split(' ')[1]}&email=${emailFilter}`;
                                    } else if (filterbusinessId && !stationTypeValue && !modelNameFilter && !regionFilter && !emailFilter && !activeInactiveFilter && !phoneFilter && !filterLocationId && !filterstationId && !filterConnectorId) {
                                        queryParams += `?status=${activeInactiveFilter}&businessId=${filterbusinessId}`;
                                    } else if (filterstationId && !stationTypeValue && !modelNameFilter && !regionFilter && !emailFilter && !activeInactiveFilter && !phoneFilter && !filterLocationId && !filterbusinessId && !filterConnectorId) {
                                        queryParams += `?status=${activeInactiveFilter}&stationId=${filterstationId}`;
                                    } else if (filterLocationId && !stationTypeValue && !modelNameFilter && !regionFilter && !emailFilter && !activeInactiveFilter && !phoneFilter && !filterstationId && !filterbusinessId && !filterConnectorId) {
                                        queryParams += `?status=${activeInactiveFilter}&locationId=${filterLocationId}`;
                                    } else if (filterConnectorId && !stationTypeValue && !modelNameFilter && !regionFilter && !emailFilter && !activeInactiveFilter && !phoneFilter && !filterLocationId && !filterbusinessId && !filterstationId) {
                                        queryParams += `?status=${activeInactiveFilter}&connectorId=${filterConnectorId}`;
                                    } else if (regionFilter && !stationTypeValue && !modelNameFilter && !filterConnectorId && !emailFilter && !activeInactiveFilter && !phoneFilter && !filterLocationId && !filterbusinessId && !filterstationId) {
                                        queryParams += `?status=${activeInactiveFilter}&region=${regionFilter}`;
                                    }
                                    else if (stationTypeValue && !regionFilter && !modelNameFilter && !filterConnectorId && !emailFilter && !activeInactiveFilter && !phoneFilter && !filterLocationId && !filterbusinessId && !filterstationId) {
                                        queryParams += `?status=${activeInactiveFilter}&stationType=${stationTypeValue}`;
                                    }
                                    else if (modelNameFilter && !regionFilter && !stationTypeValue && !filterConnectorId && !emailFilter && !activeInactiveFilter && !phoneFilter && !filterLocationId && !filterbusinessId && !filterstationId) {
                                        queryParams += `?status=${activeInactiveFilter}&modelName=${modelNameFilter}`;
                                    } else {
                                        queryParams += `?status=${activeInactiveFilter}`;

                                        if (emailFilter) {
                                            queryParams += `&email=${emailFilter}`;
                                        }
                                        if (regionFilter) {
                                            queryParams += `&region=${regionFilter}`;
                                        }

                                        if (phoneFilter) {
                                            queryParams += `&phone=${phoneFilter.split(' ')[1]}`;
                                        }
                                        if (modelNameFilter) {
                                            queryParams += `&modelName=${modelNameFilter}`;
                                        }

                                        if (stationTypeValue) {
                                            queryParams += `&stationType=${stationTypeValue}`;
                                        }

                                        if (filterbusinessId) {
                                            queryParams += `&businessId=${filterbusinessId}`;
                                        }
                                        if (filterLocationId) {
                                            queryParams += `&locationId=${filterLocationId}`;
                                        }
                                        if (filterstationId) {
                                            queryParams += `&stationId=${filterstationId}`;
                                        }
                                        if (filterConnectorId) {
                                            queryParams += `&connectorId=${filterConnectorId}`;
                                        }
                                    }


                                    dispatch(listAllStation(queryParams));



                                    // console.log(filterstationId, 'filterstationId')
                                    setStationFilterModel(false)
                                }}
                            >
                                Apply
                            </Button>
                        </div>
                    </div>
                </ModalBody>
            </Modal>

        </React.Fragment>
    );
};

export default Stations;