import {
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  VERIFY_OTP,
  RESEND_OTP,
  VERIFY_OTP_SUCCESS,
  VERIFY_EMAIL_SUCCESS,
  VERIFY_EMAIL_FAIL
} from "./actionTypes";

const initialState = {
  forgetSuccessdata: [],
  verifyOtpSuccess: [],
  forgetError: null,
  resetSuccessMsg: null,
  resetErrorMsg: null,
  customerVerified: false,
  verifyEmailError: false
};

const forgetPassword = (state = initialState, action) => {
  switch (action.type) {
    case FORGET_PASSWORD:
      state = {
        ...state,
        // forgetSuccessMsg: null,
        forgetError: null,
      };
      break;
    case FORGET_PASSWORD_SUCCESS:
      state = {
        ...state,
        forgetSuccessdata: action.payload,
      };
      break;
    case FORGET_PASSWORD_ERROR:
      state = {
        ...state,
        forgetError: action.payload
      };
      break;
    case RESET_PASSWORD:
      state = {
        ...state,
        resetSuccessMsg: null,
        resetErrorMsg: null,
      };
      break;
    case VERIFY_OTP_SUCCESS:
      state = {
        ...state,
        verifyOtpSuccess: action.payload
      }
      break;
    case RESEND_OTP:
      state = {
        ...state
      }
      break;
    case VERIFY_EMAIL_SUCCESS:
      state = {
        ...state,
        customerVerified: true,
        verifyEmailError: false
      }
      break;
    case VERIFY_EMAIL_FAIL:
      state = {
        ...state,
        customerVerified: false,
        verifyEmailError: true
      }
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default forgetPassword;
