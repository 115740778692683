import * as aesjs from 'aes-js';

const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY;
const encryptionIV = process.env.REACT_APP_ENCRYPTION_IV;

export function AES_256_CBC_encrypt(message, key, iv) {
    try {
        // Convert key and message to bytes
        let keyBytes = stringToByteArray(key);
        let messageBytes = stringToByteArray(message);

        // Convert IV to bytes
        let ivBytes = stringToByteArray(iv);

        // Pad the message to multiple of 16 bytes
        messageBytes = padBytes(messageBytes);

        // Create AES cipher object
        let aesCipher = new aesjs.ModeOfOperation.cbc(keyBytes, ivBytes);

        // Encrypt the message bytes
        let encryptedBytes = aesCipher.encrypt(messageBytes);

        // Convert encrypted bytes to hexadecimal string
        let encryptedHex = bytesToHexString(encryptedBytes);

        return encryptedHex;
    } catch (error) {
        console.log(`error ----->>>>`, error);
    }
}

export function AES_256_CBC_decrypt(encryptedHex, key, iv) {
    try {
        // Convert key and IV to bytes
        let keyBytes = stringToByteArray(key);
        let ivBytes = stringToByteArray(iv);

        // Convert encrypted string to bytes
        let encryptedBytes = hexStringToBytes(encryptedHex);

        // Create AES cipher object
        let aesCipher = new aesjs.ModeOfOperation.cbc(keyBytes, ivBytes);

        // Decrypt the encrypted bytes
        let decryptedBytes = aesCipher.decrypt(encryptedBytes);

        // Remove padding
        let decryptedMessage = unpadBytes(decryptedBytes);

        // Convert decrypted bytes to string
        return byteArrayToString(decryptedMessage);
    } catch (error) {
        // console.log(`error ----->>>>`, error);
    }
}

// Padding functions
function padBytes(input) {
    let blockSize = 16;
    let padding = blockSize - (input.length % blockSize);
    let paddedInput = new Uint8Array(input.length + padding);
    paddedInput.set(input);
    for (let i = input.length; i < paddedInput.length; i++) {
        paddedInput[i] = padding;
    }
    return paddedInput;
}

function unpadBytes(paddedInput) {
    let padding = paddedInput[paddedInput.length - 1];
    return paddedInput.slice(0, paddedInput.length - padding);
}

// Utility functions
function stringToByteArray(str) {
    let byteArray = new Uint8Array(str.length);
    for (let i = 0; i < str.length; i++) {
        byteArray[i] = str.charCodeAt(i);
    }
    return byteArray;
}

function byteArrayToString(byteArray) {
    let str = '';
    for (let i = 0; i < byteArray.length; i++) {
        str += String.fromCharCode(byteArray[i]);
    }
    return str;
}

function bytesToHexString(byteArray) {
    let hexString = '';
    for (let i = 0; i < byteArray.length; i++) {
        let hex = (byteArray[i] & 0xff).toString(16);
        hex = (hex.length === 1) ? '0' + hex : hex;
        hexString += hex;
    }
    return hexString;
}

function hexStringToBytes(hexString) {
    let byteArray = [];
    for (let i = 0; i < hexString.length; i += 2) {
        byteArray.push(parseInt(hexString.substr(i, 2), 16));
    }
    return new Uint8Array(byteArray);
}

export function decryptResponseData(resp) {
    let data = AES_256_CBC_decrypt(resp, encryptionKey, encryptionIV);
    return (data)
}

export function encryptedPayload(payload) {
    let data = AES_256_CBC_encrypt(payload, encryptionKey, encryptionIV);
    return data;
}

// Example usage
// let key = '0123456789abcdef0123456789abcdef';
// let iv = '0123456789abcdef';
// let message = 'Hello, world!';

// let encryptedHex = AES_256_CBC_encrypt(message, key, iv);
// console.log('Encrypted:', encryptedHex);

// let decryptedMessage = AES_256_CBC_decrypt(encryptedHex, key, iv);
// console.log('Decrypted:', decryptedMessage);