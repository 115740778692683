import React, { Fragment, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
  useRowSelect,
} from "react-table";
import {
  Table,
  Row,
  Col,
  Button,
  Input,
  CardBody,
  Dropdown,
  Label,
  Form,
  FormFeedback,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { getUserRole } from "../../helpers/helper.service";
import { Filter, DefaultColumnFilter } from "./filters";
import {
  ProductsGlobalFilter,
  CustomersGlobalFilter,
  OrderGlobalFilter,
  ContactsGlobalFilter,
  CompaniesGlobalFilter,
  LeadsGlobalFilter,
  CryptoOrdersGlobalFilter,
  InvoiceListGlobalSearch,
  TicketsListGlobalFilter,
  NFTRankingGlobalFilter,
  TaskListGlobalFilter,
} from "../../Components/Common/GlobalSearchFilter";
import { useLocation } from "react-router-dom";
import CustomDropdown from "./CustomDropdown";
// Define a default UI for filtering
import { useFormik } from "formik";
import * as Yup from "yup";
import Constant from "../../utils/constant";
import useWindowDimension from "../Hooks/useWindowDimension";
import OutsideClickHandler from "react-outside-click-handler";
import { useDispatch, useSelector } from "react-redux";
import contentLoaderImage from "../../assets/images/giphy.gif";
import { addNewRFID } from "../../store/rfid/actions";
import Loader from "../../assets/images/giphy.gif";
import CustomButton from "./CustomButton";
import Flatpickr from "react-flatpickr";

function GlobalFilter({
  resetRefTrue,
  resetRefFalse,
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  isCustomerFilter,
  isOrderFilter,
  isContactsFilter,
  isCompaniesFilter,
  isCryptoOrdersFilter,
  isInvoiceListFilter,
  isTicketsListFilter,
  isNFTRankingFilter,
  onCloseFilter,
  rfidFilterList,
  onClickRfidFilterItem,
  isTaskListFilter,
  isProductsFilter,
  customFilterButton,
  isFilterRfifOpen,
  isLeadsFilter,
  onClickCustomFilter,
  onClickCustomFilterRfid,
  onSelectRfidItem,
  customFilter,
  customFilterRfid,
  searchPlaceholder,
  allSelcted,
  checkValue,
  isApplyed,
  isApplyedR,
  handleCheckStatusBar,

}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const [data, setData] = useState("");
  // const { searchPlaceholder } = props;




  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
    resetRefTrue();
  }, 200);
  const { pathname } = useLocation();

  useEffect(() => {
    resetRefFalse();
  });

  return (
    <React.Fragment>
      <CardBody className="p-0 pt-3 pb-3">
        <form>
          <Row className="m-0">
            <Col className="p-0 d-flex align-items-center">
              {/* <div className={(isProductsFilter || isContactsFilter || isCompaniesFilter || isNFTRankingFilter) ? "search-box me-2 d-inline-block" : "search-box me-2 d-inline-block col-12"}> */}
              {allSelcted ? (
                <div className="checkStatusBar me-2">
                  <Input
                    type="checkbox"
                    id=""
                    className="form-check-input setSuccess"
                    defaultChecked={true}
                    onChange={(e) => { handleCheckStatusBar(e.target.checked) }}
                  />
                  {checkValue} RFID Selected
                </div>
              ) : (
                <div className={"search-box me-2 d-inline-block"}>
                  <input
                    onChange={(e) => {
                      setValue(e.target.value);
                      onChange(e.target.value);
                    }}
                    id="search-bar-0"
                    type="text"
                    maxLength={200}
                    className="form-control search /"
                    placeholder={searchPlaceholder}
                    value={value || ""}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                      }
                    }}
                  />
                  <i className="bx bx-search search-icon"></i>
                  {/* {value?.length > 0 && <i className="bx bx-x clearIconBtn fs-20" onClick={() => { setValue('') }}></i>} */}
                  {value?.length > 0 && (
                    <i
                      className="bx bx-x clearIconBtn fs-20"
                      onClick={(event) => {
                        setValue("");
                        onChange();
                        if (event.keyCode === 13) {
                          //13 is the key code for Enter
                          event.preventDefault();
                        }
                      }}
                    ></i>
                  )}
                </div>
              )}
              {customFilter && (
                <>
                  <Button
                    type="button"
                    className="filterBtn"
                    onClick={onClickCustomFilter}
                  >
                    <i className="bx bx-filter-alt fs-18" />
                    {(isApplyed || customFilterButton) && <i className="bx bxs-circle circleIcon" />}
                  </Button>
                </>
              )}
              {customFilterRfid && (
                <>
                  <div className="rfidFilter">
                    <Button
                      type="button"
                      className="filterBtn"
                      onClick={onClickCustomFilterRfid}
                    >
                      <i className="bx bx-filter-alt fs-18" />
                      {isApplyedR && <i className="bx bxs-circle circleIcon" />}
                    </Button>
                    {isFilterRfifOpen && (
                      <OutsideClickHandler
                        onOutsideClick={onClickRfidFilterItem}
                      >
                        <div className="menu">
                          <ul>
                            {rfidFilterList.map((item, index) => {
                              return (
                                <li
                                  key={index}
                                  id={item.id}
                                  className={
                                    item.id === data.id ? "selected" : ""
                                  }
                                  onClick={(e) => {
                                    onSelectRfidItem(item);
                                    onClickRfidFilterItem();
                                    setData(item);
                                  }}
                                >
                                  {item.value}
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </OutsideClickHandler>
                    )}
                  </div>
                </>
              )}
            </Col>
            {isProductsFilter && <ProductsGlobalFilter />}
            {isCustomerFilter && <CustomersGlobalFilter />}
            {isOrderFilter && <OrderGlobalFilter />}
            {isContactsFilter && <ContactsGlobalFilter />}
            {isCompaniesFilter && <CompaniesGlobalFilter />}
            {isLeadsFilter && <LeadsGlobalFilter />}
            {isCryptoOrdersFilter && <CryptoOrdersGlobalFilter />}
            {isInvoiceListFilter && <InvoiceListGlobalSearch />}
            {isTicketsListFilter && <TicketsListGlobalFilter />}
            {isNFTRankingFilter && <NFTRankingGlobalFilter />}
            {isTaskListFilter && <TaskListGlobalFilter />}
          </Row>
        </form>
      </CardBody>
    </React.Fragment >
  );
}
const TableContainer = ({
  setfilteredTable,
  columns,
  data,
  isGlobalSearch,
  isGlobalFilter,
  isProductsFilter,
  customFilterButton,
  isCustomerFilter,
  isOrderFilter,
  isContactsFilter,
  isCompaniesFilter,
  isLeadsFilter,
  isCryptoOrdersFilter,
  isInvoiceListFilter,
  isTicketsListFilter,
  isNFTRankingFilter,
  isTaskListFilter,
  isAddAssignDisabled,
  isAddAssign,
  isAddButton,
  isImport,
  isAddOptions,
  isAddUserList,
  handleOrderClicks,
  handleUserClick,
  handleCustomerClick,
  handleAssignClicks,
  isAddCustList,
  customPageSize,
  customPageAll,
  tableClass,
  theadClass,
  trClass,
  trClassName,
  thClass,
  tdClass,
  tdStyle,
  thStyle,
  divClass,
  onChangetr,
  tableHeight,
  minHeight,
  maxHeight,
  handleViewDetailClick,
  addButtonTitle,
  handleAddButtonClicks,
  dropDownItems,
  selectedValue,
  onSelect,
  isDropdown,
  dropdownTitle,
  importBtnTitle,
  AssignButtonTitle,
  handleImportClicks,
  handleDeleteClicks,
  isDelete,
  isUassign,
  handleUassignClicks,
  unassignBtnTitle,
  deleteBtnTitle,
  RfidForm,
  onClickCustomFilter,
  onClickCustomFilterRfid,
  customFilter,
  customFilterRfid,
  onSelectRfidItem,
  searchPlaceholder,
  bussinessSelectData,
  typeSelectData,
  locationData,
  stationData,
  connectorData,
  handleClose,
  modalName,
  isFilterRfifOpen,
  isAddButtonDisabled,
  pagination,
  paginationLogsPage,
  customPreviousPage,
  customDisablePreviousPage,
  customNextPage,
  customDisableNextPage,
  customPageIndex,
  noData,
  contentLoader,
  noDataImage,
  noDataTitle,
  userRole,
  allSelcted,
  checkValue,
  allFilter,
  onClickRfidFilterItem,
  rfidFilterList,
  isApplyedR,
  LocationSelect,
  onLocaionSelect,
  BusinessSelect,
  TypeSelect,
  onBusinessSelect,
  onTypeSelect,
  onModelName,
  onstatusStatus,
  statusOnclick,
  stationOnclick,
  statusCheckedValue,
  stationCheckedValue,
  modelNameFilter,
  onApplyFilterBtn,
  onFleetData,
  clearFilterData,
  isExportAssign,
  handleExportClicks,
  ExportButtonTitle,
  isExportCSVAssign,
  handleExportCSVClicks,
  ExportCSVButtonTitle,
  handleSelectedRowsU,
  handleSelectedRowsA,
  handleSelectedRFIDRows,
  handleSelectedLocationRows,
  handleResetRefOnPagination,
  fleetFilterShow,
  businessFilter
}) => {
  const { isLoadingRFID } = useSelector((state) => state.RFIDList);

  let resetRef = useRef(false);
  const resetRefFalse = () => {
    resetRef.current = false;
  };
  const resetRefTrue = () => {
    resetRef.current = true;
  };
  const statusData = [{ id: '1', value: 'Active' }, { id: '2', value: 'Inactive' }]

  useEffect(() => {
    resetRefTrue()
  }, [data])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
    rows,
    selectedFlatRows,
    toggleAllRowsSelected
  } = useTable(
    {
      columns,
      autoResetPage: resetRef.current,
      autoResetGlobalFilter: resetRef.current,
      autoResetPageIndex: resetRef.current,
      autoResetExpanded: resetRef.current,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: customPageSize,
        selectedRowIds: 0,
        sortBy: [
          {
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  // const [filterModal, setFilterModal] = useState(false);
  const [businessSelect, setBusinessSelect] = useState("");
  const [locationSelect, setLocationSelect] = useState("");
  const [stationSelect, setStatonSelect] = useState("");
  const [connector, setConnector] = useState("");
  const [headerHeight, SetHeaderHeight] = useState(0);
  const [footerHeight, SetFooterHeight] = useState(0);
  const [tableTaskBar, setTableTaskBar] = useState(0);
  const [paginationDiv, setPaginationDiv] = useState(0);
  const [locationManagerHeight, setLocationManagerHeight] = useState(0);

  const [statusFilter, setStatusFilter] = useState(false);


  const [isApplyed, setIsApplyed] = useState(false);
  const dimensions = useWindowDimension();

  const dispatch = useDispatch();

  useEffect(() => {

    if (setfilteredTable) {
      setfilteredTable(rows);
    }
  }, [state.globalFilter]);



  useEffect(() => {

    if (handleSelectedRowsU) {
      handleSelectedRowsU(selectedFlatRows);
    }
    if (handleSelectedRowsA) {
      handleSelectedRowsA(selectedFlatRows);
    }
    if (handleSelectedRFIDRows) {
      handleSelectedRFIDRows(selectedFlatRows);
    }
    if (handleSelectedLocationRows) {
      handleSelectedLocationRows(selectedFlatRows);
    }
  }, [selectedFlatRows.length]);
  // const clearAll = () => {
  //   setBusinessSelect('');
  //   setLocationSelect('')
  //   setStatonSelect('')
  //   setConnector('')
  // }
  const [changeDate, setChangeDate] = React.useState('');
  useEffect(() => {
    SetHeaderHeight(
      document.getElementsByClassName("navbar-header")[0]?.offsetHeight
    );
    setTableTaskBar(
      document.getElementsByClassName("tableTaskBar")[0]?.offsetHeight
    );
    setPaginationDiv(
      document.getElementsByClassName("paginationDiv")[0]?.offsetHeight
    );
    SetFooterHeight(document.getElementsByClassName("footer")[0]?.offsetHeight);
    setLocationManagerHeight(
      document.getElementsByClassName("locationManagerHeight")[0]?.offsetHeight
    );
  }, []);
  const handleResize = () => {
    SetHeaderHeight(
      document.getElementsByClassName("navbar-header")[0]?.offsetHeight
    );
    setTableTaskBar(
      document.getElementsByClassName("tableTaskBar")[0]?.offsetHeight
    );
    setPaginationDiv(
      document.getElementsByClassName("paginationDiv")[0]?.offsetHeight
    );
    SetFooterHeight(document.getElementsByClassName("footer")[0]?.offsetHeight);
    setLocationManagerHeight(
      document.getElementsByClassName("locationManagerHeight")[0]?.offsetHeight
    );
  };

  useEffect(() => {

    window.addEventListener("resize", handleResize);
  }, []);

  const generateSortingIndicator = (column) => {
    return column.isSorted ? (
      column.isSortedDesc ? (
        <i className="bx bx-caret-down sortByIcon" />
      ) : (
        <i className="bx bx-caret-up sortByIcon" />
      )
    ) : (
      <i className="bx bxs-sort-alt sortByIcon" />
    );
    // return column.isSorted ? (column.isSortedDesc ? " " : "") : "";
  };

  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value));
  };
  const onChangeInInput = (event) => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0;
    gotoPage(page);
  };
  const exceptThisSymbols = ["e", "E", "+", "-", "."];

  // Formik validation
  const rfidBasic = useFormik({
    enableReinitialize: true,

    initialValues: {
      rfidnumber: "",
    },
    validationSchema: Yup.object({
      rfidnumber: Yup.string()
        .matches(
          Constant.REGEX.ALPHANUMERIC,
          "Alphanumeric character allower only"
        )
        .min(12, "Minimum characters should be 12"),
    }),
    onSubmit: (values, { resetForm }) => {
      dispatch(addNewRFID(values));
      resetForm();
    },
  });

  const handleCheckStatusBar = (value) => {
    toggleAllRowsSelected(value)
  }
  return (
    <>
      <div className="tableContainer">
        <Fragment>
          <Row className="m-0 mb-3 tableTaskBar">
            {isGlobalSearch && (
              <Col md={1}>
                <select
                  className="form-select"
                  value={pageSize}
                  onChange={onChangeInSelect}
                >
                  {[10, 20, 30, 40, 50].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      Show {pageSize}
                    </option>
                  ))}
                </select>
              </Col>
            )}
            {isGlobalFilter && (
              <GlobalFilter
                resetRefTrue={resetRefTrue}
                resetRefFalse={resetRefFalse}
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
                isProductsFilter={isProductsFilter}
                customFilterButton={customFilterButton}
                isCustomerFilter={isCustomerFilter}
                isOrderFilter={isOrderFilter}
                isContactsFilter={isContactsFilter}
                isCompaniesFilter={isCompaniesFilter}
                isLeadsFilter={isLeadsFilter}
                isCryptoOrdersFilter={isCryptoOrdersFilter}
                isInvoiceListFilter={isInvoiceListFilter}
                isTicketsListFilter={isTicketsListFilter}
                isNFTRankingFilter={isNFTRankingFilter}
                isTaskListFilter={isTaskListFilter}
                onClickCustomFilter={onClickCustomFilter}
                onClickCustomFilterRfid={onClickCustomFilterRfid}
                customFilter={customFilter}
                customFilterRfid={customFilterRfid}
                searchPlaceholder={searchPlaceholder}
                allSelcted={allSelcted}
                checkValue={checkValue}
                isApplyed={isApplyed}
                onClickRfidFilterItem={onClickRfidFilterItem}
                rfidFilterList={rfidFilterList}
                isFilterRfifOpen={isFilterRfifOpen}
                onSelectRfidItem={onSelectRfidItem}
                isApplyedR={isApplyedR}
                customPageSize={customPageSize}
                customPageAll={customPageAll}

                handleCheckStatusBar={handleCheckStatusBar}
              />
            )}
            <div className="rightOptions">
              {isDropdown && (
                <div className="dropdownMain">
                  <Label>{dropdownTitle}</Label>
                  <CustomDropdown
                    dropDownItems={dropDownItems}
                    defaultValue={"All"}
                    placeholder={"Select"}
                    selectedValue={selectedValue}
                    // onSelect={evt => {
                    //     setSelectValue(evt)
                    // }}
                    onSelect={onSelect}
                  />
                </div>
              )}
              {isAddAssign && (
                <Row className="p-0 pt-3 pb-3 m-0" style={{ width: "auto" }}>
                  <div className="text-sm-end pe-0">
                    <Button
                      type="button"
                      color=""
                      className="addButton"
                      onClick={handleAssignClicks}
                      disabled={isAddAssignDisabled || false}
                    >
                      <i className="mdi mdi-plus me-1" />
                      {AssignButtonTitle}
                    </Button>
                  </div>
                </Row>
              )}
              {isImport && (
                <Row className="p-0 m-0 pt-3 pb-3" style={{ width: "auto" }}>
                  <div className="text-sm-end pe-0">
                    <Button
                      type="button"
                      color=""
                      className="importBtn"
                      onClick={handleImportClicks}
                    >
                      <i className="ri-file-download-line me-1" />
                      {importBtnTitle}
                    </Button>
                  </div>
                </Row>
              )}
              {isUassign && (
                <Row className="p-0 m-0 pt-3 pb-3" style={{ width: "auto" }}>
                  <div className="text-sm-end pe-0">
                    <Button
                      type="button"
                      className=""
                      onClick={handleUassignClicks}
                    >
                      {unassignBtnTitle}
                    </Button>
                    {/* <Button
                      className='btn-unassign'
                      type='button' onClick={handleUassignClicks} >
                      {isLoadingRFID.unassigning ? <div className="btn-unassign"><img src={Loader} alt='' height={20} width={20} /></div> : unassignBtnTitle}
                    </Button> */}
                  </div>
                </Row>
              )}
              {isDelete && (
                <Row className="p-0 m-0 pt-3 pb-3" style={{ width: "auto" }}>
                  <div className="text-sm-end pe-0">
                    <Button
                      type="button"
                      color=""
                      className="deleteBtn"
                      onClick={handleDeleteClicks}
                    >
                      <i className="bx bx-trash me-1" />
                      {deleteBtnTitle}
                    </Button>
                  </div>
                </Row>
              )}
              {isAddButton && (
                <Row className="p-0 m-0 pt-3 pb-3" style={{ width: "auto" }}>
                  <div className="text-sm-end pe-0">
                    <Button
                      disabled={isAddButtonDisabled}
                      type="button"
                      className="addButton"
                      onClick={handleAddButtonClicks}
                    >
                      <i className="mdi mdi-plus me-1" />
                      {addButtonTitle}
                    </Button>
                  </div>
                </Row>
              )}
              {/* Export Btn */}
              {isExportAssign && (
                <Row className="p-0 pt-3 pb-3 m-0" style={{ width: "auto" }}>
                  <div className="text-sm-end pe-0">
                    <Button
                      type="button"
                      color=""
                      className="addButton"
                      onClick={handleExportClicks}
                    >
                      <i className="mdi mdi-download me-1" />
                      {ExportButtonTitle}
                    </Button>
                  </div>
                </Row>
              )}
              {/* Export Btn */}
              {isExportCSVAssign && (
                <Row className="p-0 pt-3 pb-3 m-0" style={{ width: "auto" }}>
                  <div className="text-sm-end pe-0">
                    <Button
                      type="button"
                      color=""
                      className="addButton"
                      onClick={handleExportCSVClicks}
                    >
                      <i className="mdi mdi-download me-1" />
                      {ExportCSVButtonTitle}
                    </Button>
                  </div>
                </Row>
              )}
            </div>
            {isAddOptions && (
              <Col sm="7">
                <div className="text-sm-end pt-3 pb-3">
                  <Button
                    type="button"
                    color="success"
                    className="btn-rounded me-2"
                    onClick={handleOrderClicks}
                  >
                    <i className="mdi mdi-plus me-1" />
                    Add New Order
                  </Button>
                </div>
              </Col>
            )}
            {isAddUserList && (
              <Col sm="7">
                <div className="text-sm-end">
                  <Button
                    type="button"
                    color="primary"
                    className="btn me-2"
                    onClick={handleUserClick}
                  >
                    <i className="mdi mdi-plus-circle-outline me-1" />
                    Create New User
                  </Button>
                </div>
              </Col>
            )}
            {isAddCustList && (
              <Col sm="7">
                <div className="text-sm-end">
                  <Button
                    type="button"
                    color="success"
                    className="btn-rounded me-2"
                    onClick={handleCustomerClick}
                  >
                    <i className="mdi mdi-plus me-1" />
                    New Customers
                  </Button>
                </div>
              </Col>
            )}
          </Row>
          <div
            id="oops"
            className={`${divClass} tableMain`}
            style={{
              height:
                dimensions.height -
                headerHeight -
                footerHeight -
                tableTaskBar -
                paginationDiv -
                (userRole && locationManagerHeight) -
                48 -
                40 -
                58 || `${tableHeight}`,
              overflowY: "auto",
              minHeight: `${minHeight}`,
              maxHeight: maxHeight,
            }}
          >
            <Table
              hover
              {...getTableProps()}
              className={`${tableClass} customTable`}
            >
              <thead className={theadClass}>
                {headerGroups.map((headerGroup) => (
                  <tr
                    className={trClass}
                    key={headerGroup.id}
                    {...headerGroup.getHeaderGroupProps()}
                  >
                    {headerGroup.headers.map((column) => {
                      return (
                        <th
                          style={thStyle}
                          key={column.id}
                          className={thClass}
                          {...column.getSortByToggleProps()}
                        >
                          <div className="headerDiv" id="oops2">
                            <span>{column.render("Header")}</span>
                            {!column.disableSortBy &&
                              generateSortingIndicator(column)}
                            {/* <Filter column={column} /> */}
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>

              <tbody {...getTableBodyProps()}>
                {RfidForm && (
                  <tr className="rfidRow">
                    <td></td>
                    <td>
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          rfidBasic.handleSubmit();
                          return false;
                        }}
                        action="#"
                      >
                        <div className="rfidInput">
                          <div className="d-flex justify-content-center flex-column ">
                            <Input
                              name="rfidnumber"
                              type="text"
                              className="form-control"
                              id="rfidnumber"
                              placeholder="Add RFID Number"
                              value={
                                rfidBasic.values.rfidnumber.toUpperCase() || ""
                              } /*With Uppercase Value*/
                              // value={rfidBasic.values.rfidnumber || ''}  /*With-out Uppercase Value*/
                              onChange={rfidBasic.handleChange}
                              maxLength={15}
                              invalid={
                                rfidBasic.errors.rfidnumber ? true : false
                              }
                            />
                            {rfidBasic.errors.rfidnumber ? (
                              <FormFeedback type="invalid">
                                {rfidBasic.errors.rfidnumber}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <CustomButton
                            title={"Add RFID"}
                            disabled={!rfidBasic.isValid}
                            type="submit"
                            loading={isLoadingRFID.adding}
                            className="btn-add"
                          />
                        </div>
                      </Form>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                )}
                {!noData && page.length > 0 ? (
                  <>
                    {page.map((row) => {
                      prepareRow(row);
                      return (
                        <Fragment key={row.getRowProps().key}>
                          <tr
                            onChange={onChangetr}
                            className={
                              row.original.status === "Active"
                                ? "Active"
                                : "disabled"
                            }
                            onClick={(e) => {
                              handleViewDetailClick(row);
                            }}
                          >
                            {row.cells.map((cell) => {
                              return (
                                <td
                                  id="oops2"
                                  style={tdStyle}
                                  key={cell.id}
                                  className={tdClass}
                                  {...cell.getCellProps()}
                                >
                                  {cell.render("Cell")}
                                </td>
                              );
                            })}
                          </tr>
                        </Fragment>
                      );
                    })}
                  </>
                ) : (
                  <tr
                    style={{
                      height:
                        dimensions.height -
                        headerHeight -
                        footerHeight -
                        tableTaskBar -
                        paginationDiv -
                        210 +
                        "px",
                    }}
                  >
                    <td style={{ padding: "0" }} colSpan={"100%"}>
                      <div className="noData">
                        <div className="contentNoData">
                          <img src={noDataImage} alt="" />
                          <p>{noDataTitle}</p>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
                {/* {contentLoader && <tr style={{ height: dimensions.height - headerHeight - footerHeight - tableTaskBar - 210 + 'px' }}>
                  <td style={{ padding: '0' }} colSpan={"100%"}>
                    <div className="noData">
                      <div className="contentNoData">
                        <img src={contentLoaderImage} alt="" />
                      </div>
                    </div>
                  </td>
                </tr>} */}
              </tbody>
            </Table>
          </div>
          {pagination && (
            <Row className="paginationDiv justify-content-md-sm-end justify-content-end align-items-center p-2">
              {pageOptions.length > 0 && (
                <>
                  <Col className="col-md-auto col-sm-auto">
                    <div className="d-flex gap-1">
                      <Button
                        color=""
                        onClick={previousPage}
                        disabled={!canPreviousPage}
                        style={{ padding: "5px 12px" }}
                      >
                        <i className="mdi mdi-chevron-left fs-18" />
                      </Button>
                    </div>
                  </Col>
                  <Col className="col-md-auto col-sm-auto d-none d-sm-block">
                    Page{" "}
                    <strong className="text-success">
                      {/* {pageIndex + 1} of {pageOptions.length} */}
                      {pageIndex + 1} of {pageOptions.length}
                    </strong>
                  </Col>
                  {/* <Col className="col-md-auto col-sm-auto">
                  <Input
                    type="number"
                    className="text-success data-canter"
                    min={1}
                    style={{ width: 70 }}
                    max={pageOptions.length}
                    defaultValue={pageIndex + 1}
                    onChange={onChangeInInput}
                    // disabled={!canNextPage}
                    onKeyDown={(e) =>
                      exceptThisSymbols.includes(e.key) && e.preventDefault()
                    }
                  />
                </Col> */}

                  <Col className="col-md-auto col-sm-auto">
                    <div className="d-flex gap-1">
                      <Button
                        color=""
                        style={{ padding: "5px 12px" }}
                        onClick={nextPage}
                        disabled={!canNextPage}
                      >
                        <i className="mdi mdi-chevron-right fs-18" />
                      </Button>
                    </div>
                  </Col>
                </>
              )}
            </Row>
          )}
          {paginationLogsPage && (
            <Row className="paginationDiv justify-content-md-sm-end justify-content-end align-items-center p-2">
              {pageOptions.length > 0 && (
                <>
                  <Col className="col-md-auto col-sm-auto">
                    <div className="d-flex gap-1">
                      <Button
                        color=""
                        onClick={customPreviousPage}
                        disabled={customDisablePreviousPage}
                        style={{ padding: "5px 12px" }}
                      >
                        <i className="mdi mdi-chevron-left fs-18" />
                      </Button>
                    </div>
                  </Col>
                  <Col className="col-md-auto col-sm-auto d-none d-sm-block">
                    Page{" "}
                    <strong className="text-success">
                      {/* {pageIndex + 1} of {pageOptions.length} */}
                      {customPageIndex} of {customPageAll}
                    </strong>
                  </Col>
                  <Col className="col-md-auto col-sm-auto">
                    <div className="d-flex gap-1">
                      <Button
                        color=""
                        style={{ padding: "5px 12px" }}
                        onClick={customNextPage}
                        disabled={customDisableNextPage}
                      >
                        <i className="mdi mdi-chevron-right fs-18" />
                      </Button>
                    </div>
                  </Col>
                </>
              )}
            </Row>
          )}
        </Fragment>
      </div>
      {/* <Modal className={getUserRole() === 'LM' ? "filterModal LMfilterModal" : 'filterModal'} isOpen={modalName} toggle={handleClose}
        centered={true}> */}
      <Modal
        className={
          window.localStorage.getItem("users") !== null &&
            window.localStorage.getItem("users") !== undefined &&
            JSON.parse(window.localStorage.getItem("users"))?.userRole ===
            "LocationManager"
            ? "filterModal LMfilterModal"
            : "filterModal"
        }
        isOpen={modalName}
        toggle={handleClose}
        centered={true}
      >
        <ModalHeader>
          <p>Filter</p>
          <i className="bx bx-x" onClick={handleClose} />
        </ModalHeader>
        <ModalBody className="">
          {!fleetFilterShow ? (<>{
            allFilter ? (
              <>
                <Row>
                  <Col lg={6} md={12}>
                    <div className="mb-3">
                      <Label>Business</Label>
                      <CustomDropdown
                        className="allDropdowns"
                        dropDownItems={bussinessSelectData}
                        defaultValue={""}
                        placeholder={"Select"}
                        selectedValue={BusinessSelect}
                        onSelect={onBusinessSelect}
                      />
                    </div>
                  </Col>
                  <Col lg={6} md={12}>
                    <div className="mb-3">
                      <Label>Location</Label>
                      <CustomDropdown
                        className="allDropdowns"
                        dropDownItems={locationData}
                        defaultValue={""}
                        placeholder={"Select"}
                        selectedValue={LocationSelect}
                        onSelect={onLocaionSelect}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} md={12}>
                    <div className="mb-3">
                      <Label>Station</Label>
                      <CustomDropdown
                        className="allDropdowns"
                        dropDownItems={stationData}
                        defaultValue={""}
                        placeholder={"Select"}
                        selectedValue={stationSelect}
                        onSelect={(evt) => {
                          setStatonSelect(evt);
                        }}
                      />
                    </div>
                  </Col>
                  <Col lg={6} md={12}>
                    <div className="mb-3">
                      <Label>Connector</Label>
                      <CustomDropdown
                        className="allDropdowns"
                        dropDownItems={connectorData}
                        defaultValue={""}
                        placeholder={"Select"}
                        selectedValue={connector}
                        onSelect={(evt) => {
                          setConnector(evt);
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Row>
                  {/* {getUserRole() === 'LM' ? */}
                  {window.localStorage.getItem("users") !== null &&
                    window.localStorage.getItem("users") !== undefined &&
                    JSON.parse(window.localStorage.getItem("users"))?.userRole ==
                    "LocationManager" ? (
                    <Col lg={12} md={12}>
                      <div className="mb-3">
                        <Label>Location</Label>
                        <CustomDropdown
                          className="allDropdowns"
                          dropDownItems={locationData}
                          defaultValue={""}
                          placeholder={"Select"}
                          selectedValue={LocationSelect}
                          onSelect={onLocaionSelect}
                        />
                      </div>
                    </Col>
                  ) : (
                    <>
                      {!businessFilter && <Col lg={6} md={6}>
                        <div className="mb-3">
                          <Label>Business</Label>
                          <CustomDropdown
                            className="allDropdowns"
                            dropDownItems={bussinessSelectData}
                            defaultValue={""}
                            placeholder={"Select"}
                            selectedValue={BusinessSelect}
                            onSelect={onBusinessSelect}
                          />
                        </div>
                      </Col>}
                      <Col lg={6} md={6}>
                        <div className="mb-3">
                          <Label>Location</Label>
                          <CustomDropdown
                            className="allDropdowns"
                            dropDownItems={locationData}
                            defaultValue={""}
                            placeholder={"Select"}
                            selectedValue={LocationSelect}
                            onSelect={onLocaionSelect}
                          />
                        </div>
                      </Col>


                      <Col lg={6} md={6}>
                        <div className="mb-3">
                          <Label>Type</Label>
                          <CustomDropdown
                            className="allDropdowns"
                            dropDownItems={typeSelectData}
                            defaultValue={""}
                            placeholder={"Select"}
                            selectedValue={TypeSelect}
                            onSelect={onTypeSelect}
                          />
                        </div>
                      </Col>

                      <Col lg={6} md={6}>
                        <div className="mb-3">
                          <Label>Model Name</Label>
                          <Input
                            name="modelName"
                            type="text"
                            className="form-control"
                            id="modelName"
                            placeholder="Enter Model Name"
                            value={
                              modelNameFilter || ""
                            } /*With Uppercase Value*/
                            // value={rfidBasic.values.rfidnumber || ''}  /*With-out Uppercase Value*/
                            // onChange={(e) => {
                            //   setModelNameFilter(e.target.value)
                            // }}
                            onChange={onModelName}
                            maxLength={15}
                          />
                        </div>
                      </Col>

                      <Col lg={6} md={6}>
                        <div className="mb-3">
                          <Label>Station Status</Label>
                          <CustomDropdown
                            className='allDropdowns'
                            moreValue={true}
                            dropDownItems={statusData}
                            defaultValue={stationCheckedValue}
                            placeholder={'Choose Station Status'}
                            selectedValue={stationCheckedValue}
                            showSecondVal={false}
                            onSelect={onstatusStatus}

                          // onSelect={evt => {
                          // setStatusDataselect(evt.value)
                          // setFilterconnectorId(evt.connectorId)
                          // }}
                          />
                          {/* <div className="form-check form-switch">
                            <Input

                              onClick={stationOnclick}
                              className="form-check-input"
                              type="checkbox"
                              role="switch"
                              checked={stationCheckedValue}
                              id=""

                            />
                          </div> */}
                        </div>

                      </Col>
                      <Col lg={6} md={6}>
                        <div className="mb-3">
                          <Label>Status</Label>
                          <div className="form-check form-switch">

                            <Input
                              onClick={statusOnclick}
                              // onClick={(e) => {
                              //   setStatusFilter(e.target.checked)

                              // }}
                              className="form-check-input"
                              type="checkbox"
                              role="switch"
                              checked={statusCheckedValue}
                              id=""

                            />
                          </div>
                        </div>
                      </Col>
                    </>
                  )}
                </Row>
              </>
            )}
          </>)
            :
            (<div className='fleet-filter-view'>
              <Row>
                <Col lg={6} md={6}>
                  <div className="mb-3">
                    <Label>Business</Label>
                    <CustomDropdown
                      className="allDropdowns"
                      dropDownItems={bussinessSelectData}
                      defaultValue={""}
                      placeholder={"Select"}
                      selectedValue={BusinessSelect}
                      onSelect={onBusinessSelect}
                    />
                  </div>
                </Col>
                <Col lg={6} md={6} className='dateInputRowStyle'>
                  <div className="mb-3">
                    <Label>Date</Label>
                    <Flatpickr
                      placeholder='Select Date'
                      className="form-control"
                      options={{
                        dateFormat: "d M, Y",
                        mode: 'range',
                        maxDate: new Date(),
                      }}
                      value={changeDate}
                      onChange={(date) => {
                        setChangeDate(date);


                      }}
                    />
                  </div>
                </Col>
              </Row>
            </div>)
          }
          <div className="assignMain">
            <div className="bottom d-flex justify-content-end mt-1 mb-3">
              <Button
                onClick={() => {
                  setIsApplyed(false);
                  clearFilterData();
                }}
              >
                Clear
              </Button>
              <Button
                type="submit"
                // onClick={() => {
                //   onApplyFilterBtn();
                //   setIsApplyed(true);
                // }}
                onClick={() => {
                  if (!fleetFilterShow) {
                    onApplyFilterBtn();
                    setIsApplyed(true);
                  } else {
                    // console.log('fghfgh', changeDate, BusinessSelect)
                    onFleetData(changeDate, BusinessSelect);

                  }
                }}
                // disabled={getUserRole() === 'LM' ? LocationSelect === '' : BusinessSelect === '' || LocationSelect === ''}
                disabled={
                  !fleetFilterShow ? (BusinessSelect === "" && modelNameFilter === '' && Object.keys(TypeSelect).length === 0 && LocationSelect === '' && !statusCheckedValue && stationCheckedValue === '') : !changeDate && !BusinessSelect ? true : false
                }

              >
                Apply
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal >
    </>
  );
};
TableContainer.defaultProps = {
  pagination: true,
  paginationLogsPage: false,
  noData: false,
  allSelcted: false,
  contentLoader: false,
};
TableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default TableContainer;
