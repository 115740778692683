import {
    LOCATION_LISTING,
    LOCATION_LISTING_SUCCESS,
    LOCATION_FILTER_LISTING,
    LOCATION_FILTER_LISTING_SUCCESS,
    LOCATION_DETAIL,
    LOCATION_DETAIL_SUCCESS,
    ADD_NEW_LOCATION,
    EDIT_LOCATION,
    LOCATION_INVITE_RESEND,
    LOCATION_MAN_BUSINESS,
    LOCATION_MAN_BUSINESS_SUCCESS,
    ACTIVATE_DEACTIVATE_LOCATION, DELETE_LOCATION,
    ALLSTATION_BUSINESS_LISTING,
    ALLSTATION_BUSINESS_LISTING_SUCCESS,
    ALLCONNECTOR_STATION_LISTING,
    ALLCONNECTOR_STATION_LISTING_SUCCESS
} from "./actionType";


export const allLocation = (data) => {
    return {
        type: LOCATION_LISTING,
        payload: { data },
    };
};

export const LocationListSuccess = data => {
    return {
        type: LOCATION_LISTING_SUCCESS,
        payload: data,
    };
};


export const allLocationFilter = (data) => {
    return {
        type: LOCATION_FILTER_LISTING,
        payload: { data },
    };
};

export const LocationFilterListSuccess = data => {
    return {
        type: LOCATION_FILTER_LISTING_SUCCESS,
        payload: data,
    };
};




export const allStaionBusinessId = (data) => {
    return {
        type: ALLSTATION_BUSINESS_LISTING,
        payload: { data },
    };
};

export const allStaionBusinessIdSuccess = data => {
    return {
        type: ALLSTATION_BUSINESS_LISTING_SUCCESS,
        payload: data,
    };
};

export const allConnectorStationId = (data) => {
    return {
        type: ALLCONNECTOR_STATION_LISTING,
        payload: { data },
    };
};

export const allConnectorStationIdSuccess = data => {
    return {
        type: ALLCONNECTOR_STATION_LISTING_SUCCESS,
        payload: data,
    };
};


export const LocationDetail = (locationId) => {
    return {
        type: LOCATION_DETAIL,
        payload: { locationId },
    };
};

export const LocationDetailSuccess = data => {
    return {
        type: LOCATION_DETAIL_SUCCESS,
        payload: data,
    };
};

export const addNewLocation = (user, businessID, history) => {
    return {
        type: ADD_NEW_LOCATION,
        payload: { user, businessID, history }
    }
}

export const editLocation = (user, locationID, history) => {
    return {
        type: EDIT_LOCATION,
        payload: { user, locationID, history }
    }
}

export const resendLocationInvite = data => {
    return {
        type: LOCATION_INVITE_RESEND,
        payload: { data }
    }
}

export const LocManBus = userId => {
    return {
        type: LOCATION_MAN_BUSINESS,
        payload: { userId }

    }
}

export const LocManBusSucess = data => {
    return {
        type: LOCATION_MAN_BUSINESS_SUCCESS,
        payload: data

    }
}

export const activateDeactivateLocationAction = (data) => {
    console.log("businessId", data);
    return {
        type: ACTIVATE_DEACTIVATE_LOCATION,
        payload: data,
    };
};
export const deleteLocationAction = (data) => {
    return {
        type: DELETE_LOCATION,
        payload: data,
    };
};