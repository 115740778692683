import React from 'react';
import { withRouter } from 'react-router-dom';

const OnBoarding = ({ children }) => {

    return (
        <React.Fragment>
            <div className="onBoardingMain" >

                {/* pass the children */}
                {children}
            </div>
        </React.Fragment>
    );
};

export default withRouter(OnBoarding);