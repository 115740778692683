import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Col, Container, Form, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
// import { Grid, _ } from 'gridjs-react';

//import Components
import DeleteModal from '../../../Components/Common/DeleteModal'
import { useDispatch, useSelector } from 'react-redux';
import { allBusinessList } from '../../../store/actions';
import TableContainer from '../../../Components/Common/TableContainer';
import { Formik, Field } from 'formik';
import * as Yup from "yup";
import Constant from '../../../utils/constant';
import Multiselect from "multiselect-react-dropdown";
import SearchDropDown from '../../../Components/Common/SearchDropDown';
import useWindowDimension from '../../../Components/Hooks/useWindowDimension';
import { assignBusinessToRFID, bulkImportRFID, deleteRFID, editRFID, getAllAssignedRFID, getAllUnAssignedRFID, RFIDCount, unAssignRFIDFromBusiness } from '../../../store/rfid/actions';
import { Checkbox } from '../../../Components/Common/Checkbox';
import ContentLoader from '../../../Components/Common/ContentLoader';
import { decryptResponseData } from '../../../helpers/encryptDecrypt'

const customPageSize = 7

const RFID = () => {
    document.title = "RFID | Vidhyutam";
    const dimensions = useWindowDimension();
    const dispatch = useDispatch()
    const { rfidCount, allUnAssignedRFIDList, allAssignedRFIDList } = useSelector(state => state.RFIDList)
    const { loader } = useSelector(state => state.Toast)
    const business = useSelector(state => state.businessList)

    const [deleteModalU, setDeleteModalU] = useState(false);
    const [deleteModalA, setDeleteModalA] = useState(false);
    const [basicModal, setBasicModal] = useState(false);
    const [importRfidNumberMoal, setImportRfidNumberMoal] = useState(false);
    const [selectBusiness, setSelectBusiness] = useState(null);
    const [isDeleteU, setIsDeleteU] = useState(false);
    const [isDeleteA, setIsDeleteA] = useState(false);
    const [isUassign, setIsUassign] = useState(false);
    const [isImport, setIsImport] = useState(true)
    const [file, setFile] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [rowToEdit, setRowToEdit] = useState({});
    const [isEditA, setIsEditA] = useState(false);
    const [isCheakAllU, setIsCheakAllU] = useState(false);
    const [isCheakAllA, setIsCheakAllA] = useState('');
    const [orderTab, setOrderTab] = useState('unAssign');
    const [selectedFilterValue, setSelectedFilterValue] = useState('');
    const [headerHeight, setHeaderHeight] = useState(0);
    const [footerHeight, setFooterHeight] = useState(0);
    const [tableTaskBar, setTableTaskBar] = useState(0);
    const [paginationDiv, setPaginationDiv] = useState(0);
    const [cardHeader, setCardHeaderHeight] = useState(0);
    const [tabsButton, setTabsButtonHeight] = useState(0);
    const [selectedRowsU, setSelectedRowsU] = useState([]);
    const [selectedRowsA, setSelectedRowsA] = useState([]);
    const [flatArray, setFlatArray] = useState([]);
    const [selectedValues, setSelectedValues] = useState([]);
    const [csvData, setCsvData] = useState([]);

    useEffect(() => {
        dispatch(getAllUnAssignedRFID())
        dispatch(getAllAssignedRFID('All'))
        dispatch(RFIDCount())
        dispatch(allBusinessList('?id='));
        setHeaderHeight(
            document.getElementsByClassName('navbar-header')[0]?.offsetHeight
        );
        setFooterHeight(
            document.getElementsByClassName('footer')[0]?.offsetHeight
        );
        setTableTaskBar(
            document.getElementsByClassName('tableTaskBar')[0]?.offsetHeight
        );
        setPaginationDiv(
            document.getElementsByClassName('paginationDiv')[0]?.offsetHeight
        );
        setCardHeaderHeight(
            document.getElementsByClassName('cardHeader')[0]?.offsetHeight
        );
        setTabsButtonHeight(
            document.getElementsByClassName('tabsButton')[0]?.offsetHeight
        );
    }, []);
    let decryptedBusiness;
    useEffect(() => {
        if (Array.isArray(business)) {
            decryptedBusiness = business.map(row => ({
                ...row,
                businessName: decryptResponseData(row.businessName)
            }));
            // Now use `decryptedBusiness` as needed (e.g., set in state if necessary)
        }
    }, [business]);
    const handleResize = () => {
        setHeaderHeight(
            document.getElementsByClassName('navbar-header')[0]?.offsetHeight
        );
        setFooterHeight(
            document.getElementsByClassName('footer')[0]?.offsetHeight
        );
        setTableTaskBar(
            document.getElementsByClassName('tableTaskBar')[0]?.offsetHeight
        );
        setPaginationDiv(
            document.getElementsByClassName('paginationDiv')[0]?.offsetHeight
        );
        setCardHeaderHeight(
            document.getElementsByClassName('cardHeader')[0]?.offsetHeight
        );
        setTabsButtonHeight(
            document.getElementsByClassName('tabsButton')[0]?.offsetHeight
        );
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
    }, [orderTab]);

    useEffect(() => {
        console.log("selectedRowsU", selectedRowsU);
        if (selectedRowsU.length > 0) {
            setIsDeleteU(true);
            setIsImport(false);
            setIsCheakAllU(true)
        } else {
            setIsDeleteU(false)
            setIsImport(true)
            setIsCheakAllU(false)
        }
    }, [selectedRowsU])

    useEffect(() => {
        console.log("selectedRowsA", selectedRowsA);
        if (selectedRowsA.length > 0) {
            setIsDeleteA(true);
            setIsUassign(true);
            setIsCheakAllA(true)
        } else {
            setIsDeleteA(false)
            setIsUassign(false)
            setIsCheakAllA(false)
        }
    }, [selectedRowsA])

    //handle selected row from TableContainer
    const handleSelectedRowsU = (selectedFlatRows) => {
        console.log("RFID", selectedFlatRows);
        setSelectedRowsU(selectedFlatRows.map((row) => +row.original.id));
    };
    const handleSelectedRowsA = (selectedFlatRows) => {
        console.log("RFID", selectedFlatRows);
        setSelectedRowsA(selectedFlatRows.map((row) => +row.original.id));
    };

    // handle delete
    const handleDeleteContactU = () => {
        console.log("delete", selectedRowsU);
        dispatch(deleteRFID(selectedRowsU))
        setDeleteModalU(false);
        setSelectedRowsU([])
    };
    const handleDeleteContactA = () => {
        dispatch(deleteRFID(selectedRowsA))
        setDeleteModalA(false);
        setSelectedRowsA([])
    };

    // Import RFID from CSV
    const handleCsvImported = () => {
        // console.log("CSV data", csvData);
        dispatch(bulkImportRFID(csvData))
        setImportRfidNumberMoal(false);
    };

    const parseCSV = (text) => {
        const lines = text.split('\n');
        const data = [];

        for (let i = 0; i < lines.length; i++) {
            const line = lines[i].trim();
            console.log("line", lines[i])

            if (line && line !== "RFID") {
                const values = line.split(',');
                data.push(values);
            }
        }
        return data;
    };



    const initiateCSVDownload = (data) => {

        if (data.length > 0) {
            const link = document.createElement('a');
            link.setAttribute('href', encodeURI(createCSVContent(data)));
            link.setAttribute('download', 'Data.csv');
            link.click();
        }
    }

    const createCSVContent = (data) => {
        const headers = ['RFID'];
        const csvContent = [
            headers.join(','), // Headers row
            ...data.map(row => headers.map(header => row[header]).join(',')) // Data rows
        ].join('\n');
        return 'data:text/csv;charset=utf-8,' + csvContent;
    }

    const downloadSample = () => {
        let arrayRFID = [{ RFID: "ABC123456PQR" }];
        initiateCSVDownload(arrayRFID);
        setImportRfidNumberMoal(false);
    }


    const handleFileUpload = event => {
        const file = event.target.files[0];

        if (file) {
            const reader = new FileReader();

            reader.onload = (event) => {
                const text = event.target.result;
                const data = parseCSV(text);
                setCsvData(data);
            };

            reader.readAsText(file);
            setFile(URL.createObjectURL(file))
        }
    };

    // Handle single Assign and UnAssign
    const handleAssignContact = () => {
        let payload = { rfidList: selectedValues, businessId: selectBusiness?.businessId }
        dispatch(assignBusinessToRFID(payload))
        setSelectBusiness(null)
        setBasicModal(false);
    };
    const handleUnAssignContact = (data) => {
        dispatch(unAssignRFIDFromBusiness([data.row.original.rfidNumber]))
    };

    //Handle Multiple assign and un-assign
    const handleAssignClicks = () => {
        const list = [...allUnAssignedRFIDList]
        const flatArrayOptions = list.map((item) => item.rfidNumber)
        const selectedRFIDs = list
            .filter(item => selectedRowsU.includes(item.id))
            .map(item => item.rfidNumber);
        setFlatArray(flatArrayOptions)
        setSelectedValues(selectedRFIDs)
        setBasicModal(true);
    }
    const handleUassignClicks = () => {
        const list = [...allAssignedRFIDList]
        const selectedRFIDs = list
            .filter(item => selectedRowsA.includes(item.id))
            .map(item => item.rfidNumber);
        dispatch(unAssignRFIDFromBusiness(selectedRFIDs))
    }

    //Opens the Model
    const handleImportClicks = () => {
        setImportRfidNumberMoal(true);
    }
    const handleDeleteClicks = () => {
        setDeleteModalU(true);
    }
    const handleDeleteClicksA = () => {
        setDeleteModalA(true);
    }
    const handleViewDetailClick = (row) => {
        setRowToEdit(row)
    }

    // Column
    const columns1 = useMemo(
        () => [
            {
                id: 'selection',
                disableSortBy: true,
                Header: ({ getToggleAllPageRowsSelectedProps }) => (
                    <Checkbox className="form-check-input setSuccess" {...getToggleAllPageRowsSelectedProps()} />
                ),
                Cell: ({ row }) => (
                    <Checkbox className="orderCheckBox form-check-input setSuccess" {...row.getToggleRowSelectedProps()} />
                ),
            },
            {
                Header: "RFID Number",
                accessor: "rfidNumber",
                filterable: true,
                Cell: (cell) => {
                    return <>
                        <Formik
                            initialValues={{ rfidNumber: cell?.value }}
                            validationSchema={Yup.object({
                                rfidNumber: Yup.string()
                                    .required("This field is required")
                                    .matches(Constant.REGEX.ALPHANUMERIC, 'Alphanumeric character allower only')
                                    .min(12, 'Minimum characters should be 12')
                            })}
                            onSubmit={(values) => { }}
                        >
                            {({ values, handleChange, handleBlur }) => (

                                <Form>
                                    {isEdit && rowToEdit.id === cell.row.id ? console.log('>>', values.users) || <div className="rfidInput">
                                        <div className="d-flex justify-content-center ">
                                            <Field name={`rfidNumber`}>
                                                {({ field, form, meta }) => (
                                                    <>
                                                        <div>
                                                            <Input
                                                                name='rfidNumber'
                                                                type="text"
                                                                className="form-control"
                                                                id={cell.row.index}
                                                                placeholder="Add RFID Number"
                                                                value={values.rfidNumber.toUpperCase() || ''}
                                                                onChange={handleChange}
                                                                onKeyUp={handleBlur}
                                                                maxLength={12}
                                                                invalid={
                                                                    meta.touched && meta.error ? true : false
                                                                }
                                                            />
                                                            {meta.error && meta.touched ? (
                                                                <FormFeedback type="invalid">{meta.error}</FormFeedback>
                                                            ) : null}
                                                        </div>
                                                        <Button
                                                            type="submit"
                                                            className=""
                                                            disabled={meta.error && meta.touched}
                                                            onClick={() => {
                                                                console.log("values to edit", values)
                                                                dispatch(editRFID(values.rfidNumber, cell.row?.original?.id))
                                                                setIsEdit(false)
                                                            }}
                                                        >
                                                            Ok
                                                        </Button>

                                                    </>
                                                )}
                                            </Field>
                                        </div>

                                    </div>
                                        : <p className="">{cell.value}</p>
                                    }
                                </Form>
                            )}

                        </Formik>
                    </>;
                },
            },
            {
                Header: 'Status',
                accessor: 'status',
                disableSortBy: true,
                filterable: true,

                Cell: (cell) => {
                    return <span className={cell.value === 'Assign' ? "badge text-uppercase badge-soft-success" : 'badge text-uppercase badge-soft-warning'}>{cell?.row?.original?.businessStatus || 'Un-Assigned'} </span>;
                }
            },
            {
                Header: "Action",
                disableSortBy: true,
                Cell: (cell) => {
                    return (
                        <ul className="list-inline hstack gap-2 mb-0">
                            <li className="list-inline-item edit">
                                <Link
                                    to="#"
                                    className="text-primary d-inline-block edit-item-btn"
                                    onClick={() => {
                                        setIsEdit(true);
                                    }}
                                >
                                    <i className="bx bx-edit-alt editBtn"></i>
                                </Link>
                            </li>
                            <li className="list-inline-item">
                                <Link
                                    to="#"
                                    className="text-danger d-inline-block remove-item-btn"
                                    onClick={() => {
                                        setSelectedRowsU([cell?.row?.original?.id])
                                        setDeleteModalU(true);
                                    }}
                                >
                                    <i className="bx bx-trash deleteBtn "></i>
                                </Link>
                            </li>
                        </ul>
                    );
                },
            },
        ], [isEdit]
    );
    const columns2 = useMemo(
        () => [
            {
                id: '#',
                disableSortBy: true,
                Header: ({ getToggleAllPageRowsSelectedProps }) => (
                    <div>
                        <Checkbox id="checkBoxAll2" className="form-check-input setSuccess" {...getToggleAllPageRowsSelectedProps()} />
                    </div>
                ),
                Cell: ({ row }) => (
                    <div>
                        <Checkbox className="orderCheckBox form-check-input setSuccess" {...row.getToggleRowSelectedProps()} />
                    </div>
                ),
            },
            {
                Header: "RFID Number",
                accessor: "rfidNumber",
                filterable: true,
                disableSortBy: true,
                Cell: (cell) => {
                    return <>
                        <Formik
                            initialValues={{ rfidNumber: cell?.value }}
                            validationSchema={Yup.object({
                                rfidNumber: Yup.string()
                                    .required("This field is required")
                                    .matches(Constant.REGEX.ALPHANUMERIC, 'Alphanumeric character allower only')
                                    .min(12, 'Minimum characters should be 12')
                            })}
                            onSubmit={(values) => { }}
                        >
                            {({ values, handleChange, handleBlur }) => (

                                <Form>
                                    {isEditA && rowToEdit.id === cell.row.id
                                        ? console.log('>>', values.users) || <div className="rfidInput">
                                            <div className="d-flex justify-content-center ">
                                                <Field name='rfidNumber'>
                                                    {({ field, form, meta }) => (
                                                        <>
                                                            <div>
                                                                <Input
                                                                    name='rfidNumber'
                                                                    type="text"
                                                                    className="form-control"
                                                                    value={values.rfidNumber.toUpperCase() || ''}
                                                                    placeholder="Add RFID Number"
                                                                    // value={values}
                                                                    onChange={handleChange}
                                                                    onKeyUp={handleBlur}
                                                                    maxLength={12}
                                                                    invalid={
                                                                        meta.touched && meta.error ? true : false
                                                                    }
                                                                />
                                                                {meta.error && meta.touched ? (
                                                                    <FormFeedback type="invalid">{meta.error}</FormFeedback>
                                                                ) : null}
                                                            </div>
                                                            <Button
                                                                type="submit"
                                                                className=""
                                                                disabled={meta.error && meta.touched}
                                                                onClick={() => {
                                                                    console.log("values to edit", values)
                                                                    dispatch(editRFID(values.rfidNumber, cell.row?.original?.id))
                                                                    setIsEditA(false);
                                                                }}
                                                            >
                                                                Ok
                                                            </Button>

                                                        </>
                                                    )}
                                                </Field>
                                            </div>

                                        </div>
                                        : <p className="">{cell.value}</p>
                                    }
                                </Form>
                            )}

                        </Formik>
                    </>;
                },
            },
            {
                Header: 'Assigned to',
                accessor: 'business',
                filterable: true,

                Cell: (cell) => {
                    return <Button className='removeRfidBtn' onClick={() => handleUnAssignContact(cell)}><span className='textOverflow'>{cell.value}</span>
                        <i className='bx bx-x fs-16' /></Button>;
                }
            },

            {
                Header: "Action",
                disableSortBy: true,
                Cell: (cell) => {
                    return (
                        <ul className="list-inline hstack gap-2 mb-0">
                            <li className="list-inline-item edit">
                                <Link
                                    to="#"
                                    className="text-primary d-inline-block edit-item-btn"
                                    onClick={() => {
                                        setIsEditA(true);
                                    }}
                                >
                                    <i className="bx bx-edit-alt editBtn"></i>
                                </Link>
                            </li>
                            <li className="list-inline-item">
                                <Link
                                    to="#"
                                    className="text-danger d-inline-block remove-item-btn"
                                    onClick={() => {
                                        setSelectedRowsA([cell?.row?.original?.id])
                                        setDeleteModalA(true);
                                    }}
                                >
                                    <i className="bx bx-trash deleteBtn "></i>
                                </Link>
                            </li>
                        </ul>
                    );
                },
            },
        ], [isEditA]
    );

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <div className='rfid_Page'>
                        <Row>
                            <Col lg={12}>
                                <Card className='mb-0'>
                                    <CardHeader className='pb-0 cardHeader'>
                                        <Row>
                                            <Col lg={12} className='mb-2'>
                                                <div className='rightPart'>
                                                    <Row>
                                                        <Col lg={12} className='lside'>
                                                            <div>
                                                                <Row>
                                                                    <Col md={4} lg={4} xl={3}>
                                                                        <div className='box d-flex align-items-start justify-content-start'>
                                                                            <div className='header d-flex align-items-center justify-content-center mb-0'>
                                                                                <i className='ri-money-dollar-circle-fill' />
                                                                                <div className='content  d-flex align-items-start justify-content-center'>
                                                                                    <span className='txt'>Total RFID:</span>
                                                                                    <span className='value'>{rfidCount?.totalRFID < 10 ? `0${rfidCount?.totalRFID}` : `${rfidCount?.totalRFID}`}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                    <Col md={4} lg={4} xl={3}>
                                                                        <div className='box d-flex align-items-start justify-content-start'>
                                                                            <div className='header d-flex align-items-center justify-content-center mb-0'>
                                                                                <i className='ri-file-copy-2-fill' />
                                                                                <div className='content  d-flex align-items-start justify-content-center'>
                                                                                    <span className='txt'>Assigned RFID:</span>
                                                                                    <span className='value'>{rfidCount?.assignedRFID < 10 ? `0${rfidCount?.assignedRFID}` : `${rfidCount?.assignedRFID}`}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                    <Col md={4} lg={4} xl={3}>
                                                                        <div className='box d-flex align-items-start justify-content-start'>
                                                                            <div className='header d-flex align-items-center justify-content-center mb-0'>
                                                                                <i className='ri-stack-fill' />
                                                                                <div className='content  d-flex align-items-start justify-content-center'>
                                                                                    <span className='txt'>Unassigned RFID :</span>
                                                                                    <span className='value'>{rfidCount?.unAssignedRFID < 10 ? `0${rfidCount?.unAssignedRFID}` : `${rfidCount?.unAssignedRFID}`}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                    <CardBody>
                                        <div className='tabsButton'>
                                            <Button
                                                className={orderTab === 'unAssign' ? 'activeTab tab' : 'tab'}
                                                onClick={() => setOrderTab('unAssign')}
                                            >
                                                Un-assigned
                                            </Button>
                                            <Button
                                                className={orderTab === 'assign' ? 'activeTab tab' : 'tab'}
                                                onClick={() => setOrderTab('assign')}
                                            >
                                                Assigned
                                            </Button>
                                        </div>
                                        <div className='table-content '>
                                            {/* {loader ? <ContentLoader /> : */}
                                            {
                                                orderTab === 'unAssign'
                                                    ?
                                                    <div className='contentOne'>
                                                        <TableContainer
                                                            divClass="table-responsive mb-1 unAssigneeTable"
                                                            tableClass="align-middle table-nowrap"
                                                            theadClass="table-light text-muted"
                                                            columns={columns1}
                                                            data={allUnAssignedRFIDList}
                                                            customPageSize={customPageSize}
                                                            isGlobalFilter={true}
                                                            isAddAssign={true}
                                                            RfidForm={true}
                                                            isImport={isImport}
                                                            isDelete={isDeleteU}
                                                            allSelcted={isCheakAllU === true ? true : false}
                                                            maxHeight={dimensions.height - headerHeight - footerHeight - tableTaskBar - paginationDiv - cardHeader - tabsButton - 105}
                                                            AssignButtonTitle={'Assign '}
                                                            importBtnTitle={'Import'}
                                                            deleteBtnTitle={'Delete'}
                                                            searchPlaceholder='Search RFID Number'
                                                            handleAssignClicks={handleAssignClicks}
                                                            handleImportClicks={handleImportClicks}
                                                            handleDeleteClicks={handleDeleteClicks}
                                                            checkValue={selectedRowsU.length}
                                                            noData={false}
                                                            noDataImage={"https://res.cloudinary.com/djyl1goby/image/upload/v1671678720/Lubi/WebApp/Static-images/no_rfid.svg"}
                                                            noDataTitle={'No RFID Found!'}
                                                            handleViewDetailClick={handleViewDetailClick}
                                                            handleSelectedRowsU={handleSelectedRowsU}
                                                        />
                                                    </div>
                                                    :
                                                    <div className='contentTwo'>
                                                        <TableContainer
                                                            divClass="table-responsive mb-1 assigneeTable"
                                                            tableClass="align-middle table-nowrap"
                                                            theadClass="table-light text-muted"
                                                            columns={columns2}
                                                            data={allAssignedRFIDList}
                                                            customPageSize={customPageSize}
                                                            isGlobalFilter={true}
                                                            searchPlaceholder='Search RFID Number'
                                                            allSelcted={isCheakAllA === true ? true : false}
                                                            maxHeight={dimensions.height - headerHeight - footerHeight - tableTaskBar - paginationDiv - cardHeader - tabsButton - 105}
                                                            checkValue={selectedRowsA.length}
                                                            isUassign={isUassign}
                                                            isDelete={isDeleteA}
                                                            handleUassignClicks={handleUassignClicks}
                                                            handleDeleteClicks={handleDeleteClicksA}
                                                            isDropdown={true}
                                                            unassignBtnTitle={'Unassign'}
                                                            deleteBtnTitle={'Delete'}
                                                            dropDownItems={[
                                                                { id: 'All', value: 'All' },
                                                                ...business?.allBusinessList.map((item => { return { id: item.businessId.toString(), value: decryptResponseData(item.businessName) } })) || []
                                                            ]}
                                                            selectedValue={selectedFilterValue}
                                                            dropdownTitle='Business'
                                                            onSelect={(evt) => { setSelectedFilterValue(evt); dispatch(getAllAssignedRFID(evt.id)) }}
                                                            noData={false}
                                                            noDataImage={"https://res.cloudinary.com/djyl1goby/image/upload/v1671678720/Lubi/WebApp/Static-images/no_rfid.svg"}
                                                            noDataTitle={'No RFID Found!'}
                                                            handleViewDetailClick={handleViewDetailClick}
                                                            handleSelectedRowsA={handleSelectedRowsA}
                                                        />
                                                    </div>
                                            }
                                            {/* } */}
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Modal className='assignModal' isOpen={basicModal} toggle={() => {
                            setBasicModal(false);
                        }}
                            centered={true}>
                            <ModalHeader>
                                <p>Assign</p>
                                <i className='bx bx-x' onClick={() => {
                                    setBasicModal(false);
                                }} />
                            </ModalHeader>
                            <ModalBody className="">
                                <div className='assignMain'>
                                    <div className='subMian subMainEdit mb-3'>
                                        <Label htmlFor="choices-single-default" className="form-label">RFID</Label>
                                        {
                                            isCheakAllU === true ?
                                                <Multiselect
                                                    id=''
                                                    isObject={false}
                                                    className='customMultiselect customSelect'
                                                    placeholder={'Search and Select RFID number'}
                                                    hidePlaceholder
                                                    closeIcon='cancel'
                                                    avoidHighlightFirstOption
                                                    options={flatArray}
                                                    selectedValues={selectedValues}
                                                    onRemove={(e) => {
                                                        console.log("remove", e)
                                                        setSelectedValues(e)
                                                    }}
                                                    onSelect={(e) => {
                                                        console.log("data", e)
                                                        setSelectedValues(e)
                                                    }}
                                                />
                                                : <SearchDropDown
                                                    options={allUnAssignedRFIDList}
                                                    placeholder='Search and Select RFID number'
                                                    label="rfidNumber"
                                                    id="id"
                                                    selectedVal={selectedValues[0]}
                                                    handleChange={(val) => { console.log(val); setSelectedValues([val.rfidNumber]) }}
                                                />
                                        }

                                    </div>
                                    <div className='subMian mb-4'>
                                        <Label htmlFor="choices-single-default" className="form-label">Assign to Business</Label>
                                        <SearchDropDown
                                            options={business?.allBusinessList}
                                            placeholder='Select business'
                                            label="businessName"
                                            id="id"
                                            selectedVal={selectBusiness?.businessName}
                                            handleChange={(val) => setSelectBusiness(val)}
                                        />
                                    </div>
                                    <div className='bottom d-flex justify-content-end mt-1 mb-3'>
                                        <Button onClick={() => { handleAssignContact() }}>
                                            Assign
                                        </Button>
                                        <Button onClick={() => { setBasicModal(false); }}>
                                            Cancel
                                        </Button>
                                    </div>
                                </div>
                            </ModalBody>
                        </Modal>
                        <Modal className='importRfidNumberModel' isOpen={importRfidNumberMoal} toggle={() => {
                            setImportRfidNumberMoal(false);
                        }}
                            centered={true}>
                            <ModalHeader>
                                <p>Import RFID Numbers</p>
                                <i className='bx bx-x' onClick={() => {
                                    setImportRfidNumberMoal(false);
                                }} />
                            </ModalHeader>
                            <ModalBody className="">
                                <div className='assignMain'>
                                    <div className='subMian mb-3'>
                                        <div className='d-flex justify-content-between'>
                                            <Label htmlFor="formFile"
                                                className="form-label">Upload CSV</Label>
                                            <Label htmlFor="formFile" className="form-label">
                                                Sample file <Link onClick={downloadSample} to={"#"}>Download</Link>
                                            </Label>
                                        </div>
                                        <Input
                                            className="form-control"
                                            type="file"
                                            id="formFile"
                                            name="file"
                                            accept=".csv"
                                            onChange={handleFileUpload}
                                        />
                                    </div>
                                    <div className='bottom d-flex justify-content-end mt-1 mb-3'>
                                        <Button className='custombtn btn-loading' onClick={handleCsvImported}>
                                            Assign
                                        </Button>
                                        <Button onClick={() => { setImportRfidNumberMoal(false); }}>
                                            Cancel
                                        </Button>
                                    </div>
                                </div>
                            </ModalBody>
                        </Modal>
                    </div>
                </Container>
            </div>
            <DeleteModal
                show={deleteModalU}
                title='Are you sure?'
                description='Are you sure you want to delete this record Make this going on unAssign List'
                onDeleteClick={handleDeleteContactU}
                onCloseClick={() => setDeleteModalU(false)}
            />
            <DeleteModal
                show={deleteModalA}
                title='Are you sure?'
                description='Are you sure you want to delete this record Make this going on unAssign List'
                onDeleteClick={handleDeleteContactA}
                onCloseClick={() => setDeleteModalA(false)}
            />
        </React.Fragment>
    );
};

export default RFID;