export const LOCATION_LISTING = 'LOCATION_LISTING'
export const LOCATION_LISTING_SUCCESS = 'LOCATION_LISTING_SUCCESS'
export const LOCATION_DETAIL = 'LOCATION_DETAIL'
export const LOCATION_DETAIL_SUCCESS = 'LOCATION_DETAIL_SUCCESS'
export const ADD_NEW_LOCATION = 'ADD_NEW_LOCATION'
export const EDIT_LOCATION = 'EDIT_LOCATION'
export const LOCATION_INVITE_RESEND = 'LOCATION_INVITE_RESEND'
export const LOCATION_MAN_BUSINESS = 'LOCATION_MAN_BUSINESS'
export const LOCATION_MAN_BUSINESS_SUCCESS = 'LOCATION_MAN_BUSINESS_SUCCESS'
export const DELETE_LOCATION = "DELETE_LOCATION";
export const ACTIVATE_DEACTIVATE_LOCATION = "ACTIVATE_DEACTIVATE_LOCATION";
export const ALLSTATION_BUSINESS_LISTING = "ALLSTATION_BUSINESS_LISTING";
export const ALLSTATION_BUSINESS_LISTING_SUCCESS = "ALLSTATION_BUSINESS_LISTING_SUCCESS";


export const ALLCONNECTOR_STATION_LISTING = "ALLCONNECTOR_STATION_LISTING";
export const ALLCONNECTOR_STATION_LISTING_SUCCESS = "ALLCONNECTOR_STATION_LISTING_SUCCESS";


export const LOCATION_FILTER_LISTING = "LOCATION_FILTER_LISTING";
export const LOCATION_FILTER_LISTING_SUCCESS = "LOCATION_FILTER_LISTING_SUCCESS";