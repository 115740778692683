import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import socket from "../../../socketConfig";

//import Components
import DeleteModal from "../../../Components/Common/DeleteModal";
import { showToast } from "../../../store/actions";
import TableContainer from "../../../Components/Common/TableContainer";
import useWindowDimension from "../../../Components/Hooks/useWindowDimension";
import {
  businessOwnerRFIDList,
  businessOwnerRFIDAssignList,
  businessOwnerRFIDAssign,
  getOldRFID
} from "../../../store/rfid/actions";

import { Checkbox } from "../../../Components/Common/Checkbox";
import ContentLoader from "../../../Components/Common/ContentLoader";

const RFIDManagement = () => {
  document.title = "RFID Management | Vidhyutam";
  const dimensions = useWindowDimension();
  const dispatch = useDispatch();
  let {
    businessOwnerRFIDListR,
    BusinessOwnerRfidAssignListR,
    dataRFIDListR
  } = useSelector((state) => state.RFIDList);
  BusinessOwnerRfidAssignListR = BusinessOwnerRfidAssignListR
    ? BusinessOwnerRfidAssignListR
    : [];
  let { loader } = useSelector((state) => state.Toast);

  const [deleteModalU, setDeleteModalU] = useState(false);
  const [basicModal, setBasicModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [isFilterRfifOpen, setIsFilterRfifOpen] = useState(false);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [footerHeight, setFooterHeight] = useState(0);
  const [tableTaskBar, setTableTaskBar] = useState(0);
  const [paginationDiv, setPaginationDiv] = useState(0);
  const [cardHeader, setCardHeaderHeight] = useState(0);
  const [filteredTable, setfilteredTable] = useState([]);
  const [rfidNumber, setRFIDNumber] = useState();
  const [selectedFilter, setSelectedFilter] = useState("All");
  const [selectedRFIDList, setSelectedRFIDList] = useState([]);
  const [selectedRFIDListRFID, setSelectedRFIDListRFID] = useState([]);
  const [locationStationDetailsList, setLocationStationDetailsList] = useState([])

  useEffect(() => {
    dispatch(businessOwnerRFIDList(`?filter=${selectedFilter}`));
    dispatch(businessOwnerRFIDAssignList());
  }, [selectedFilter]);
  useEffect(() => {
    if (dataRFIDListR) {
      let mergeData = dataRFIDListR.map(item => item.RFID).concat(selectedRFIDListRFID);
      let array = locationStationDetailsList.map(item => {
        return {
          cpId: item.stationName,
          listVersion: 1,
          idTag: mergeData.map(id => ({ "idTag": id, "idTagInfo": { "status": "Accepted" } }))
        };
      });
      // // let array = locationStationDetailsList.flatMap(itemB => selectedRFIDListRFID.map(itemA => ({ name: itemB.stationName, id: itemA })));
      array?.map((element) => {
        let obj1 = {
          cpId: element.cpId,
          idTag: element.idTag,
        }
        socket.emit('checkRFIDVersion', JSON.stringify(array));
      });

      dispatch(
        businessOwnerRFIDAssign({
          rfidList: selectedRFIDList,
          locationStationDetails: locationStationDetailsList,
        })
      );
      setBasicModal(false);


    }





  }, [dataRFIDListR]);

  useEffect(() => {
    setHeaderHeight(
      document.getElementsByClassName("navbar-header")[0]?.offsetHeight
    );
    setFooterHeight(document.getElementsByClassName("footer")[0]?.offsetHeight);
    setTableTaskBar(
      document.getElementsByClassName("tableTaskBar")[0]?.offsetHeight
    );
    setPaginationDiv(
      document.getElementsByClassName("paginationDiv")[0]?.offsetHeight
    );
    setCardHeaderHeight(
      document.getElementsByClassName("cardHeader")[0]?.offsetHeight
    );
  }, []);

  const handleResize = () => {
    setHeaderHeight(
      document.getElementsByClassName("navbar-header")[0]?.offsetHeight
    );
    setFooterHeight(document.getElementsByClassName("footer")[0]?.offsetHeight);
    setTableTaskBar(
      document.getElementsByClassName("tableTaskBar")[0]?.offsetHeight
    );
    setPaginationDiv(
      document.getElementsByClassName("paginationDiv")[0]?.offsetHeight
    );
    setCardHeaderHeight(
      document.getElementsByClassName("cardHeader")[0]?.offsetHeight
    );
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
  }, []);

  // Handles the selected rows
  const handleSelectedRFIDRows = (selectedFlatRows) => {
    setSelectedRFIDList([
      ...new Set(
        selectedFlatRows?.map((element) => element?.original?.rfidNumber)
      ),
    ]);
    setSelectedRFIDListRFID([
      ...new Set(
        selectedFlatRows?.map((element) => element?.original?.RFID)
      ),
    ]);

  };

  const handleSelectedLocationRows = (selectedFlatRows) => {
    setLocationStationDetailsList(
      selectedFlatRows?.map((element) => {
        return {
          stationId: element?.original?.stationId,
          locationId: element?.original?.locationId,
          businessId: element?.original?.businessId,
          stationName: element?.original?.stationName,
        };
      })
    );
  };

  const handleDeleteContactU = () => {
    dispatch(
      showToast({
        message: "record has been deleted successfully.",
        type: "success",
      })
    );
    setDeleteModalU(false);
  };

  const handleAssignContact = () => {
    // let obj = {
    //   "businessId": 314,
    //   "locationId": 278,
    //   "stationId": 486
    // }
    // dispatch(getOldRFID(obj));
    locationStationDetailsList.map(item => {
      let obj = {
        "businessId": item.businessId,
        "locationId": item.locationId,
        "stationId": item.stationId
      }
      dispatch(getOldRFID(obj));

    });

  };


  const rfidFilterList = [
    { id: "1", value: "All" },
    { id: "2", value: "Assigned" },
    { id: "3", value: "Not Assigned" },
  ];

  const columns1 = useMemo(
    () => [
      {
        Header: ({ getToggleAllPageRowsSelectedProps }) => (
          <div className="d-flex align-items-center">
            <Checkbox
              className="form-check-input setSuccess"
              {...getToggleAllPageRowsSelectedProps()}
            />
            <span>RFID Number</span>
          </div>
        ),

        accessor: "RFID",
        filterable: true,
        disableSortBy: true,
        Cell: (cell) => {
          return (
            <div className="customDiv d-flex align-items-center">
              <Checkbox
                className="orderCheckBox form-check-input setSuccess"
                // disabled={cell.row.original.assignmentStatus === "Assigned"}
                {...cell.row.getToggleRowSelectedProps()}
              />
              <p className="">{cell.row.original.RFID}</p>
            </div>
          );
        },
      },

      {
        Header: "Status",
        accessor: "assignmentStatus",
        disableSortBy: true,
        filterable: true,

        Cell: (cell) => {
          return cell.value === "Not-Assigned" ? (
            <p className="statusTxt">{cell.value}</p>
          ) : (
            <p
              className="viewBtn"
              id={cell.row.original.rfidNumber}
              onClick={() => {
                setRFIDNumber(cell.row.original.rfidNumber);
                setViewModal(true);
              }}
            >
              View
            </p>
          );
        },
      },
    ],
    []
  );

  const columns2 = useMemo(
    () => [
      {
        // id: "selection",
        Header: ({ getToggleAllPageRowsSelectedProps }) => (
          <div className="d-flex align-items-center">
            <Checkbox
              className="form-check-input setSuccess"
              {...getToggleAllPageRowsSelectedProps()}
            />
            <span>Station Name</span>
          </div>
        ),
        accessor: "stationName",
        // filterable: false,
        disableSortBy: true,
        Cell: (cell) => {
          return (
            <div className="customDiv d-flex align-items-center">
              <Checkbox
                className="orderCheckBox form-check-input setSuccess"
                {...cell.row.getToggleRowSelectedProps()}
              />
              <p className="text">{cell.value}</p>
            </div>
          );
        },
      },
      {
        Header: "Location",
        accessor: "locationName",
        filterable: false,

        Cell: (cell) => {
          return <p className="text textOverflow">{cell.value}</p>;
        },
      },
      {
        Header: "Business",
        accessor: "businessName",
        filterable: false,

        Cell: (cell) => {
          return <p className="text textOverflow">{cell.value}</p>;
        },
      },
    ],
    [basicModal]
  );

  const columns3 = useMemo(
    () => [
      {
        Header: "StationName",
        accessor: "stationName",
        filterable: false,

        Cell: (cell) => {
          return <p className="text textOverflow">{cell.value}</p>;
        },
      },
      {
        Header: "Location",
        accessor: "locationName",
        filterable: false,

        Cell: (cell) => {
          return <p className="text textOverflow">{cell.value}</p>;
        },
      },
    ],
    [viewModal]
  );

  const handleAssignClicks = () => {
    setBasicModal(true);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="rfidManagementPage">
            <Row>
              <Col lg={12}>
                <Card className="mb-0">
                  <CardHeader className="pb-0 cardHeader">
                    <Row>
                      <Col lg={12} className="mb-2">
                        <div className="rightPart">
                          <Row>
                            <Col lg={12} className="lside">
                              <div>
                                <Row>
                                  <Col lg={4} xl={3}>
                                    <div className="box d-flex align-items-start justify-content-start">
                                      <div className="header d-flex align-items-center justify-content-center mb-0">
                                        <i className="ri-money-dollar-circle-fill" />
                                        <div className="content  d-flex align-items-start justify-content-center">
                                          <span className="txt">
                                            Total RFID:
                                          </span>
                                          <span className="value">
                                            {businessOwnerRFIDListR
                                              ? businessOwnerRFIDListR
                                                ?.RFIDCount?.totalRFIDCount
                                              : 0}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </Col>
                                  <Col lg={4} xl={3}>
                                    <div className="box d-flex align-items-start justify-content-start">
                                      <div className="header d-flex align-items-center justify-content-center mb-0">
                                        <i className="ri-file-copy-2-fill" />
                                        <div className="content  d-flex align-items-start justify-content-center">
                                          <span className="txt">
                                            Assigned RFID:
                                          </span>
                                          <span className="value">
                                            {" "}
                                            {businessOwnerRFIDListR
                                              ? businessOwnerRFIDListR
                                                ?.RFIDCount?.assignedRFIDCount
                                              : 0}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </Col>
                                  <Col lg={4} xl={3}>
                                    <div className="box d-flex align-items-start justify-content-start">
                                      <div className="header d-flex align-items-center justify-content-center mb-0">
                                        <i className="ri-stack-fill" />
                                        <div className="content  d-flex align-items-start justify-content-center">
                                          <span className="txt">
                                            unassigned RFID:
                                          </span>
                                          <span className="value">
                                            {" "}
                                            {businessOwnerRFIDListR
                                              ? businessOwnerRFIDListR
                                                ?.RFIDCount
                                                ?.unAssignedRFIDCount
                                              : 0}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </CardHeader>
                  {loader ? (
                    <ContentLoader />
                  ) : (
                    <CardBody>
                      <div
                        className={
                          filteredTable.length > 0 &&
                            businessOwnerRFIDListR?.RFIDList.length > 0
                            ? "table-content"
                            : "table-content disablePointer"
                        }
                      >
                        <div className="contentOne">
                          <TableContainer
                            setfilteredTable={setfilteredTable}
                            divClass="table-responsive mb-1 unAssigneeTable"
                            tableClass="align-middle table-nowrap"
                            theadClass="table-light text-muted"
                            columns={columns1}
                            data={businessOwnerRFIDListR?.RFIDList || []}
                            customPageSize={16}
                            isGlobalFilter={true}
                            isAddAssignDisabled={
                              (businessOwnerRFIDListR?.RFIDList?.some(rfid => (selectedRFIDList?.includes(rfid.rfidNumber)) && rfid.assignmentStatus === "Assigned")) || selectedRFIDList.length === 0
                                ? true : false}
                            isAddAssign={true}
                            AssignButtonTitle={"Assign"}
                            searchPlaceholder="Search RFID Number"
                            handleAssignClicks={handleAssignClicks}
                            noData={false}
                            noDataImage={
                              "https://res.cloudinary.com/djyl1goby/image/upload/v1671678720/Lubi/WebApp/Static-images/no_rfid.svg"
                            }
                            noDataTitle={"No RFID Found!"}
                            maxHeight={
                              dimensions.height -
                              headerHeight -
                              footerHeight -
                              tableTaskBar -
                              paginationDiv -
                              cardHeader -
                              80
                            }
                            customFilterRfid={true}
                            onClickCustomFilterRfid={() => {
                              setIsFilterRfifOpen(true);
                            }}
                            onClickRfidFilterItem={() => {
                              setIsFilterRfifOpen(false);
                            }}
                            isApplyedR={isFilterApplied}
                            onSelectRfidItem={(e) => {
                              setSelectedFilter(
                                e.value === "Not Assigned"
                                  ? "Not-Assigned"
                                  : e.value
                              );
                              setIsFilterApplied(e.value !== 'All' ? true : false);
                            }}
                            isFilterRfifOpen={isFilterRfifOpen}
                            rfidFilterList={rfidFilterList}
                            handleSelectedRFIDRows={handleSelectedRFIDRows}
                            handleViewDetailClick={() => { }}
                          />
                        </div>
                      </div>
                    </CardBody>
                  )}
                </Card>
              </Col>
            </Row>
            <Modal
              className="rfidAssifnednumberModal"
              id="1"
              isOpen={basicModal}
              toggle={() => {
                setBasicModal(false);
              }}
              centered={true}
            >
              <ModalHeader>
                <p>Assign</p>
                <i
                  className="bx bx-x"
                  onClick={() => {
                    setBasicModal(false);
                  }}
                />
              </ModalHeader>
              <ModalBody className="">
                <div className="assignMain">
                  <div className="subMian mb-3">
                    <Label
                      htmlFor="choices-single-default"
                      className="form-label"
                    >
                      {locationStationDetailsList?.length} RFID Selected
                    </Label>

                    <TableContainer
                      divClass="table-responsive mb-1 unAssigneeTable"
                      tableClass="align-middle table-nowrap"
                      theadClass="table-light text-muted"
                      columns={columns2}
                      data={BusinessOwnerRfidAssignListR}
                      customPageSize={1000}
                      isGlobalFilter={false}
                      pagination={false}
                      maxHeight={"350px"}
                      handleSelectedLocationRows={handleSelectedLocationRows}
                      handleViewDetailClick={() => { }}
                    />
                  </div>
                  <div className="bottom d-flex justify-content-end mt-1 mb-3">
                    <Button onClick={handleAssignContact}>Assign</Button>
                    <Button
                      onClick={() => {
                        setBasicModal(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </ModalBody>
            </Modal>
            <Modal
              className="viewModal"
              isOpen={viewModal}
              toggle={() => {
                setViewModal(false);
              }}
              centered={true}
            >
              <ModalHeader>
                <p>Assigned RFID To</p>
                <i
                  className="bx bx-x"
                  onClick={() => {
                    setViewModal(false);
                  }}
                />
              </ModalHeader>
              <ModalBody className="">
                <div className="assignMain">
                  <div className="subMian subMainEdit mb-3">
                    <TableContainer
                      divClass="table-responsive mb-1 unAssigneeTable"
                      tableClass="align-middle table-nowrap"
                      theadClass="table-light text-muted"
                      columns={columns3}
                      data={
                        rfidNumber
                          ? businessOwnerRFIDListR?.RFIDList?.find(
                            (rfid) => rfid.rfidNumber === rfidNumber
                          )?.details
                          : []
                      }
                      customPageSize={1000}
                      pagination={false}
                      maxHeight={"350px"}
                      isGlobalFilter={false}
                      handleViewDetailClick={() => { }}
                    />
                  </div>
                </div>
              </ModalBody>
            </Modal>
          </div>
        </Container>
      </div>
      <DeleteModal
        show={deleteModalU}
        title="Are you sure?"
        description="Are you sure you want to delete this record"
        onDeleteClick={handleDeleteContactU}
        onCloseClick={() => setDeleteModalU(false)}
      />
    </React.Fragment>
  );
};

export default RFIDManagement;
