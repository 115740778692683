import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button, Card, CardBody, Col, Container, Row, Form, Input, Label, FormFeedback, UncontrolledAlert } from 'reactstrap';
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import { setPassword } from '../../store/actions';

//formik
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Constant from '../../utils/constant';
import { showToast } from '../../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import AuthLogo from '../../Components/Common/AuthLogo';
import CustomButton from '../../Components/Common/CustomButton';

const SetPasswordPage = (props) => {

  document.title = "Set Password | Vidhyutam";
  const dispatch = useDispatch();
  const history = useHistory();
  const urlParams = new URLSearchParams(window.location.search);
  const linkToken = urlParams.get('token');
  const [passwordShow, setPasswordShow] = useState(false);
  const [confrimPasswordShow, setConfrimPasswordShow] = useState(false);
  

  const { loading } = useSelector(state => ({
    loading: state.Toast.loader
  }));

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object().shape({
      password: Yup.string()
        .required("Please enter your password")
        .min(8, 'Password must be in 8 to 16 characters')
        .max(16, 'Password must be in 8 to 16 characters')
        .matches(Constant.REGEX.UPPERCASE, 'At least 1 Capital letter')
        .matches(Constant.REGEX.LOWERCASE, 'At least 1 small letter')
        .matches(Constant.REGEX.NUMBER, 'At least 1 number')
        .matches(Constant.REGEX.SPECIALCHARACTERS, '1 Special character'),
      confirmPassword: Yup.string()
        .when("password", {
          is: (val) => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref("password")],
            "Both passwords must match"
          ),
        })
        .required("Please enter your password"),
    }),
    onSubmit: (values) => {
      
      // onSuccess();
      dispatch(setPassword(values, props.history, linkToken))
    }
  });

  const onSuccess = (values) => {
    // Success Toast
    dispatch(showToast({
      message: 'Password set successfully.',
      type: 'success'
    }));
    setTimeout(() => {
      history.push('/login')
    }, 3000);
  }
  
  return (
    <ParticlesAuth>
      <div className="auth-page-content set-password">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="mt-4">

                <CardBody className="p-4">
                  <Row>
                    <Col lg={12}>
                      <div className="text-center mt-sm-2 mb-4 text-white-50">
                        <AuthLogo />
                      </div>
                    </Col>
                  </Row>
                  <div className="text-center mt-2">
                    <h5 className="text-success">Create new password</h5>
                    <p className="text-muted">Please provide below mentioned details to reset password.</p>
                  </div>

                  <div className="p-2">
                    <Form onSubmit={validation.handleSubmit} action="/login">
                      <div className="mb-3">
                        <Label className="form-label requierdLabel" htmlFor="password-input">New Password</Label>
                        <div className="position-relative auth-pass-inputgroup">
                          <Input
                            type={passwordShow ? "text" : "password"}
                            className="form-control pe-5 password-input passwordError"
                            placeholder="Enter new password"
                            id="password-input"
                            name="password"
                            value={validation.values.password}
                            onKeyUp={validation.handleBlur}
                            onChange={validation.handleChange}
                            tabIndex={1}
                            invalid={validation.errors.password && validation.touched.password ? true : false}
                          />
                          <Button color="link" onClick={() => setPasswordShow(!passwordShow)} className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button"
                            id="password-addon">
                            <i className={!passwordShow ? "bx bx-hide align-middle" : "ri-eye-fill align-middle"}></i>
                          </Button>
                          {validation.errors.password && validation.touched.password ? (
                            <>
                              {!validation.values.password && <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>}
                              {!Constant.REGEX.MIN_MAX_PASSWORD.test(validation.values.password) && validation.values.password && <FormFeedback type="invalid">Password must be in 8 to 16 characters</FormFeedback>}
                              {!Constant.REGEX.UPPERCASE.test(validation.values.password) && validation.values.password && <FormFeedback type="invalid">At least 1 Capital letter</FormFeedback>}
                              {!Constant.REGEX.LOWERCASE.test(validation.values.password) && validation.values.password && <FormFeedback type="invalid">At least 1 small letter</FormFeedback>}
                              {!Constant.REGEX.NUMBER.test(validation.values.password) && validation.values.password && <FormFeedback type="invalid">At least 1 number</FormFeedback>}
                              {!Constant.REGEX.SPECIALCHARACTERS.test(validation.values.password) && validation.values.password && <FormFeedback type="invalid">1 Special character</FormFeedback>}
                            </>
                          ) : null}

                        </div>
                        {/* <div id="passwordInput" className="form-text">Must be at least 8 characters.</div> */}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label requierdLabel" htmlFor="confirm-password-input">Confirm Password</Label>
                        <div className="position-relative auth-pass-inputgroup mb-3">
                          <Input
                            type={confrimPasswordShow ? "text" : "password"}
                            className="form-control pe-5 password-input passwordError"
                            placeholder="Enter confirm password"
                            id="confirm-password-input"
                            name="confirmPassword"
                            value={validation.values.confirmPassword}
                            onChange={validation.handleChange}
                            onKeyUp={validation.handleBlur}
                            tabIndex={2}
                            invalid={validation.errors.confirmPassword && validation.touched.confirmPassword ? true : false}
                          />
                          <Button color="link" onClick={() => setConfrimPasswordShow(!confrimPasswordShow)} className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button"
                            id="password-addon">
                            <i className={!confrimPasswordShow ? "bx bx-hide align-middle" : "ri-eye-fill align-middle"}></i>
                          </Button>
                          {validation.errors.confirmPassword && validation.touched.confirmPassword ? (
                            <FormFeedback type="invalid">{validation.errors.confirmPassword}</FormFeedback>
                          ) : null}
                          {/* <Button color="link" onClick={() => setConfrimPasswordShow(!confrimPasswordShow)} className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button">
                            <i className="ri-eye-fill align-middle"></i></Button> */}

                        </div>
                      </div>


                      <div className="mt-4">
                        <CustomButton
                          title='Set Password'
                          disabled={!(validation.dirty && validation.isValid)}
                          type='submit'
                          loading={loading}
                          className='submitBTN'
                        />
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-4 text-center">
                <p className="mb-0">Back to <Link to="/login" className="text-success text-decoration-underline"> Sign-in </Link> </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </ParticlesAuth>
  );
};

export default SetPasswordPage;