import PropTypes from "prop-types";
import React, { useState, useEffect, useLayoutEffect } from "react";
import { Row, Col, Alert, Card, CardBody, Container, FormFeedback, Input, Label, Form, Button, UncontrolledAlert } from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link, useHistory } from "react-router-dom";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// action
import { showToast, userForgetPassword } from "../../store/actions";

// import images
// import profile from "../../assets/images/bg.png";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";

import { loadAnimation } from "lottie-web";
import { defineLordIconElement } from "lord-icon-element";
import Constant from "../../utils/constant";

// import { userForgetPassword } from "../../store/actions";

import AuthLogo from "../../Components/Common/AuthLogo";
import CustomButton from "../../Components/Common/CustomButton";
// register lottie and define custom element
defineLordIconElement(loadAnimation);

const ForgetPasswordPage = props => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { loading } = useSelector(state => ({
    loading: state.Toast.loader
  }));

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required('Please enter your email address').matches(Constant.REGEX.EMAIL, 'Please provide a valid email address'),
    }),
    onSubmit: (values) => {
      dispatch(userForgetPassword(values, props.history))
      window.localStorage.setItem('current', 'verify')
    }
  });

  // send user back to otp page if already got otp
  useEffect(() => {
    if (window.localStorage.getItem('current') !== 'forget') {
      history.push('/login');
    }
  }, [])

  useLayoutEffect(() => {
    if (window.sessionStorage.getItem('users') !== null) {
      history.push('/dashboard');
    }
  }, [])

  // const { forgetError, forgetSuccessMsg } = useSelector(state => ({
  //   forgetError: state.ForgetPassword.forgetError,
  //   forgetSuccessMsg: state.ForgetPassword.forgetSuccessMsg,
  // }));
  // const onSuccess = (values) => {
  //   // Success Toast
  //   dispatch(showToast({
  //     message: 'Verification code sent successfully.',
  //     type: 'success'
  //   }));
  //   setTimeout(() => {
  //     history.push('/verify')
  //   }, 3000);
  // }
  const onFaild = () => {
    // Error Toast
    dispatch(showToast({
      message: 'Email does not associated with system.',
      type: 'error'
    }));
  }

  const { forgetError, forgetSuccessMsg } = useSelector(state => ({
    forgetError: state.ForgetPassword.forgetError,
    // forgetSuccessMsg: state.ForgetPassword.forgetSuccessMsg,
    forgetSuccessMsg: state.ForgetPassword.forgetSuccessdata,
  }));
  

  document.title = "Forgot Password | Vidhyutam";

  return (
    <ParticlesAuth>
      <div className="auth-page-content">
        <Container>

          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="mt-4">

                <CardBody className="p-4">
                  <Row>
                    <Col lg={12}>
                      <div className="text-center mt-sm-2 mb-4 text-white-50">
                        <AuthLogo />
                      </div>
                    </Col>
                  </Row>
                  <div className="text-center mt-2">
                    <h5 className="text-success">Forgot Password?</h5>
                    <p className="text-muted mb-4">Enter an email associated with your account and we’ll send an email with verification code to reset your password.</p>

                    <lord-icon
                      src="https://cdn.lordicon.com/rhvddzym.json"
                      trigger="loop"
                      colors="primary:#00bd9d"
                      className="avatar-xl"
                      style={{ width: "120px", height: "120px" }}
                    >
                    </lord-icon>

                  </div>

                  <div className="p-2">
                    {/* {forgetError && forgetError ? (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {forgetError}
                      </Alert>
                    ) : null}
                    {forgetSuccessMsg ? (
                      <Alert color="success" style={{ marginTop: "13px" }}>
                        {forgetSuccessMsg}
                      </Alert>
                    ) : null} */}
                    <Form
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <div className="mb-4">
                        <Label className="form-label requierdLabel">Email Address</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter email address"
                          type="text"
                          // onChange={validation.handleChange}
                          onChange={(e) => validation.handleChange('email')(e.target.value.trim())}
                          onKeyUp={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={validation.errors.email && validation.touched.email ? true : false}
                        />
                        {validation.errors.email && validation.touched.email ? (
                          <FormFeedback type="invalid"><div>{validation.errors.email}</div></FormFeedback>
                        ) : null}
                      </div>

                      <div className="text-center mt-4">
                        <CustomButton
                          title='Send verification code'
                          disabled={!(validation.dirty && validation.isValid)}
                          type='submit'
                          loading={loading}
                          className='submitBTN'
                        />
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>

              <div className="mt-4 text-center">
                <p className="mb-0" onClick={() => { window.localStorage.removeItem('current') }}>Back to <Link to="/login" className="text-success text-decoration-underline"> Sign-in </Link> </p>
              </div>

            </Col>
          </Row>
        </Container>
      </div>
    </ParticlesAuth>
  );
};

ForgetPasswordPage.propTypes = {
  history: PropTypes.object,
};

export default withRouter(ForgetPasswordPage);
