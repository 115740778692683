import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import { LIST_ALL_STATION, VIEW_DETAIL_STATION, ADD_STATION, GET_STATION_NAME, CHECK_STATION, EDIT_STATIONS, GET_STATION_CONNECTOR, ACTIVATE_DEACTIVATE_STATION, DELETE_STATION, DELETE_RESERVATION, LIST_ALL_CONNECTOR, UPDATE_RESERVATION, GET_RESERVATION } from "./actionTypes";

import { listAllStationSuccess, getListAllConnectoruccess, updateReservationSuccess, listReservationSuccess, deleteReservationSucess, detailViewStationSuccess, getStationNameSuccess, getStatConnectorSuccess, activateDeactivateStationAction } from "../actions"

import { ListallStations, ListallConnector, StationsDetail, AddStation, getStationName, checkStationName, editStation, getStationConnectors, activateDeactivateStation, deleteStation, delReservation, updateReservation, listReservation } from "../../helpers/Auth";
import { showToast, showLoader } from "../actions";





function* lsitAllStationList({ payload: { id } }) {
    try {
        const response = yield call(ListallStations, id)
        if (response) {
            yield put(listAllStationSuccess(response.payload));
        }
    } catch (e) {
        console.log(e);
        // yield put(showToast({
        //     message: e?.response?.data?.message,// e.message, 'Logged failed.'
        //     type: 'error'
        // }));
    }
}

function* DetailViewStation({ payload: { stationId } }) {
    try {
        const response = yield call(StationsDetail, stationId)
        if (response) {
            yield put(detailViewStationSuccess(response.payload));
        }
    } catch (error) {
        console.log(error);
        // yield put(showToast({
        //     message: error.message,
        //     type: 'error'
        // }));
    }
}

function* addStation({ payload: { data, history } }) {
    yield put(showLoader(true));
    try {
        const response = yield call(AddStation, data)
        if (response) {
            console.log('Res', response);
            yield put(showToast({
                message: response.message,
                type: 'success'
            }));
            setTimeout(() => {
                history.goBack();
            }, 1000);
            yield put(showLoader(false));
        }
    } catch (e) {
        yield put(showToast({
            message: e?.response?.data?.message,  //'Account verified successfully.',
            type: 'error'
        }))
        yield put(showLoader(false));
    }
}

function* stationName({ payload: { locationId } }) {
    try {
        const response = yield call(getStationName, locationId)
        if (response) {
            yield put(getStationNameSuccess(response.payload));
        }
    } catch (error) {
        // console.log(error);
        yield put(showToast({
            message: error.message,
            type: 'error'
        }));
    }
}

function* checkStation({ payload: { Id, data } }) {
    yield put(showLoader(true));
    try {
        const response = yield call(checkStationName, Id, data)
        if (response) {
            yield put(showToast({
                message: response.message,
                type: 'success'
            }));
            yield put(showLoader(false));
        }
    } catch (e) {
        yield put(showToast({
            message: e?.response?.data?.message,  //'Account verified successfully.',
            type: 'error'
        }))
        yield put(showLoader(true));
    }
}

function* editStations({ payload: { data, evseID, history } }) {
    yield put(showLoader(true));
    try {
        const response = yield call(editStation, data, evseID)
        if (response) {
            yield put(showToast({
                message: response.message,
                type: 'success'
            }));
            setTimeout(() => {
                history.goBack();
            }, 1000);
            yield put(showLoader(false));
        }
    } catch (e) {
        yield put(showToast({
            message: e?.response?.data?.message,  //'Account verified successfully.',
            type: 'error'
        }))
        yield put(showLoader(false));
    }
}

function* getStationConn({ payload: { stationId } }) {
    try {
        const response = yield call(getStationConnectors, stationId)
        if (response) {
            yield put(getStatConnectorSuccess(response.payload));
        }
    } catch (error) {
        console.log(error);
        // yield put(showToast({
        //     message: error.message,
        //     type: 'error'
        // }));
    }
}


function* activateDeactivateStationFunction(payload) {
    try {

        const response = yield call(activateDeactivateStation, payload);

        if (response) {
            yield put(showToast({
                message: response?.message,
                type: "success",
            }));
        }
    } catch (error) {

        yield put(showToast({
            message: error?.response?.data?.message,
            type: 'error'
        }));
    }
}

function* deleteStationFunction(payload) {
    try {
        const response = yield call(deleteStation, payload);

        if (response.status === 200) {

            yield put(
                showToast({
                    message: response?.message,
                    type: "success",
                })
            );
        }
    } catch (error) {
        yield put(
            showToast({
                message: error?.response?.data?.message,
                type: "error",
            })
        );
    }
}



function* getreservationFunc({ payload: { data } }) {
    try {
        const response = yield call(listReservation, data)
        if (response) {
            yield put(listReservationSuccess(response.payload));
        }
    } catch (e) {
        console.log(e);
        // yield put(showToast({
        //     message: e?.response?.data?.message,// e.message, 'Logged failed.'
        //     type: 'error'
        // }));
    }
}



function* deleteReservationFunc({ payload: { data } }) {
    try {
        const response = yield call(delReservation, data)
        if (response) {
            yield put(deleteReservationSucess(response.payload));
        }
    } catch (e) {
        console.log(e);
        // yield put(showToast({
        //     message: e?.response?.data?.message,// e.message, 'Logged failed.'
        //     type: 'error'
        // }));
    }
}




function* getAllConnectorFunc({ payload: { id } }) {
    try {
        const response = yield call(ListallConnector)
        if (response) {
            yield put(getListAllConnectoruccess(response.payload));
        }
    } catch (e) {
        console.log(e);
        // yield put(showToast({
        //     message: e?.response?.data?.message,// e.message, 'Logged failed.'
        //     type: 'error'
        // }));
    }
}




function* setUpdateReservationFunc({ payload: { data } }) {
    try {
        const response = yield call(updateReservation, data)
        if (response) {
            yield put(updateReservationSuccess(response.payload));
        }
    } catch (e) {
        console.log(e);
        // yield put(showToast({
        //     message: e?.response?.data?.message,// e.message, 'Logged failed.'
        //     type: 'error'
        // }));
    }
}


export function* StationManagement() {
    yield takeEvery(LIST_ALL_STATION, lsitAllStationList);
    yield takeEvery(VIEW_DETAIL_STATION, DetailViewStation);
    yield takeEvery(ADD_STATION, addStation);
    yield takeEvery(GET_STATION_NAME, stationName);
    yield takeEvery(CHECK_STATION, checkStation);
    yield takeEvery(EDIT_STATIONS, editStations);
    yield takeEvery(GET_STATION_CONNECTOR, getStationConn)
    yield takeEvery(ACTIVATE_DEACTIVATE_STATION, activateDeactivateStationFunction)
    yield takeEvery(DELETE_STATION, deleteStationFunction)
    yield takeEvery(GET_RESERVATION, getreservationFunc)
    yield takeEvery(DELETE_RESERVATION, deleteReservationFunc)

    yield takeEvery(UPDATE_RESERVATION, setUpdateReservationFunc)

    yield takeEvery(LIST_ALL_CONNECTOR, getAllConnectorFunc)


}

export default StationManagement;