export const ADD_NEW_RFID = "ADD_NEW_RFID";
export const ADD_RFID_SET_LOADER = "ADD_RFID_SET_LOADER";
export const BULK_IMPORT_RFID = "BULK_IMPORT_RFID";

export const UPDATE_RFID = "UPDATE_RFID";
export const UPDATE_RFID_SET_LOADER = "UPDATE_RFID_SET_LOADER";
export const DELETE_RFID = "DELETE_RFID";

export const ASSIGN_BUSINESS_TO_RFID = "ASSIGN_BUSINESS_TO_RFID"
export const UNASSIGN_BUSINESS_FROM_RFID = "UNASSIGN_BUSINESS_FROM_RFID"
export const ALL_ASSIGNED_RFID = "ALL_ASSIGNED_RFID";
export const RFID_ASSIGNED_LIST_SUCCESS = "RFID_ASSIGNED_LIST_SUCCESS";

export const ALL_UNASSIGNED_RFID = "ALL_UNASSIGNED_RFID";
export const UNASSIGN_RFID_SET_LOADER = "UNASSIGN_RFID_SET_LOADER";
export const RFID_UNASSIGNED_LIST_SUCCESS = "RFID_UNASSIGNED_LIST_SUCCESS";

export const GET_RFID_COUNT = "GET_RFID_COUNT";
export const GET_RFID_COUNT_SUCCESS = "GET_RFID_COUNT_SUCCESS";

export const GET_RFID_ALL_DATA = "GET_RFID_ALL_DATA";
export const GET_RFID_ALL_DATA_SUCCESS = "GET_RFID_ALL_DATA_SUCCESS";
export const BUSINESS_OWNER_RFID_ASSIGN_SUCCESS =
  "BUSINESS_OWNER_RFID_ASSIGN_SUCCESS";
export const BUSINESS_OWNER_RFID_ASSIGN_FAILURE =
  "BUSINESS_OWNER_RFID_ASSIGN_FAILURE";
export const BUSINESS_OWNER_RFID_ASSIGN = "BUSINESS_OWNER_RFID_ASSIGN";
export const OLD_RFID_DATA = "OLD_RFID_DATA";
export const BUSINESS_OWNER_RFID_LIST_SUCCESS =
  "BUSINESS_OWNER_RFID_LIST_SUCCESS";

export const DATA_RFID_LIST_SUCCESS =
  "DATA_RFID_LIST_SUCCESS";
export const BUSINESS_OWNER_RFID_LIST_FAILURE =
  "BUSINESS_OWNER_RFID_LIST_FAILURE";
export const BUSINESS_OWNER_RFID_LIST = "BUSINESS_OWNER_RFID_LIST";
export const BUSINESS_OWNER_RFID_ASSIGN_LIST_SUCCESS =
  "BUSINESS_OWNER_RFID_ASSIGN_LIST_SUCCESS";
export const BUSINESS_OWNER_RFID_ASSIGN_LIST_FAILURE =
  "BUSINESS_OWNER_RFID_ASSIGN_LIST_FAILURE";
export const BUSINESS_OWNER_RFID_ASSIGN_LIST =
  "BUSINESS_OWNER_RFID_ASSIGN_LIST";
