import {
    LIST_ALL_TARIFFS,
    LIST_ALL_TARIFFS_SUCCESS,
    VIEW_DETAIL_TARIFF,
    VIEW_DETAIL_TARIFF_SUCCESS,
    ADD_TARIFF,
    DELET_TARIFF,
    EDIT_TARIFF,
    ASSING_TARIFF
} from "./actionTypes";

export const listAllTariffs = (ID) => {
    return {
        type: LIST_ALL_TARIFFS,
        payload: { ID },
    };
};

export const listAllTariffsSuccess = data => {
    return {
        type: LIST_ALL_TARIFFS_SUCCESS,
        payload: data,
    };
}

export const detailViewTariff = (TariffID) => {
    return {
        type: VIEW_DETAIL_TARIFF,
        payload: { TariffID },
    };
};

export const detailViewTariffSuccess = data => {
    return {
        type: VIEW_DETAIL_TARIFF_SUCCESS,
        payload: data,
    };
}

export const addTariff = (data, history) => {
    return {
        type: ADD_TARIFF,
        payload: { data, history }
    }

};

export const editTariff = (data, TariffID, history) => {
    return {
        type: EDIT_TARIFF,
        payload: { data, TariffID, history }
    }

};

export const deleteTariff = (TariffID) => {
    return {
        type: DELET_TARIFF,
        payload: { TariffID },
    };
};

export const AssingTariff = (data) => {
    return {
        type: ASSING_TARIFF,
        payload: { data },
    };
};