import {
  ALL_CUSTOMER_LIST,
  CUSTOMER_LIST_FAILURE,
  CUSTOMER_LIST_SUCCESS,
} from "./actionTypes";
    
const initialState = {
  loading: false,
  list: [],
  error: null,
};

const customerList = (state = initialState, action) => {
  switch (action.type) {
    case ALL_CUSTOMER_LIST:
      return {
        ...state,
        loading: true,
      };
    case CUSTOMER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload,
        error: null,
      };
    case CUSTOMER_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        list: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export default customerList;
