import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Card, CardBody, CardHeader, Col, Container, Input, Modal, ModalBody, ModalHeader, Row, Label } from 'reactstrap';

import DeleteModal from "../../../Components/Common/DeleteModal";
import { showToast } from "../../../store/actions";


import moment from "moment";
import { allBusinessList, getStatConnector, allLocation, listAllStation } from '../../../store/actions';
import CustomDropdown from '../../../Components/Common/CustomDropdown';

//import Components
import DisableModal from "../../../Components/Common/DisableModal";
import { useDispatch, useSelector } from "react-redux";
import TableContainer from "../../../Components/Common/TableContainer";
import useWindowDimension from "../../../Components/Hooks/useWindowDimension";
import ContentLoader from "../../../Components/Common/ContentLoader";
import {
    RFIDAllData,
} from "../../../store/rfid/actions";
import { decryptResponseData } from '../../../helpers/encryptDecrypt'

const Fleet = () => {

    document.title = "Customers | Vidhyutam";
    const history = useHistory();
    const dispatch = useDispatch();

    const userRole = JSON.parse(localStorage.getItem("users"))?.userRole || ''
    const { RFIDListData, RFIDListTotalAmount, loading } = useSelector(
        (state) => state.RFIDList
    );

    const { businessList, locationList, stationDetail, getStationConnector } = useSelector((state) => ({
        // businessList: state?.businessList?.allBusinessList.filter(
        //     (i) => i?.status !== "Deleted"
        // ),

        locationList: state?.locationsList?.allLocationList.map(row => ({
            ...row,
            locationName: decryptResponseData(row.locationName),
        })),
        businessList: state?.businessList?.allBusinessList.map(row => ({
            ...row,
            businessName: decryptResponseData(row.businessName),
        })),
        stationDetail: state?.ManageStations?.listAllStation,
        getStationConnector: state?.ManageStations?.getStationConn?.data,


    }));


    let { result, totalAmount } = RFIDListData;
    result = result ? result : [];
    // totalAmount = totalAmount ? totalAmount : 0;

    const dimensions = useWindowDimension();
    const [filteredTable, setfilteredTable] = useState([]);
    const [deleteModal, setDeleteModal] = useState(false);
    const [disabledModal, setDisabledModal] = useState(false);
    const [filterModal, setFilterModal] = useState(false);
    const [headerHeight, setHeaderHeight] = useState(0);
    const [footerHeight, setFooterHeight] = useState(0);
    const [tableTaskBar, setTableTaskBar] = useState(0);
    const [paginationDiv, setPaginationDiv] = useState(0);
    const [cardHeader, setCardHeaderHeight] = useState(0);
    const [bSelectFilter, setBSelectFilter] = useState('');
    const [businessName, setBusinessName] = useState([]);
    const [isFilterApplied, setisFilterApplied] = useState(false)


    const [getBusId, setGetBusId] = useState('');

    const [getLocId, setGetLocId] = useState('');
    const [getStaId, setGetStaId] = useState('');
    const [getConId, setGetConId] = useState('');
    const [filterbusinessId, setFilterbusinessId] = useState('');

    const [filterlocationId, setFilterlocationId] = useState('');
    const [filterstationId, setFilterstationId] = useState('');
    const [filterConnectorId, setFilterconnectorId] = useState('');

    const [buttonFilter, setButtonFilter] = useState(false)
    const [statusDataselect, setStatusDataselect] = useState('');
    const [locationListDAta, setLocationListDAta] = useState([]);
    const [stationListDAta, setStationListDAta] = useState([]);
    const [connectorListDAta, setConnectorListDAta] = useState([]);

    useEffect(() => {
        dispatch(RFIDAllData('?id='));

    }, []);

    // const { businessList } = useSelector(state => ({
    //     businessList: state?.businessList?.allBusinessList,
    // }));

    useEffect(() => {
        if (businessList.length > 0) {
            const numOfLoca = businessList?.map((e, i) => e.nLocations).reduce((Acc, e) => Acc + e, 0);

            const numOfStat = businessList?.map((e, i) => e.nStations).reduce((Acc, e) => Acc + e, 0);
        }
    }, [businessList])
    useEffect(() => {
        const setLocationFilter = () => {
            const TempList = locationList?.map((eve, ind) => {
                return {
                    id: `${ind + 1}`,
                    value: eve.locationName,
                    LocationID: eve.locationId
                }
            })
            return TempList;
        }
        const LocationT = setLocationFilter()
        setLocationListDAta(LocationT);

    }, [locationList]);


    useEffect(() => {
        const setStationFilter = () => {
            const TempList = stationDetail?.map((eve, ind) => {

                return {
                    id: `${ind + 1}`,
                    value: eve.stationName,
                    StationId: eve.stationId
                }
            })
            return TempList;
        }
        const LocationT = setStationFilter()
        setStationListDAta(LocationT);

    }, [stationDetail]);

    useEffect(() => {
        const setConnectorFilter = () => {
            const TempList = getStationConnector?.map((eve, ind) => {

                return {
                    id: `${ind + 1}`,
                    value: '#' + eve.connectorNumber,
                    connectorId: eve.connectorId
                }
            })
            return TempList;
        }
        const ConnectorT = setConnectorFilter()
        setConnectorListDAta(ConnectorT);

    }, [getStationConnector]);



    useEffect(() => {
        setHeaderHeight(
            document.getElementsByClassName("navbar-header")[0]?.offsetHeight
        );
        setFooterHeight(document.getElementsByClassName("footer")[0]?.offsetHeight);
        setTableTaskBar(
            document.getElementsByClassName("tableTaskBar")[0]?.offsetHeight
        );
        setPaginationDiv(
            document.getElementsByClassName("paginationDiv")[0]?.offsetHeight
        );
        setCardHeaderHeight(
            document.getElementsByClassName("cardHeader")[0]?.offsetHeight
        );

    }, []);

    const handleResize = () => {
        setHeaderHeight(
            document.getElementsByClassName("navbar-header")[0]?.offsetHeight
        );
        setFooterHeight(document.getElementsByClassName("footer")[0]?.offsetHeight);
        setTableTaskBar(
            document.getElementsByClassName("tableTaskBar")[0]?.offsetHeight
        );
        setPaginationDiv(
            document.getElementsByClassName("paginationDiv")[0]?.offsetHeight
        );
        setCardHeaderHeight(
            document.getElementsByClassName("cardHeader")[0]?.offsetHeight
        );
    };


    const bussinessSelectData = result
        ?.filter((item, index, self) => {
            // Return true if the item's index is the first occurrence of the id
            return (
                index === self.findIndex((obj) => obj.businessId === item.businessId)
            );
        })
        ?.map((i) => {
            return { id: i.businessId, value: i.businessName };
        });
    bussinessSelectData?.unshift({ id: 0, value: "All" });


    useEffect(() => {
        dispatch(allBusinessList('?id='));
        // 
    }, []);

    const onfilterFleetData = (filterDate, businessData) => {
        let startDate = moment(filterDate[0]).format('DD:MM:YYYY')
        let endDate = moment(filterDate[1]).format('DD:MM:YYYY')
        console.log(startDate, 'startDate!!!')
        console.log(endDate, 'endDate!!!')
        console.log(bSelectFilter, 'bSelectFilter!!!')
        console.log(result, 'result!!!')

    }
    useEffect(() => {
        window.addEventListener("resize", handleResize);
    }, []);



    const setBusiness = () => {
        const businessName = businessList.map((eve, ind) => {
            return {
                id: `${ind + 1}`,
                value: eve.businessName,
                BusinessID: eve.businessId
            }
        })
        // return tempArray.concat(businessName);
        return businessName;
    }
    const tempArray = [{ id: '0', value: 'All', BusinessID: 'All' }]

    useEffect(() => {
        if (businessList?.length > 0) {
            const BusinessNameT = setBusiness();
            setBusinessName([...BusinessNameT]);
        }
    }, [businessList]);


    const handleDeleteContact = () => {
        dispatch(
            showToast({
                message: "record has been deleted successfully.",
                type: "success",
            })
        );
        setDeleteModal(false);
    };
    const handleDisableContact = () => {
        dispatch(
            showToast({
                message: "business has been disabled successfully.",
                type: "success",
            })
        );
        setDisabledModal(false);
    };



    const columns1 = useMemo(
        () => [
            {
                Header: "RFID",
                accessor: "RFID",
                filterable: true,
                show: true,
                Cell: (cell) => {
                    return <p className="text textOverflow">{cell?.value}</p>;
                },
            },
            {
                Header: "Business",
                accessor: "businessName",
                filterable: true,
                show: true,
                Cell: (cell) => {
                    return <p className="text textOverflow">{cell?.value}</p>;
                },
            },
            {
                Header: "Location",
                accessor: "locationName",
                filterable: true,
                show: true,
                Cell: (cell) => {
                    return <p className="text textOverflow">{cell?.value}</p>;
                },
            },
            {
                Header: "Station",
                accessor: "stationName",
                filterable: true,
                disableSortBy: true,
                Cell: (cell) => {
                    return <p className="text textOverflow">{cell?.value}</p>;
                },
            },
            {
                Header: "Connector",
                //   accessor: "connector",
                filterable: true,
                disableSortBy: true,
                show: true,
                Cell: ({ row }) => {
                    return (
                        <p className="text textOverflow">
                            {`Connector${row?.original?.connectorId}(${row?.original?.format})`}
                        </p>
                    );
                },
            },
            {
                Header: (
                    <div className="customHeader">
                        <span className="txt1">Earning</span>
                        <p className="text2 textOverflow">
                            <span className="">₹</span>
                            {RFIDListTotalAmount ? RFIDListTotalAmount.toFixed(3) : 0}
                        </p>

                    </div>
                ),
                accessor: "amount",
                filterable: true,
                disableSortBy: true,
                show: true,
                Cell: (cell) => {
                    return (
                        <p className="text textOverflow">
                            &#8377; {cell?.value ? cell?.value : 0}
                        </p>
                    );
                },
            },
            {
                Header: "Duration",
                accessor: "duration",
                filterable: true,
                disableSortBy: true,
                show: true,
                Cell: (cell) => {
                    return (
                        <p className="text textOverflow">
                            {cell?.value ? cell?.value : "00:00:00"}
                        </p>
                    );
                },
            },
        ],
        [history, RFIDListTotalAmount]
    );

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <div className="customerPage">
                        <Row>
                            <Col lg={12}>
                                <Card className="mb-0">
                                    <CardHeader className="cardHeader">
                                        <p className="headerTxt">Fleet Management</p>
                                    </CardHeader>
                                    {loading ? (
                                        <ContentLoader />
                                    ) : (
                                        <CardBody>
                                            <div
                                                className={
                                                    result?.length > 0
                                                        ? "table-content"
                                                        : "table-content disablePointer"
                                                }
                                            >
                                                <TableContainer
                                                    columns={columns1?.filter((a) => a.show)}
                                                    data={result}
                                                    isGlobalFilter={true}
                                                    customPageSize={16}
                                                    setfilteredTable={setfilteredTable}
                                                    searchPlaceholder="Search by RFID,Business,Location,Connector"
                                                    divClass="table-responsive mb-1 customerTable"
                                                    tableClass="align-middle table-nowrap"
                                                    theadClass="table-light text-muted"
                                                    addButtonTitle="Add Business"
                                                    maxHeight={
                                                        dimensions.height -
                                                        headerHeight -
                                                        footerHeight -
                                                        tableTaskBar -
                                                        paginationDiv -
                                                        cardHeader -
                                                        96
                                                    }
                                                    customFilterButton={buttonFilter}
                                                    handleViewDetailClick={() => { }}

                                                    isApplyedR={true}
                                                    // modalName={filterModal}
                                                    onClickCustomFilter={() => {
                                                        setFilterModal(true);
                                                    }}
                                                    // isApplyed={businessFilterModel} <i className="bx bxs-circle circleIcon" />

                                                    // handleClose={() => {
                                                    //     setFilterModal(false);
                                                    // }}
                                                    // onFleetData={(changeDate, BusinessSelect) => {
                                                    //     onfilterFleetData(changeDate, BusinessSelect)
                                                    //     setisFilterApplied(true);

                                                    // }}
                                                    // bussinessSelectData={tempArray.concat(businessName.sort((a, b) => { return a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1 }))}
                                                    // onApplyFilterBtn={() => {

                                                    //     setFilterModal(false);
                                                    //     setisFilterApplied(true);


                                                    // }}
                                                    // BusinessSelect={bSelectFilter}
                                                    // onBusinessSelect={(e) => {
                                                    //     setBSelectFilter(e);
                                                    //     // setLSelectFilter('')
                                                    //     // if (e.value === 'All') {
                                                    //     //     dispatch(allLocation('?id='));
                                                    //     // } else {
                                                    //     //     dispatch(allLocation(`?id=${e?.BusinessID}`));
                                                    //     // }
                                                    // }}

                                                    customFilter={true}
                                                    // allFilter={true}
                                                    // clearFilterData={() => {

                                                    //     setFilterModal(false);
                                                    // }}
                                                    // fleetFilterShow={true}
                                                    noData={result?.length > 0 ? false : true}
                                                    noDataImage={
                                                        "https://res.cloudinary.com/djyl1goby/image/upload/v1672224362/Lubi/WebApp/Static-images/noCustomerfound.svg"
                                                    }
                                                    noDataTitle={"No Fleet Found!"}
                                                />
                                            </div>
                                        </CardBody>
                                    )}
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>

            <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeleteContact}
                onCloseClick={() => setDeleteModal(false)}
            />
            <DisableModal
                show={disabledModal}
                title="Are you sure?"
                subtitle="Are you sure you want to disable this business?"
                btnActionText="Yes, Disable it!"
                btncancelText="Cancel"
                onActionClick={handleDisableContact}
                onCloseClick={() => setDisabledModal(false)}
            />



            <Modal className='tariffModal' isOpen={filterModal}
                centered={true}>
                <ModalHeader>
                    <p>Filter</p>
                    <i className='bx bx-x' onClick={() => {
                        setFilterModal(false)
                    }} />
                </ModalHeader>
                <ModalBody className="">
                    <div className='qrMain'>
                        <div className='fleet-filter-view'>

                            <Row style={{ marginTop: '15px' }}>
                                <Col lg={6}>
                                    <div className="lg-6" >
                                        <Label>Business</Label>
                                        <CustomDropdown
                                            className='allDropdowns'
                                            moreValue={true}
                                            dropDownItems={businessName}
                                            defaultValue={getBusId}
                                            placeholder={'Choose Business'}
                                            selectedValue={getBusId}
                                            showSecondVal={false}
                                            onSelect={evt => {
                                                setGetBusId(evt.value)
                                                setFilterbusinessId(evt.BusinessID)
                                                dispatch(allLocation(`?id=${evt?.BusinessID}`));
                                                setGetConId('')
                                                setGetLocId('')
                                                setGetStaId('')
                                            }}
                                        />
                                    </div>
                                </Col>

                                <Col lg={6}>
                                    <div className="lg-6" >
                                        <Label>Location</Label>
                                        <CustomDropdown
                                            className='allDropdowns'
                                            moreValue={true}
                                            dropDownItems={locationListDAta}
                                            defaultValue={getLocId}
                                            placeholder={'Choose Location'}
                                            selectedValue={getLocId}
                                            showSecondVal={false}
                                            onSelect={evt => {
                                                setGetLocId(evt.value);
                                                setFilterlocationId(evt.LocationID)

                                                dispatch(listAllStation(`?locationId=${evt.LocationID}`));
                                                setGetStaId('')

                                                // // getUserRole() !== 'LM' && setSelectedGroup1(evt);
                                                // setSelectedGroup1(evt);
                                                // addStationForm.handleChange('location')(evt.value)
                                                // setStaReadOnly(true);
                                            }}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <></>
                            <Row style={{ marginTop: '15px' }}>


                                <Col lg={6}>
                                    <div className="lg-6" >
                                        <Label>Station</Label>
                                        <CustomDropdown
                                            className='allDropdowns'
                                            moreValue={true}
                                            dropDownItems={stationListDAta}
                                            defaultValue={getStaId}
                                            placeholder={'Choose Station'}
                                            selectedValue={getStaId}
                                            showSecondVal={false}
                                            onSelect={evt => {
                                                setFilterstationId(evt.StationId)
                                                setGetStaId(evt.value);
                                                dispatch(getStatConnector(evt.StationId));


                                            }}
                                        />
                                    </div>
                                </Col>

                                <Col lg={6}>
                                    <div className="lg-6" >
                                        <Label>Connector</Label>
                                        <CustomDropdown
                                            className='allDropdowns'
                                            moreValue={true}
                                            dropDownItems={connectorListDAta}
                                            defaultValue={getConId}
                                            placeholder={'Choose Connector'}
                                            selectedValue={getConId}
                                            showSecondVal={false}
                                            onSelect={evt => {
                                                setGetConId(evt.value)
                                                setFilterconnectorId(evt.connectorId)

                                            }}
                                        />
                                    </div>
                                </Col>


                            </Row>

                            <></>

                        </div>
                    </div>
                    <div className="assignMain">
                        <div className="hstack gap-2  mt-2 justify-content-end">
                            <Button
                                onClick={() => {

                                    setButtonFilter(false)
                                    setGetLocId('')
                                    setLocationListDAta([])
                                    setStationListDAta([])
                                    setConnectorListDAta([])
                                    setGetBusId('')
                                    setGetStaId('')
                                    setGetConId('')
                                    setFilterbusinessId('')
                                    setFilterlocationId('')
                                    setFilterstationId('')
                                    setFilterconnectorId('')
                                    setStatusDataselect('')
                                    setFilterModal(false)
                                    setisFilterApplied(false);
                                    dispatch(RFIDAllData('?id= '));

                                }}
                            >
                                Clear
                            </Button>
                            <Button
                                color={(filterlocationId === '' && filterbusinessId == '' && filterstationId === '' && filterConnectorId === '' && statusDataselect === '') ? 'secondary' : 'success'}
                                type="submit"
                                disabled={filterlocationId === '' && filterbusinessId == '' && filterstationId === '' && filterConnectorId === '' && statusDataselect === ''}
                                onClick={() => {
                                    setButtonFilter(true)
                                    let queryParams = '?';
                                    const params = [];
                                    if (filterbusinessId) {
                                        params.push(`businessId=${filterbusinessId}`);
                                    }
                                    if (filterlocationId) {
                                        params.push(`locationId=${filterlocationId}`);
                                    }
                                    if (filterstationId) {
                                        params.push(`stationId=${filterstationId}`);
                                    }
                                    if (filterConnectorId) {
                                        params.push(`connectorId=${filterConnectorId}`);
                                    }
                                    setisFilterApplied(true);

                                    queryParams += params.join('&');
                                    dispatch(RFIDAllData(queryParams));
                                    setFilterModal(false)
                                }}
                            >
                                Apply
                            </Button>
                        </div>
                    </div>
                </ModalBody>
            </Modal>


        </React.Fragment>
    );
};

export default Fleet;

