
import { ContactsGlobalFilter } from "../../Components/Common/GlobalSearchFilter";
import {
    LIST_ALL_STATION,
    LIST_ALL_STATION_SUCCESS,
    UPDATE_RESERVATION,
    UPDATE_RESERVATION_SUCCESS,
    VIEW_DETAIL_STATION,
    VIEW_DETAIL_STATION_SUCCESS,
    ADD_STATION,
    GET_STATION_NAME,
    GET_STATION_NAME_SUCCESS,
    CHECK_STATION,
    EDIT_STATIONS,
    GET_STATION_CONNECTOR,
    GET_STATION_CONNECTOR_SUCCESS,
    GET_SOCKET_DATA,
    GET_SOCKET_HEART_DATA,
    SOCKET_CREDS,
    FIRMWARE_STATUS,
    RESET_FIRMWARE_STATUS,
    ACTIVATE_DEACTIVATE_STATION,
    DELETE_STATION,
    GET_RESERVATION,
    LIST_RESERVATION_SUCCESS,
    DELETE_RESERVATION,
    DELETE_RESERVATION_SUCESS,
    LIST_ALL_CONNECTOR,
    LIST_ALL_CONNECTOR_SUCCESS
} from "./actionTypes";

export const activateDeactivateStationAction = (payload) => {
    return {

        type: ACTIVATE_DEACTIVATE_STATION,
        payload: payload,
    };
};
export const deleteStationAction = (payload) => {
    return {
        type: DELETE_STATION,
        payload: payload,
    };
};
export const listAllStation = (id) => {
    return {
        type: LIST_ALL_STATION,
        payload: { id },
    };
};

export const listAllStationSuccess = data => {
    return {
        type: LIST_ALL_STATION_SUCCESS,
        payload: data,
    };
}



export const updateReservation = (data) => {
    return {
        type: UPDATE_RESERVATION,
        payload: { data },
    };
};

export const updateReservationSuccess = data => {
    return {
        type: UPDATE_RESERVATION_SUCCESS,
        payload: data,
    };
}


export const getListAllConnector = (data) => {
    return {
        type: LIST_ALL_CONNECTOR,
        payload: { data },
    };
};

export const getListAllConnectoruccess = data => {
    return {
        type: LIST_ALL_CONNECTOR_SUCCESS,
        payload: data,
    };
}


export const detailViewStation = (stationId) => {
    return {
        type: VIEW_DETAIL_STATION,
        payload: { stationId },
    };
};

export const detailViewStationSuccess = data => {
    return {
        type: VIEW_DETAIL_STATION_SUCCESS,
        payload: data,
    };
}

export const addStation = (data, history) => {
    return {
        type: ADD_STATION,
        payload: { data, history }
    }

}

export const getStationName = (locationId) => {
    return {
        type: GET_STATION_NAME,
        payload: { locationId }
    };
}

export const getStationNameSuccess = (data) => {
    return {
        type: GET_STATION_NAME_SUCCESS,
        payload: data
    };
}

export const checkStationName = (Id, data) => {
    return {
        type: CHECK_STATION,
        payload: { Id, data }
    };
}

export const editStations = (data, evseID, history) => {
    return {
        type: EDIT_STATIONS,
        payload: { data, evseID, history }
    };
}

export const getStatConnector = (stationId) => {
    return {
        type: GET_STATION_CONNECTOR,
        payload: { stationId }
    };
}
export const getStatConnectorSuccess = (data) => {
    return {
        type: GET_STATION_CONNECTOR_SUCCESS,
        payload: { data }
    };
}

export const socketData = (data) => {
    // console.log(data)
    return {
        type: GET_SOCKET_DATA,
        payload: { data }
    };
}


export const socketHeartData = (data) => {
    return {
        type: GET_SOCKET_HEART_DATA,
        payload: { data }
    };
}




export const socketCredentials = (data) => {
    console.log('socketCredentials', data)
    return {
        type: SOCKET_CREDS,
        payload: data
    };
}


export const firmwareStatusAll = (data) => {
    return {
        type: FIRMWARE_STATUS,
        payload: data
    };
}

export const clearfirmwareStatus = () => {
    return {
        type: RESET_FIRMWARE_STATUS,
    };
}


export const getReservation = (data) => {
    return {
        type: GET_RESERVATION,
        payload: { data },
    };
};

export const listReservationSuccess = data => {
    return {
        type: LIST_RESERVATION_SUCCESS,
        payload: data,
    };
}

export const deleteReservation = (data) => {
    return {
        type: DELETE_RESERVATION,
        payload: { data },
    };
};

export const deleteReservationSucess = (data) => {
    return {
        type: DELETE_RESERVATION_SUCESS,
        payload: data,
    };
};
