import { LIST_ALL_STATION_SUCCESS, DELETE_RESERVATION_SUCESS, LIST_ALL_CONNECTOR_SUCCESS, LIST_RESERVATION_SUCCESS, SOCKET_CREDS, FIRMWARE_STATUS, VIEW_DETAIL_STATION_SUCCESS, GET_STATION_NAME_SUCCESS, GET_STATION_CONNECTOR_SUCCESS, GET_SOCKET_DATA, GET_SOCKET_HEART_DATA, RESET_FIRMWARE_STATUS } from "./actionTypes";

const initialState = {
    listAllStation: [],
    listAllReservation: [],
    DetailStation: [],
    StationName: [],
    deleteSucess: {},
    getStationConn: [],
    getSocketStatus: [],
    getSocketHeartStatus: [],
    listAllConnector: [],
    firmwareStatus: {
        updateResp: {
            status: ''
        }
    },
    socketCreds: {
        id: '',
        firmwareStatus: ''
    }
};

const ManageStations = (state = initialState, action) => {
    switch (action.type) {


        case LIST_ALL_STATION_SUCCESS:
            state = {
                ...state,
                listAllStation: action.payload,
            };
            break;
        case DELETE_RESERVATION_SUCESS:
            state = {
                ...state,
                deleteSucess: action,
            };
            break;
        case LIST_ALL_CONNECTOR_SUCCESS:
            state = {
                ...state,
                listAllConnector: action.payload,
            };
            break;


        case LIST_RESERVATION_SUCCESS:
            state = {
                ...state,
                listAllReservation: action.payload,
            };
            break;
        case VIEW_DETAIL_STATION_SUCCESS:
            state = {
                ...state,
                DetailStation: action.payload,
            };
            break;
        case GET_STATION_NAME_SUCCESS:
            state = {
                ...state,
                StationName: action.payload,
            };
            break;
        case GET_STATION_CONNECTOR_SUCCESS:
            state = {
                ...state,
                getStationConn: action.payload,
            };
            break;
        case GET_SOCKET_DATA:
            state = {
                ...state,
                getSocketStatus: action.payload,
            };
            break;

        case GET_SOCKET_HEART_DATA:
            state = {
                ...state,
                getSocketHeartStatus: action.payload,
            };
            break;

        case SOCKET_CREDS:
            state = {
                ...state,
                socketCreds: { ...state.socketCreds, ...action.payload }
            };
            break;
        case FIRMWARE_STATUS:
            state = {
                ...state,
                firmwareStatus: { ...action.payload }
            };
            break;
        case RESET_FIRMWARE_STATUS:
            state = {
                ...state,
                firmwareStatus: { ...state.firmwareStatus, updateResp: { status: '' } }
            };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default ManageStations;