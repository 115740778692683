import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import { LOCATION_LISTING, LOCATION_FILTER_LISTING, LOCATION_DETAIL, ADD_NEW_LOCATION, EDIT_LOCATION, LOCATION_INVITE_RESEND, LOCATION_MAN_BUSINESS, ACTIVATE_DEACTIVATE_LOCATION, ALLSTATION_BUSINESS_LISTING, ALLCONNECTOR_STATION_LISTING, DELETE_LOCATION } from "./actionType";

import { LocationListSuccess, allStaionBusinessIdSuccess, allConnectorStationIdSuccess, LocationDetailSuccess, LocManBusSucess, activateDeactivateLocationAction, deleteLocationAction } from "../actions"

import { Locations, LocationsFilter, stationListBusinessF, connectorListStationF, LocationDetail, newLocationAdd, LocationEdit, locationInviteResend, LocmanBuss, activateDeactivateLocation, deleteLocation } from "../../helpers/Auth";
import { showToast, showLoader } from "../actions";

function* locationList({ payload: { data } }) {
    try {
        const response = yield call(Locations, data)
        if (response) {
            yield put(LocationListSuccess(response.payload));
        }
    } catch (e) {
        yield put(LocationListSuccess([]));
        // yield put(showToast({
        //     message: e?.response?.data?.message,  //'Account verified successfully.',
        //     type: 'error'
        // }))
    }
}

function* locationsDetail({ payload: { locationId } }) {
    try {
        const response = yield call(LocationDetail, locationId);
        if (response) {
            yield put(LocationDetailSuccess(response?.payload));

        }
    } catch (e) {
        yield put(showToast({
            message: e?.response?.data?.message,  //'Account verified successfully.',
            type: 'error'
        }))
    }
}

function* addLocation({ payload: { user, businessID, history } }) {
    yield put(showLoader(true));
    try {
        const response = yield call(newLocationAdd, user, businessID);
        if (response) {

            yield put(showToast({
                message: response.message,
                type: 'success'
            }));

            setTimeout(() => {
                history.goBack();
            }, 2000);
            yield put(showLoader(false));
        }
    } catch (e) {
        yield put(showToast({
            message: e?.response?.data?.message,  //'Account verified successfully.',
            type: 'error'
        }))
        yield put(showLoader(false));
    }
}

function* EditLocation({ payload: { user, locationID, history } }) {
    yield put(showLoader(true));
    try {
        const response = yield call(LocationEdit, user, locationID);
        if (response) {

            yield put(showToast({
                message: response.message,
                type: 'success'
            }));
            setTimeout(() => {
                history.goBack();
            }, 1000);
            yield put(showLoader(false));
        }
    } catch (e) {
        yield put(showToast({
            message: e?.response?.data?.message,  //'Account verified successfully.',
            type: 'error'
        }))
        yield put(showLoader(false));
    }
}

function* resendLocationInvite({ payload: { data } }) {
    yield put(showLoader(true));
    try {
        const response = yield call(locationInviteResend, data);
        if (response) {
            yield put(showToast({
                message: response.message,
                type: 'success'
            }));
            yield put(showLoader(false));
        }
    } catch (e) {
        yield put(showToast({
            message: e?.response?.data?.message,  //'Account verified successfully.',
            type: 'error'
        }))
        yield put(showLoader(false));
    }
}

function* LocationManBusDetail({ payload: { userId } }) {
    try {
        const response = yield call(LocmanBuss, userId);
        if (response) {
            yield put(LocManBusSucess(response?.payload));

            if (response.payload.length > 0) {
                localStorage.setItem('businessLogo', response.payload[0].businessLogo)
                localStorage.setItem('businessName', response.payload[0].businessName)
            }
        }
    } catch (e) {
        yield put(showToast({
            message: e?.response?.data?.message,  //'Account verified successfully.',
            type: 'error'
        }))
    }
}
function* deleteLocationFunction(payload) {
    try {
        const response = yield call(deleteLocation, payload);
        if (response) {
            yield put(
                showToast({
                    message: response?.message,
                    type: "success",
                })
            );
        }
    } catch (e) {
        yield put(
            showToast({
                message: e?.response?.data?.message,
                type: "error",
            })
        );
    }
}
function* activateDeactivateLocationFunction(payload) {
    try {
        const response = yield call(activateDeactivateLocation, payload);
        if (response) {
            yield put(
                showToast({
                    message: response?.message,
                    type: "success",
                })
            );
        }
    } catch (e) {
        yield put(
            showToast({
                message: e?.response?.data?.message,
                type: "error",
            })
        );
    }
}


function* stationListBusnessId({ payload: { data } }) {
    try {
        const response = yield call(stationListBusinessF, data)
        if (response) {
            yield put(allStaionBusinessIdSuccess(response.payload));
        }
    } catch (e) {
        yield put(allStaionBusinessIdSuccess([]));
        // yield put(showToast({
        //     message: e?.response?.data?.message,  //'Account verified successfully.',
        //     type: 'error'
        // }))
    }
}

function* connectorListStationId({ payload: { data } }) {
    try {
        const response = yield call(connectorListStationF, data)
        if (response) {
            yield put(allConnectorStationIdSuccess(response.payload));
        }
    } catch (e) {
        yield put(allConnectorStationIdSuccess([]));
        // yield put(showToast({
        //     message: e?.response?.data?.message,  //'Account verified successfully.',
        //     type: 'error'
        // }))
    }
}



function* locationListFilter({ payload: { data } }) {
    try {
        const response = yield call(LocationsFilter, data)
        if (response) {
            yield put(LocationListSuccess(response.payload));
        }
    } catch (e) {
        yield put(LocationListSuccess([]));
        // yield put(showToast({
        //     message: e?.response?.data?.message,  //'Account verified successfully.',
        //     type: 'error'
        // }))
    }
}



export function* listAllLocation() {
    yield takeEvery(LOCATION_LISTING, locationList);
    yield takeEvery(LOCATION_DETAIL, locationsDetail);
    yield takeEvery(ADD_NEW_LOCATION, addLocation);
    yield takeEvery(EDIT_LOCATION, EditLocation);
    yield takeEvery(LOCATION_MAN_BUSINESS, LocationManBusDetail);
    yield takeEvery(LOCATION_INVITE_RESEND, resendLocationInvite);
    yield takeEvery(DELETE_LOCATION, deleteLocationFunction);
    yield takeEvery(ACTIVATE_DEACTIVATE_LOCATION, activateDeactivateLocationFunction);
    yield takeEvery(ALLSTATION_BUSINESS_LISTING, stationListBusnessId);
    yield takeEvery(ALLCONNECTOR_STATION_LISTING, connectorListStationId);
    yield takeEvery(LOCATION_FILTER_LISTING, locationListFilter);

}

export default listAllLocation;