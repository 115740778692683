import {
  SHOW_TOAST,
  HIDE_TOAST,
  SHOW_LOADER
} from "./actionType";

// common success
export const showToast = (payload) => ({
  type: SHOW_TOAST,
  payload
});

// common error
export const hideToast = (payload) => ({
  type: HIDE_TOAST,
  payload
});

export const showLoader = (payload) => ({
  type: SHOW_LOADER,
  payload
});