import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Input, Label, Row, Button, Form, FormFeedback } from 'reactstrap';
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import Constant from '../../utils/constant';

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link, useHistory } from "react-router-dom";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { loginUser } from "../../store/actions";

import AuthLogo from '../../Components/Common/AuthLogo';
import CustomButton from '../../Components/Common/CustomButton';

const Login = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [userLogin, setUserLogin] = useState([]);
    
    const [passwordShow, setPasswordShow] = useState('password');
    const [rememberMe, setRememberMe] = useState(window.sessionStorage.getItem("Lubi_rememberMe") === 'true' ? true : false);

    const { user, loading } = useSelector(state => ({
        user: state.Account.user,
        loading: state.Toast.loader
    }));

    useLayoutEffect(() => {
        // if (window.sessionStorage.getItem('users') !== null || window.localStorage.getItem('islogged') !== null) {
        //     history.push('/dashboard');
        // }
        if (window.localStorage.getItem('islogged') !== null) {
            history.push('/dashboard');
        }
        // window.localStorage.removeItem('token');
        // window.localStorage.removeItem('email');
        // window.sessionStorage.clear();
        window.localStorage.setItem('current', 'login');
    }, [])


    useEffect(() => {
        if (user && user) {
            setUserLogin({
                email: user.user.email || '',
                password: user.user.confirm_password || ''
            });
        }
    }, [user]);
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            // email: userLogin.email || "admin@themesbrand.com" || '',
            // password: userLogin.password || "123456" || '',
            email: window.sessionStorage.getItem("Lubi_rememberMe_email") || '',
            password: window.sessionStorage.getItem("Lubi_rememberMe_password") || ''
        },
        validationSchema: Yup.object({
            // email: Yup.string().required("Please Enter Your Email"),
            email: Yup.string()
                .required('Please enter your email address')
                .matches(Constant.REGEX.EMAIL, 'Please provide a valid email address'),
            password: Yup.string()
                .required("Please enter your password")
                .matches("", 'incorrect password'),
        }),
        onSubmit: (values) => {
            
            dispatch(loginUser(values, props.history));
            window.sessionStorage.setItem("Lubi_rememberMe", rememberMe);
            if (rememberMe) {
                window.sessionStorage.setItem("Lubi_rememberMe_email", values.email);
                window.sessionStorage.setItem("Lubi_rememberMe_password", values.password);
            } else {
                window.sessionStorage.setItem("Lubi_rememberMe_email", '');
                window.sessionStorage.setItem("Lubi_rememberMe_password", '');
            }
        }
    });

    useEffect(() => {
        validation.validateForm();

    }, [])
    

    document.title = "SignIn | Vidhyutam";
    const togglerPassword = () => {
        if (passwordShow === "password") {
            setPasswordShow("text")
            return;
        }
        setPasswordShow("password")
    }
    return (
        <React.Fragment>
            <ParticlesAuth>
                <div className="auth-page-content login">
                    <Container>
                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="mt-4">
                                    <CardBody className="p-4">
                                        <Row>
                                            <Col lg={12}>
                                                <div className="text-center mt-sm-2 mb-4 text-white-50">
                                                    <AuthLogo />
                                                </div>
                                            </Col>
                                        </Row>
                                        <div className="text-center mt-2">
                                            <h5 className="text-success">Welcome Back !</h5>
                                            <p className="text-muted">Sign-in to continue</p>
                                        </div>
                                        {/* {error && error ? (<Alert color="danger"> {error} </Alert>) : null} */}
                                        <div className="p-2 mt-4">
                                            <Form
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    validation.handleSubmit();
                                                    return false;
                                                }}
                                                action="#">
                                                <div className="mb-3">
                                                    <Label htmlFor="email" className="form-label requierdLabel">Email Address</Label>
                                                    <Input
                                                        name="email"
                                                        value={validation.values.email || ""}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter email address"
                                                        onChange={(e) => validation.handleChange('email')(e.target.value.trim())}
                                                        onKeyUp={validation.handleBlur}
                                                        tabIndex={1}
                                                        invalid={
                                                            validation.errors.email && validation.touched.email ? true : false
                                                        }
                                                    />
                                                    {validation.errors.email && validation.touched.email ? (
                                                        <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                                    ) : null}
                                                </div>

                                                <div className="mb-3">
                                                    <div className="float-end" onClick={() => {
                                                        window.localStorage.setItem('current', 'forget');
                                                    }}>
                                                        <Link to="/forgot-password" className="text-muted">Forgot password?</Link>
                                                    </div>
                                                    <Label className="form-label requierdLabel" htmlFor="password-input">Password</Label>
                                                    <div className="position-relative auth-pass-inputgroup mb-3">
                                                        <Input
                                                            name="password"
                                                            value={validation.values.password.trim() || ""}
                                                            type={passwordShow}
                                                            className="form-control pe-5 passwordError"
                                                            placeholder="Enter password"
                                                            onChange={validation.handleChange}
                                                            onKeyUp={validation.handleBlur}
                                                            tabIndex={2}
                                                            invalid={
                                                                validation.errors.password && validation.touched.password ? true : false
                                                            }
                                                        />
                                                        {validation.errors.password && validation.touched.password ? (
                                                            <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                                                        ) : null}
                                                        <Button
                                                            color="link"
                                                            onClick={() =>
                                                                togglerPassword()
                                                            }
                                                            className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                                                            type="button"
                                                            id="password-addon">
                                                            <i className={passwordShow === "password"
                                                                ? 'bx bx-hide align-middle'
                                                                : "ri-eye-fill align-middle"}></i>
                                                        </Button>
                                                    </div>
                                                </div>

                                                <div className="form-check">
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox" value=""
                                                        id="auth-remember-check"
                                                        checked={rememberMe}
                                                        onChange={() => {
                                                            setRememberMe(!rememberMe)
                                                        }}

                                                    />
                                                    <Label className="form-check-label" htmlFor="auth-remember-check">Remember me</Label>
                                                </div>

                                                <div className="mt-4">
                                                    <CustomButton
                                                        title='Sign-in'
                                                        disabled={!(validation.isValid)}
                                                        type='submit'
                                                        loading={loading}
                                                        className='submitBTN'
                                                    />
                                                </div>
                                            </Form>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </ParticlesAuth>
        </React.Fragment>
    );
};

export default withRouter(Login);