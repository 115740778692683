import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import ParticlesAuth from "../ParticlesAuth";

// Import Images
import error from "../../../assets/images/error.svg";


const Basic404 = () => {
    const history = useHistory();
    document.title = "404 Error | Vidhyutam";
    return (
        <React.Fragment>
            <div className="auth-page-wrapper Page-not-found-custome-page">
                <ParticlesAuth>
                    <div className="auth-page-content">
                        <Container>
                            <Row>
                                <Col lg={12}>
                                    <div className="text-center">
                                        <div className="">
                                            <img src={error} alt="" className="error-basic-img move-animation" />
                                        </div>
                                        <div className="mt-n4">
                                            <h1 className="display-1 fw-medium">404</h1>
                                            <h3 className="text-uppercase">SORRY, PAGE NOT FOUND 😭</h3>
                                            <p className="text-muted mb-4">The page you are looking for is not available!</p>
                                            {/* <Link to="#" onClick={() => { history.goBack() }} className="btn btn-success"><i className="mdi mdi-home me-1"></i>Back to Dashboard</Link> */}
                                            <Link to="#" onClick={() => { history.push('/') }} className="btn btn-success"><i className="mdi mdi-home me-1"></i>Go to Dashboard</Link>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </ParticlesAuth>
            </div>
        </React.Fragment>
    );
};

export default Basic404;