import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { FORGET_PASSWORD, RESET_PASSWORD, VERIFY_OTP, RESEND_OTP, VERIFY_EMAIL } from "./actionTypes";
import { userForgetPasswordSuccess, userForgetPasswordError, VerifyOtpSuccess, verifyEmailSuccess } from "./actions";

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";
// import {
//   postFakeForgetPwd,
//   postJwtForgetPwd,
// } from "../../../helpers/fakebackend_helper";

import { Forget, Reset, VerifyOTP, ResendOTP, VerifyEmail } from "../../../helpers/Auth";
import { showToast, showLoader } from "../../actions";

const fireBaseBackend = getFirebaseBackend();

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* forgetUser({ payload: { user, history } }) {
  try {
    yield put(showLoader(true));
    const response = yield call(Forget, {
      email: user.email
    });
    if (response) {
      yield put(userForgetPasswordSuccess(response.payload));
      yield put(showToast({
        message: response.message, //"Reset link are sended to your mailbox, check there first"
        type: 'success'
      }));
      setTimeout(() => {
        history.push("/verify", {
          state: {
            token: response.payload,
            email: user.email
          }
        })
        //window.localStorage.setItem('token', JSON.stringify(response.payload.resetPasswordToken));
        window.localStorage.setItem('email', user.email);

      }, 3000);
      yield put(showLoader(false));
    }
  } catch (e) {
    yield put(showToast({
      message: e?.response?.data?.message,
      type: 'error'
    }));
    yield put(showLoader(false));
  }
}

function* resetUserPassword({ payload: { user, history, token } }) {
  try {
    yield put(showLoader(true));
    console.log('link', token)
    const response = yield call(Reset, {
      password: user.password,
      confirmPassword: user.confirmPassword,
    }, token);
    if (response) {
      yield put(showToast({
        message: response.message, //'Password reset  successfully.',
        type: 'success'
      }));
      setTimeout(() => {
        history.push('/login')
      }, 3000);
      yield put(showLoader(false));
    }
  } catch (e) {
    yield put(showToast({
      message: e?.response?.data?.message,
      type: 'error'
    }));
    yield put(showLoader(false));
  }
}

function* verifyOtP({ payload: { user, history, token } }) {
  try {
    yield put(showLoader(true));
    const response = yield call(VerifyOTP, {
      otp: user.otp
    }, token);
    console.log(token);
    if (response) {
      yield put(VerifyOtpSuccess(response.payload))
      yield put(showToast({
        message: response.message,  //'Account verified successfully.',
        type: 'success'
      }));
      setTimeout(() => {
        history.push('/resetpassword', {
          state: {
            Token: token
          }
        })
      }, 3000);
      yield put(showLoader(false));
    }
  } catch (e) {
    yield put(showToast({
      message: e?.response?.data?.message,  //'Account verified successfully.',
      type: 'error'
    }))
    console.log(e)
    yield put(showLoader(false));
  }
}

function* resendOTP({ payload: { user } }) {
  try {
    const response = yield call(ResendOTP, {
      email: user
    })
    if (response) {
      yield put(showToast({
        message: response.message,   //'OTP resent successfully.',
        type: 'success'
      }));
    }
  } catch (e) {
    yield put(showToast({
      message: e?.response?.data?.message,  //'Account verified successfully.',
      type: 'error'
    }));
  }
}

function* verifyEmail({ payload }) {
  console.log(payload)
  try {
    const response = yield call(VerifyEmail, payload)
    console.log(response)
    if (response) {

      yield put(verifyEmailSuccess('awd'))
    }
  } catch (e) {
    yield put(verifyEmailSuccess('error'))

  }
}



export function* watchUserPasswordForget() {
  yield takeEvery(FORGET_PASSWORD, forgetUser);
  yield takeEvery(RESET_PASSWORD, resetUserPassword);
  yield takeEvery(VERIFY_OTP, verifyOtP);
  yield takeEvery(RESEND_OTP, resendOTP);
  yield takeEvery(VERIFY_EMAIL, verifyEmail);

}

function* forgetPasswordSaga() {
  yield all([fork(watchUserPasswordForget)]);
}

export default forgetPasswordSaga;
