import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { EDIT_PROFILE, CHANGE_PASSWORD, USER_PROFILE_DATA, EDIT_PROFILE_DATA } from "./actionTypes";
import { profileSuccess, profileError, changePasswordSucess, changePasswordError, userProfileSuccess, editProfileDataSucess } from "./actions";
//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";
import {
  changePasswordData,
  userProfileData,
  userEditData
} from "../../../helpers/Auth";
import {
  postFakeProfile,
  postJwtProfile,
} from "../../../helpers/fakebackend_helper";

const fireBaseBackend = getFirebaseBackend();

function* editProfile({ payload: { user } }) {

  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(
        fireBaseBackend.editProfileAPI,
        user.username,
        user.idx
      );
      yield put(profileSuccess(response));
    } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const response = yield call(postJwtProfile, "/post-jwt-profile", {
        username: user.username,
        idx: user.idx,
      });
      yield put(profileSuccess(response));
    } else if (process.env.REACT_APP_API_URL) {
      const response = yield call(postFakeProfile, user);
      yield put(profileSuccess(response));
    }
  } catch (error) {
    yield put(profileError(error));
  }
}

function* changePassword({ payload: { user } }) {

  try {
    const response = yield call(changePasswordData, {
      oldPassword: user.password,
      newPassword: user.new_password
    });
    if (response?.payload.message === "Error") {
      yield put(changePasswordError(response.payload));
    } else {
      yield put(changePasswordSucess(response.payload));
    }

  } catch (error) {
    yield put(profileError(error));
  }
}



function* userProfile() {
  try {

    const response = yield call(userProfileData);
    yield put(userProfileSuccess(response.payload));

  } catch (error) {
    yield put(profileError(error));
  }
}


function* editProfileData({ payload: { user } }) {
  try {

    const response = yield call(userEditData, user);
    let dataGet = JSON.parse(window.localStorage.getItem("users"));
    response.payload[0].primaryColor = dataGet.primaryColor;
    sessionStorage.setItem("users", JSON.stringify(response.payload[0]));
    window.localStorage.setItem("users", JSON.stringify(response.payload[0]));
    // window.localStorage.setItem("users", JSON.stringify(response.payload[0]));
    yield put(editProfileDataSucess(response.payload));

  } catch (error) {
    yield put(profileError(error));
  }
}

export function* watchProfile() {
  yield takeEvery(EDIT_PROFILE, editProfile);
  yield takeEvery(CHANGE_PASSWORD, changePassword);
  yield takeEvery(USER_PROFILE_DATA, userProfile);
  yield takeEvery(EDIT_PROFILE_DATA, editProfileData);
}

function* ProfileSaga() {
  yield all([fork(watchProfile)]);
}

export default ProfileSaga;
