import {
  SHOW_TOAST,
  HIDE_TOAST,
  SHOW_LOADER
} from "./actionType";

const INIT_STATE = {
    loader: false,
    toast: {
      show: false,
      message: '',
      type: ''
    }
};

const Toast = (state = INIT_STATE, action) => {
  if (action.type === SHOW_TOAST) {
    return {
        ...state,
        toast: { show: true, ...action.payload }
    };
  }
  if (action.type === HIDE_TOAST) {
      return {
          ...state,
          toast: INIT_STATE.toast
      };
  }
  if (action.type === SHOW_LOADER) {
      return {
          ...state,
          loader: action.payload
      };
  }
  return state;
};

export default Toast;