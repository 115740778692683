import React, { useState, useEffect, useLayoutEffect } from 'react';
import { Button, Card, CardBody, Col, Container, Row, Form, Input, Label, FormFeedback, UncontrolledAlert } from 'reactstrap';
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link, useHistory, useLocation } from "react-router-dom";

//formik
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Constant from '../../utils/constant';
import { userResetPassword } from '../../store/actions';
import AuthLogo from '../../Components/Common/AuthLogo';
import CustomButton from '../../Components/Common/CustomButton';

const ResetPasswordPage = (props) => {

  document.title = "Reset Password | Vidhyutam";
  const dispatch = useDispatch();
  const history = useHistory();
  const { state } = useLocation();
  // const urlParams = new URLSearchParams(window.location.search);
  // const linkToken = urlParams.get('token');
  const [token, setToken] = useState('');
  const [passwordShow, setPasswordShow] = useState('password');
  const [confrimPasswordShow, setConfrimPasswordShow] = useState('password');
  
  // send user back to reset password if user comes back to otp
  useEffect(() => {
    if (window.localStorage.getItem('current') !== 'resetpassword') {
      history.push('/login');
    }
  }, [])

  useLayoutEffect(() => {
    if (window.sessionStorage.getItem('users') !== null) {
      history.push('/dashboard');
    }
  }, [])

  const togglerPassword = () => {
    if (passwordShow === "password") {
      setPasswordShow("text")
      return;
    }
    setPasswordShow("password")
  }
  const togglerConfirmPassword = () => {
    if (confrimPasswordShow === "password") {
      setConfrimPasswordShow("text")
      return;
    }
    setConfrimPasswordShow("password")
  }
  const { loading, userToken } = useSelector(state => ({
    loading: state.Toast.loader,
    userToken: state.ForgetPassword.verifyOtpSuccess
  }));
  

  useEffect(() => {
    // setToken(state?.state?.Token);
    //setToken(JSON.parse(window.localStorage.getItem('token')));
    setToken((userToken?.resetPasswordToken));
    
  }, [userToken]);

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object().shape({
      password: Yup.string()
        .required("Please enter your password")
        .min(8, 'Password must be in 8 to 16 characters')
        .max(16, 'Password must be in 8 to 16 characters')
        .matches(Constant.REGEX.UPPERCASE, 'At least 1 Capital letter')
        .matches(Constant.REGEX.LOWERCASE, 'At least 1 small letter')
        .matches(Constant.REGEX.NUMBER, 'At least 1 number')
        .matches(Constant.REGEX.SPECIALCHARACTERS, '1 Special character'),
      // .matches(RegExp('(.*[a-z].*)'), 'At least lowercase letter')
      // .matches(RegExp('(.*[A-Z].*)'), 'At least uppercase letter')
      // .matches(RegExp('(.*[0-9].*)'), 'At least one number'),
      confirmPassword: Yup.string()
        .when("password", {
          is: (val) => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref("password")],
            "Both passwords must match"
          ),
        })
        .required("Please enter your password"),
    }),
    onSubmit: (values) => {
      dispatch(userResetPassword(values, props.history, token))
      window.localStorage.setItem('current', 'resetpassword');
    }
  });
  return (
    <ParticlesAuth>
      <div className="auth-page-content reset-password">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="mt-4">

                <CardBody className="p-4">
                  <Row>
                    <Col lg={12}>
                      <div className="text-center mt-sm-2 mb-4 text-white-50">
                        <AuthLogo />
                      </div>
                    </Col>
                  </Row>
                  <div className="text-center mt-2">
                    <h5 className="text-success">Create new password</h5>
                    {/* <p className="text-muted">Please enter new password to reset your password</p> */}
                    <p className="text-muted">Please provide below mentioned details
                      to reset password.</p>
                  </div>

                  <div className="p-2">
                    <Form onSubmit={validation.handleSubmit} action="/login">
                      <div className="mb-3">
                        <Label className="form-label requierdLabel" htmlFor="password-input">New Password</Label>
                        <div className="position-relative auth-pass-inputgroup">
                          <Input
                            type={passwordShow}
                            className="form-control pe-5 password-input passwordError"
                            placeholder="Enter new password"
                            id="password-input"
                            name="password"
                            value={validation.values.password.trim()}
                            onKeyUp={validation.handleBlur}
                            onChange={validation.handleChange}
                            tabIndex={1}
                            invalid={validation.errors.password && validation.touched.password ? true : false}
                          />
                          <Button
                            color="link"
                            onClick={() => togglerPassword()}
                            className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                            type="button"
                            id="password-addon"
                          >
                            <i className={passwordShow === "password"
                              ? 'bx bx-hide align-middle'
                              : "ri-eye-fill align-middle"}></i>
                          </Button>
                          {validation.errors.password && validation.touched.password ? (
                            <>
                              {!validation.values.password && <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>}
                              {!Constant.REGEX.MIN_MAX_PASSWORD.test(validation.values.password) && validation.values.password && <FormFeedback type="invalid">Password must be in 8 to 16 characters</FormFeedback>}
                              {!Constant.REGEX.UPPERCASE.test(validation.values.password) && validation.values.password && <FormFeedback type="invalid">At least 1 Capital letter</FormFeedback>}
                              {!Constant.REGEX.LOWERCASE.test(validation.values.password) && validation.values.password && <FormFeedback type="invalid">At least 1 small letter</FormFeedback>}
                              {!Constant.REGEX.NUMBER.test(validation.values.password) && validation.values.password && <FormFeedback type="invalid">At least 1 number</FormFeedback>}
                              {!Constant.REGEX.SPECIALCHARACTERS.test(validation.values.password) && validation.values.password && <FormFeedback type="invalid">1 Special character</FormFeedback>}
                            </>
                          ) : null}
                        </div>
                        {/* <div id="passwordInput" className="form-text">Must be at least 8 characters.</div > */}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label requierdLabel" htmlFor="confirm-password-input">Confirm Password</Label>
                        <div className="position-relative auth-pass-inputgroup mb-3">
                          <Input
                            type={confrimPasswordShow}
                            className="form-control pe-5 password-input passwordError"
                            placeholder="Enter confirm password"
                            id="confirm-password-input"
                            name="confirmPassword"
                            value={validation.values.confirmPassword.trim()}
                            onChange={validation.handleChange}
                            onKeyUp={validation.handleBlur}
                            tabIndex={2}
                            invalid={validation.errors.confirmPassword && validation.touched.confirmPassword ? true : false}
                          />
                          <Button
                            color="link"
                            onClick={() => togglerConfirmPassword()}
                            className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                            type="button"
                            id="password-addon"
                          >
                            <i className={confrimPasswordShow === "password"
                              ? 'bx bx-hide align-middle'
                              : "ri-eye-fill align-middle"}></i>
                          </Button>
                          {validation.errors.confirmPassword && validation.touched.confirmPassword ? (
                            <FormFeedback type="invalid">{validation.errors.confirmPassword}</FormFeedback>
                          ) : null}
                          {/* <Button color="link" onClick={() => setConfrimPasswordShow(!confrimPasswordShow)} className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button">
                            <i className="ri-eye-fill align-middle"></i></Button> */}
                        </div>
                      </div>


                      <div className="mt-4">
                        <CustomButton
                          title='Reset Password'
                          disabled={!(validation.dirty && validation.isValid)}
                          type='submit'
                          loading={loading}
                          className='submitBTN'
                        />
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-4 text-center">
                <p className="mb-0" onClick={() => { window.localStorage.removeItem('current') }}>Back to <Link to="/login" className="text-success text-decoration-underline"> Sign-in </Link> </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </ParticlesAuth>
  );
};

export default ResetPasswordPage; 