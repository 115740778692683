import React, { useMemo, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Col, Container, Input, Modal, ModalBody, ModalHeader, Row, Label } from 'reactstrap';

//import Components
import { useDispatch, useSelector } from 'react-redux';
import { showToast, showLoader } from '../../../store/actions';
import TableContainer from '../../../Components/Common/TableContainer';
import { allBusinessList, businessResendInvite, getBusinessDetails, listAllStation, allLocation, getStatConnector } from "../../../store/actions";
import CustomButton from '../../../Components/Common/CustomButton';
import { getValueFromObject, getUserRole } from '../../../helpers/helper.service';
import useWindowDimension from '../../../Components/Hooks/useWindowDimension';
import ContentLoader from '../../../Components/Common/ContentLoader';
import CustomDropdown from '../../../Components/Common/CustomDropdown';
import ReactInputMask from 'react-input-mask';
import { decryptResponseData } from '../../../helpers/encryptDecrypt'


const Businesses = (props) => {
    document.title = "Businesses | Vidhyutam";
    const history = useHistory();
    const dispatch = useDispatch();
    const dimensions = useWindowDimension();
    const [headerHeight, setHeaderHeight] = useState(0);
    const [footerHeight, setFooterHeight] = useState(0);
    const [tableTaskBar, setTableTaskBar] = useState(0);
    const [paginationDiv, setPaginationDiv] = useState(0);
    const [cardHeader, setCardHeaderHeight] = useState(0);
    const [busssinessData, setBusinessData] = useState('');
    const [noOfLocation, setNoOfLocation] = useState('');
    const [noOfStation, setNoOfStation] = useState('');
    const [noOfBusiness, setNoOfBusiness] = useState('');
    const [businessListLoader, setBusinessListLoader] = useState(false)
    const [filteredTable, setfilteredTable] = useState([]);




    const [businessFilterModel, setBusinessFilterModel] = useState(false);
    const [emailFilter, setEmailFilter] = useState('');
    const [phoneFilter, setPhoneFilter] = useState('');
    const [activeInactiveFilter, setActiveInactiveFilter] = useState(false);

    const [locationListDAta, setLocationListDAta] = useState([]);
    const [stationListDAta, setStationListDAta] = useState([]);
    const [connectorListDAta, setConnectorListDAta] = useState([]);

    const [getLocId, setGetLocId] = useState('');
    const [getStaId, setGetStaId] = useState('');
    const [getConId, setGetConId] = useState('');
    const [filterlocationId, setFilterlocationId] = useState('');
    const [filterstationId, setFilterstationId] = useState('');
    const [filterConnectorId, setFilterconnectorId] = useState('');

    const [buttonFilter, setButtonFilter] = useState(false)
    const [statusDataselect, setStatusDataselect] = useState('');

    // handle filtered table counting
    const handleFilterCount = () => {
        let count = 0;
        let stationcount = 0;
        setNoOfBusiness(filteredTable.length);
        filteredTable.forEach((item) => {
            count = count + item.original.nLocations;
            stationcount = stationcount + item.original.nStations;
        })
        setNoOfLocation(count);
        setNoOfStation(stationcount);

    }

    // stop loader on page load 
    useEffect(() => {
        dispatch(showLoader(false))
    }, [])

    useEffect(() => {
        handleFilterCount();
    }, [filteredTable])

    useEffect(() => {
    }, [noOfLocation])

    useEffect(() => {
        setTimeout(() => {
            setBusinessListLoader(true)
        }, 2000)
    }, [])
    useEffect(() => {
        setHeaderHeight(
            document.getElementsByClassName('navbar-header')[0]?.offsetHeight
        );
        setFooterHeight(
            document.getElementsByClassName('footer')[0]?.offsetHeight
        );
        setTableTaskBar(
            document.getElementsByClassName('tableTaskBar')[0]?.offsetHeight
        );
        setPaginationDiv(
            document.getElementsByClassName('paginationDiv')[0]?.offsetHeight
        );
        setCardHeaderHeight(
            document.getElementsByClassName('cardHeader')[0]?.offsetHeight
        );
    }, []);
    const handleResize = () => {

        setHeaderHeight(
            document.getElementsByClassName('navbar-header')[0]?.offsetHeight
        );
        setFooterHeight(
            document.getElementsByClassName('footer')[0]?.offsetHeight
        );
        setTableTaskBar(
            document.getElementsByClassName('tableTaskBar')[0]?.offsetHeight
        );
        setPaginationDiv(
            document.getElementsByClassName('paginationDiv')[0]?.offsetHeight
        );
        setCardHeaderHeight(
            document.getElementsByClassName('cardHeader')[0]?.offsetHeight
        );
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        dispatch(allBusinessList('?id='));
    }, []);

    const { businessList, locationList, stationDetail, getStationConnector } = useSelector((state) => ({
        businessList: state?.businessList?.allBusinessList
            .filter(i => i?.status !== "Deleted") // Filter out deleted businesses
            .map(row => ({
                ...row,
                businessName: decryptResponseData(row.businessName),
                firstName: decryptResponseData(row.firstName),
                lastName: decryptResponseData(row.lastName),
                email: decryptResponseData(row.email),
            })),
        // locationList: state?.locationsList?.allLocationList.map(row => ({
        //     ...row,
        //     locationName: decryptResponseData(row.locationName),
        // })),
        // stationDetail: state?.ManageStations?.listAllStation.map(row => ({
        //     ...row,
        //     stationName: decryptResponseData(row.stationName),
        // })),
        // getStationConnector: state?.ManageStations?.getStationConn?.data,

        locationList: state?.locationsList?.allLocationList,
        // businessList: state?.businessList?.allBusinessList,
        stationDetail: state?.ManageStations?.listAllStation,
        getStationConnector: state?.ManageStations?.getStationConn?.data,

    }));

    useEffect(() => {
        dispatch(allLocation('?id='));

    }, []);

    useEffect(() => {
        if (businessList.length > 0) {
            const numOfLoca = businessList?.map((e, i) => e.nLocations).reduce((Acc, e) => Acc + e, 0);
            setNoOfLocation(numOfLoca);

            const numOfStat = businessList?.map((e, i) => e.nStations).reduce((Acc, e) => Acc + e, 0);
            setNoOfStation(numOfStat);
            setNoOfBusiness(businessList.length);
        }
    }, [businessList])


    useEffect(() => {
        if (businessList.length > 0) {
            const numOfLoca = businessList?.map((e, i) => e.nLocations).reduce((Acc, e) => Acc + e, 0);
            setNoOfLocation(numOfLoca);

            const numOfStat = businessList?.map((e, i) => e.nStations).reduce((Acc, e) => Acc + e, 0);
            setNoOfStation(numOfStat);
            setNoOfBusiness(businessList.length);
        }
    }, [businessList])
    useEffect(() => {
        const setLocationFilter = () => {
            const TempList = locationList?.map((eve, ind) => {
                return {
                    id: `${ind + 1}`,
                    value: eve.locationName,
                    LocationID: eve.locationId
                }
            })
            return TempList;
        }
        const LocationT = setLocationFilter()
        setLocationListDAta(LocationT);

    }, [locationList]);


    useEffect(() => {
        const setStationFilter = () => {
            const TempList = stationDetail?.map((eve, ind) => {

                return {
                    id: `${ind + 1}`,
                    value: eve.stationName,
                    StationId: eve.stationId
                }
            })
            return TempList;
        }
        const LocationT = setStationFilter()
        setStationListDAta(LocationT);

    }, [stationDetail]);


    useEffect(() => {
        const setConnectorFilter = () => {
            const TempList = getStationConnector?.map((eve, ind) => {

                return {
                    id: `${ind + 1}`,
                    value: '#' + eve.connectorNumber,
                    connectorId: eve.connectorId
                }
            })
            return TempList;
        }
        const ConnectorT = setConnectorFilter()
        setConnectorListDAta(ConnectorT);

    }, [getStationConnector]);


    const statusData = [{ id: '1', value: 'Active' }, { id: '2', value: 'Inactive' }]

    const handleViewDetailClick = (e) => {
        history.push('/businesses/businessesdetail', {
            state: {
                value: e.original.businessId
            }
        });
    }
    const handleAddButtonClicks = () => {
        history.push('/businesses/addbusiness');
    }

    // custom sort fn
    const handleSort = React.useMemo(() => {
        return (rowA, rowB, columnId, desc) => {

            return rowA.values.businessName.toLowerCase() > rowB.values.businessName.toLowerCase() ? 1 : -1;
        }
    }, [])

    // Column
    const columns1 = useMemo(
        () => [
            {
                Header: dimensions.width > 1280 ? "Business ID" : "Business Details",
                accessor: d => `${d.prefix + d.rBusinessId}`,
                filterable: true,
                show: true,
                Cell: (cell) => {
                    return (dimensions.width > 1280 ? <><p className='text textOverflow'>{cell.row.original.prefix + cell.row.original.rBusinessId}</p></> :
                        <p className='text textOverflow'>{cell.row.original.prefix + cell.row.original.rBusinessId}
                            <p className='text textOverflow'>{cell?.row?.original?.businessName}</p></p>);
                },
            },
            dimensions.width > 1280 ? {
                Header: `Business Name (${noOfBusiness ? noOfBusiness : '0'})`,
                accessor: "businessName",
                filterable: true,
                sortType: handleSort,
                show: true,
                Cell: (cell) => {
                    return <p className='text textOverflow'>{cell.value}</p>;
                },
            } : {},
            {
                Header: "Business Owner",
                accessor: d => `${d.lastName} ${d.firstName} ${d.email} ${d.contactnumber}`,
                filterable: true,
                disableSortBy: true,
                // show: getUserRole(),
                show: window.localStorage.getItem('users') !== undefined && window.localStorage.getItem('users') !== null && JSON.parse(window.localStorage.getItem('users')).userRole !== 'BusinessOwner',
                Cell: (businessOwner) => {
                    return <div className='managerBox'>
                        <p className='name wordBreakAll textOverflow'>{getValueFromObject('firstName', businessOwner.row.original, '')} {getValueFromObject('lastName', businessOwner.row.original, '')}</p>
                        <p className='email textOverflow wordBreakAll'>{getValueFromObject('email', businessOwner.row.original, '')}</p>
                        {/* <p className='number'>{getValueFromObject('countryCode', businessOwner.row.original, '')} {getValueFromObject('contactnumber', businessOwner.row.original, '')}</p> */}
                        <p className='number'>{businessOwner.row.original.contactNumber ? `+${businessOwner.row.original.countryCode} ${businessOwner.row.original.contactNumber}` : ''}</p>
                    </div>
                },
            },
            {
                Header: (e) => {
                    return dimensions.width > 1280
                        ? `Location (${noOfLocation ? noOfLocation : '0'})`
                        : `Counts (${noOfLocation ? noOfLocation : '0'})`;
                },
                accessor: "nLocations",
                filterable: true,
                disableGlobalFilter: true,
                show: true,
                Cell: (cell) => {
                    return (
                        dimensions.width > 1280 ? <>
                            <p className="text textOverflow">
                                {cell.value}
                            </p></> :
                            <>
                                <p className="text textOverflow">
                                    Location : {cell.value}

                                </p>
                                <p className="text textOverflow">
                                    Station : {cell?.row?.original?.nStations}

                                </p>
                            </>
                    );
                }
            },
            dimensions.width > 1280 ? {
                Header: (e) => `Station (${noOfStation ? noOfStation : '0'})`,
                accessor: "nStations",
                filterable: true,
                disableGlobalFilter: true,
                show: true,
                Cell: (cell) => {
                    return <p className='text textOverflow'>{cell.value}</p>;
                },
            } : {},
            {
                Header: "Action",
                // show: getUserRole(),
                show: window.localStorage.getItem('users') !== undefined && window.localStorage.getItem('users') !== null && JSON.parse(window.localStorage.getItem('users')).userRole !== 'BusinessOwner',
                disableSortBy: true,
                Cell: (item) => {
                    return (
                        <ul className="list-inline hstack gap-2 mb-0" onClick={(e) => { e.stopPropagation() }}>
                            <li className="list-inline-item">
                                {
                                    (item.row.original.isVerified === 0 &&

                                        <CustomButton
                                            // disabled={item.row.original.checked === false ? true : false}
                                            title='Resend invite'
                                            type='button'
                                            loading={false}
                                            className='resendBtn'
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                dispatch(businessResendInvite({ businessId: item.row.original.businessId }));
                                            }}
                                        />
                                    ) || '-'

                                }
                            </li>
                        </ul>
                    );
                },
            },
        ], [history, businessList, busssinessData.checked, noOfLocation, noOfStation, noOfBusiness, handleSort]
    );
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <div className='businessPage'>
                        <Row>
                            <Col lg={12}>
                                <Card className='mb-0'>
                                    <CardHeader className='cardHeader'>
                                        <p className='headerTxt'>Manage Businesses</p>
                                    </CardHeader>
                                    <CardBody>
                                        <div className={filteredTable.length > 0 && businessList.length > 0 ? 'table-content' : 'table-content disablePointer'}>
                                            {businessListLoader ?
                                                <TableContainer
                                                    onClickCustomFilter={() => { setBusinessFilterModel(true); }}
                                                    customFilter={JSON.parse(window.localStorage.getItem('users')).userRole === 'SuperAdmin' ? true : false}
                                                    setfilteredTable={setfilteredTable}
                                                    columns={columns1.filter((a) => a.show)}
                                                    data={businessList}
                                                    isGlobalFilter={true}
                                                    customPageSize={8}
                                                    customFilterButton={buttonFilter}

                                                    handleViewDetailClick={(row) => handleViewDetailClick(row)}
                                                    maxHeight={dimensions.height - headerHeight - footerHeight - tableTaskBar - paginationDiv - cardHeader - 105}
                                                    // divClass={getUserRole() ? "table-responsive mb-1 bussinessTable" : "table-responsive mb-1 bussinessTable businessOwner"}
                                                    divClass={window.localStorage.getItem('users') !== undefined && window.localStorage.getItem('users') !== null && JSON.parse(window.localStorage.getItem('users')).userRole !== 'BusinessOwner' ? "table-responsive mb-1 bussinessTable" : "table-responsive mb-1 bussinessTable businessOwner"}
                                                    tableClass="align-middle table-nowrap"
                                                    theadClass="table-light text-muted"
                                                    // isAddButton={true && getUserRole()}
                                                    isAddButton={true && window.localStorage.getItem('users') !== undefined && window.localStorage.getItem('users') !== null && JSON.parse(window.localStorage.getItem('users')).userRole !== 'BusinessOwner'}
                                                    searchPlaceholder={getUserRole() ? 'Search Business ID, Business Name, Business Owner' : 'Search Business ID, Business Name'}
                                                    handleAddButtonClicks={handleAddButtonClicks}
                                                    addButtonTitle='Add Business'
                                                    noData={businessList.length > 0 ? false : true}
                                                    // noDataImage={"https://res.cloudinary.com/djyl1goby/image/upload/v1670402495/Lubi/WebApp/Static-images/no_data_found.svg"}
                                                    noDataImage={"https://res.cloudinary.com/https-www-nimblechapps-com/image/upload/v1688013508/Lubi/WebApp/Static-images/no_data_found.svg"}
                                                    noDataTitle={'No Data Found!'}
                                                />
                                                : <ContentLoader />}
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>


            <Modal className='tariffModal' isOpen={businessFilterModel}
                centered={true}>
                <ModalHeader>
                    <p>Filter</p>
                    <i className='bx bx-x' onClick={() => {
                        setBusinessFilterModel(false)
                    }} />
                </ModalHeader>
                <ModalBody className="">
                    <div className='qrMain'>
                        <div className='fleet-filter-view'>
                            <Row>
                                <Col lg={6}>
                                    <div className="lg-6">
                                        <Label>Email</Label>
                                        <Input
                                            name='connectorType'
                                            type="email"
                                            className="form-control"
                                            id="connectorType"
                                            placeholder="Enter Email"
                                            value={emailFilter || ''}
                                            onChange={(e) => {
                                                // console.log(e.target.value, '======>')
                                                setEmailFilter(e.target.value)
                                            }}
                                        />
                                    </div>
                                </Col>

                                <Col lg={6}>
                                    <div className="lg-6" >
                                        <Label>Business Status</Label>
                                        <CustomDropdown
                                            className='allDropdowns'
                                            moreValue={true}
                                            dropDownItems={statusData}
                                            defaultValue={statusDataselect}
                                            placeholder={'Choose Status'}
                                            selectedValue={statusDataselect}
                                            showSecondVal={false}
                                            onSelect={evt => {
                                                setStatusDataselect(evt.value)
                                                // setFilterconnectorId(evt.connectorId)
                                            }}
                                        />
                                    </div>
                                </Col>

                                {/* <Col lg={6} className='dateInputRowStyle'>
                                    <div className="lg-6">
                                        <Label>Phone No.</Label>
                                        <ReactInputMask
                                            mask={"+\\91 9999999999"}
                                            maskChar={""}
                                            name="contactNumber"
                                            className={
                                                "form-control"
                                            }
                                            id="phonenumberInput"
                                            placeholder="Enter Contact Number"
                                            value={phoneFilter || ''}
                                            onChange={(e) => {
                                                // console.log(e.target.value)
                                                setPhoneFilter(e.target.value)
                                            }}
                                        />
                                    </div>
                                </Col> */}
                            </Row>

                            <></>
                            <Row style={{ marginTop: '15px' }}>
                                <Col lg={6}>
                                    <div className="lg-6" >
                                        <Label>Location</Label>
                                        <CustomDropdown
                                            className='allDropdowns'
                                            moreValue={true}
                                            dropDownItems={locationListDAta.map(row => ({
                                                ...row,
                                                value: decryptResponseData(row.value),
                                            }))}
                                            defaultValue={getLocId}
                                            placeholder={'Choose Location'}
                                            selectedValue={getLocId}
                                            showSecondVal={false}
                                            onSelect={evt => {
                                                setGetLocId(evt.value);
                                                setFilterlocationId(evt.LocationID)

                                                dispatch(listAllStation(`?locationId=${evt.LocationID}`));
                                                setGetStaId('')
                                                setGetConId('')
                                                setConnectorListDAta([])
                                                // // getUserRole() !== 'LM' && setSelectedGroup1(evt);
                                                // setSelectedGroup1(evt);
                                                // addStationForm.handleChange('location')(evt.value)
                                                // setStaReadOnly(true);
                                            }}
                                        />
                                    </div>
                                </Col>

                                <Col lg={6}>
                                    <div className="lg-6" >
                                        <Label>Station</Label>
                                        <CustomDropdown
                                            className='allDropdowns'
                                            moreValue={true}
                                            dropDownItems={stationListDAta}
                                            defaultValue={getStaId}
                                            placeholder={'Choose Station'}
                                            selectedValue={getStaId}
                                            showSecondVal={false}
                                            onSelect={evt => {
                                                setFilterstationId(evt.StationId)
                                                setGetStaId(evt.value);
                                                dispatch(getStatConnector(evt.StationId));


                                            }}
                                        />
                                    </div>
                                </Col>
                            </Row>

                            <></>
                            <Row style={{ marginTop: '15px' }}>

                                <Col lg={6}>
                                    <div className="lg-6" >
                                        <Label>Connector</Label>
                                        <CustomDropdown
                                            className='allDropdowns'
                                            moreValue={true}
                                            dropDownItems={connectorListDAta}
                                            defaultValue={getConId}
                                            placeholder={'Choose Connector'}
                                            selectedValue={getConId}
                                            showSecondVal={false}
                                            onSelect={evt => {
                                                console.log(evt.value)
                                                setGetConId(evt.value)
                                                setFilterconnectorId(evt.connectorId)

                                            }}
                                        />
                                    </div>
                                </Col>





                            </Row>

                        </div>
                    </div>
                    <div className="assignMain">
                        <div className="hstack gap-2  mt-2 justify-content-end">
                            <Button
                                onClick={() => {
                                    setButtonFilter(false)

                                    setActiveInactiveFilter(false)
                                    setEmailFilter('')
                                    setPhoneFilter('')
                                    setGetLocId('')
                                    setGetStaId('')
                                    setGetConId('')
                                    dispatch(allBusinessList('?id='));
                                    setStatusDataselect('')
                                    setBusinessFilterModel(false)
                                    setFilterlocationId('');
                                    setFilterstationId('');
                                    setFilterconnectorId('');
                                    setStationListDAta([])
                                    setConnectorListDAta([])
                                }}
                            >
                                Clear
                            </Button>
                            <Button
                                color={(filterlocationId === '' && filterstationId === '' && filterConnectorId === '' && emailFilter === '' && statusDataselect === '') ? 'secondary' : 'success'}
                                type="submit"
                                disabled={filterlocationId === '' && filterstationId === '' && filterConnectorId === '' && emailFilter === '' && statusDataselect === ''}
                                onClick={() => {
                                    let queryParams = '?';

                                    setButtonFilter(true)


                                    const params = [];

                                    if (statusDataselect) {
                                        params.push(`status=${statusDataselect}`);
                                    }
                                    if (emailFilter) {
                                        params.push(`email=${emailFilter}`);
                                    }
                                    // if (phoneFilter) {
                                    //     params.push(`phone=${phoneFilter.split(' ')[1]}`);
                                    // }
                                    if (filterlocationId) {
                                        params.push(`locationId=${filterlocationId}`);
                                    }
                                    if (filterstationId) {
                                        params.push(`stationId=${filterstationId}`);
                                    }
                                    if (filterConnectorId) {
                                        params.push(`connectorId=${filterConnectorId}`);
                                    }

                                    queryParams += params.join('&');


                                    dispatch(allBusinessList(queryParams));



                                    setBusinessFilterModel(false)
                                }}
                            >
                                Apply
                            </Button>
                        </div>
                    </div>
                </ModalBody>
            </Modal>

        </React.Fragment>
    );
};

export default Businesses;