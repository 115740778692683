import React from 'react';
import PropTypes from 'prop-types';
import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'

const CustomDropdownAvtar = (props) => {
    let { selectedValue, dropDownItems = [], placeholder, onSelect, defaultValue, disabled, moreValue, id } = props;
    const getTitle = () => {
        if (selectedValue && selectedValue.id) {
            const item = dropDownItems.find(i => i.id === selectedValue.id || i.name === selectedValue)
            return (item && <img className='selectedImg' src={item.src} alt='' />) || placeholder
        } else {
            return defaultValue ? defaultValue : placeholder
        }
    }

    return (
        <div className="customDropdownAvtar">
            <DropdownButton
                defaultValue={defaultValue}
                disabled={disabled}
                key={selectedValue?.id}
                id={`dropdown-variants-${selectedValue?.id}${id}`}
                title={getTitle()}
                onSelect={(evt) => {
                    onSelect(dropDownItems.find(i => evt === i.id))
                }}
                className={selectedValue ? "selected" : ""}
            >
                <div className="dropdownData">
                    {dropDownItems?.map((item) => <Dropdown.Item eventKey={item.id} key={`${item.id}`} >
                        <img src={item.src} alt='' className='connectorTypeAvtar' />
                    </Dropdown.Item>)}
                </div>
            </DropdownButton>
        </div>
    )
}

CustomDropdownAvtar.propTypes = {
    onClick: PropTypes.func
};

export default CustomDropdownAvtar;
